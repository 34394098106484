import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { confirmAlert } from "react-confirm-alert";

import { BetService } from "../service/bet.service";

import { ProgressMiniTable } from "../share/progress/progress-mini-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  begin: Date;
  end: Date;
  userTableValue: any[];

  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  cities: any[];
  selectViewType: string;

  viewType: any[];
  selectType?: any;
  miniOption?: any;
  summary?: any;
}

export class EndMini extends Component<any, State> {
  betService = new BetService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],
    };

    this.handleGetMiniGameList();

    this.headerGetEndMiniGame(
      1,
      "categoryId",
      this.state.selectType,
      this.state.selectViewType
    );
  }

  componentDidMount() { }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.headerGetEndMiniGame(
      page,
      "categoryId",
      this.state.selectType,
      this.state.selectViewType
    );
  };

  headerSelectType = (select: any) => {
    this.setState({ selectType: select });
    this.headerGetEndMiniGame(
      1,
      "categoryId",
      select,
      this.state.selectViewType
    );
  };

  handleGetMiniGameList = () => {
    this.betService.get_mini_game_list().then((data) => {
      let options = [{ label: "전체", value: "all" }];
      for (let option of data.miniOption) {
        options.push({ label: option.categoryName, value: option.categoryId });
      }
      this.setState({ miniOption: options });
    });
  };

  headerGetEndMiniGame = (
    page: any,
    findText: string,
    searchText: string,
    mode: string
  ) => {
    let item = searchText;
    if (searchText == "all") {
      item = "";
    }

    this.betService
      .get_end_mini_game(page, findText, item, mode)
      .then((data) => {
        this.setState({
          userTableValue: data.minis,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  // handlePageChange = page => {
  //   this.setState({ page: page });
  //   this.handlePageChange(page);
  // };

  handleOpenDetail = (matchId: string) => {
    this.handlePageChange(matchId);
  };

  handleMiniRecovery = (id: string) => {
    confirmAlert({
      title: "미니 게임",
      message: "미니게임 배팅을 복원 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.betService.admin_mini_recovery(id).then((data) => {
              this.setState({
                userTableValue: data.minis,
                maxCount: data.maxCount,
                summary: data.summary,
              });
              window.location.reload();
            });
          },
        },
        {
          label: "취소",
          onClick: () => {
            window.location.reload();

          },
        },
      ],
    });
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      //   return userTableValue.map((contact, i) => {
      return (
        <ProgressMiniTable
          mode={"end"}
          game={userTableValue}
          handleSelectWinGame={(
            par1: any,
            par2: any,
            par3: any,
            par4: any,
            par5: any,
            par6: any
          ) => { }}
          //   key={contact._id}
          handleOpenDetail={this.handleOpenDetail}
          handleMiniDo={this.handleMiniRecovery}
          handleEndBet={() => { }}

        ></ProgressMiniTable>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12 ">
              <div className="card">
                <div className="p-col-12 ">
                  <div className="card-title">종료된 미니 게임</div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-8">
                      <SelectButton
                        className={"p-buttonset-1"}
                        options={this.state.miniOption}
                        value={this.state.selectType}
                        onChange={(event) => {
                          this.setState({ selectType: event.value });

                          this.headerSelectType(event.value);
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Button
                          label="마감 취소"
                          onClick={() => {
                            this.handlePageChange(0);
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-6">
                      <SelectButton
                        value={this.state.selectViewType}
                        options={this.state.viewType}
                        onChange={(event) => {
                          this.setState({ selectViewType: event.value });
                          this.headerGetEndMiniGame(
                            1,
                            "categoryId",
                            this.state.selectType,
                            event.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="p-col-12">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
