import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
import { confirmAlert } from "react-confirm-alert";

// import ReactPaginate from 'react-paginate';
import { BetService } from "../../service/bet.service";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  OpenUserDetail,
} from "../../utility/help";

interface MiniProps {
  bets: any;

}

interface MiniState {
  active: any;
  deposits: any;
  page: any;
  pages: any;
  field: any;
}

export class BetMiniTable extends Component<MiniProps, MiniState> {

  betService = new BetService();

  constructor(props: MiniProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      page: 0,
      pages: {},
      field: "",
    };
  }

  componentDidMount = () => { };


  handleCanselBet = (idx) => {

    confirmAlert({
      title: "미니 게임",
      message: "미니게임 취소 하시곘습니까.",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.betService
              .admin_mini_cancel_by_user(idx)
              .then((data) => {
                if (data.status == "success") {
                  confirmAlert({
                    title: "미니 게임",
                    message: "배팅이 취소되었습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();

                        },
                      },
                    ],
                  });
                }
                else {
                  confirmAlert({
                    title: "미니 게임",
                    message: "배팅을 취소할수 없습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();

                        },
                      },
                    ],
                  });

                }

              });

          },
        },
        {
          label: "취소",
          onClick: () => {
            window.location.reload();

          },
        },
      ],
    });

  }


  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    // const mode = this.props.mode;
    const bets = this.props.bets;
    // const OnEditItem = this.props.OnEditItem;
    // const OnSelectItem = this.props.OnSelectItem;

    return (
      <DataTable value={bets} rows={20}>
        <Column
          style={{ textAlign: "center" }}
          header="에피소드"
          field="id"
          body={(rowData: any, column: any) => {
            return <div>{rowData.episode}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="아이디"
          field="id"
          body={(rowData: any, column: any) => {
            return (
              <div
                style={{ color: rowData.color }}
                onClick={() => {
                  OpenUserDetail(rowData.id);
                }}
              >
                {rowData.id}
                <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          field="bet"
          header="배팅금"
          body={(rowData: any, column: any) => {
            return (
              <div>
                <div>{numTomoeny(rowData.bet)}</div>
              </div>
            );
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          field="win"
          header="이긴금액"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.win)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="보유금"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.balance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="회사"
          body={(rowData: any, column: any) => {
            return <div>{rowData.categoryName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="배팅타입"
          body={(rowData: any, column: any) => {
            return <div>{rowData.marketName}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="유저배팅"
          body={(rowData: any, column: any) => {
            return <div>{rowData.betName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="결과"
          body={(rowData: any, column: any) => {
            return <div>{rowData.resultName}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="에이전트"
          body={(rowData: any, column: any) => {
            return (
              <div>
                <div>{rowData.agent_tree}</div>
                <br />
                <div>{rowData.agent_roll}</div>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="배팅일"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: getDepositColor() }}>
                {convertDate(rowData.betdate)}
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="취소"
          body={(rowData: any, column: any) => {

            return (
              <Button
                style={{ backgroundColor: "#dc3545" }}
                label={"취소"}
                onClick={() => {
                  this.handleCanselBet(rowData._id);
                  // this.headerDepositAndWithdraw(
                  //   this.state.id,
                  //   0,
                  //   this.state.selectType,
                  //   this.state.begin,
                  //   this.state.end
                  // );
                }}
              />
            )

          }}
        />
      </DataTable>
    );
  };
}
