import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { AgentBasic } from "../share/agentdetail/agent-basic";
import { AgentBalanceLog } from "../share/agentdetail/agent-balance-log";
import { AgentBalanceLogKor } from "../share/agentdetail/agent-balance-log-kor";
import { AgentLogLogin } from "../share/agentdetail/agent-loglogin";

import { AgentBalance } from "../share/agentdetail/agnet-balance";
import { AgentBenefit } from "../share/agentdetail/agent-benefit";

import { AgentCalculate } from "../share/agentdetail/agent-calculate";

import { AgentService } from "../service/agent.service";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converAgentStatus,
  ConvertNick
} from "../utility/help";

interface Props {
  agentId: string;
}

interface State {
  agent: any;
  userTableValue: any[];
  page: number;
  pages: any;
  field: string;
  checkboxValue: any[];
}

export class AgentDetail extends Component<Props, State> {
  agentService: AgentService = new AgentService();
  constructor(props: Props) {
    super(props);
    this.state = {
      agent: {},
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      checkboxValue: [],
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount() {
    {
      process.env.REACT_APP_API_SHOW_PASS === 'Y' ? (
        this.agentService
          .admin_get_agnet_detail_pass(this.props.agentId)
          .then((data) => {
            if (data.status == "success") {
              this.setState({ agent: data.agent });
            }
          })
      ) : (
        this.agentService
          .admin_get_agnet_detail(this.props.agentId)
          .then((data) => {
            if (data.status == "success") {
              this.setState({ agent: data.agent });
            }
          })
      )

    }
  }

  handleUserChange = (e: any) => {
    console.log(e.target)
    if (this.state.agent[e.target.id] != undefined) {
      this.state.agent[e.target.id] = e.target.value;

      this.setState({ agent: this.state.agent });
    }
  };

  render() {
    let agent = this.state.agent;
    if (agent == undefined || agent.agent == undefined) {
      return <div></div>;
    }

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-3">
              <div className="card card-w-title">
                <div className="card-title">에이전트 기본정보</div>
                <div className="p-grid">

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">트리</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <span dangerouslySetInnerHTML={{ __html: ConvertNick(agent.agent_tree, agent.agent_tree_ko) }} ></span>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">아이디</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px",
                        color: agent.color
                      }}
                    >
                      {agent.agent}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">비밀번호</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p style={{ textAlign: "right", marginBottom: "0px" }}>
                      {agent.pass}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">전화번호</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p style={{ textAlign: "right", marginBottom: "0px" }}>
                      {agent.phone}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">회원상태</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p style={{ textAlign: "right", marginBottom: "0px" }}>
                      {converAgentStatus(agent.status)}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">벨런스</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px",
                        color: "green",
                      }}
                    >
                      {numTomoeny(agent.balance)}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">포인트</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px",
                        color: "green",
                      }}
                    >
                      {numTomoeny(agent.point)}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">롤링</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px",
                        color: "green",
                      }}
                    >
                      {numTomoeny(agent.rolling)}
                    </p>
                  </div>

                  <div className="p-col-12 p-md-4">
                    <label htmlFor="input">루징</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <p
                      style={{
                        textAlign: "right",
                        marginBottom: "0px",
                        color: "green",
                      }}
                    >
                      {numTomoeny(agent.losing)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-9">
              {/* <div className="card card-w-title"> */}
              <TabView>
                <TabPanel header="기본 정보">
                  <AgentBasic
                    agent={agent}
                    handleUserChange={this.handleUserChange}
                  ></AgentBasic>
                </TabPanel>

                <TabPanel header="정산 배당설정">
                  <AgentBenefit agentId={agent.agent}></AgentBenefit>
                </TabPanel>

                <TabPanel header="로그인 기록">
                  <AgentLogLogin agentid={agent.agent}></AgentLogLogin>
                </TabPanel>


                <TabPanel header="머니이동">

                  {
                    (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOR' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOM' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'WINLOSE' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KCROWN' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINO'
                    ) ? (
                      <AgentBalanceLogKor agentid={agent.agent}></AgentBalanceLogKor>
                    ) : (
                      <AgentBalanceLog agentid={agent.agent}></AgentBalanceLog>
                    )}
                </TabPanel>


                <TabPanel header="에이전트 지급/회수">
                  <AgentBalance id={agent.agent}></AgentBalance>
                </TabPanel>

                <TabPanel header="정산내역">
                  <AgentCalculate id={agent.agent}></AgentCalculate>
                </TabPanel>
              </TabView>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
