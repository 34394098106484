import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";
import { isMobile, isMobileOnly } from "react-device-detect";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  begin: Date;
  end: Date;
  benefits: any[];
  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  maxLevel: number;
  levels: any[];
}

export class Level extends Component<any, State> {
  adminService: AdminService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      benefits: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      maxLevel: 8,
      levels: [

        { type: "first_deposit_fix", text: "첫충전 특정금액이상시 보너스 고정" },
        { type: "again_deposit_fix", text: "재충전 특정금액이상시 보너스 고정" },
        { type: "deposit", text: "충전신청시 보너스" },
        { type: "deposit_again", text: "재충전 충전신청시 보너스" },
        // { type: "withdraw", text: "환전신청시 보너스" },

        { type: "depositfriend", text: "추천인 충전신청시 보너스" },
        { type: "withdrawfriend", text: "추천인  환전신청시 보너스" },

        { type: "sportwserwin", text: "스포츠 당첨시 지급" },
        { type: "sportuserlose", text: "스포츠 낙점시 지급" },
        { type: "sportfriendlose", text: "스포츠 당첨시 추천인 지급" },
        { type: "sportfriendwin", text: "스포츠 낙점시 추천인 지급" },

        { type: "miniwin", text: "미니게임 당첨시 지급" },
        { type: "minilose", text: "미니게임 낙점시 지급" },
        { type: "minifriendlose", text: "미니게임 당첨시 추천인 지급" },
        { type: "minifriendwin", text: "미니게임 낙점시 추천인 지급" },

        { type: "livewin", text: "라이브 당첨시 지급" },
        { type: "livelose", text: "라이브 낙점시 지급" },
        { type: "livefriendlose", text: "라이브 당첨시 추천인 지급" },
        { type: "livefriendwin", text: "라이브 낙점시 추천인 지급" },

        { type: "casinowin", text: "카지노 당첨시 지급" },
        { type: "casinolose", text: "카지노 낙점시 지급" },
        { type: "casinofriendlose", text: "카지노 당첨시 추천인 지급" },
        { type: "casinofriendwin", text: "카지노 낙점시 추천인 지급" },

        { type: "slotwin", text: "슬롯 당첨시 지급" },
        { type: "slotlose", text: "슬롯 낙점시 지급" },
        { type: "slotfriendlose", text: "슬롯 당첨시 추천인 지급" },
        { type: "slotfriendwin", text: "슬롯 낙점시 추천인 지급" },

        { type: "fxlose", text: "FX 낙점 지급" },
        { type: "fxwin", text: "FX 당첨 지급" },
        { type: "fxbtclose", text: "FX BTC 낙점" },
        { type: "fxbtcwin", text: "FX BTC 당첨" },
      ],
    };

    this.handleMakeUserLevel = this.handleMakeUserLevel.bind(this);
    this.handleGetBenefitList = this.handleGetBenefitList.bind(this);
    this.handleUpdateBenefit = this.handleUpdateBenefit.bind(this);

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetBenefitList();
  }

  handleUpdateBenefit = () => {
    this.adminService.admin_update_benefit(this.state.benefits).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "레벨",
          message: "레벨정보가 업데이트 되어습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
        // this.setState({ benefits: data.benefits });
      }
    });
  };

  handleGetBenefitList = () => {
    this.adminService.admin_get_benefit_list().then((data) => {
      if (data.status == "success") {
        this.setState({ benefits: data.benefits });
      }
    });
  };

  handleMakeUserLevel = () => {
    this.adminService
      .admin_make_benefit_level(this.state.maxLevel, this.state.levels)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "레벨",
            message: "레벨정보가 생성되었습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "레벨",
            message: "관리자에게 문의 부탁드림니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  handleDeleteUserLevel = (index) => {
    this.adminService
      .admin_delete_benefit(index)
      .then((data) => {
        if (data.status == "success") {
          // window.location.reload();

          // confirmAlert({
          //   title: "레벨",
          //   message: "레벨정보가 삭제되었습니다.",
          //   buttons: [
          //     {
          //       label: "확인",
          //       onClick: () => {
          //         window.location.reload();
          //       },
          //     },
          //   ],
          // });
        } else {
          confirmAlert({
            title: "레벨",
            message: "관리자에게 문의 부탁드림니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    const RenderPanel = () => {
      let benefitList: any[] = [];
      let levelIndexs: any[] = [];
      for (let i = 1; i < this.state.maxLevel; i++) {
        levelIndexs.push(i);
        if (this.state.benefits != null) {
          let results = this.state.benefits.filter((x) => x.level == i);

          benefitList.push(results);
        }
      }

      return levelIndexs.map((level, i) => {
        let items = benefitList[level - 1];
        return (
          <TabPanel
            header={level + "레벨"}
            leftIcon="pi-md-check"
            key={"level" + level}
          >
            <DataTable
              value={items}
              paginatorPosition="both"
              rows={100}
              sortOrder={-1}
              scrollable={true}

            // style={{ width: isMobile ? "1900px" : "" }}
            >
              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="Level"
                body={(rowData: any, column: any) => {
                  return rowData.level;
                }}
              />
              <Column
                style={{ textAlign: "center", width: "220px" }}
                header="ID"
                body={(rowData: any, column: any) => {
                  return rowData.benefit_type;
                }}
              />
              <Column
                style={{ textAlign: "center", width: "220px" }}
                header="설명"
                body={(rowData: any, column: any) => {
                  return rowData.text;
                }}
              />

              <Column
                style={{ textAlign: "center", width: "220px" }}
                header="퍼센테이지"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.percent}
                      onChange={(even: any) => {
                        let ben = this.state.benefits.find((s) => s.idx == rowData.idx);
                        if (ben != null) {
                          ben.percent = even.target.value;
                          this.setState({ benefits: this.state.benefits });
                        }
                      }}
                    ></InputText>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center", width: "220px" }}
                header="조건"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.necessary}
                      onChange={(even: any) => {
                        let ben = this.state.benefits.find((s) => s.idx == rowData.idx);
                        if (ben != null) {
                          ben.necessary = even.target.value;
                          this.setState({ benefits: this.state.benefits });
                        }
                      }}
                    ></InputText>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center", width: "220px" }}
                header="삭제"
                body={(rowData: any, column: any) => {
                  return (
                    <Button
                      label="삭제"
                      onClick={() => {
                        this.handleDeleteUserLevel(rowData.idx);
                      }}
                    />
                  );
                }}
              />
            </DataTable>
          </TabPanel>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">레벨 설정</div>

                  <div className="p-md-6">
                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleUpdateBenefit();
                      }}
                    />

                    <Button
                      label="레벨 생성"
                      onClick={() => {
                        this.handleMakeUserLevel();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                {/* {RenderBet()} */}

                <TabView>{RenderPanel()}</TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
