//eBetting\admin\src\service\WORK\eBetting\admin\src\service\AxiosService.js;
import { AxiosMag } from "./axios.service";

export class BalanceService {
  get_wait_deposit = async (page: any, type: any, sort: any) => {
    return await AxiosMag.SendMessageByPost("/balance/get_wait_deposit", {
      skip: page,
    });
  };

  get_wait_withdraw = async (page: any) => {
    return await AxiosMag.SendMessageByPost("/balance/get_wait_withdraw", {
      skip: page,
    });
  };

  set_deposit_success = async (deposits: any) => {
    return await AxiosMag.SendMessageByPost("/balance/deposit_success", {
      deposits: deposits,
    });
  };

  set_deposit_success_one_balance = async (deposits: any) => {
    return await AxiosMag.SendMessageByPost("/balance/deposit_success_one_balance", {
      deposits: deposits,
    });
  };

  set_deposit_wait = async (deposits: any) => {
    return await AxiosMag.SendMessageByPost("/balance/deposit_wait", {
      deposits: deposits,
    });
  };

  set_deposit_cancel = async (deposits: any) => {
    return await AxiosMag.SendMessageByPost("/balance/deposit_cancel", {
      deposits: deposits,
    });
  };

  set_withdraw_success = async (withdraws: any) => {
    return await AxiosMag.SendMessageByPost("/balance/withdraw_success", {
      withdraws: withdraws,
    });
  };

  set_withdraw_success_one_balance = async (withdraws: any) => {
    return await AxiosMag.SendMessageByPost("/balance/withdraw_success_one_balance", {
      withdraws: withdraws,
    });
  };


  set_withdraw_wait = async (withdraws: any) => {
    return await AxiosMag.SendMessageByPost("/balance/withdraw_wait", {
      withdraws: withdraws,
    });
  };

  set_withdraw_cancel = async (withdraws: any) => {
    return await AxiosMag.SendMessageByPost("/balance/withdraw_cancel", {
      withdraws: withdraws,
    });
  };

  del_withdraw = async (page: any, type: any, sort: any) => {
    return await AxiosMag.SendMessageByPost("/balance/withdraw_del", {
      skip: page,
    });
  };

  del_deposit = async (page: any, type: any, sort: any) => {
    return await AxiosMag.SendMessageByPost("/balance/deposit_del", {
      skip: page,
    });
  };

  get_deposit_and_withdraw = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any,
    viewType: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/balance/admin_get_deposit_and_withdraw",
      {
        skip: page,
        begin: begin,
        end: end,
        searchText: searchText,
        findText: findText,
        viewType: viewType,
      }
    );
  };


  get_deposit_and_withdraw_total_balance = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any,
    viewType: any,
    selectBalanceType: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/balance/admin_get_deposit_and_withdraw_total_balance",
      {
        skip: page,
        begin: begin,
        end: end,
        searchText: searchText,
        findText: findText,
        viewType: viewType,
        selectBalanceType: selectBalanceType
      }
    );
  };

  get_deposit_and_withdrawAdmin = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/balance/admin_get_deposit_and_withdraw_admin",
      {
        skip: page,
        begin: begin,
        end: end,
        searchText: searchText,
        findText: findText,
      }
    );
  };

  get_usre_deposit_and_withdraw = async (
    id: string,
    page: any,
    type: any,
    begin: any,
    end: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/balance/admin_get_user_deposit_and_withdraw",
      {
        userid: id,
        skip: page,
        type: type,
        begin: begin,
        end: end,
      }
    );
  };

  admin_give_balance = async (
    uuid: string,
    id: string,
    type: any,
    balance: any,
    rolling: any,
    text: any
  ) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_slot_give_balance", {
      uuid: uuid,
      userid: id,
      type: type,
      balance: balance,
      rolling: rolling,
      text: text,
    });
  };


  admin_give_balance_one_balance = async (
    uuid: string,
    id: string,
    type: any,
    balance: any,
    rolling: any,
    text: any
  ) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_slot_give_balance_one_balance", {
      uuid: uuid,
      userid: id,
      type: type,
      balance: balance,
      rolling: rolling,
      text: text,
    });
  };

  get_user_calculate = async (id: any, page: any, day: any, type: any) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_get_calculate", {
      userid: id,
      skip: page,
      day: day,
      type: type,
    });
  };

  get_admin_balance = async (
    page: any,
    begin: any,
    end: any,
    searchText: any,
    findText: any,
    type: any
  ) => {
    return await AxiosMag.SendMessageByPost("/balance/admin_get_balance_log", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };

  admin_agent_give_balance = async (
    agentid: any,
    balance: any,
    type: any,
    ms: any
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_give_agent_balance", {
      agent: agentid,
      balance: balance,
      type: type,
      ms: ms,
    });
  };


  admin_agent_give_balance_one_balance = async (
    agentid: any,
    balance: any,
    type: any,
    ms: any
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_give_agent_balance_one_balance", {
      agent: agentid,
      balance: balance,
      type: type,
      ms: ms,
    });
  };

  admin_get_balance_deposit_and_withdraw = async (balanceType: string, id: string, page: any, begin: any, end: any,) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_balance_deposit_and_withdraw",
      {
        agent: id,
        skip: page,
        searchText: balanceType,
        findText: 'balanceType',
        begin: begin,
        end: end,
      }
    );
  };

  get_in_game_balance = async (id: any) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_in_game_balance", {
      id,
    });
  };

  get_update_all_balance = async () => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_update_all_balance", {
    });
  };


  // agent_deposit_to_user = async (balance: any, id: any) => {
  //   return await AxiosMag.SendMessageByPost("/slot/admin_get_in_game_balance", {
  //     id,
  //   });
  // };

  // agent_withdraw_to_user = async (balance: any, id: any) => {
  //   return await AxiosMag.SendMessageByPost("/slot/admin_get_in_game_balance", {
  //     id,
  //   });
  // };
}
