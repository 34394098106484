import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { Panel } from "primereact/panel";
import { SelectButton } from "primereact/selectbutton";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { AgentService } from "../service/agent.service";
import { HelpStatus, OpenAgentDetail, convertDate } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";
import { CommunityService } from "../service/community.service";

interface State {
  note: any[];
  selTemplate: any;
  template: any[];
  help: any;
  activePage: number;
  maxCount: number;
  selectBox: any[];
  dropdownItem: any;
  seach: any;
  templates: any

}

export class AgentHelp extends Component<any, State> {
  agentService = new AgentService();
  communityService = new CommunityService();

  constructor(props: any) {
    super(props);

    this.state = {
      note: [],
      templates: [],
      template: [],

      selTemplate: "",
      activePage: 1,
      maxCount: 1,
      help: {
        mode: "user",
        del: "n",
        contents: "  ",
        title: "",
        userId: "",
        ref: { contents: "<div></div>" },

      },

      seach: "",
      dropdownItem: null,
      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "제목", value: "title" },
        // { label: "내용", value: "text" },
      ],
    };
  }

  componentDidMount() {
    this.handleUserNote('', ' ', 1);
    this.handleTemplate();
  }




  handleDelAllNote = () => {
    confirmAlert({
      title: "에이전트 고객센터",
      message: "에이전트 고객센터를 삭제합니다 3일전 내역은 모두 삭제됩니다.",
      buttons: [
        {
          label: "확인",
          onClick: () => {

            this.communityService.admin_del_all_agent_help().then((data: any) => {
              if (data.status == "success") {
                confirmAlert({
                  title: "에이전트 고객센터",
                  message: "에이전트페이지에서 삭제되었습니다.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    },
                  ],
                });
              }
              else {
                confirmAlert({
                  title: "에이전트 고객센터",
                  message: "알수 없는 예러 발생.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    },
                  ],
                });

              }
            });
          },
        },
        {
          label: "취소",
          onClick: () => {

          },
        },
      ],
    });
    return;

  };

  handleTemplate = async () => {
    let data = await this.communityService.admin_get_help_template_list(1)
    if (data.status == "success") {
      let templates: any = []
      for (let note of data.note) {
        templates.push({ value: note._id, label: note.title })
      }

      this.setState({ template: data.note, templates: templates });
    }
    // let data = await this.agentService.admin_get_help_template_list(1, '', '')
    // if (data.status == "success") {
    //   this.setState({ template: data.note });
    // }
  };

  handleUserNote = (dropdownItem, seach, skip: any) => {
    this.setState({ activePage: skip });
    this.agentService.admin_get_agent_help_list(seach, dropdownItem, skip).then((data: any) => {
      if (data.status == "success") {
        this.setState({ note: data.note, maxCount: data.maxCount });
      }
    });
  };


  handleAlreadRead = async (help) => {
    await this.agentService.admin_agent_help_already_read(help).then(s => s)
  };


  handleWirteHelp = () => {
    this.agentService.admin_wirte_ref_agent_help(this.state.help).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "고객센터",
          message: "고객센터 작성/수정 성공.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "고객센터 작성 실패.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };


  handleDelHelp = (_idx) => {
    this.agentService.admin_del_agent_help(_idx).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "고객센터",
          message: "고객센터 삭제되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "고객센터 작성 실패.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  render() {
    let items = this.state.note;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {

              this.setState({ help: rowData });
              this.handleAlreadRead(rowData)
            }}
          ></Button>
        </div>
      );
    };

    const RenderHelp = () => {
      if (this.state.help.userId !== null) {
        return (
          <div className="p-col-12 p-md-8">
            <div className="card">
              <div className="p-col-12">
                <span className="p-float-label">
                  <InputText
                    id="in"
                    style={{
                      width: "70%",
                    }}
                    value={this.state.help.title}
                    onChange={(e: any) => {
                      this.state.help.title = e.target.value;
                      this.setState({ help: this.state.help });
                    }}
                  />
                  <label htmlFor="in">Title</label>
                </span>
              </div>
              <div className="p-col-12">
                <Panel header="문의 시간">
                  {convertDate(this.state.help.regDate)}
                </Panel>
              </div>
              <div className="p-col-12">
                <Panel header="내용">
                  <div dangerouslySetInnerHTML={{ __html: this.state.help.contents }} />
                </Panel>
              </div>

              <div className="p-col-12">
                <Editor
                  style={{ height: "320px" }}
                  value={this.state.help.ref.contents}
                  onTextChange={(e) => {
                    this.state.help.ref.contents = e.htmlValue;

                    this.setState({ help: this.state.help });
                  }}
                // onChange={(event) =>
                //   this.setState({ selTemplate: event.value })
                // }
                />
              </div>

              <div className="p-col-12">
                {this.state.help && this.state.help.ref ? (
                  <Panel header="">
                    <p>답변 시간</p>
                    <p>{convertDate(this.state.help.ref.date)}</p>
                  </Panel>
                ) : (<></>)
                }
              </div>

              {/* <div className="p-col-12">
                <div className="card-title">고객센터 템플릿</div>
                <Dropdown
                  optionLabel="title"
                  style={{ width: "200px" }}
                  options={this.state.template}
                  value={this.state.selTemplate}
                  onChange={(event) =>
                    this.setState({ selTemplate: event.value })
                  }
                  autoWidth={false}
                />

                <Button
                  label="적용하기"
                  onClick={() => {
                    console.log(this.state.selTemplate);

                    this.state.help.ref.contents = this.state.selTemplate.contents;
                    this.setState({ help: this.state.help });
                  }}
                />

              </div> */}

              <div className="p-col-12">
                <SelectButton
                  options={this.state.templates}
                  onChange={(e) => {
                    if (e.value == null) {
                      return
                    }
                    console.log(this.state.selTemplate)

                    const value = Object.values(this.state.template).find((s: any) => s._id == e.value)
                    if (value != null) {


                      this.state.help.ref.contents = value.contents;
                      this.setState({ help: this.state.help });
                    }
                  }}
                />
              </div>

              <div className="p-col-12">
                <div className="p-grid">

                  <div className="p-col-6">

                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleWirteHelp();
                        //this.handleEdithelp(this.state.help);
                        //this.handleUpdateBenefit();
                      }}
                    />
                  </div>
                  <div className="p-col-4">

                    <Button
                      label="삭제"
                      color="secondary"
                      onClick={() => {
                        this.handleDelHelp(this.state.help._id);
                      }}
                    />
                  </div>


                </div>
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">에이전트 고객센터</div>
                <div className="p-md-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          style={{ width: "100px" }}
                          options={this.state.selectBox}
                          value={this.state.dropdownItem}
                          onChange={(event) =>
                            this.setState({ dropdownItem: event.value })
                          }
                          autoWidth={false}
                        />
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.seach}
                          onChange={(e: any) => this.setState({ seach: e.target.value })}
                          onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              this.handleUserNote(
                                this.state.dropdownItem,
                                this.state.seach,
                                1
                              );
                            } else {
                            }
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handleUserNote(
                              this.state.dropdownItem,
                              this.state.seach,
                              1
                            );
                          }}
                        />
                      </span>
                    </div>

                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Button
                          label="삼일 이전내역삭제"
                          onClick={() => {
                            this.handleDelAllNote();
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handleUserNote(this.state.dropdownItem, this.state.seach, page)
                  }}
                ></CustomPagination>
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "left" }}
                    header="제목"
                    body={(rowData: any, column: any) => {
                      console.log(`${rowData.title}, ${rowData.color}, ${rowData.agentId}`)
                      return <div style={{ color: rowData.color }}>  {rowData.title}</div>;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="아이디"
                    body={(rowData: any, column: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(rowData.agentId);
                          }}

                          style={{ color: rowData.color }}
                        >
                          {rowData.agentId}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                        </div>
                      );
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="상태"
                    body={(rowData: any, column: any) => {

                      if (rowData.del == 'y') {
                        return (
                          <>
                            <DeleteForeverIcon></DeleteForeverIcon>
                            {HelpStatus(rowData.status)}
                          </>
                        )
                      }
                      return <>{HelpStatus(rowData.status)}</>;
                    }}
                  />
                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handleUserNote(this.state.dropdownItem, this.state.seach, page)
                  }}
                ></CustomPagination>
              </div>
            </div>

            {RenderHelp()}
          </div>
        </div>
      </div>
    );
  }
}
