import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';



import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, ConvertCalendar } from "../utility/help";


interface BalanceState {
  details: any;
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;
  miniGames: any;
  options: any[];
  options_type: string;
  begin: any;
  end: any;

  agent_name: any;
  management: any;
  agents_option: any[];
  totalBalance: any
}

export class CalculateAgent extends Component<any, BalanceState> {
  adminService: AdminService = new AdminService();

  constructor(props: any) {
    super(props);

    const today = new Date();
    const begin = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
    );

    today.setMonth(today.getMonth() + 1);

    const end = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
    );

    this.state = {
      begin: begin,
      end: end,
      findText: "",
      searchText: "",
      selectItem: {},

      details: [],
      miniGames: [],
      management: [],
      options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

      options_type: "balance",

      select: [{ label: "선택", value: null }],

      agent_name: "",
      agents_option: [],
      totalBalance: {}
    };
  }

  handleCalculate(agent: string, begin: Date, end: Date) {
    this.setState({ options: [] });
    this.adminService
      .admin_get_calculate_agent(agent, begin, end)
      .then((data) => {
        if (data.status == "success") {
          let detail = Object.values(data.calculate);
          detail.sort(function (a: any, b: any) {
            return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
          });

          this.state.options.push({ label: "입출금/통계", value: "balance" });
          this.state.options.push({ label: "총판/입출", value: "agentGive" });

          if (data.management.sportpre === "y") {
            this.state.options.push({ label: "스포츠", value: "sport_pre" });
          }
          if (data.management.sportlive === "y") {
            this.state.options.push({
              label: "라이브스포츠",
              value: "sport_live",
            });
          }
          if (data.management.casino === "y") {
            this.state.options.push({ value: "casino", label: "casino" });
          }
          if (data.management.slot === "y") {
            this.state.options.push({ label: "slot", value: "slot" });
          }
          if (data.management.fx === "y") {
            this.state.options.push({ label: "fx", value: "fx" });
          }

          let options = this.state.options;
          for (let mini of this.state.miniGames) {
            options.push({ value: mini.categoryId, label: mini.categoryName });
          }

          let totalBalance = {}
          for (let balances of Object.values(data.calculate) as any) {
            for (let key of Object.keys(balances as [])) {

              if (typeof (balances[key]) === 'number') {
                if (totalBalance[key] == null) {
                  totalBalance[key] = 0
                }
                totalBalance[key] += Number(balances[key] as Number)
              }
              else if (typeof (balances[key]) === 'object') {

                for (let key2 of Object.keys(balances[key] as [])) {

                  if (typeof (balances[key][key2]) === 'number') {
                    if (totalBalance[`${key}.${key2}`] == null) {
                      totalBalance[`${key}.${key2}`] = 0
                    }

                    totalBalance[`${key}.${key2}`] += Number(balances[key][key2] as Number)
                  }
                }


              }
            }
          }

          console.log(totalBalance)

          this.setState({
            details: detail, totalBalance, management: data.management,
            options: this.state.options,
          });

        }
      });
  }

  // handleMiniGame() {
  //   this.adminService.admin_get_minigame_list().then((data) => {
  //     if (data.status == "success") {
  //       let options = this.state.options;
  //       for (let mini of data.miniGames) {
  //         options.push({ value: mini.categoryId, label: mini.categoryName });
  //       }

  //       this.setState({ options: options, miniGames: data.miniGames });
  //     }
  //   });
  // }

  handleAgents() {
    this.adminService.admin_get_agnet_list().then((data) => {
      if (data.status == "success") {
        let agents = data.agents;

        let option: any = [];
        for (let agent of agents) {
          option.push({
            label: agent.nick,
            value: agent.agent,
            key: agent.agent,
          });
        }


        this.setState({ agents_option: option });
      }
    });
  }

  componentDidMount() {
    // this.handleMiniGame();
    this.handleAgents();
  }


  render() {

    console.log(this.state.totalBalance)

    const pad2 = (n) => {
      return (n < 10 ? "0" : "") + n;
    };



    const total = (this.state.totalBalance['balance.depositBalance'] + this.state.totalBalance['agentChangeBalance'] + this.state.totalBalance['agentGive.depositBalance']) -
      (this.state.totalBalance['balance.withdrawBalance'] + this.state.totalBalance['agentExchangeBalance'] + this.state.totalBalance['agentGive.withdrawBalance'])

    let headerGroup = (
      <ColumnGroup>


        <Row>
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="에이전트" />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>충전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>환전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>파트너 지급</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>파트너 차감</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>회원 잔액</div>} />

          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>배팅</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>당첨</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>배팅 - 당첨</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>파트너 충전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>파트너 환전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>파트너 잔액</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>매출액</div>} />



        </Row>
        {
          (

            <Row >
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header="정산 합계" />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['balance.depositBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['balance.withdrawBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentGive.depositBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentGive.withdrawBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div> </div>} />


              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betDoBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betWinBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betDoBalance'] - this.state.totalBalance['betting.betWinBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentChangeBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentExchangeBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{ }</div>} />

              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(total)}</div>} />



            </Row>
          )

        }


      </ColumnGroup >
    );

    const RenderBalance = () => {
      if (this.state.options_type == "balance" || this.state.options_type === "agentGive") {
        return (
          <DataTable
            value={this.state.details}
            paginatorPosition="both"
            rows={9999}
            sortOrder={-1}
            scrollable={true}
            headerColumnGroup={headerGroup}
          >
            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                let date = new Date(rowData.date);

                return (
                  pad2(date.getMonth() + 1) +
                  "-" +
                  pad2(date.getDate()) +
                  " " +
                  pad2(date.getHours())
                );
                //return rowData.date;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.balance == null) return <span>0</span>
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.balance.depositBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.balance == null) return <span>0</span>

                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.balance.withdrawBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.agentGive == null) return <span>0</span>
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentGive.depositBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.agentGive == null) return <span>0</span>
                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.agentGive.withdrawBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.inBalance == null) return <span>0</span>
                return (
                  <span style={{ color: "green" }}>
                    {numTomoeny(rowData.inBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) return <span>0</span>
                return (
                  <div style={{ color: "#e91224" }}>
                    <div style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betDoBalance)}
                    </div>
                    <div style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betDoBalance)}
                    </div>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) return <span>0</span>
                return (
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(rowData.betting.betWinBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) return <div><span>0</span><span>0</span></div>
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.betting.betDoBalance -
                          rowData.betting.betWinBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.betting.betDoBalance -
                        rowData.betting.betWinBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentChangeBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentChangeBalance)}
                  </span>
                );
              }}
            />



            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "red" }}>
                    {numTomoeny(rowData.agentChangeBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "blue" }}>
                    {numTomoeny(rowData.agentExchangeBalance)}
                  </span>
                );
              }}
            />

            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                return (
                  <span style={{ color: "green" }}>
                    {numTomoeny(rowData.agentBalance)}
                  </span>
                );
              }}
            />


            <Column
              style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
              body={(rowData: any, column: any) => {
                if (rowData.balance == null ||
                  rowData.balance.depositBalance == null ||
                  rowData.agentChangeBalance == null ||
                  rowData.balance.withdrawBalance == null ||
                  rowData.agentGive.withdrawBalance == null ||
                  rowData.agentExchangeBalance == null) {
                  return (
                    <span style={{ color: "green" }}>0</span>
                  );
                }

                const total = (rowData.balance.depositBalance + rowData.agentGive.depositBalance + rowData.agentChangeBalance) -
                  (rowData.balance.withdrawBalance + rowData.agentGive.withdrawBalance + rowData.agentExchangeBalance)
                return (
                  <span style={{ color: "green" }}>
                    {numTomoeny(total)}
                  </span>
                );
              }}
            />




          </DataTable>
        );
      }
    };


    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">에이전트 정산</div>

                <div className="p-col-12 ">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <Dropdown
                        style={{ width: "200px" }}
                        value={this.state.agent_name}
                        options={this.state.agents_option}
                        placeholder="에이전트 선택"
                        onChange={(e) => {
                          this.setState({ agent_name: e.value });

                          this.handleCalculate(
                            e.value,
                            this.state.begin,
                            this.state.end
                          );
                        }}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        view="date"
                        value={this.state.begin}
                        onChange={(e) => this.setState({ begin: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        view="date"
                        value={this.state.end}
                        onChange={(e) => this.setState({ end: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Button
                        label="검색"
                        onClick={() => {
                          this.handleCalculate(
                            this.state.agent_name,
                            this.state.begin,
                            this.state.end
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="card">
                {/* {RenderCalculate()} */}
                {RenderBalance()}
                {/* {RenderBet()} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
