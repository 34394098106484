import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";
//import { AlertYesNo } from "../share/alert-yes-no";
import { confirmAlert } from "react-confirm-alert";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail
} from "../utility/help";
import { CONNREFUSED } from "dns";

interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
}

export class UserRegList extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_list(page, find, search, sortType, sort, "reg")
      .then(data => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount
        });
      });
  };

  handleUseStauseChange = (userId: string, status: string) => {
    if (status == "wait") {
      this.userService
        .admin_user_stause_change(userId, status)
        .then(data => {});
    } else if (status == "used") {
      //   <AlertYesNo
      //     type="success"
      //     className="p-button-success"
      //     btnLabel="사용자 상태변경"
      //     title="사용자 상태변경"
      //     message="사용자를 승인하시겠습니까 ?"
      //     call={() => {
      //       this.userService
      //         .admin_user_stause_change(userId, status)
      //         .then(data => {  
      //           location.reload();
      //         });
      //     }}
      //   ></AlertYesNo>;
      confirmAlert({
        title: "사용자 상태변경",
        message: "사용자를 승인하시겠습니까 ?",
        buttons: [
          {
            label: "예",
            onClick: () => {
              this.userService
                .admin_user_stause_change(userId, status)
                .then(data => {
                  location.reload();
                });
            }
          },
          {
            label: "아니요",
            onClick: () => {}
          }
        ]
      });
    } else if (status == "remove") {
      confirmAlert({
        title: "사용자 상태변경",
        message: "사용자를 삭제하시겠습니까 ?",
        buttons: [
          {
            label: "예",
            onClick: () => {
              this.userService
                .admin_user_stause_change(userId, status)
                .then(data => {
                  location.reload();
                });
            }
          },
          {
            label: "아니요",
            onClick: () => {}
          }
        ]
      });
    }
  };

  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    console.log(search, find);
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  MoveSel = (page: any) => {
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">가입대기중 유저</div>

                <HelpBox SearchData={this.SearchData}></HelpBox>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>

              <UserTable
                userTableValue={this.state.userTableValue}
                SortTable={this.SortTable}
                handleUseStauseChange={this.handleUseStauseChange}
                mode="reg"
              ></UserTable>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
