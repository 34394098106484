import React, { Component } from "react";
import { styled } from "@material-ui/core/styles";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Spinner } from "primereact/spinner";
import { confirmAlert } from "react-confirm-alert"; // Import
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "primereact/checkbox";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  OpenMatchDetail,
} from "../../utility/help";

import { BetService } from "../../service/bet.service";

const CumTable = withStyles({
  root: {
    color: "white",
  },
  // label: {
  //   color: 'white',
  //   textTransform: 'capitalize',
  // },
})(Table);

const CumTitle = withStyles({
  root: {
    padding: "10px",
    backgroundColor: "#ffb300",
    border: "1px solid black",
  },
  // label: {
  //   textTransform: 'capitalize',
  // },
})(TableCell);

const DataCell = withStyles({
  root: {
    color: "#000",
    padding: "1px",
    border: "1px solid black",
  },
  // label: {
  //   textTransform: 'capitalize',
  // },
})(TableCell);

const NumberField = withStyles({
  root: {
    padding: "0px",
    color: "#0000ff",
  },
  // label: {
  //   textTransform: 'capitalize',
  //   padding : "0px"

  // },
  // textField:
  // {
  //   padding : "0px"
  // },

  // overrides: {
  //   MuiInputBase: {
  //     input: {
  //       padding : "0px"
  //     },
  //   },
  // },
})(TextField);

interface Props {
  key: any;
  contact: any;
  detailsBet: any;
  editOdds: any;

  mode: any;
  matchId: number;
  marketId: number;
  handleEditOdds: any;
  handleUpdateOdds: any;
  handleUpdateActive: any;
  handleUpdateFix: any;
}

interface State {}

export class MatchDetail extends Component<Props, State> {
  betService = new BetService();
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  handleCanselame = (matchId: any, marketId: any, specifiers: any) => {
    this.betService
      .admin_cancel_match_game_by_matchId_and_gameId(
        matchId,
        marketId,
        specifiers
      )
      .then((s) => {
        confirmAlert({
          title: "마감",
          message: "정상적으로  취소되었습니다.",
          buttons: [
            {
              label: "예",
              onClick: () => {},
            },
          ],
        });
      });
  };

  handleFixOdds = (matchId: any, marketId: any, specifiers: any) => {
    this.betService
      .admin_cancel_match_game_by_matchId_and_gameId(
        matchId,
        marketId,
        specifiers
      )
      .then((s) => {
        confirmAlert({
          title: "마감",
          message: "정상적으로  취소되었습니다.",
          buttons: [
            {
              label: "예",
              onClick: () => {},
            },
          ],
        });
      });
  };
  handleFinishGame = (
    matchId: any,
    marketId: any,
    winId: any,
    specifiers: any
  ) => {
    this.betService
      .admin_end_match_game_by_matchId_and_gameId(
        matchId,
        marketId,
        winId,
        specifiers
      )
      .then((s) => {
        confirmAlert({
          title: "마감",
          message: "정상적으로 마감되었습니다.",
          buttons: [
            {
              label: "예",
              onClick: () => {},
            },
          ],
        });
      });
  };

  render = () => {
    let mode = this.props.mode;
    let matchId = this.props.matchId;
    let marketId = this.props.marketId;
    let contact = this.props.contact == undefined ? {} : this.props.contact;
    let detailsBet =
      this.props.detailsBet == undefined ? {} : this.props.detailsBet;

    let active =
      this.props.editOdds.active == undefined
        ? true
        : this.props.editOdds.active;

    let editOdds: any = this.props.editOdds == null ? {} : this.props.editOdds;
    if (contact.outcomeOdds == null) {
      return <div></div>;
    }

    let isBet: boolean = false;
    for (let idx in Object.keys(contact.outcomeOdds)) {
      let odds: any = contact.outcomeOdds[idx];
      if (
        odds != null &&
        odds.preBetBalance != null &&
        Number(odds.preBetBalance) > 0
      ) {
        isBet = true;
      }
    }

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };
    if (mode == "end") {
      customHeaderStyle = { backgroundColor: "#ffC107", textAlign: "center" };
    }

    if (isBet == true) {
      customHeaderStyle = { backgroundColor: "red", textAlign: "center" };
    }

    return (
      <CumTable aria-label="spanning table">
        <TableHead>
          <TableRow>
            <CumTitle align="center" colSpan={999}>
              {contact.name + " , " + contact.id + "," + contact.groups}{" "}
              <FormControlLabel
                value="end"
                control={<Radio checked={active === true} />}
                label="배팅가능"
                onClick={() => {
                  this.props.editOdds.active = true;
                  this.props.handleUpdateActive(
                    matchId,
                    marketId,
                    contact.specifiers,
                    true
                  );
                }}
              />
              <FormControlLabel
                value="end"
                control={<Radio checked={active === false} color="primary" />}
                label="배팅중지"
                onClick={() => {
                  this.props.editOdds.active = false;
                  this.props.handleUpdateActive(
                    matchId,
                    marketId,
                    contact.specifiers,
                    false
                  );
                }}
              />
              <Button
                label="적특처리"
                className="p-button-danger"
                onClick={() => {
                  confirmAlert({
                    title: "취소",
                    message: "데이터를 강제 취소시킴니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          this.handleCanselame(
                            matchId,
                            contact.id,
                            contact.specifiers
                          );
                        },
                      },
                      {
                        label: "아니오",
                        onClick: () => {},
                      },
                    ],
                  });
                  return;
                }}
              />
            </CumTitle>
          </TableRow>
          <TableRow>
            {Object.values(contact.outcomeOdds).map((outcome: any) => {
              let betBalance = detailsBet[outcome.id]
                ? detailsBet[outcome.id].betBalance
                : 0;
              return (
                <DataCell>
                  <strong className="p-field">
                    {outcome.name} <strong>{outcome.result}</strong>
                  </strong>{" "}
                  <Button
                    label="적중"
                    onClick={() => {
                      confirmAlert({
                        title: "적중",
                        message: "데이터를 강제 적중시킴니다.",
                        buttons: [
                          {
                            label: "예",
                            onClick: () => {
                              this.handleFinishGame(
                                matchId,
                                contact.id,
                                outcome.id,
                                contact.specifiers
                              );
                            },
                          },
                          {
                            label: "아니오",
                            onClick: () => {},
                          },
                        ],
                      });
                      return;
                    }}
                  />
                  {betBalance > 0 ? (
                    <strong
                      style={{ color: "#ff0000" }}
                      onClick={() => {
                        OpenMatchDetail(
                          matchId,
                          outcome.id,
                          JSON.stringify(contact.specifiers)
                        );
                      }}
                    >
                      ({numTomoeny(betBalance)})
                    </strong>
                  ) : (
                    <></>
                  )}
                </DataCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={1}>
            {Object.values(contact.outcomeOdds).map((row: any) => {
              editOdds[row.id] == null ? (editOdds[row.id] = { odds: 0 }) : "";
              editOdds[row.id].odds == null ? (editOdds[row.id].odds = 0) : "";
              let viewOdds = editOdds[row.id].odds;

              console.log(viewOdds);
              return (
                <DataCell>
                  {row.odds}
                  <NumberField
                    value={viewOdds}
                    // value={editOdds[row.id].odds}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: any) => {
                      this.props.handleEditOdds(
                        matchId,
                        marketId,
                        contact.specifiers,
                        row.id,
                        e.target.value
                      );
                    }}
                    // onClick={(e: any) => {
                    //   row.plusOdds = e.target.value;
                    //   console.log(e.key);
                    //   if (e.key === "Enter") {
                    //     this.props.handleUpdateOdds(
                    //       matchId,
                    //       marketId,
                    //       contact.specifiers,
                    //       row.id,
                    //       e.target.value
                    //     );
                    //   }
                    // }}
                    onKeyPress={(e: any) => {
                      row.Odds = e.target.value;
                      if (e.key === "Enter") {
                        this.props.handleUpdateOdds(
                          matchId,
                          marketId,
                          contact.specifiers,
                          row.id,
                          e.target.value
                        );
                      }
                    }}
                  />
                </DataCell>
              );
            })}
          </TableRow>
          <TableRow key={1}>
            {Object.values(contact.outcomeOdds).map((row: any) => {
              let isFix =
                editOdds == null
                  ? false
                  : editOdds[row.id] == null
                  ? false
                  : editOdds[row.id].fix == null
                  ? false
                  : editOdds[row.id].fix;

              return (
                <DataCell>
                  <FormControlLabel
                    value="end"
                    control={<Radio checked={isFix === false} />}
                    label="더하기"
                    onClick={() => {
                      this.props.handleUpdateFix(
                        matchId,
                        marketId,
                        contact.specifiers,
                        row.id,
                        false
                      );
                    }}
                  />
                  <FormControlLabel
                    value="end"
                    control={<Radio checked={isFix === true} color="primary" />}
                    label="고정"
                    onClick={() => {
                      this.props.handleUpdateFix(
                        matchId,
                        marketId,
                        contact.specifiers,
                        row.id,
                        true
                      );
                    }}
                  />
                </DataCell>
              );
            })}
          </TableRow>
        </TableBody>
      </CumTable>
    );
  };
}
