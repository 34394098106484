import React, { Component } from "react";
import { Chart } from "primereact/chart";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";

import { UserService } from "../service/user.service";
import { AdminService } from "../service/admin.service";

import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
} from "../utility/help";
import { CONNREFUSED } from "dns";
import { Console } from "console";

// console.log(process.env.REACT_APP_API_OUTSIDE_BALANCE)

interface State {
  redBalance: number
  plutoBalance: number

  casionRolling: number;
  slotRolling: number;
  totalBalance: number;
  realBetCount: number;
  realMiniCount: number;
  deposit: number;
  withdraw: number;

  depositAgent: number;
  withdrawAgent: number;

  userCount: number;
  todayCount: number;
  connectCount: number;

  regCount: number;
  totalBetCount: number;

  matchBetCount: number;
  matchWaitCount: number;
  miniBetCount: number;
  miniWaitCount: number;

  pieData: any;

  total: any;
  mini: any;
  miniWin: any;

  match: any;
  matchWait: any;

  slot: any;
  casino: any;
  casinoInOut: any;
  management: any;
  outsideBalance: any;

}

export class Dashboard extends Component<any, State> {
  dashborad: any = null
  userService = new UserService();
  adminService = new AdminService();
  menu: any;
  constructor(props: any) {
    super(props);
    this.state = {
      redBalance: 0,
      plutoBalance: 0,

      management: null,
      outsideBalance: 0,
      casionRolling: 0,
      slotRolling: 0,
      totalBalance: 0,
      realBetCount: 0,
      realMiniCount: 0,
      deposit: 0,
      withdraw: 0,
      regCount: 0,
      userCount: 0,
      todayCount: 0,
      connectCount: 0,
      depositAgent: 0,
      withdrawAgent: 0,

      matchBetCount: 0,
      matchWaitCount: 0,
      miniBetCount: 0,
      miniWaitCount: 0,
      totalBetCount: 0,

      total: {},
      mini: {},
      match: {},
      matchWait: {},
      miniWin: {},
      casinoInOut: {},

      slot: {},
      casino: {},
      pieData: {
        labels: ["A", "B"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      },
    };

    if (this.dashborad == null) {
      this.dashborad = setInterval(
        function (dash) {
          if (window.location.hash.indexOf("dashboard") >= 0) {
            dash.updateDashborad();
          }
        },
        30000,
        this
      );
    }
    this.updateDashborad();
    this.outsideBalance();

    this.adminService.admin_get_management().then((s: any) => {
      this.setState({ management: s.management });
    });
  }

  outsideBalance = async () => {

    // if (REACT_APP_API_OUTSIDE_BALANCE === 'n' || REACT_APP_API_OUTSIDE_BALANCE !== 'Y') {
    //   return
    // }

    setInterval(async () => {
      if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
        const outside = await this.adminService.admin_get_one_balance();
        if (outside.status == "success") {
          this.setState({ outsideBalance: outside.balance, redBalance: outside.redBalance, plutoBalance: outside.plutoBalance })
        }
      } else {
        const outside = await this.adminService.admin_get_outside_balance();
        if (outside.status == "success") {
          this.setState({ outsideBalance: outside.balance, redBalance: outside.redBalance, plutoBalance: outside.plutoBalance })
        }
      }


    }, 60000)
    if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
      const outside = await this.adminService.admin_get_one_balance();
      if (outside.status == "success") {
        this.setState({ outsideBalance: outside.balance, redBalance: outside.redBalance, plutoBalance: outside.plutoBalance })
      }
    } else {
      const outside = await this.adminService.admin_get_outside_balance();
      if (outside.status == "success") {
        this.setState({ outsideBalance: outside.balance, redBalance: outside.redBalance, plutoBalance: outside.plutoBalance })
      }
    }

  }

  componentDidMount() {

  }

  updateDashborad = () => {

    if (process.env.REACT_APP_API_CALCULATE_TOTAL_BANLACE === 'Y') {

      this.userService.get_dashboard_total_banace().then((s: any) => {
        if (s.status == "success") {
          let dashboard = s.dashboard;
          let total = {
            bet: {
              balance: dashboard.match.bet.balance + dashboard.mini.bet.balance,
              doCount: dashboard.match.bet.doCount + dashboard.mini.bet.doCount,
            },
            win: {
              balance: dashboard.match.win.balance + dashboard.mini.win.balance,
              doCount: dashboard.match.win.doCount + dashboard.mini.win.doCount,
            },
          };

          this.setState({
            total: total,
            casinoInOut: dashboard.casinoMove,

            depositAgent: dashboard.depositAgent,
            withdrawAgent: dashboard.withdrawAgent,


            totalBalance: dashboard.totalBalance,

            realBetCount: s.dashboard.realBetCount,
            realMiniCount: s.dashboard.realMiniCount,
            deposit: s.dashboard.deposit,
            withdraw: s.dashboard.withdraw,

            userCount: s.dashboard.userCount,
            todayCount: s.dashboard.todayCount,
            connectCount: s.dashboard.connectCount,
            regCount: s.dashboard.regCount,

            totalBetCount:
              s.dashboard.matchBetCount +
              s.dashboard.matchWaitCount +
              s.dashboard.miniBetCount +
              s.dashboard.miniWaitCount,

            matchBetCount: s.dashboard.matchBetCount,
            matchWaitCount: s.dashboard.matchWaitCount,
            miniBetCount: s.dashboard.miniBetCount,
            miniWaitCount: s.dashboard.miniWaitCount,

            mini: s.dashboard.mini,
            match: s.dashboard.match,
            miniWin: s.dashboard.miniWin,

            matchWait: s.dashboard.matchWait,
            slot: s.dashboard.slot,
            casino: s.dashboard.casino,
            casionRolling: s.dashboard.casionRolling,
            slotRolling: s.dashboard.slotRolling,


          });
        }
      });
    }
    else {

      this.userService.get_dashboard().then((s: any) => {
        if (s.status == "success") {
          let dashboard = s.dashboard;
          let total = {
            // wait: {
            //   balance:
            //     dashboard.match.waitBet.balance + dashboard.mini.wait.balance,
            //   doCount:
            //     dashboard.match.waitBet.doCount + dashboard.mini.wait.doCount,
            // },
            bet: {
              balance: dashboard.match.bet.balance + dashboard.mini.bet.balance,
              doCount: dashboard.match.bet.doCount + dashboard.mini.bet.doCount,
            },
            win: {
              balance: dashboard.match.win.balance + dashboard.mini.win.balance,
              doCount: dashboard.match.win.doCount + dashboard.mini.win.doCount,
            },
          };

          this.setState({
            total: total,
            casinoInOut: dashboard.casinoMove,

            totalBalance: dashboard.totalBalance,

            depositAgent: dashboard.depositAgent,
            withdrawAgent: dashboard.withdrawAgent,


            realBetCount: s.dashboard.realBetCount,
            realMiniCount: s.dashboard.realMiniCount,
            deposit: s.dashboard.deposit,
            withdraw: s.dashboard.withdraw,

            userCount: s.dashboard.userCount,
            todayCount: s.dashboard.todayCount,
            connectCount: s.dashboard.connectCount,
            regCount: s.dashboard.regCount,

            totalBetCount:
              s.dashboard.matchBetCount +
              s.dashboard.matchWaitCount +
              s.dashboard.miniBetCount +
              s.dashboard.miniWaitCount,

            matchBetCount: s.dashboard.matchBetCount,
            matchWaitCount: s.dashboard.matchWaitCount,
            miniBetCount: s.dashboard.miniBetCount,
            miniWaitCount: s.dashboard.miniWaitCount,

            mini: s.dashboard.mini,
            match: s.dashboard.match,
            miniWin: s.dashboard.miniWin,

            matchWait: s.dashboard.matchWait,
            slot: s.dashboard.slot,
            casino: s.dashboard.casino,
            casionRolling: s.dashboard.casionRolling,
            slotRolling: s.dashboard.slotRolling,


          });
        }
      });
    }

  };

  render() {
    const RenderBase = (data) => {
      return (
        <div className="p-col-12 p-md-1 summary-box summary-box-messages">
          <div className="card">
            <div className="summary-box-title">{data.title}</div>
            <p>{numTomoeny(data.info)}</p>
          </div>
        </div>
      );
    };

    const RenderBalance = (data) => {
      let depositBalance = this.state.deposit;

      let withdrawBalance = this.state.withdraw;

      let pieData = {
        labels: ["충전", "환전"],
        datasets: [
          {
            data: [depositBalance, withdrawBalance],
            backgroundColor: ["#ff0000", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-red"></span>충전
                </div>
                <div className="p-col-6">{numTomoeny(depositBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>환전
                </div>
                <div className="p-col-6">{numTomoeny(withdrawBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>차액
                </div>
                <div className="p-col-6">
                  {numTomoeny(depositBalance - withdrawBalance)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };


    const RenderAgentBalance = (data) => {
      let depositBalance = this.state.depositAgent == null ? 0 : this.state.depositAgent;
      let withdrawBalance = this.state.withdrawAgent == null ? 0 : this.state.withdrawAgent;

      let pieData = {
        labels: ["충전", "환전"],
        datasets: [
          {
            data: [depositBalance, withdrawBalance],
            backgroundColor: ["#ff0000", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };



      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-red"></span>충전
                </div>
                <div className="p-col-6">{numTomoeny(depositBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>환전
                </div>
                <div className="p-col-6">{numTomoeny(withdrawBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>차액
                </div>
                <div className="p-col-6">
                  {numTomoeny(depositBalance - withdrawBalance)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const RenderWinLose = (data) => {
      let winBalance =
        data.balance == null
          ? 0
          : data.balance.win == null
            ? 0
            : data.balance.win.balance == null
              ? 0
              : data.balance.win.balance;

      let betBalance =
        data.balance == null
          ? 0
          : data.balance.bet == null
            ? 0
            : data.balance.bet.balance == null
              ? 0
              : data.balance.bet.balance;

      let pieData = {
        labels: ["이긴금액", "배팅금액"],
        datasets: [
          {
            data: [winBalance, betBalance],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-orange"></span>배팅금액
                </div>
                <div className="p-col-6">{numTomoeny(betBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-red"></span>이긴금액
                </div>
                <div className="p-col-6">{numTomoeny(winBalance)} </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>합
                </div>
                <div className="p-col-6">
                  {numTomoeny(betBalance - winBalance)}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const RenderCustom = (data) => {
      let winBalance =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.in == null
            ? 0
            : data.casinoInOut.in.balance == null
              ? 0
              : data.casinoInOut.in.balance;

      let winCount =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.in == null
            ? 0
            : data.casinoInOut.in.count == null
              ? 0
              : data.casinoInOut.in.count;

      let betBalance =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.out == null
            ? 0
            : data.casinoInOut.out.balance == null
              ? 0
              : data.casinoInOut.out.balance;
      let betCount =
        data.casinoInOut == null
          ? 0
          : data.casinoInOut.out == null
            ? 0
            : data.casinoInOut.out.count == null
              ? 0
              : data.casinoInOut.out.count;

      let pieData = {
        labels: ["스포>카지", "카지>스포"],
        datasets: [
          {
            data: [winBalance, betBalance],
            backgroundColor: ["#990000", "#0066ff"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      };

      return (
        <div className="card">
          <div className="card-title">{data.text}</div>
          {/* <div className="overview-numbers">2,449,570</div> */}
          <div className="p-grid">
            <div className="p-col-7 connection-chart">
              <Chart type="doughnut" data={pieData} height="150" />
            </div>
            <div className="p-col-5 connection-chart-legend">
              <div className="p-grid">
                <div className="p-col-6">
                  <span className="dot dot-orange"></span>{"스포>카지"}
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * betBalance)} ({betCount}){" "}
                </div>

                <div className="p-col-6">
                  <span className="dot dot-red"></span>{"카지>스포"}
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * winBalance)} ({winCount}){" "}
                </div>

                <div className="p-col-6">
                  <span className="dot dot-orange"></span>합
                </div>
                <div className="p-col-6">
                  {numTomoeny(-1 * (betBalance + winBalance))}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    if (this.state.management == null) {
      return <ProgressSpinner />;
    }

    console.log(this.state.redBalance)
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid dashboard-grid">

                {RenderBase({ title: "알보유", info: this.state.outsideBalance, })}
                {Number(this.state.redBalance) > 0 && RenderBase({ title: "레드플레릿 알", info: this.state.redBalance, })}
                {Number(this.state.plutoBalance) > 0 && RenderBase({ title: "풀루토 알", info: this.state.plutoBalance, })}

                {/* {REACT_APP_API_OUTSIDE_BALANCE === 'Y' && RenderBase({ title: "알보유", info: this.state.outsideBalance, })} */}


                {RenderBase({ title: "회원수", info: this.state.userCount })}

                {RenderBase({ title: "금일가입", info: this.state.regCount })}
                {RenderBase({ title: "금일접속", info: this.state.todayCount })}
                {RenderBase({
                  title: "현재접속",
                  info: this.state.connectCount,
                })}
                {/* {RenderBase({
                  title: "금일배팅",
                  info: this.state.totalBetCount,
                })} */}

                {this.state.management.sportpre === "y" ? (
                  RenderBase({
                    title: "스포유저수",
                    info: this.state.realBetCount,
                  })
                ) : (
                  <></>
                )}

                {this.state.management.mini === "y" ? (
                  RenderBase({
                    title: "미니유저수",
                    info: this.state.realMiniCount,
                  })
                ) : (
                  <></>
                )}

                {this.state.management.sportpre === "y" ? (
                  RenderBase({
                    title: "스포+미니수",
                    info: this.state.realBetCount + this.state.realMiniCount,
                  })
                ) : (
                  <></>
                )}


                {this.state.management.casino === "y" ? (
                  RenderBase({
                    title: "카지노 롤링합",
                    info: this.state.casionRolling,
                  })
                ) : (
                  <></>
                )}

                {this.state.management.slot === "y" ? (
                  RenderBase({
                    title: "슬롯 롤링합",
                    info: this.state.slotRolling,
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="p-col-12">
              <div className="p-grid dashboard-grid">
                <div className="p-col-12 p-xl-4 overview-box connections">
                  {RenderBalance({ text: "충전/환전" })}
                </div>

                {
                  (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOR'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOM'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'WINLOSE'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINO'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINONSLOT'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KCROWN'
                    || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOMPW'

                  ) && (
                    <div className="p-col-12 p-xl-4 overview-box connections">
                      {RenderAgentBalance({ text: "총판 충전/환전" })}
                    </div>

                  )
                }


                {this.state.management.sportpre === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderCustom({
                      text: "카지노 머니이동",
                      casinoInOut: this.state.casinoInOut,
                    })}
                  </div>
                ) : (
                  <></>
                )}

                {this.state.management.mini === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "파워볼 Win/Bet",
                      balance: this.state.mini,
                    })}
                  </div>
                ) : (
                  <></>
                )}

                {this.state.management.casino === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "카지노 Win/Lose",
                      balance: this.state.casino,
                    })}
                  </div>
                ) : (
                  <></>
                )}

                {this.state.management.slot === "y" ? (
                  <div className="p-col-12 p-xl-4 overview-box connections">
                    {RenderWinLose({
                      text: "슬롯 Win/Lose",
                      balance: this.state.slot,
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
