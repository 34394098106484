import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetYesterDay, ConvertCalendar } from "../utility/help";

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];
    viewType: any;

}

export class CalculateMainAgentNor extends Component<any, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: any) {
        super(props);

        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            viewType: "balance",
        };

        this.handlegetTopAgentCalculate(begin, end);

    }


    handlegetTopAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_main_agent(begin, end).then((data) => {
            if (data.status == "success") {
                this.setState({ agentInfo: data.agentCombine });
            }
        });
    }

    // handlegetTopAgentCalculate(begin: any, end: any) {
    //     this.adminService.admin_get_main_agent(begin, end).then((data) => {
    //         if (data.status == "success") {
    //             this.setState({ agentInfo: data.agentCombine });
    //         }
    //     });
    // }

    componentDidMount() {
    }

    render() {


        return (

            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">에이전트 정산</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetTopAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card">


                                <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={99999}
                                    sortOrder={-1}
                                    style={{ width: isMobile ? '1600px' : '100%' }}
                                >
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header="에이전트"
                                        body={(rowData: any, column: any) => {
                                            return <>
                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                </a>
                                                <br />
                                                <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                </a>
                                            </>

                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 입금</div><div>총판 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 출금</div><div>총판 회수</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>Bet</div><div>Win</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                            </div>;

                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>총회원 루징</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤링</div><div>롤링 프로테이지%</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>
                                            <div>정산금</div>
                                            <div>루징프로테이지%</div>

                                        </div>}
                                        body={(rowData: any, column: any) => {

                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) / 100) * rowData.benefitLosing) - ((rowData.benefitSlotRolling / 100) * rowData.totalSlotBet))}</div>
                                                <div>{rowData.benefitLosing} %</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>롤링 유저지급</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />


                                </DataTable>
                                {/* {RenderCalculate()}
                                {RenderBalance()}
                                {RenderBet()} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
