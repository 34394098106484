import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Checkbox } from "primereact/checkbox";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";

// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { CommunityService } from "../service/community.service";
import { CustomPagination } from "../share/custom-pagination";
import { convertDate, OpenAgentDetail } from "../utility/help";

interface State {
    selectBox: any[];
    dropdownItem: any;
    seach: any;

    note: any[];
    notice: any;
    activePage: number;
    maxCount: number;
}

export class NoteAgent extends Component<any, State> {
    communityService = new CommunityService();

    constructor(props: any) {
        super(props);

        this.state = {

            note: [],
            activePage: 1,
            maxCount: 1,
            notice: {
                mode: "agent",
                del: "n",
                text: "",
                title: "",
                agentId: "",
            },

            selectBox: [
                { label: "선택", value: null },
                { label: "아이디", value: "id" },
                { label: "에이전트", value: "agent" },
                { label: "레벨", value: "level" },
            ],
            dropdownItem: null,
            seach: "",

        };

    }

    componentDidMount() {
        this.handleUserNote(1);
    }



    handleDelUserNote = () => {
        let del: any[] = []
        for (let no of this.state.note) {
            if (no.check) {
                del.push(no.code)
            }
        }
        // this.setState({ activePage: skip });
        this.communityService.admin_del_user_note(del).then((data: any) => {
            if (data.status == "success") {
                confirmAlert({
                    title: "에이전트 쪽지",
                    message: "에이전트페이지에서 삭제되었습니다.",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                window.location.reload();
                            },
                        },
                    ],
                });
            }
            else {
                confirmAlert({
                    title: "에이전트 쪽지",
                    message: "알수 없는 예러 발생.",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                window.location.reload();
                            },
                        },
                    ],
                });

            }
        });
    };

    handleUserNote = (skip: any) => {
        this.setState({ activePage: skip });

        if (this.state.dropdownItem == null || this.state.seach == '') {
            this.communityService.admin_get_agent_note(null, null, null, skip).then((data: any) => {
                if (data.status == "success") {
                    this.setState({
                        note: data.notes, maxCount: data.maxCount,
                    });
                }
            });
        }
        else if (this.state.dropdownItem === 'id') {
            this.communityService.admin_get_agent_note(null, this.state.seach, null, skip).then((data: any) => {
                if (data.status == "success") {
                    this.setState({
                        note: data.notes, maxCount: data.maxCount,
                    });
                }
            });
        }
        else if (this.state.dropdownItem === 'agent') {
            this.communityService.admin_get_agent_note(null, null, this.state.seach, skip).then((data: any) => {
                if (data.status == "success") {
                    this.setState({
                        note: data.notes, maxCount: data.maxCount,
                    });
                }
            });
        }
        else if (this.state.dropdownItem === 'level') {
            this.communityService.admin_get_agent_note(Number(this.state.seach), null, null, skip).then((data: any) => {
                if (data.status == "success") {
                    this.setState({
                        note: data.notes, maxCount: data.maxCount,
                    });
                }
            });
        }

    };


    handleDelAllNote = () => {
        confirmAlert({
            title: "에이전트 쪽지",
            message: "에이전트 쪽지를 삭제합니다 3일전 내역은 모두 삭제됩니다.",
            buttons: [
                {
                    label: "확인",
                    onClick: () => {

                        this.communityService.admin_del_all_agent_note().then((data: any) => {
                            if (data.status == "success") {
                                confirmAlert({
                                    title: "에이전트 쪽지",
                                    message: "에이전트페이지에서 삭제되었습니다.",
                                    buttons: [
                                        {
                                            label: "확인",
                                            onClick: () => {
                                                window.location.reload();
                                            },
                                        },
                                    ],
                                });
                            }
                            else {
                                confirmAlert({
                                    title: "에이전트 쪽지",
                                    message: "알수 없는 예러 발생.",
                                    buttons: [
                                        {
                                            label: "확인",
                                            onClick: () => {
                                                window.location.reload();
                                            },
                                        },
                                    ],
                                });

                            }
                        });
                    },
                },
                {
                    label: "취소",
                    onClick: () => {

                    },
                },
            ],
        });
        return;

    };


    handleWirteNote = () => {
        if (this.state.notice.title == null || this.state.notice.title == "" || this.state.notice.title.length === 0) {
            confirmAlert({
                title: "에이전트 쪽지",
                message: "내용밎 제목을 적어주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return;
        }

        if (this.state.notice.text == null || this.state.notice.text == "" || this.state.notice.text.length === 0) {
            confirmAlert({
                title: "에이전트 쪽지",
                message: "내용밎 제목을 적어주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return;
        }

        this.communityService
            .admin_wirte_agent_note(this.state.notice)
            .then((data) => {
                if (data.status == "success") {
                    confirmAlert({
                        title: "에이전트 쪽지",
                        message: "메세지 전송 성공.",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    window.location.reload();
                                },
                            },
                        ],
                    });
                } else {
                    confirmAlert({
                        title: "에이전트 쪽지",
                        message: "찾을수 없는 에이전트 아이디입니다.",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    window.location.reload();
                                },
                            },
                        ],
                    });
                }
            });
    };

    render() {
        let items = this.state.note;

        const actionTemplate = (rowData, column) => {
            return (
                <div>
                    <Button
                        icon="pi-md-edit"
                        onClick={() => {
                            this.setState({ notice: rowData });
                        }}
                    ></Button>
                </div>
            );
        };

        const RenderUser = () => {
            if (this.state.notice.mode == "agent") {
                return (
                    <div className="p-col-12">
                        <span className="p-float-label">
                            <InputText
                                id="in"
                                style={{
                                    width: "70%",
                                }}
                                value={this.state.notice.user}
                                onChange={(e: any) => {
                                    this.state.notice.agentId = e.target.value;
                                    this.setState({ notice: this.state.notice });
                                }}
                            />
                            <label htmlFor="in">user</label>
                        </span>
                    </div>
                );
            }

        };

        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">에이전트 쪽지</div>

                                <div className="p-grid form-group">
                                    <div className="p-col-12 p-md-4">
                                        <span className="md-inputfield">
                                            <Dropdown
                                                style={{ width: "100px" }}
                                                options={this.state.selectBox}
                                                value={this.state.dropdownItem}
                                                onChange={(event) =>
                                                    this.setState({ dropdownItem: event.value })
                                                }
                                                autoWidth={false}
                                            />
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <span className="md-inputfield">
                                            <InputText
                                                value={this.state.seach}
                                                onChange={(e: any) => this.setState({ seach: e.target.value })}
                                                onKeyPress={(e: any) => {
                                                    if (e.key === "Enter") {
                                                        this.handleUserNote(1);
                                                    } else {
                                                    }
                                                }}
                                            />
                                            <label>검색</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <span className="md-inputfield">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handleUserNote(1);
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-2">
                                        <span className="md-inputfield">
                                            <Button
                                                label="삼일 이전내역삭제"
                                                onClick={() => {
                                                    this.handleDelAllNote();
                                                }}
                                            />
                                        </span>
                                    </div>
                                    {/* <div className="p-col-12 p-md-2">
                                        <span className="md-inputfield">
                                            <Button
                                                style={{ backgroundColor: "#dc3545" }}
                                                label="삭제"
                                                onClick={() => this.handleDelUserNote()}
                                            />
                                        </span>
                                    </div> */}
                                </div>
                            </div>


                        </div>
                        <div className="p-col-12 p-md-4">
                            <div className="card">
                                <CustomPagination
                                    active={this.state.activePage}
                                    take={20}
                                    count={this.state.maxCount}
                                    handlePageChange={(page) => {
                                        this.handleUserNote(page)
                                    }}
                                ></CustomPagination>
                                <DataTable
                                    value={items}
                                    paginatorPosition="both"
                                    rows={20}
                                    sortOrder={-1}
                                >

                                    <Column
                                        style={{ textAlign: "center" }}
                                        header="타이틀"
                                        body={(rowData: any, column: any) => {
                                            return <div onClick={() => { this.setState({ notice: rowData }) }}>{rowData.title}</div>;
                                        }}
                                    />





                                    <Column
                                        style={{ textAlign: "center" }}
                                        header="에이전트"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.length > 1) return "전체 쪽지";
                                            return (
                                                <div onClick={() => OpenAgentDetail(rowData.userId)}>{rowData.userId}</div>
                                            );
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center" }}
                                        header="확인/미확인"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.isRead == "n") return "미확인";
                                            return (
                                                <div>
                                                    <div>{"확인"}</div>
                                                    <div>{convertDate(rowData.regDate)}</div>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        body={actionTemplate}
                                        style={{ textAlign: "center", width: "8em" }}
                                    />
                                </DataTable>
                                <CustomPagination
                                    active={this.state.activePage}
                                    take={20}
                                    count={this.state.maxCount}
                                    handlePageChange={(page) => {
                                        this.handleUserNote(page)
                                    }}                ></CustomPagination>


                            </div>
                        </div>
                        <div className="p-col-12 p-md-8">
                            <div className="card">
                                <div className="p-col-12">
                                    <div className="p-col-12 p-md-4">
                                        <RadioButton
                                            value="all"
                                            inputId="rb1"
                                            onChange={(event) => {
                                                this.state.notice.mode = event.value;

                                                this.setState({ notice: this.state.notice });
                                            }}
                                            checked={this.state.notice.mode === "all"}
                                        />
                                        <label htmlFor="cb1" className="p-checkbox-label">
                                            전체
                                        </label>
                                        <RadioButton
                                            value="agent"
                                            inputId="rb1"
                                            onChange={(event) => {
                                                this.state.notice.mode = event.value;
                                                this.setState({ notice: this.state.notice });
                                            }}
                                            checked={this.state.notice.mode === "agent"}
                                        />

                                        <label htmlFor="cb2" className="p-checkbox-label">
                                            에이전트
                                        </label>


                                    </div>
                                </div>

                                {RenderUser()}

                                <div className="p-col-12">
                                    <span className="p-float-label">
                                        <InputText
                                            id="in"
                                            style={{
                                                width: "70%",
                                            }}
                                            value={this.state.notice.title}
                                            onChange={(e: any) => {
                                                this.state.notice.title = e.target.value;
                                                this.setState({ notice: this.state.notice });
                                            }}
                                        />
                                        <label htmlFor="in">Title</label>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <Editor
                                        style={{ height: "320px" }}
                                        value={this.state.notice.text}
                                        onTextChange={(e) => {
                                            this.state.notice.text = e.htmlValue;

                                            this.setState({ notice: this.state.notice });
                                        }}
                                    />
                                </div>
                                <div className="p-col-12">
                                    <div className="p-col-12 p-md-4">
                                        <RadioButton
                                            value="y"
                                            inputId="rb1"
                                            onChange={(event) => {
                                                this.state.notice.del = event.value;

                                                this.setState({ notice: this.state.notice });
                                            }}
                                            checked={this.state.notice.del === "y"}
                                        />
                                        <label htmlFor="cb1" className="p-checkbox-label">
                                            숨김
                                        </label>
                                        {"      "}
                                        <RadioButton
                                            value="n"
                                            inputId="rb1"
                                            onChange={(event) => {
                                                this.state.notice.del = event.value;
                                                this.setState({ notice: this.state.notice });
                                            }}
                                            checked={this.state.notice.del === "n"}
                                        />

                                        <label htmlFor="cb2" className="p-checkbox-label">
                                            보기
                                        </label>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <Button
                                        label="저장 하기"
                                        onClick={() => {
                                            this.handleWirteNote();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
