import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";
import { isMobile, isMobileOnly } from "react-device-detect";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";
import { AgentService } from "../service/agent.service";

interface State {


  telegramCode : string; 
  selectBox: any;
  dropdownItem: any;


}

export class Setting extends Component<any, State> {
  adminService: AdminService;
  growl: any;
  agentService: AgentService = new AgentService()


  constructor(props: any) {
    super(props);
    this.state = {
        telegramCode : '',
        selectBox: [
          { label: "알람1", value: '021' },
          { label: "알람2", value: '022' },
          { label: "알람3", value: '023' },
          { label: "알람4", value: '024' },
          { label: "알람5", value: '025' },
          { label: "알람6", value: '026' },
          { label: "알람10", value: '030' },
          { label: "알람11", value: '010' },
          { label: "알람12", value: '011' },
          { label: "알람13", value: '012' },
          { label: "알람14", value: '013' },
          { label: "알람15", value: '014' },

          { label: "알람20", value: '111' },
          { label: "알람21", value: '112' },

        ],
        dropdownItem: localStorage.getItem('sound') == null ? '024' : localStorage.getItem('sound')
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetTelegram()
  }

  handleUpdateTelegram = async () => {

    await this.agentService.updateTelegram()


  }

  handleGetTelegram = async () => {

    const telegram = await this.agentService.getTelegram()

    if(telegram.status === 'success'){

      this.setState({telegramCode : telegram.code})
      

    }
  }
  


  render() {

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">

                <div className="card-title">설정</div>
                <div className="p-col-6">
                  <div className="p-grid form-group">
           
               
                  </div>
                </div>

                <div className="p-col-6">
                  <div className="p-grid form-group">
                    {/* <div className="p-col-6">
                      <span className="md-inputfield">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={event => {
                            this.setState({ selectViewType: event.value });

                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              event.value
                            );
                          }}
                        />
                      </span>
                    </div> */}

                    
                  <div className="checkbox">
                    <label>

                      <Dropdown
                        options={this.state.selectBox}
                        value={this.state.dropdownItem}
                        onChange={(event) => {

                          this.setState({ dropdownItem: event.value })
                        }}


                        className="form-control input-sm"
                        style={{ width: "120px" }}
                      />
                      알람음 변경
                    </label>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      label="적용"
                      onClick={() => {
                        localStorage.setItem('sound', this.state.dropdownItem);

                      }}
                      className="btn btn-primary btn-sm"
                    />
                  </div>
                  </div>
                </div>
              </div>


              <div className="card">


                <div className="p-md-6">
                    텔레그램 추가후 @notificationBalance
                    <br/>
                    <br/>

                    <span style={{ color: "red" }}>{`${this.state.telegramCode}`}</span> {`<= 해당코드 입력후 아래 보이는 텔레그램 업데이트 버튼을 눌러주세요`}
                    <br/>

                    <Button
                        label="텔레그램 업데이트"
                        onClick={() => {
                          this.handleUpdateTelegram();
                        }}
                    />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
