import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { AgentService } from "../../service/agent.service";
import { AlertYesNo } from "../alert-yes-no";
import { confirmAlert } from "react-confirm-alert";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
} from "../../utility/help";

interface Props {
  agent: any;
  handleUserChange: (e: any) => any;
}

interface State {
  userTableValue: any;
  page: any;
  pages: any;
  field: any;
  checkboxValue: any;
}

export class AgentBasic extends Component<Props, State> {
  agentService: AgentService;
  constructor(props: Props) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      checkboxValue: [],
    };

    this.agentService = new AgentService();
  }

  render() {
    let agent = this.props.agent;
    let handleUserChange = this.props.handleUserChange;

    // admin_update_user_detail

    const SaveUser = () => {
      return (
        <AlertYesNo
          type="success"
          className="p-button-success"
          btnLabel="저장하기"
          title="에이전트 저장"
          message="에이전트 정보 를 변경하시겠습니까 "
          call={() => {
            this.agentService
              .save_agent_detail({
                nick: agent.nick,
                agent: agent.agent,
                pass: agent.pass,
                phone: agent.phone,
                bankname: agent.bankname,
                banknum: agent.banknum,
                bankowner: agent.bankowner,
                simplememo: agent.simplememo,
                status: agent.status,
                ignored: agent.ignored,
                ignoredCasino: agent.ignoredCasino,

                memo: agent.memo,
                color: agent.color,
              })
              .then((data) => {
                if (data.status == "success") {
                  confirmAlert({
                    title: "확인",
                    message: "에이전트 정보가 변경되었습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status == "params") {
                  confirmAlert({
                    title: "확인",
                    message: "잘못된 데이터가 포함되어 있습니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "확인",
                    message: "관리자에게 문의 부탁드림니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          }}
        ></AlertYesNo>
      );
    };

    return (
      <div>
        <div className="p-grid">
          <div className="p-col-12 p-md-4">
            <label htmlFor="input">닉네임</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              placeholder="닉네임"
              id="nick"
              value={agent.nick}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">비밀번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              placeholder="비밀번호"
              id="pass"
              value={agent.pass}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">전화번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="phone"
              placeholder="전화번호"
              value={agent.phone}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">은행명</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="bankname"
              placeholder="은행명"
              value={agent.bankname}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">통장번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="banknum"
              placeholder="통장번호"
              value={agent.banknum}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">예금주</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="bankowner"
              placeholder="예금주"
              value={agent.bankowner}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">가입일</label>
          </div>
          <div className="p-col-12 p-md-8">{convertDate(agent.regdate)}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">최근접속일</label>
          </div>

          <div className="p-col-12 p-md-8">{convertDate(agent.lastdate)}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">IP</label>
          </div>

          <div className="p-col-12 p-md-8">{agent.loginip ? agent.loginip : '-'}</div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">간단메모</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="simplememo"
              field="simplememo"
              value={agent.simplememo}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">메모</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputTextarea
              id="memo"
              rows={5}
              cols={30}
              autoResize={true}
              field="memo"
              value={agent.memo}
              onChange={handleUserChange}
            ></InputTextarea>{" "}
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">색상</label>
          </div>
          <div className="p-col-12 p-md-8">
            <div className="p-grid form-group">
              <InputText
                id="color"
                placeholder="아이디 색상"
                value={agent.color}
                onChange={handleUserChange}
              />
              <Button
                style={{ backgroundColor: "#007bff" }}
                label="#007bff"
                onClick={() => {
                  let color = "#007bff";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
              <Button
                style={{ backgroundColor: "#28a745" }}
                label="#28a745"
                onClick={() => {
                  let color = "#28a745";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />

              <Button
                style={{ backgroundColor: "#fd7e14" }}
                label="#fd7e14"
                onClick={() => {
                  let color = "#fd7e14";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
              <Button
                style={{ backgroundColor: "#dc3545" }}
                label="#dc3545"
                onClick={() => {
                  let color = "#dc3545";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />

              <Button
                style={{ backgroundColor: "#000000" }}
                label="#000000"
                onClick={() => {
                  let color = "#000000";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-4">
            <label htmlFor="input">회원 상태</label>
          </div>
          <div className="p-col-12 p-md-8">
            <div className="p-grid">
              <div className="p-col-12">
                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="used"
                    id="status"
                    inputId="status"
                    onChange={handleUserChange}
                    checked={agent.status === "used"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    사용중
                  </label>
                </div>

                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="stop"
                    id="status"
                    inputId="status"
                    onChange={handleUserChange}
                    checked={agent.status === "stop"}
                  />
                  <label htmlFor="status" className="p-radiobutton-label">
                    사용정지
                  </label>
                </div>

                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="remove"
                    id="status"
                    inputId="status"
                    onChange={handleUserChange}
                    checked={agent.status === "remove"}
                  />
                  <label htmlFor="status" className="p-radiobutton-label">
                    삭제
                  </label>
                </div>
              </div>
            </div>
          </div>



          <div className="p-col-12 p-md-4">
            <label htmlFor="input">슬롯 바퀴당</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="ignored"
              placeholder="0 으로 셋팅시 안빠짐"
              value={agent.ignored}
              onChange={handleUserChange}
            />
            <br />
            <br />
            <label htmlFor="input">롤링개수 빼기</label>
          </div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">카지노 바퀴당</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="ignoredCasino"
              placeholder="0 으로 셋팅시 안빠짐"
              value={agent.ignoredCasino}
              onChange={handleUserChange}
            />
            <br />
            <br />
            <label htmlFor="input">롤링개수 빼기</label>
          </div>


          <div className="p-col-12 p-md-4"></div>
          <div className="p-col-12 p-md-4">
            {SaveUser()}
            {/* <Button
              label="저장하기"
              icon="pi-md-launch"
              onClick={() => SaveUser()}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
