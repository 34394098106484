import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";
interface DetailProps {
  matchId: string;
}

interface DetailState {
  matchId: string;
  begin: any;
  end: any;
  userTableValue: any;
  details: any;
  findText: any;
  searchText: any;
  page: any;
  maxCount: any;
  cities: any;
  summary: any;
}

export class DetailMatch extends Component<DetailProps, DetailState> {
  betService: BetService;
  constructor(props: DetailProps) {
    super(props);
    let today = GetToday();

    this.state = {
      matchId: "",
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 1,
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" }
      ],

      summary: {}
    };

    this.betService = new BetService();

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleOpenDetail = this.handleOpenDetail.bind(this);
    this.handleGetMatchDetailByMatchId = this.handleGetMatchDetailByMatchId.bind(
      this
    );
  }

  componentDidMount() {
    let matchId = this.props.matchId;
    this.handleGetMatchDetailByMatchId(0, matchId);
  }

  handleGetMatchDetailByMatchId = (page: any, matchId: any) => {
    this.betService.get_match_details_by_matchId(page, matchId).then(data => {
      for (let match of data.matchs) {
        this.betService
          .get_match_detail_by_betId(match._id)
          .then(detailData => {
            this.state.details[detailData.betId] = detailData.detail;

            this.setState({
              details: this.state.details,
              maxCount: data.maxCount,
              summary: data.summary
            });
          });
      }

      this.setState({
        userTableValue: data.bets,
        maxCount: data.count,
        summary: data.summary
      });
    });
  };

  get_match_details = (matchId: any) => {
    this.betService.get_match_details(matchId).then(data => {
      if (data.pre != undefined) {
        let matchID = data.pre._id;

        this.state.details[matchID] = data.pre;

        this.setState({
          details: this.state.details
        });
      }
    });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px"
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    //    this.get_progress_live_game(page);
  };

  handleOpenDetail = (matchId: any) => {
    console.log(matchId);
    this.get_match_details(matchId);
  };

  render() {
    let activePage = this.state.page == undefined ? 1 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      return userTableValue.map((contact: any, i: any) => {
        return (
          <BetMatchTable
            bet={contact}
            key={contact._id}
            details={this.state.details[contact._id]}
            mode="bet"
          ></BetMatchTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">스포츠 배팅정보</div>
                <div>
                  <div
                    className="card card-w-title"
                    style={{ padding: "0px" }}
                  ></div>
                </div>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
