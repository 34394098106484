import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";

import { Growl } from "primereact/growl";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  markets: any;
  maxCount: any;
  activePage: number;
  expandedRows?: any;

  selectBox: any;
  searchText: any;
  findText: any;
}

export class SetMakets extends Component<any, State> {
  [x: string]: any;
  adminService = new AdminService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      markets: [],
      maxCount: 1,
      activePage: 1,
      searchText: "",
      findText: "",

      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "name" },
        { label: "필터", value: "filter" },
      ],
    };
  }

  componentDidMount() {
    this.handleGetMiniGameSet(1, "", "");
  }

  handleGetMiniGameSet = (page: any, searchText: any, findText: any) => {
    this.setState({ activePage: page });
    this.adminService
      .admin_get_markets(page, searchText, findText)
      .then((data: any) => {
        this.setState({ markets: data.markets, maxCount: data.maxCount });
      });
  };

  handleEditMarket = (id: any, groups: any, separate: any, nameko: any) => {
    this.adminService
      .admin_update_markets(id, groups, separate, nameko)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
        //        this.setState({ markets: data.markets, maxCount: data.maxCount });
      });
  };

  render() {
    let markets = this.state.markets;

    const rowExpansionTemplate = (data: any): any => {
      if (data.outcomes == undefined) {
        return <div></div>;
      }

      let keys = Object.keys(data.outcomes);

      return (
        <DataTable
          value={keys}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          className="success"
          header={"test"}
        >
          <Column
            style={{ textAlign: "center" }}
            body={(rowData: any, column: any) => {
              return (
                <div className="p-grid">
                  <div className="p-col-12 p-md-3">{rowData}</div>
                  <div className="p-col-12 p-md-3">
                    {data.outcomes[rowData].en}
                  </div>
                  <div className="p-col-12 p-md-3">
                    <InputText
                      style={{ width: "400px" }}
                      placeholder="stop Time"
                      value={data.outcomes[rowData].ko}
                    />
                  </div>
                </div>
              );
            }}
          />
        </DataTable>
      );
    };

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">마켓 설정</div>
                  {/* 
                  <div className="p-md-6">
                    <Button label="저장 하기" onClick={() => {}} />
                  </div> */}

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.selectBox}
                          value={this.state.searchText}
                          onChange={(event) =>
                            this.setState({ searchText: event.value })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(e: any) =>
                            this.setState({ findText: e.target.value })
                          }
                          onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              this.handleGetMiniGameSet(
                                1,
                                this.state.searchText,
                                this.state.findText
                              );
                            } else {
                            }
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handleGetMiniGameSet(
                              1,
                              this.state.searchText,
                              this.state.findText
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handleGetMiniGameSet(
                      event,
                      this.state.searchText,
                      this.state.findText
                    );
                  }}
                ></CustomPagination>
                <DataTable
                  value={markets}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={this.state.expandedRows}
                  onRowToggle={(e: any) => {
                    this.setState({ expandedRows: e.data });
                  }}
                >
                  <Column expander={true} style={{ width: "2em" }} />
                  <Column
                    style={{ textAlign: "center" }}
                    header="categoryId"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return <div>{rowData.name.en} </div>;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="게임타입"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            style={{ width: "300px" }}
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.name.ko = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={rowData.name.ko}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="필터"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.groups = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={rowData.groups}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="그룹 묶음"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div className="p-col-12">
                          <div className="p-grid form-group">
                            {/* <div className="p-col-12 p-md-4">
                              <RadioButton
                                value="y"
                                id="status"
                                inputId="status"
                                onChange={(even: any) => {
                                  let market = markets.find(
                                    s => s._id == rowData._id
                                  );
                                  if (market != null) {
                                    market.separate = "n";
                                    this.setState({ markets: markets });
                                  }
                                }}
                                checked={rowData.separate === "n"}
                              />
                              <label
                                htmlFor="rb2"
                                className="p-radiobutton-label"
                              >
                                묶기
                              </label>
                            </div> */}

                            <InputText
                              placeholder="stop Time"
                              onChange={(even: any) => {
                                let market = markets.find(
                                  (s) => s._id == rowData._id
                                );
                                if (market != null) {
                                  market.separate = even.target.value;
                                  this.setState({ markets: markets });
                                }
                              }}
                              value={rowData.separate}
                            />
                            {/* <div className="p-col-12 p-md-4">
                              <RadioButton
                                value="n"
                                id="status"
                                inputId="status"
                                onChange={(even: any) => {
                                  let market = markets.find(
                                    s => s._id == rowData._id
                                  );
                                  if (market != null) {
                                    market.separate = "y";
                                    this.setState({ markets: markets });
                                  }
                                }}
                                checked={rowData.separate === "y"}
                              />
                              <label
                                htmlFor="status"
                                className="p-radiobutton-label"
                              >
                                분리
                              </label>
                            </div> */}
                          </div>
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="저장"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <Button
                            className="p-button-raised p-button-warning"
                            label="저장하기 "
                            onClick={() => {
                              this.handleEditMarket(
                                rowData.id,
                                rowData.groups,
                                rowData.separate,
                                rowData.name.ko
                              );
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handleGetMiniGameSet(
                      event,
                      this.state.searchText,
                      this.state.findText
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
