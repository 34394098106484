import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Spinner } from "primereact/spinner";
import { confirmAlert } from "react-confirm-alert";

import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { MatchDetail } from "./match-detail";

import { BetService } from "../../service/bet.service";

//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  ConvertSpecifiersGame,
} from "../../utility/help";
import { config } from "@fullcalendar/core";

interface Props {
  mode: any;
  game: any;
  selectBalanceType: string;
  // handleOpenDetail: any;
  handleSelectWinGame: any;
  handleUpdateOdds: any;
}

interface State {
  active: number;
  detailsBet: any;
  deposits: any[];
  page: number;
  pages: any;
  field: string;
  details: any[][];
}

export class ProgressLiveTable extends Component<Props, State> {
  betService = new BetService();
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      details: [],
      page: 0,
      pages: {},
      field: "",
      detailsBet: {},
    };
  }

  componentDidMount = () => {};

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };
  handlePlusOddsChange = (e: any) => {
    console.log(e);
  };

  handlePageChange = (e: any) => {
    console.log(e);
    this.setState({ active: e });
  };

  handleLiveView = (id: any, isOpen: any) => {
    this.betService.admin_set_open_live_game(id, isOpen).then((data: any) => {
      if (data.status == "success") {
        // confirmAlert({
        //   title: "라이브",
        //   message: "게임정보가 수정되었습니다.",
        //   buttons: [
        //     {
        //       label: "확인",
        //       onClick: () => {},
        //     },
        //   ],
        // });
      } else {
        confirmAlert({
          title: "라이브",
          message:
            "에러가 발생되었습니다 지속적으로 발생될경우 문의 부탁드림니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  render = () => {
    const mode = this.props.mode;
    const game = this.props.game;
    const details = this.state.details;
    const matchId = game.matchId;

    // const handleOpenDetail = this.props.handleOpenDetail;
    // const handleSelectWinGame = this.props.handleSelectWinGame;

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    if (mode == "end") {
      customHeaderStyle = { backgroundColor: "#cecece", textAlign: "center" };
    }

    let first: any[] = [];
    let second: any[] = [];
    let thread: any[] = [];

    let filters: any[] = [];

    if (details !== undefined) {
      let firstCount = 0;
      let secondCount = 0;
      let thirdCount = 0;

      for (let [key, value] of Object.entries(details)) {
        if (value.length >= 1 && value[0].outcomeOdds.length >= 4) {
          let odds: any[] = [];
          let copyOdds = JSON.parse(JSON.stringify(value[0].outcomeOdds));

          for (var i = 0; i < copyOdds.length; i++) {
            let index = Math.trunc(i / 3);
            if (odds[index] == null) {
              odds[index] = [];
            }
            odds[index].push(copyOdds[i]);
          }

          value[0].outcomes = odds[0];

          for (var i = 1; i < odds.length; i++) {
            var bar = JSON.parse(JSON.stringify(value[0]));
            bar.outcomes = JSON.parse(JSON.stringify(odds[i]));
            details[Number(key)].push(bar);
          }
        } else {
          for (let element of Object.values(value)) {
            if (element.outcomes === undefined && element.outcomeOdds != null) {
              if (Object.values(element.outcomeOdds).length >= 4) {
                element.outcomes = [];
              } else {
                element.outcomes = Object.values(element.outcomeOdds);
              }
            }
          }
        }
      }

      for (let value of Object.values(details)) {
        if (value.length > 0) {
          let group = value[0].group == undefined ? "" : value[0].group;
          for (let filter of group.split("|")) {
            filters[filter] = true;
          }
        }
        let length = 0;
        for (let item of Object.values(value)) {
          if (item.outcomeOdds != null) {
            length = length + Object.values(item.outcomeOdds).length;
          }
        }

        if (firstCount == 0 || thirdCount > firstCount) {
          firstCount += length;
          first.push(value);
        } else if (firstCount > secondCount) {
          secondCount += length;
          second.push(value);
        } else {
          thirdCount += length;
          thread.push(value);
        }
      }
    }

    const RenderMode = () => {
      if (mode == "end") {
        return (
          <Column
            style={{ textAlign: "center" }}
            header={"결과"}
            body={(rowData: any, column: any) => {
              return <div>{rowData.result}</div>;
            }}
          />
        );
      }

      return <div></div>;
    };

    const RenderMiniBet = (matchId: number, items: any[]) => {
      return items.map((item, i) => {
        return Object.values(item).map((contact: any, i: any) => {
          let name = i + "_" + contact.id;

          let specifiers = ConvertSpecifiersGame(contact.specifiers);
          let betBalance =
            this.state.detailsBet == null
              ? {}
              : this.state.detailsBet[contact.id] == null
              ? {}
              : this.state.detailsBet[contact.id][specifiers];
          return (
            <MatchDetail
              key={name}
              matchId={matchId}
              marketId={contact.id}
              contact={contact}
              detailsBet={betBalance}
              mode={mode}
              editOdds={{}}
              handleUpdateOdds={this.props.handleUpdateOdds}
              handleUpdateActive={() => {}}
              handleUpdateFix={() => {}}
              handleEditOdds={() => {}}
            ></MatchDetail>
          );
        });
      });
    };

    return (
      <div>
        <br />
        <DataTable
          value={[game]}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
        >
          <Column
            style={{ textAlign: "center", width: "70px" }}
            header="코드"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{rowData.matchId} </div>;
            }}
          />
          <Column
            style={{ textAlign: "center", width: "120px" }}
            header="게임시간"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.dateOfMatch)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "120px" }}
            header="스포츠"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{rowData.sportName}</div>;
            }}
          />
          <Column
            style={{ textAlign: "center", width: "120px" }}
            field="win"
            header="리그"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{rowData.tournamentName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="홈"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              let home = rowData.competitors.find(
                (s: any) => s.qualifier == "home"
              );

              return <div>{home.name}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="원정"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              let away = rowData.competitors.find(
                (s: any) => s.qualifier == "away"
              );

              return <div>{away.name}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "120px" }}
            header="게임 수"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              if (rowData.live == null) {
                return <div>{0}</div>;
              }
              let count = Object.values(rowData.live).length;
              return <div>{count}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            header="배팅금액 합"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              if (rowData.liveBetBalance > 500000) {
                return (
                  <div style={{ color: "red" }}>
                    {numTomoeny(
                      rowData.liveBetBalance || rowData.liveBetBalance || 0
                    )}
                  </div>
                );
              } else if (rowData.liveBetBalance > 0) {
                return (
                  <div style={{ color: "orange" }}>
                    {numTomoeny(
                      rowData.liveBetBalance || rowData.liveBetBalance || 0
                    )}
                  </div>
                );
              } else {
                return (
                  <div>
                    {numTomoeny(
                      rowData.liveBetBalance || rowData.liveBetBalance || 0
                    )}
                  </div>
                );
              }
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            header="게임닫기/열기"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <div className="p-grid">
                  <div className="p-col-6">
                    <RadioButton
                      value="y"
                      inputId="rb2"
                      onChange={(event) => {
                        rowData.isOpenLive = "y";
                        this.handleLiveView(rowData._id, "y");
                      }}
                      checked={rowData.isOpenLive === "y"}
                    />
                    <label htmlFor="rb2" className="p-radiobutton-label">
                      열기
                    </label>
                  </div>
                  <div className="p-col-6">
                    <RadioButton
                      value="n"
                      inputId="rb1"
                      onChange={(event) => {
                        rowData.isOpenLive = "n";

                        this.handleLiveView(rowData._id, "n");
                      }}
                      checked={
                        rowData.isOpenLive === "n" ||
                        rowData.isOpenLive === null
                      }
                    />
                    <label htmlFor="rb1" className="p-radiobutton-label">
                      닫기
                    </label>
                  </div>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center", width: "150px" }}
            header=""
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <div>
                  <Button
                    label="더보기"
                    icon="pi-md-launch"
                    onClick={() => {
                      if (
                        this.state.details == null ||
                        Object.values(this.state.details).length == 0
                      ) {
                        this.setState({
                          details: rowData.live,
                          detailsBet: rowData.liveBet,
                        });
                      } else {
                        this.setState({ details: [], detailsBet: {} });
                      }
                    }}
                  />
                </div>
              );
            }}
          />
        </DataTable>
        <div className="p-col-12 ">
          <div className="p-grid">
            <div className="p-col-12 p-md-4">
              {RenderMiniBet(matchId, first)}
            </div>
            <div className="p-col-12 p-md-4">
              {RenderMiniBet(matchId, second)}
            </div>
            <div className="p-col-12 p-md-4">
              {RenderMiniBet(matchId, thread)}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
