import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import { SubPaging } from "../share/sub-paging";

import {
  OpenAgentDetail,
  GetAgentTreeByLevel,
  converAgentStatus,
} from "../utility/help";

interface AgentState {
  agent: any;

  activePage: any;
  maxCount: any;
}

export class AgentReg extends Component<any, AgentState> {
  AgentService: AgentService;
  constructor(props: any) {
    super(props);

    this.state = {
      agent: [],
      activePage: 1,
      maxCount: 1,
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);

    this.AgentService = new AgentService();
  }

  componentDidMount() {
    this.handleAgentWaitList();
  }

  handleMoveSel = (page: any) => { };

  handleAgentWaitList = () => {
    this.AgentService.admin_get_agent_wait().then((s: any) => {
      this.setState({
        agent: s.agent,
        maxCount: s.maxCount,
      });
    });
  };

  handleAgentDel = (idx: any) => {
    this.AgentService.admin_agnet_stause_change_del(idx).then((s: any) => {
      this.setState({
        agent: s.agent,
      });
      window.location.reload();
    });
  };
  handleAgentWait = (idx: any) => {
    this.AgentService.admin_agnet_stause_change_wait(idx).then((s: any) => {
      this.setState({
        agent: s.agent,
      });

      window.location.reload();
    });
  };
  handleAgentAccept = (idx: any) => {
    this.AgentService.admin_agnet_stause_change_accept(idx).then((s: any) => {
      this.setState({
        agent: s.agent,
      });
      window.location.reload();
    });
  };

  render = () => {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 승인대기</div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />

                <DataTable
                  value={this.state.agent}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                // onSort={this.onSortChange}
                // header={header}
                // sortField={this.state.sortField}
                // sortOrder={this.state.sortOrder}
                >
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="아이디"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                          <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="연락처"
                    body={(agent: any) => {
                      return agent.phone;
                    }}
                    field="phone"

                  // sortFunction={}
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="본사"
                    // body={(agent: any) => {
                    //   return agent.code;
                    // }}
                    body={(agent: any) => {
                      return GetAgentTreeByLevel(agent.agent_tree, 1);
                    }}
                    field="level"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="부본사"
                    body={(agent: any) => {
                      return GetAgentTreeByLevel(agent.agent_tree, 2);
                    }}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="총판"
                    body={(agent: any) => {
                      return GetAgentTreeByLevel(agent.agent_tree, 3);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="매장"
                    body={(agent: any) => {
                      return GetAgentTreeByLevel(agent.agent_tree, 4);
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="은행명"
                    body={(agent: any) => {
                      return agent.bankname;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="입금자명"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="계좌번호"
                    body={(agent: any) => {
                      return agent.banknum;
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="등록일"
                    body={(agent: any) => {
                      return agent.regdate;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="상태"
                    body={(agent: any) => {
                      return converAgentStatus(agent.status);
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    header="수락"
                    body={(agent: any) => {
                      return (
                        <Button
                          label="수락"
                          className="p-button-success"
                          onClick={() => {
                            this.handleAgentAccept(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    header="대기"
                    body={(agent: any) => {
                      return (
                        <Button
                          label="대기"
                          className="p-button-warning"
                          onClick={() => {
                            this.handleAgentWait(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    header="삭제"
                    body={(agent: any) => {
                      return (
                        <Button
                          label="삭제"
                          className="p-button-danger"
                          onClick={() => {
                            this.handleAgentDel(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"

                  // handleAgentDel = (idx: any) => {};
                  // handleAgentWait = (idx: any) => {};
                  // handleAgentAccept = (idx: any) => {};
                  />
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
