import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
    casinos: any[];
    maxCount: number;
    activePage: number;
    selectItem: any;
    select: any;
    searchText: any;
    findText: any;
    selectViewType: string;
    viewType: any[];
}

export class SetCasinoMain extends Component<any, State> {
    adminService: AdminService = new AdminService();
    growl: any;

    constructor(props: any) {
        super(props);
        this.state = {
            casinos: [],
            maxCount: 1,
            activePage: 1,
            selectItem: {},
            searchText: "",
            findText: "",

            selectViewType: "all",

            viewType: [
                { label: "모두 보기", value: "all" },
                { label: "카테고리", value: "in" },
            ],

            select: [{ label: "리그", value: "agent" }],
        };

        this.handleGetCasinoGame(1, "", "", "all");

    }

    componentDidMount() {
    }

    handlePageChange = (page: any, searchText: any, findText: any, mode: any) => {
        console.log(page);
        this.setState({ activePage: page });
        this.handleGetCasinoGame(page, searchText, findText, mode);
    };

    // handleUpdateSlotGame = () => {
    //     confirmAlert({
    //         title: "게임 업데이트",
    //         message: "신규 게임은 한달에 한번 업데이트 됨니다",
    //         buttons: [
    //             {
    //                 label: "확인'",

    //                 onClick: () => {
    //                     this.adminService.admin_update_slot_games().then((data: any) => {
    //                         let msg = {
    //                             severity: "success",
    //                             summary: "게임이 ",
    //                             detail: "업데이트 되습니다",
    //                         };
    //                         this.growl.show(msg);
    //                     });
    //                 },
    //             },
    //         ],
    //     });
    // };

    handleGetCasinoGame = (page, searchText, findText, mode) => {
        this.adminService
            .admin_get_casino_main_games(page, searchText, findText, mode)
            .then((data: any) => {
                if (data.status == "success") {
                    this.setState({
                        casinos: data.casinos,
                        maxCount: Number(data.maxCount),
                    });
                }
            });
    };

    render() {
        let casinos = this.state.casinos;
        let activePage = this.state.activePage;
        let adminService = this.adminService;
        let growl: any;

        const handleEditCasino = (casino: any) => {
            adminService.admin_update_main_casino(casino).then((data: any) => {
                if (data.status == "success") {
                    let msg = {
                        severity: "success",
                        summary: data.name,
                        detail: "가 수정되었습니다",
                    };
                    this.growl.show(msg);
                } else {
                    let msg = {
                        severity: "error",
                        summary: data.tournamentid + " - " + data.name,
                        detail: "예러 발생 되었습니다 관리자에게 문의",
                    };
                    this.growl.show(msg);
                }
            });
        };

        return (
            <div>
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card">
                                <div className="card-title">카지노 게임 설정</div>

                                <div className="p-col-6">
                                    <div className="p-grid form-group">

                                        {/* 
                                        <div className="p-md-6">
                                            <Button
                                                label="슬롯 업데이트"
                                                onClick={() => {
                                                    this.handleUpdateSlotGame();
                                                }}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <Messages
                ref={(el: any) => this.setState({ messages: el })}
                style={{ marginBottom: "1em" }}
              /> */}
                            <Growl
                                ref={(el) => (this.growl = el)}
                                // ref={(el: any) => {
                                //   growl = el;
                                // }}
                                style={{ marginTop: "75px" }}
                            />

                            <div className="card">
                                <CustomPagination
                                    active={activePage}
                                    take={20}
                                    count={this.state.maxCount}
                                    handlePageChange={(event: any) => {
                                        this.handlePageChange(
                                            event,
                                            this.state.searchText,
                                            this.state.findText,
                                            this.state.selectViewType
                                        );
                                    }}
                                ></CustomPagination>

                                <DataTable
                                    value={casinos}
                                    paginatorPosition="both"
                                    rows={20}
                                    sortOrder={-1}
                                    scrollable={true}
                                >
                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="카테고리"
                                        body={(rowData: any, column: any) => {
                                            return `${rowData.company}`;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="영어"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.nameEn == null) return rowData.nameEn;
                                            return rowData.nameEn;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", width: "220px" }}
                                        header="한국어"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.nameKo == null) {
                                                rowData.nameKo = rowData.nameEn;
                                            }

                                            return (
                                                <InputText
                                                    style={{ width: "200px" }}
                                                    value={rowData.nameKo}
                                                    onChange={(even: any) => {
                                                        console.log(rowData.nameKo);
                                                        let tournament = this.state.casinos.find(
                                                            (s: any) => s._id == rowData._id
                                                        );
                                                        tournament.nameKo = even.target.value;

                                                        this.setState({
                                                            casinos: this.state.casinos,
                                                        });
                                                    }}
                                                ></InputText>
                                            );
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", width: "220px" }}
                                        header="사용"
                                        body={(rowData: any, column: any) => {
                                            return (
                                                <div className="p-grid form-group">
                                                    <div className="p-col-6">
                                                        <RadioButton
                                                            value="Ultima"
                                                            inputId="cb1"
                                                            onChange={() => {
                                                                rowData.used = "y";
                                                            }}
                                                            checked={rowData.used == "y"}
                                                        />
                                                        <label htmlFor="cb1" className="p-checkbox-label">
                                                            사용
                                                        </label>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <RadioButton
                                                            value="Avalon"
                                                            inputId="cb2"
                                                            onChange={() => {
                                                                rowData.used = "n";
                                                            }}
                                                            checked={rowData.used == "n"}
                                                        />
                                                        <label htmlFor="cb2" className="p-checkbox-label">
                                                            미사용
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="정렬 순서"
                                        body={(rowData: any, column: any) => {
                                            return (
                                                <InputText
                                                    style={{ width: "80px" }}
                                                    value={rowData.order}
                                                    onChange={(even: any) => {
                                                        console.log(rowData.nameKo);
                                                        let tournament = this.state.casinos.find(
                                                            (s: any) => s._id == rowData._id
                                                        );
                                                        tournament.order = even.target.value;

                                                        this.setState({
                                                            casinos: this.state.casinos,
                                                        });
                                                    }}
                                                ></InputText>
                                            );
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="수정"
                                        body={(rowData: any, column: any) => {
                                            return (
                                                <Button
                                                    label="확인"
                                                    onClick={() => {
                                                        let slot = this.state.casinos.find(
                                                            (s: any) => s._id == rowData._id
                                                        );

                                                        handleEditCasino(slot);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </DataTable>

                                <CustomPagination
                                    active={activePage}
                                    take={20}
                                    count={this.state.maxCount}
                                    handlePageChange={(event: any) => {
                                        this.handlePageChange(
                                            event,
                                            this.state.searchText,
                                            this.state.findText,
                                            this.state.selectViewType
                                        );
                                    }}
                                ></CustomPagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
