import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { SlotService } from "../service/slot.service";

import { HelpBox } from "../share/user/help-box";
import { BetSlotTable } from "../share/bet/bet-slot-table";
import { CustomPagination } from "../share/custom-pagination";
import { Menu } from "primereact/menu";
import { confirmAlert } from "react-confirm-alert";

import { FileService } from "../service/file.service";

import {
  numTomoeny, GetToday, GetTodayToString, ConvertCalendar,
  convertToCSV,
  convertDate,

  GetNow
} from "../utility/help";

interface MiniState {
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  cities: any;
  page: any;
  maxCount: any;
  summary: any;
  selectSort: any;
}

export class BetSlot extends Component<any, MiniState> {
  menu: any;
  slotService: SlotService = new SlotService();
  fileService: FileService = new FileService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      summary: {},
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      selectSort: 'betTime',
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "userID" },
        { label: "에이전트", value: "agent" },
        { label: "에이전트(하부포함)", value: "tree" },
      ],
    };

    this.handlePageChange = this.handlePageChange.bind(this);

    this.get_slot_bet_list(0, this.state.selectSort, 0);
  }

  componentDidMount() { }

  get_slot_bet_list = (page: any, sort: string, count: number) => {
    this.slotService
      .admin_get_slot_bets(
        page,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE'),
        this.state.findText,
        this.state.searchText,
        sort,
        count
      )
      .then((data) => {
        this.setState({
          userTableValue: data.slots,
          maxCount: data.maxCount,
        });
      });
  };

  admin_get_slot_search = (page: any) => {
    this.slotService
      .admin_get_slot_search(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText
      )
      .then((data: any) => {
        this.setState({
          summary: data.summary,
        });
      });
  };


  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any, sort: any) => {
    this.setState({ page: page });
    this.get_slot_bet_list(page, sort, this.state.maxCount);
  };




  exportSave = async () => {

    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;

    this.fileService
      .export_slot_bet(
        begin.toLocaleString('sv-SE'),
        end.toLocaleString('sv-SE'),
        searchText,
      )
      .then((data: any) => {

        for (let balance of Object.values(data.slots) as any) {
          delete balance.idx
          delete balance.master
          delete balance.del
          delete balance.color
        }

        let csv = convertToCSV(data.slots)
        csv = '키,아이디,에이전트,에이전트트리, ,,타입,,보너스,게임명,업체, \r\n' + csv

        const link = window.document.createElement('a');
        link.charset = 'UTF-8';
        link.setAttribute('href', 'data:text/csv;utf-8,\uFEFF' + encodeURIComponent(csv));

        link.setAttribute('download', `${convertDate(GetNow())}.csv`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

      });
  };




  exportOrgSave = async () => {

    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;

    this.fileService
      .export_org_slot_bet(
        begin.toLocaleString('sv-SE'),
        end.toLocaleString('sv-SE'),
        searchText,
      )
      .then((data: any) => {

        if (data.status === 'success') {
          for (let balance of Object.values(data.slots) as any) {
            delete balance.idx
            delete balance.master
            delete balance.del
            delete balance.color
          }

          let csv = convertToCSV(data.slots)
          csv = '키,서버시간,스템프,KR 시간,WIN-LOSE, BET, WIN,,,보너스,게임명,업체,유저아이디, \r\n' + csv

          const link = window.document.createElement('a');
          link.charset = 'UTF-8';
          link.setAttribute('href', 'data:text/csv;utf-8,\uFEFF' + encodeURIComponent(csv));

          link.setAttribute('download', `${convertDate(GetNow())}.csv`); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();

        }
        else if (data.status === 'user') {
          confirmAlert({
            title: "예러",
            message: "유저 아이디를 입력해주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
        }
        else if (data.status === 'time') {
          confirmAlert({
            title: "예러",
            message: "최대 데이터뽑을수 있는시간은 한시간입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                },
              },
            ],
          });
        }


      });
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="card card-w-title" style={{ padding: "0px" }}>
            <div className="p-col-12 ">
              <div className="card-title">슬롯 배팅정보</div>

              <div className="p-grid form-group">
                <div className="p-col-12 p-md-3">
                  <Calendar
                    placeholder="Begin"
                    showIcon={true}
                    dateFormat="yy-mm-dd"
                    value={new Date(this.state.begin)}
                    showWeek={false}
                    onChange={(e: any) => this.setState({ begin: e.value, maxCount: 0 })}
                    showTime
                    locale={ConvertCalendar()}
                  />
                </div>
                <div className="p-col-12 p-md-3">
                  <Calendar
                    placeholder="End"
                    showIcon={true}
                    dateFormat="yy-mm-dd"
                    showWeek={false}
                    value={new Date(this.state.end)}
                    onChange={(e: any) => this.setState({ end: e.value, maxCount: 0 })}
                    showTime
                    locale={ConvertCalendar()}
                  />
                </div>
                <div className="p-col-12 p-md-3">
                  <Dropdown
                    style={{ width: "150px" }}
                    options={this.state.cities}
                    value={this.state.findText}
                    onChange={(event: any) =>
                      this.setState({ findText: event.value, maxCount: 0 })
                    }
                    autoWidth={true}
                  />

                </div>
                <div className="p-col-12 p-md-3">
                  <span className="md-inputfield">
                    <InputText
                      onChange={(event: any) => {
                        this.setState({
                          searchText: event.target.value,
                          maxCount: 0
                        });
                      }}
                    />
                    <Button
                      label="검색"
                      onClick={() => {
                        this.handlePageChange(0, this.state.selectSort);
                        this.admin_get_slot_search(0);
                      }}
                    />
                  </span>


                </div>
                <div className="p-col-12 p-md-5">
                  <DataTable
                    style={{ padding: "0px" }}
                    value={this.state.summary}
                    paginatorPosition="both"
                    rows={20}
                  >
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="error" text="BET" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>{numTomoeny(rowData.totalBet)}</span>
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="info" text="WIN" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>{numTomoeny(rowData.totalWin)}</span>
                          </div>
                        );
                      }}
                    />

                    <Column
                      style={{ textAlign: "center", padding: "0px" }}
                      header={<Message severity="info" text="BET - WIN" />}
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <span>
                              {numTomoeny(rowData.totalBet - rowData.totalWin)}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </DataTable>



                  <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-warning "
                    onClick={(event) => {
                      this.menu.toggle(event);
                    }}

                  />



                  <Menu
                    model={[
                      {
                        label: "로컬 배팅 저장",
                        icon: "pi pi-fw pi-file",

                        command: () => {
                          this.exportSave();
                        },
                      },
                      {
                        label: "오리지널 배팅 저장",
                        icon: "pi pi-fw pi-file",

                        command: () => {
                          this.exportOrgSave();
                        },
                      },


                    ]}
                    popup
                    ref={(el) => {
                      if (el != null) {
                        this.menu = el
                      }
                    }}
                    id="popup_menu1"
                  />
                </div>
                <div className="p-col-12 p-md-5">
                  <SelectButton
                    value={this.state.selectSort}
                    options={
                      [
                        { label: "배팅시간", value: "betTime" },
                        { label: "이긴금액", value: "balanceWin" },
                      ]
                    }
                    onChange={(event) => {
                      this.setState({ selectSort: event.target.value });
                      this.handlePageChange(0, event.target.value)
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-col-12">
              <CustomPagination
                active={activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={(page: any) => {
                  this.handlePageChange(page, this.state.selectSort)
                }}
              ></CustomPagination>

              <BetSlotTable
                mode={"bet"}
                slots={this.state.userTableValue}
              ></BetSlotTable>

              <CustomPagination
                active={activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={(page: any) => {
                  this.handlePageChange(page, this.state.selectSort)
                }}
              ></CustomPagination>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
