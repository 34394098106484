import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

// import RefreshIcon from "@material-ui/icons/Refresh";

import { UserBasic } from "../share/userdetail/user-basic";
import { UserBets } from "../share/userdetail/user-bets";
import { UserBalance } from "../share/userdetail/user-balance";
import { UserNote } from "../share/userdetail/user-note";
import { UserCalculate } from "../share/userdetail/user-calculate";
import { UserLogUserLogin } from "../share/userdetail/user-loguserlogin";
import { UserLogUserBalance } from "../share/userdetail/user-loguserbalance";

// import { UserRegCode } from "../share/userdetail/user-regcode";

import { UserService } from "../service/user.service";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
} from "../utility/help";

interface Props {
  userId: string;
}

interface State {
  user: any;
  management: any;
  userTableValue: any[];
  page: number;
  pages: any;
  field: string;
  checkboxValue: any[];
}

export class UserDetail extends Component<Props, State> {
  userService: UserService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        deposit: 0,
        withdraw: 0,
      },
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      checkboxValue: [],
      management: {},
    };


    this.handelGetUser()
  }


  componentDidMount() {

  }

  handelGetUser = async () => {
    console.log('get_admin_user_detail')
    const data = await this.userService.get_admin_user_detail(this.props.userId)
    if (data.status == "success") {
      this.setState({ user: data.user, management: data.management });
    }
    // this.userService.get_admin_user_detail(this.props.userId).then((data) => {
    //   if (data.status == "success") {
    //   }
    // });

  }

  handleUserChange = (e: any) => {
    // if (this.state.user[e.target.id] != undefined) {
    this.state.user[e.target.id] = e.target.value;
    this.setState({ user: this.state.user });
    // }
  };

  render() {
    let user = this.state.user;

    if (user == null) {
      return <></>;
    }

    return (
      <div className="p-grid">
        <div className="p-col-3">
          <div className="card card-w-title">
            <div className="card-title">기본정보</div>
            <div className="p-grid">
              <div className="p-col-12 p-md-5">
                <label htmlFor="input">아이디</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: user.color,
                  }}
                >
                  {user.id}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">비밀번호</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p style={{ textAlign: "right", marginBottom: "0px" }}>
                  {user.pw}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">환전비번</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p style={{ textAlign: "right", marginBottom: "0px" }}>
                  {user.exchange_pw}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">전화번호</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p style={{ textAlign: "right", marginBottom: "0px" }}>
                  {user.phone}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">레벨</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p style={{ textAlign: "right", marginBottom: "0px" }}>
                  {user.level}
                </p>
              </div>
              {/* 
              <div className="p-col-12 p-md-5">
                <label htmlFor="input">회원상태</label>
              </div>
              <div className="p-col-12 p-md-7"></div> */}

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">보유금액</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.balance)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">포인트</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.point)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">롤링</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.rolling)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">지인루징</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.friendLosing)}
                </p>
              </div>
              <div className="p-col-12 p-md-5">
                <label htmlFor="input">가입코드</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {user.regcode}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전금액(당일)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getDepositColor(),
                  }}
                >
                  {numTomoeny(user.today_deposit)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">환전금액(당일)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getWithdrawColor(),
                  }}
                >
                  {numTomoeny(user.today_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input" className="p-button-secondary">
                  충전-환전 (당일)
                </label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.today_deposit - user.today_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전금액(주)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getDepositColor(),
                  }}
                >
                  {numTomoeny(user.week_deposit)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">환전금액(주)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getWithdrawColor(),
                  }}
                >
                  {numTomoeny(user.week_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전-환전 금액(주)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.week_deposit - user.week_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전금액(월)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getDepositColor(),
                  }}
                >
                  {numTomoeny(user.month_deposit)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">환전금액(월)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getWithdrawColor(),
                  }}
                >
                  {numTomoeny(user.month_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전-환전 금액(월)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.month_deposit - user.month_withdraw)}
                </p>
              </div>


              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전금액(합)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getDepositColor(),
                  }}
                >
                  {numTomoeny(user.total_deposit)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">환전금액(합)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: getWithdrawColor(),
                  }}
                >
                  {numTomoeny(user.total_withdraw)}
                </p>
              </div>

              <div className="p-col-12 p-md-5">
                <label htmlFor="input">충전-환전 금액(합)</label>
              </div>
              <div className="p-col-12 p-md-7">
                <p
                  className=""
                  style={{
                    textAlign: "right",
                    marginBottom: "0px",
                    color: "green",
                  }}
                >
                  {numTomoeny(user.total_deposit - user.total_withdraw)}
                </p>
              </div>


            </div>
          </div>
        </div>

        <div className="p-col-9">
          {/* <div className="card card-w-title"> */}
          <TabView>
            <TabPanel header="기본 정보" leftIcon="pi-md-check">
              <UserBasic
                user={user}
                management={this.state.management}
                handleUserChange={this.handleUserChange}
              ></UserBasic>
            </TabPanel>
            <TabPanel header="배팅정보" leftIcon="pi-md-person">
              <UserBets
                management={this.state.management}
                id={this.props.userId}
                handleUserChange={() => { }}
              ></UserBets>
            </TabPanel>
            <TabPanel header="충전/환전">
              <UserBalance id={user.id}></UserBalance>
            </TabPanel>
            {/* <TabPanel header="가입코드">
              <UserRegCode id={user.id}></UserRegCode>
            </TabPanel> */}

            <TabPanel header="쪽지">
              <UserNote id={user.id}></UserNote>
            </TabPanel>

            <TabPanel header="로그인/IP">
              <UserLogUserLogin id={user.id}></UserLogUserLogin>
            </TabPanel>
            <TabPanel header="머니이동">
              <UserLogUserBalance id={user.id}></UserLogUserBalance>
            </TabPanel>

            <TabPanel header="정산내역">
              <UserCalculate
                id={this.props.userId}
                management={this.state.management}
              ></UserCalculate>
            </TabPanel>
          </TabView>
          {/* </div> */}
        </div>
      </div>
    );
  }
}
