import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { UserService } from "../../service/user.service";
import { AdminService } from "../../service/admin.service";

// import { BetMatchTable } from "../share/bet/bet-match-table";
// import { CustomPagination } from "../share/custom-pagination";
// import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, ConvertCalendar } from "../../utility/help";
// import { CONNREFUSED } from "dns";

interface Props {
  id: any;
  management: any;
}

interface BalanceState {
  details: any;
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;

  options: any[];
  options_type: string;
  begin: any;
  end: any;
}

export class UserCalculate extends Component<Props, BalanceState> {
  userService: UserService;
  adminService: AdminService;

  constructor(props: any) {
    super(props);

    const today = new Date();
    const begin = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
    );

    today.setMonth(today.getMonth() + 1);

    const end = new Date(
      `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
    );

    this.state = {
      begin: begin,
      end: end,
      findText: "",
      searchText: "",
      selectItem: {},

      details: [],
      options: [{ label: "입출금/통계", value: "balance" }],

      options_type: "balance",

      select: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
    };

    this.userService = new UserService();
    this.adminService = new AdminService();
  }

  handleCalculate(begin: Date, end: Date) {
    this.userService
      .admin_user_detail_calculate(this.props.id, begin, end)
      .then((data) => {
        if (data.status == "success") {
          let detail = Object.values(data.calculate);
          detail.sort(function (a: any, b: any) {
            return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
          });

          this.setState({ details: detail });
        }
      });
  }

  handleMiniGame() {
    this.adminService.admin_get_minigame_list().then((data) => {
      if (data.status == "success") {
        let options = this.state.options;

        console.log(this.props.management);

        if (this.props.management.sportpre === "y") {
          options.push({ label: "스포츠", value: "sport_pre" });
        }

        if (this.props.management.sportlive === "y") {
          options.push({ label: "라이브스포츠", value: "sport_live" });
        }

        for (let mini of data.miniGames) {
          options.push({ value: mini.categoryId, label: mini.categoryName });
        }
        if (this.props.management.casino === "y") {
          options.push({ value: "casino", label: "casino" });
        }

        if (this.props.management.slot === "y") {
          options.push({ value: "slot", label: "slot" });
        }
        if (this.props.management.fx === "y") {
          options.push({ value: "fx", label: "fx" });
        }

        //this.setState({ options: options });
      }
    });
  }

  componentDidMount() {
    this.handleMiniGame();
    this.handleCalculate(this.state.begin, this.state.end);
    // this.handleCalculate();
  }

  render() {
    //let logs = this.state.logs;
    //C:\WORK\eBetting\admin\src\main\calculate.tsx



    const RenderCalculate = () => {
      if (this.state.options_type == "balance") {
        let sum = {
          betDoUserCount: 0,
          betDoCount: 0,
          betDoBalance: 0,
          betWinUserCount: 0,
          betWinCount: 0,
          betWinBalance: 0,
          depositUserCount: 0,
          depositCount: 0,
          depositBalance: 0,
          withdrawUserCount: 0,
          withdrawCount: 0,
          withdrawBalance: 0,
        };

        for (let data of this.state.details) {
          if (data.betting != null) {
            sum["betDoUserCount"] += data.betting.betDoUserCount;
            sum["betDoCount"] += data.betting.betDoCount;
            sum["betDoBalance"] += data.betting.betDoBalance;
            sum["betWinUserCount"] += data.betting.betWinUserCount;
            sum["betWinCount"] += data.betting.betWinCount;
            sum["betWinBalance"] += data.betting.betWinBalance;
          }

          if (data.balance != null) {

            sum["depositUserCount"] += data.balance.depositUserCount;
            sum["depositCount"] += data.balance.depositCount;
            sum["depositBalance"] += data.balance.depositBalance;
            sum["withdrawUserCount"] += data.balance.withdrawUserCount;
            sum["withdrawCount"] += data.balance.withdrawCount;
            sum["withdrawBalance"] += data.balance.withdrawBalance;
          }
        }

        return (
          <DataTable
            value={[sum]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            rowClassName={() => {
              return { "p-highlight": true };
            }}
          >
            <Column
              style={{ textAlign: "center" }}
              header="날짜 토탈"
              body={(rowData: any, column: any) => {
                return "토탈 ";
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="충전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.depositUserCount}/{rowData.depositCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.depositBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="환전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.withdrawUserCount}/{rowData.withdrawCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.withdrawBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="합 충전-환전"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.depositBalance - rowData.withdrawBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.depositBalance - rowData.withdrawBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="배팅(회원/건수/합계)	"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betDoUserCount}/{rowData.betDoCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betDoBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    {rowData.betWinUserCount}/{rowData.betWinCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="배팅 -당첨"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.betDoBalance - rowData.betWinBalance
                        ),
                      }}
                    >
                      {numTomoeny(rowData.betDoBalance - rowData.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />
          </DataTable>
        );
      } else if (this.state.options_type == "all") {
      } else {
        let sum = {
          win: { userCount: 0, doCount: 0, balance: 0 },
          bet: { userCount: 0, doCount: 0, balance: 0 },
          lose: { userCount: 0, doCount: 0, balance: 0 },
        };

        for (let detail of this.state.details) {
          let data = detail && detail.detail[this.state.options_type];

          if (data != null) {
            sum.bet.userCount += data.bet.userCount;
            sum.bet.doCount += data.bet.doCount;
            sum.bet.balance += data.bet.balance;

            sum.win.userCount += data.win.userCount;
            sum.win.doCount += data.win.doCount;
            sum.win.balance += data.win.balance;

            if (data.lose != null) {
              sum.lose.userCount += data.lose.userCount;
              sum.lose.doCount += data.lose.doCount;
              sum.lose.balance += data.lose.balance;
            }
          }
        }

        return (
          <DataTable
            value={[sum]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            rowClassName={() => {
              return { "p-highlight": true };
            }}
          >
            <Column
              style={{ textAlign: "center" }}
              header="날짜 토탈"
              body={(rowData: any, column: any) => {
                return "토탈 ";
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="베팅(회원/건수/합계)	"
              body={(rowData: any, column: any) => {
                let bet = rowData.bet;
                return (
                  <div>
                    {bet.userCount}/{bet.doCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(bet.balance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                let win = rowData.win;

                return (
                  <div>
                    {win.userCount}/{win.userCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(win.balance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="낙점(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                let lose = rowData.lose;

                return (
                  <div>
                    {lose.userCount}/{lose.userCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(lose.balance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="환전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                let win = rowData.win;
                let bet = rowData.bet;

                return (
                  <div>
                    {/* {rowData.loseUserCount}/{rowData.loseCount}/
                     */}
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(bet.balance - win.balance)}
                    </span>
                  </div>
                );
              }}
            />
          </DataTable>
        );
      }
    };

    const RenderBet = () => {
      let options_type = this.state.options_type;
      if (options_type == "balance" || options_type == "all") {
        return <div></div>;
      }

      return (
        <DataTable
          value={this.state.details}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
        >
          <Column
            style={{ textAlign: "center" }}
            header="날짜"
            body={(rowData: any, column: any) => {
              let date = new Date(rowData.date);

              return (
                pad2(date.getMonth() + 1) +
                "-" +
                pad2(date.getDate()) +
                " " +
                pad2(date.getHours())
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="베팅(회원/건수/합계)	"
            body={(rowData: any, column: any) => {
              let detail = { userCount: 0, doCount: 0, balance: 0 };
              if (rowData.detail[options_type] != null) {
                detail = rowData.detail[options_type].bet;
              }

              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="당첨(회원/건수/합계)"
            body={(rowData: any, column: any) => {
              let detail = { userCount: 0, doCount: 0, balance: 0 };
              if (rowData.detail[options_type] != null) {
                detail = rowData.detail[options_type].win;
              }
              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="낙점(회원/건수/합계)"
            body={(rowData: any, column: any) => {
              let detail = { userCount: 0, doCount: 0, balance: 0 };
              if (
                rowData.detail[options_type] != null &&
                rowData.detail[options_type].lose != null
              ) {
                detail = rowData.detail[options_type].lose;
                console.log(detail);
                detail["userCount"] = 0;
              }
              return (
                <div>
                  {detail.userCount}/{detail.doCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(detail.balance)}
                  </span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="수익금"
            body={(rowData: any, column: any) => {
              let balance = {
                withdrawUserCount: 0,
                withdrawCount: 0,
              };

              let bet = {
                userCount: 0,
                doCount: 0,
                balance: 0,
                withdrawUserCount: 0,
                withdrawCount: 0,
              };
              let win = {
                userCount: 0,
                doCount: 0,
                balance: 0,
                withdrawUserCount: 0,
                withdrawCount: 0,
              };

              if (rowData.balance != null) {
                balance = rowData.balance;
              }
              if (
                rowData.detail[options_type] != null &&
                rowData.detail[options_type].lose != null
              ) {
                bet = rowData.detail[options_type].bet;
              }

              if (
                rowData.detail[options_type] != null &&
                rowData.detail[options_type].win != null
              ) {
                win = rowData.detail[options_type].win;
              }

              return (
                <div>
                  {balance.withdrawUserCount}/{balance.withdrawCount}/
                  <span style={{ color: "#e91224" }}>
                    {numTomoeny(bet.balance - win.balance)}
                  </span>
                </div>
              );
            }}
          />
        </DataTable>
      );
    };

    const pad2 = (n) => {
      return (n < 10 ? "0" : "") + n;
    };

    const RenderBalance = () => {
      if (this.state.options_type == "balance") {
        return (
          <DataTable
            value={this.state.details}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
          >
            <Column
              style={{ textAlign: "center" }}
              header="날짜"
              body={(rowData: any, column: any) => {
                let date = new Date(rowData.date);

                return (
                  pad2(date.getMonth() + 1) +
                  "-" +
                  pad2(date.getDate()) +
                  " " +
                  pad2(date.getHours())
                );
                //return rowData.date;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="충전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                if (rowData.balance == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }
                return (
                  <div>
                    {rowData.balance.depositUserCount}/
                    {rowData.balance.depositCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.balance.depositBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="환전(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                if (rowData.balance == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }
                return (
                  <div>
                    {rowData.balance.withdrawUserCount}/
                    {rowData.balance.withdrawCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.balance.withdrawBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="충전 /환전 "
              body={(rowData: any, column: any) => {
                if (rowData.balance == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }

                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.balance.depositBalance -
                          rowData.balance.withdrawBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.balance.depositBalance -
                        rowData.balance.withdrawBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="배팅(회원/건수/합계)	"
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }
                return (
                  <div>
                    {rowData.betting.betDoUserCount}/
                    {rowData.betting.betDoCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betDoBalance)}
                    </span>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="당첨(회원/건수/합계)"
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }
                return (
                  <div>
                    {rowData.betting.betWinUserCount}/
                    {rowData.betting.betWinCount}/
                    <span style={{ color: "#e91224" }}>
                      {numTomoeny(rowData.betting.betWinBalance)}
                    </span>
                  </div>
                );
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="배팅 - 환전"
              body={(rowData: any, column: any) => {
                if (rowData.betting == null) {
                  return (
                    <div>0/0/<span style={{ color: "#e91224" }}>0</span>
                    </div>
                  );
                }
                return (
                  <div>
                    <span
                      style={{
                        color: balanceColor(
                          rowData.betting.betDoBalance -
                          rowData.betting.betWinBalance
                        ),
                      }}
                    >
                      {numTomoeny(
                        rowData.betting.betDoBalance -
                        rowData.betting.betWinBalance
                      )}
                    </span>
                  </div>
                );
              }}
            />
          </DataTable>
        );
      }
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">정산 기록</div>

                <div className="p-col-12 ">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        view="date"
                        value={this.state.begin}
                        onChange={(e) => this.setState({ begin: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}

                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        view="date"
                        value={this.state.end}
                        onChange={(e) => this.setState({ end: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}

                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Button
                        label="검색"
                        onClick={() => {
                          this.handleCalculate(
                            this.state.begin,
                            this.state.end
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="p-col-12 ">
                    <SelectButton
                      value={this.state.options_type}
                      options={this.state.options}
                      onChange={(e) => {
                        if (e.value == null) {
                          return;
                        }
                        this.setState({ options_type: e.value });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="card">
                {RenderCalculate()}
                {RenderBalance()}
                {RenderBet()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
