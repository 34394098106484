import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Spinner } from "primereact/spinner";
import { Menu } from "primereact/menu";

import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { confirmAlert } from "react-confirm-alert"; // Import
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { MatchDetail } from "./match-detail";
import { withStyles } from "@material-ui/core/styles";
import { SplitButton } from "primereact/splitbutton";
import { SelectButton } from "primereact/selectbutton";
import { SlideMenu } from "primereact/slidemenu";
import { Checkbox } from "primereact/checkbox";

import { BetService } from "../../service/bet.service";

//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

const CustomListItemText = withStyles({
  root: {
    padding: "0px",
  },
  label: {
    textTransform: "capitalize",
  },
})(ListItemText);

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  ConvertSpecifiersGame,
} from "../../utility/help";
import { config } from "@fullcalendar/core";

interface Props {
  mode: any;
  game: any;
  selectBalanceType: string;

  // handleOpenDetail: any;
  handleSelectWinGame: any;
  handleUpdateOdds: any;
  handleUpdateActive: any;
  handleUpdateFix: any;
  handleEditOdds: any;
  handleChangeToStause: any;
  handleSelectMatch: any;
}

interface State {
  deposits: any[];
  page: number;
  pages: any;
  field: string;

  activeMatchId: any;
  details: any[][];
  detailsBet: any;
  filter: string;
}

export class ProgressMatchTable extends Component<Props, State> {
  menu: any | null;
  betService = new BetService();

  constructor(props: Props) {
    super(props);
    this.state = {
      deposits: [],
      details: [],
      page: 0,
      pages: {},
      field: "",
      filter: "유저배팅",
      detailsBet: {},
      activeMatchId: 0,
    };
  }

  componentDidMount = () => {};

  render = () => {
    const mode = this.props.mode;
    const game = this.props.game;
    const details = this.state.details;
    const matchId = game.matchId;
    const PreBet = game.preBet == null ? [] : game.preBet;

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    if (mode == "end") {
      customHeaderStyle = { backgroundColor: "#cecece", textAlign: "center" };
    }

    let first: any[] = [];
    let second: any[] = [];
    let thread: any[] = [];
    let allitem: any[] = [];

    let filters: any = [];

    if (details !== undefined) {
      let firstCount = 0;
      let secondCount = 0;
      let thirdCount = 0;

      for (let [key, value] of Object.entries(details)) {
        if (value.length >= 1 && value[0].outcomeOdds.length >= 4) {
          let odds: any[] = [];
          let copyOdds = JSON.parse(JSON.stringify(value[0].outcomeOdds));

          for (var i = 0; i < copyOdds.length; i++) {
            let index = Math.trunc(i / 3);
            if (odds[index] == null) {
              odds[index] = [];
            }
            odds[index].push(copyOdds[i]);
          }

          value[0].outcomes = odds[0];

          for (var i = 1; i < odds.length; i++) {
            var bar = JSON.parse(JSON.stringify(value[0]));
            bar.outcomes = JSON.parse(JSON.stringify(odds[i]));
            details[Number(key)].push(bar);
          }
        } else {
          for (let element of Object.values(value)) {
            if (element.outcomes === undefined && element.outcomeOdds != null) {
              if (Object.values(element.outcomeOdds).length >= 4) {
                element.outcomes = [];
              } else {
                element.outcomes = Object.values(element.outcomeOdds);
              }
            }
          }
        }
      }

      for (let value of Object.values(details)) {
        if (Object.values(value).length > 0) {
          filters["유저배팅"] = true;

          let group =
            Object.values(value)[0].groups == undefined
              ? ""
              : Object.values(value)[0].groups;
          for (let filter of group.split("|")) {
            filters[filter] = true;
          }

          if (group.trim() == "" || group.indexOf(this.state.filter) === -1) {
            continue;
          }
        }
        let length = 0;
        for (let item of Object.values(value)) {
          if (item.outcomeOdds != null) {
            length = length + Object.values(item.outcomeOdds).length;
          }
        }

        if (firstCount == 0 || thirdCount > firstCount) {
          firstCount += length;
          first.push(value);
        } else if (firstCount > secondCount) {
          secondCount += length;
          second.push(value);
        } else {
          thirdCount += length;
          thread.push(value);
        }

        allitem.push(value);
      }

      if (this.state.filter === "유저배팅") {
        for (let key of Object.keys(PreBet)) {
          for (let gamde of Object.keys(PreBet[key])) {
            allitem.push(details[key]);
          }
        }
      }
    }

    const RenderMiniBet = (matchId: number, items: any[], editOdds: any) => {
      if (items.length === 0) {
        return <></>;
      }

      return items.map((item, i) => {
        if (item == null) {
          return <></>;
        }
        return Object.values(item).map((contact: any, i: any) => {
          let name = i + "_" + contact.id;
          let specifiers = ConvertSpecifiersGame(contact.specifiers);
          let betBalance =
            this.state.detailsBet == null
              ? {}
              : this.state.detailsBet[contact.id] == null
              ? {}
              : this.state.detailsBet[contact.id][specifiers];
          let exDditOdds =
            editOdds == null
              ? {}
              : editOdds[contact.id] == null
              ? {}
              : editOdds[contact.id] == null
              ? {}
              : editOdds[contact.id][specifiers] == null
              ? {}
              : editOdds[contact.id][specifiers];

          return (
            <MatchDetail
              key={name}
              matchId={matchId}
              marketId={contact.id}
              contact={contact}
              mode={mode}
              detailsBet={betBalance}
              editOdds={exDditOdds}
              handleUpdateOdds={this.props.handleUpdateOdds}
              handleUpdateActive={this.props.handleUpdateActive}
              handleUpdateFix={this.props.handleUpdateFix}
              handleEditOdds={this.props.handleEditOdds}
            ></MatchDetail>
          );
        });
      });
    };

    const RenderFilters = (matchId: any, filter: any) => {
      return Object.keys(filter).map((contact: any, i: any) => {
        return (
          <List component="nav" aria-label="contacts">
            <ListItem
              button
              onClick={() => {
                this.setState({ filter: contact });
              }}
            >
              <CustomListItemText inset primary={contact} />
            </ListItem>
          </List>
        );
      });
    };

    return (
      <div>
        <div className="p-col-12 ">
          <DataTable
            value={[game]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            scrollable={true}
          >
            <Column
              style={{ textAlign: "center", width: "150px" }}
              filterElement={<div className="confirmation-content"></div>}
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <Checkbox
                      inputId="binary"
                      checked={rowData.checked}
                      onChange={(e: any) => {
                        this.props.handleSelectMatch(
                          rowData.matchId,
                          e.checked == null ? true : e.checked
                        );
                      }}
                    />

                    <Button
                      icon="pi pi-info"
                      className="p-button-rounded p-button-info "
                      onClick={() => {
                        if (rowData.pre == null) {
                          confirmAlert({
                            title: "예러",
                            message: "해당 메치는 게임 디테일게임이 없습니다.",
                            buttons: [
                              {
                                label: "예",
                                onClick: () => {},
                              },
                            ],
                          });
                          return;
                        }
                        if (
                          this.state.details == null ||
                          Object.values(this.state.details).length == 0
                        ) {
                          this.setState({
                            filter: "크로스",
                            details: rowData.pre,
                            detailsBet: rowData.preBet,
                          });
                        } else {
                          this.setState({ details: [], detailsBet: {} });
                        }
                      }}
                    />
                    {/* 
                    <Button
                      icon="pi pi-cog"
                      className="p-button-rounded p-button-warning "
                      onClick={(event) => {
                        this.setState({ activeMatchId: rowData.matchId });
                        this.menu.toggle(event);

                        return;
                      }}
                    /> */}
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center", width: "150px" }}
              header="배팅금액 합"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                if (rowData.preBetBalance > 500000) {
                  return (
                    <div style={{ color: "red" }}>
                      {numTomoeny(
                        rowData.preBetBalance || rowData.liveBetBalance || 0
                      )}
                    </div>
                  );
                } else if (rowData.preBetBalance > 0) {
                  return (
                    <div style={{ color: "orange" }}>
                      {numTomoeny(
                        rowData.preBetBalance || rowData.liveBetBalance || 0
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div>
                      {numTomoeny(
                        rowData.preBetBalance || rowData.liveBetBalance || 0
                      )}
                    </div>
                  );
                }
              }}
            />
            <Column
              style={{ textAlign: "center", width: "70px" }}
              header="코드"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return <div>{rowData.matchId} </div>;
              }}
            />
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="게임시간"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return <div>{convertDate(rowData.dateOfMatch)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="스포츠"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return <div>{rowData.sportName}</div>;
              }}
            />
            <Column
              style={{ textAlign: "center", width: "120px" }}
              field="win"
              header="리그"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return <div>{rowData.tournamentName}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="홈"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                let home: any = Object.values(rowData.competitors).find(
                  (s: any) => s.qualifier == "home"
                );

                return <div>{home.name}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "220px" }}
              header="원정"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                let away: any = Object.values(rowData.competitors).find(
                  (s: any) => s.qualifier == "away"
                );

                return <div>{away.name}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="게임 수"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                if (rowData.pre == null) {
                  return <div>{0}</div>;
                }
                let count = Object.values(rowData.pre).length;
                return <div>{count}</div>;
              }}
            />
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="상태"
              bodyStyle={customHeaderStyle}
              body={(rowData: any, column: any) => {
                return <div>{rowData.statusName}</div>;
              }}
            />
          </DataTable>
          <div className="p-grid">
            <div className="p-col-12 p-md-1">
              {RenderFilters(matchId, filters)}
            </div>
            <div className="p-col-12 p-md-11">
              {RenderMiniBet(matchId, allitem, game.editOdds)}
            </div>
          </div>
        </div>
      </div>
    );
  };
}
