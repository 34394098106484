import axios from "axios";

import { AxiosMag } from "./axios.service";

export class AgentService {

  admin_make_agent = async (agent: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_make_agent",
      agent
    );
  };

  admin_edit_agent = async (agent: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_edit_agent",
      agent
    );
  };

  admin_get_top_agent = async (agent: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_top_agent",
      agent
    );
  };

  admin_get_down_agent = async (agent_tree: any, level: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_down_agent", {
      agent_tree: agent_tree,
      level: level,
    });
  };

  admin_make_agnet_benefit = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_make_agnet_benefit", {
    });
  };


  admin_agnet_tree_update = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_agnet_tree_update", {
    });
  };


  admin_get_agent_benefit_list = async (agentId) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_info_agent_benefit",
      { agentId: agentId }
    );
  };

  admin_agent_benefit_edit = async (
    agentId: string,
    benefit_type: string,
    percent: number
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_agent_benefit_edit", {
      agentId: agentId,
      benefit_type: benefit_type,
      percent: percent,
    });
  };

  admin_get_agent_balance_list = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agent_balance_listv2",
      { skip: skip }
    );
  };

  admin_get_agent_codes = async (page) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_codes", {
      page: page,
    });
  };

  admin_add_reg_codes = async (
    type: string,
    code: string,
    agent: string,
    userid: string,
    count: number
  ) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_add_codes", {
      type: type,
      code: code,
      agent: agent,
      userid: userid,

      count: count,
    });
  };

  admin_del_agent_code = async (idx: string) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_del_codes", {
      idx: idx,
    });
  };

  admin_edit_agent_code = async (code) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_edit_code", code);
  };

  admin_get_agent_wait = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_wait", {});
  };

  admin_agnet_stause_change_del = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_stause_change_del",
      { idx: idx }
    );
  };

  admin_agnet_stause_change_wait = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_stause_change_wait",
      { idx: idx }
    );
  };

  admin_agnet_stause_change_accept = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_stause_change_accept",
      { idx: idx }
    );
  };


  admin_get_agnet_balance_log = async (page: any, begin: any, end: any, findText: any, searchText: any, mode: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_balance_log_v2",
      { skip: page, mode, begin, end, searchText, findText }
    );
  };

  admin_get_agnet_balance_tree_log = async (page: any, mode: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_balance_tree_log",
      { skip: page, mode }
    );
  };

  admin_get_agnet_detail = async (agentId: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agnet_detail", {
      agentid: agentId,
    });
  };


  admin_get_agnet_detail_pass = async (agentId: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agnet_detail_pass", {
      agentid: agentId,
    });
  };

  save_agent_detail = async (detail: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_save_agnet_detail",
      detail
    );
  };

  admin_get_agnet_balance_tree_log_by_id = async (
    agentid: any,
    search: any,
    find: any,
    page: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_balance_tree_log",
      {
        agentid: agentid,
        findText: search,
        searchText: find,
        skip: page,
      }
    );
  };


  admin_get_agnet_balance_log_by_id = async (
    agentid: any,
    search: any,
    find: any,
    page: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_balance_log",
      {
        agentid: agentid,
        findText: search,
        searchText: find,
        skip: page,
      }
    );
  };

  admin_get_agnet_balance_withdraw = async (page: any, begin: any, end: any, balanceType: any, findText: any, searchText) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_balance_withdraw",
      {
        skip: page,
        begin: begin,
        end: end,
        balanceType,
        findText,
        searchText
      }
    );
  };

  admin_agnet_balance_cancel = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_balance_cancel",
      { idx: idx }
    );
  };

  admin_agnet_banace_wait = async (idx: any) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_agnet_banace_wait", {
      idx: idx,
    });
  };

  admin_agnet_banace_accept = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_banace_accept",
      { idx: idx }
    );
  };

  admin_agnet_banace_accept_one_balance = async (idx: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_banace_accept_one_balance",
      { idx: idx }
    );
  };


  admin_agnet_calculate = async (agent: any, begin: any, end: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agnet_calculate",
      { agent: agent, begin: begin, end: end }
    );
  };

  admin_agnet_login_log = async (agent: any, page: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_login_log",
      { agent, page }
    );
  };


  admin_agnet_remove_list = async (page: any, status: string, type: string, id: string) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agnet_remove_list",
      { page, status, type, id }
    );
  };


  admin_get_agent_help_list = async (searchText, findText, skip) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_agent_help_list",
      {
        skip: skip,
        findText: findText,
        searchText: searchText,
      }
    );
  };



  admin_agent_help_already_read = async (help) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agent_help_already_read",
      help
    );
  };



  admin_wirte_ref_agent_help = async (ref) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_wirte_ref_agent_help",
      ref
    );
  };




  admin_del_agent_help = async (idx) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_del_agent_help",
      { idx: idx }
    );
  };


  admin_agent_stop = async (agent) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agent_stop",
      { agent: agent }
    );
  };


  admin_agent_used = async (agent) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_agent_used",
      { agent: agent }
    );
  };

  admin_get_reg_domain = async (page) => {
    return await AxiosMag.SendMessageByPost("/user/admin_get_reg_domain", {
      skip: page,
    });
  };


  admin_add_reg_domain = async (newDomain, code) => {
    return await AxiosMag.SendMessageByPost("/user/admin_add_reg_domain", {
      newDomain, code
    });
  };


  admin_del_reg_domain = async (domain) => {
    return await AxiosMag.SendMessageByPost("/user/admin_del_reg_domain", {
      domain: domain,
    });
  };

  
  updateTelegram = async () => {
    return await AxiosMag.SendMessageByPost("/utility/updateTelegram", {
    });
  };

  getTelegram = async () => {
    return await AxiosMag.SendMessageByPost("/utility/getTelegram", {
    });
  };


}
