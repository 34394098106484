import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { Growl } from "primereact/growl";
import { Menu } from "primereact/menu";
import { confirmAlert } from "react-confirm-alert";

import { BetService } from "../service/bet.service";
import { ProgressMatchTable } from "../share/progress/progress-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday, ConvertSpecifiersGame, ConvertCalendar } from "../utility/help";

interface Props { }

interface State {
  begin: Date;
  end: Date;
  userTableValue: any[];
  details: any;

  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  cities: any[];

  selectItem: any;

  selectViewType: string;
  viewType: any[];

  selectSortType: string;
  sortType: any[];

  balanceType: any[];
  selectBalanceType: string;

  summary: any;
  sports: any;
  selectSports: any;
}

export class ProgressMatch extends Component<any, State> {
  betService = new BetService();
  growl: any;
  menu: any;

  items: any;

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      cities: [
        { label: "선택", value: null },
        { label: "종목", value: "sportName" },
        { label: "팀명", value: "team" },
        { label: "리그명", value: "tournamentName" },
      ],

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],

      sortType: [
        { label: "시간", value: "dateOfMatchTimeStamp" },
        { label: "배팅금액", value: "preBetBalance" },
      ],
      selectSortType: "dateOfMatchTimeStamp",

      balanceType: [
        { label: "미배팅", value: "dateOfMatchTimeStamp" },
        { label: "배팅중", value: "preBetBalance" },
      ],
      selectBalanceType: "dateOfMatchTimeStamp",

      summary: {},
      selectItem: {},
      sports: [],
      selectSports: "전체보기",
    };

    this.items = [
      {
        label: "선택",
        icon: "pi pi-fw pi-file",
        items: [
          {
            label: "모두선택",
            icon: "pi pi-fw pi-trash",
            command: () => {
              this.handleSelectAllMatch();
            },
          },
          {
            label: "선택취소",
            icon: "pi pi-fw pi-external-link",

            command: () => {
              this.handleRemoveAllMatch();
            },
          },
        ],
      },
      {
        label: "상태변경",
        icon: "pi pi-fw pi-file",
        items: [
          {
            label: "숨김",
            icon: "pi pi-refresh",
            command: () => {
              confirmAlert({
                title: "유저 페이지",
                message: "유저페이지에서 숨김",
                buttons: [
                  {
                    label: "예",
                    onClick: () => {
                      let items = this.state.userTableValue
                        .filter((s) => s.checked == true)
                        .map((x) => x.matchId);

                      this.handleChangeToStause(items, "wait");
                    },
                  },
                  {
                    label: "아니요",
                    onClick: () => { },
                  },
                ],
              });
            },
          },
          {
            label: "배팅",
            icon: "pi pi-times",
            command: () => {
              confirmAlert({
                title: "유저 페이지",
                message: "유저페이지에서 보기",
                buttons: [
                  {
                    label: "예",
                    onClick: () => {
                      let items = this.state.userTableValue
                        .filter((s) => s.checked == true)
                        .map((x) => x.matchId);

                      this.handleChangeToStause(items, "not_started");
                    },
                  },
                  {
                    label: "아니요",
                    onClick: () => { },
                  },
                ],
              });
            },
          },
          {
            label: "마감",
            icon: "pi pi-times",
            command: () => {
              confirmAlert({
                title: "유저 페이지",
                message: "종료된 게임으로 보내기",
                buttons: [
                  {
                    label: "예",
                    onClick: () => {
                      let items = this.state.userTableValue
                        .filter((s) => s.checked == true)
                        .map((x) => x.matchId);

                      this.handleChangeToStause(items, "ended");
                    },
                  },
                  {
                    label: "아니요",
                    onClick: () => { },
                  },
                ],
              });
            },
          },
        ],
      },
    ];

    this.get_progress_match_game(
      0,
      this.state.selectViewType,
      this.state.selectSortType,
      this.state.selectSports
    );

    this.betService.admin_get_all_sport_name().then((s) => {
      console.log(s.sports)
      s.sports.map((s) => {
        s.label = s._id;
        s.value = s._id;
      });

      s.sports.sort(function (a, b) {
        if (a.count < b.count) {
          return 1;
        }
        if (a.count > b.count) {
          return -1;
        }
        return 0;
      });

      s.sports.unshift({
        label: "전체보기",
        value: "전체보기",
      });
      this.setState({
        sports: s.sports,
      });
    });
  }

  componentDidMount() { }

  get_progress_match_game = (
    page: any,
    selectViewType: any,
    selectSortType: string,
    selectSports: string
  ) => {
    this.betService
      .get_progress_match_game(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText,
        selectViewType,
        selectSortType,
        selectSports
      )
      .then((data) => {

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  get_match_details = (matchId: any) => {
    this.betService.get_match_details(matchId).then((data) => {
      if (data.pre != undefined) {
        let matchID = data.pre._id;

        this.state.details[matchID] = data.pre;

        this.setState({
          details: this.state.details,
        });
      }
    });
  };

  del_match_game = () => {
    this.betService.del_match_game().then((data) => {
      window.location.reload();
    });
  };

  handleSelectAllMatch = () => {
    Object.values(this.state.userTableValue).map((s) => {
      s.checked = true;
    });
    this.setState({ userTableValue: this.state.userTableValue });
  };

  handleRemoveAllMatch = () => {
    Object.values(this.state.userTableValue).map((s) => {
      s.checked = false;
    });
    this.setState({ userTableValue: this.state.userTableValue });
  };

  handleSelectMatch = (matchId: number, checked: boolean) => {
    let match = this.state.userTableValue.find((s) => s.matchId === matchId);

    match.checked = checked;

    this.setState({ userTableValue: this.state.userTableValue });
  };

  handleChangeToStause = (matchIds: any, status: any) => {
    this.betService.admin_change_match_status(matchIds, status).then((data) => {
      if (data.status == "success") {
        let msg = {
          severity: "success",
          summary: "게임",
          detail: "게임이 업데이트 되습니다",
        };
        this.growl.show(msg);
      } else {
        let msg = {
          severity: "success",
          summary: "게임",
          detail: "관리자에게 문의 부탁드림니다",
        };
        this.growl.show(msg);
      }
    });
  };

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_progress_match_game(
      page,
      this.props.selectViewType,
      this.state.selectSortType,
      this.state.selectSports
    );
  };

  handleOpenDetail = (matchId: any) => {
    if (this.state.details[matchId] == undefined) {
      this.get_match_details(matchId);
    } else {
      delete this.state.details[matchId];

      this.setState({ details: this.state.details });
    }
  };

  handleUpdateOdds = (
    matchId: number,
    marketId: number,
    specifiers: any,
    oddsId: any,
    plusOdds: number
  ) => {
    this.betService
      .admin_match_update_odds(matchId, marketId, specifiers, oddsId, plusOdds)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
      });
  };

  handleUpdateFixOdds = (
    matchId: number,
    marketId: number,
    specifiers: any,
    oddsId: any,
    fix: boolean
  ) => {
    let match = Object.values(this.state.userTableValue).find(
      (s: any) => s.matchId == matchId
    );
    let specifier = ConvertSpecifiersGame(specifiers);
    match.editOdds == null ? (match.editOdds = {}) : match.editOdds;

    match.editOdds[marketId] == null
      ? (match.editOdds[marketId] = {})
      : match.editOdds[marketId];
    match.editOdds[marketId][specifier] == null
      ? (match.editOdds[marketId][specifier] = {})
      : {};
    match.editOdds[marketId][specifier] == null
      ? (match.editOdds[marketId][specifier] = {})
      : {};
    match.editOdds[marketId][specifier][oddsId] == null
      ? (match.editOdds[marketId][specifier][oddsId] = {})
      : {};
    match.editOdds[marketId][specifier][oddsId]["fix"] = fix;

    this.betService
      .admin_match_update_fix(matchId, marketId, specifiers, oddsId, fix)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
      });
  };

  handleUpdateActiveGame = (
    matchId: number,
    marketId: number,
    specifiers: any,
    active: boolean
  ) => {
    this.betService
      .admin_match_update_game_active(matchId, marketId, specifiers, active)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
      });
  };

  handleEditOdds = (
    matchId: number,
    marketId: number,
    specifiers: any,
    oddsId: any,
    odds: number
  ) => {
    let match = Object.values(this.state.userTableValue).find(
      (s: any) => s.matchId == matchId
    );
    let specifier = ConvertSpecifiersGame(specifiers);
    match.editOdds == null ? (match.editOdds = {}) : match.editOdds;

    match.editOdds[marketId] == null
      ? (match.editOdds[marketId] = {})
      : match.editOdds[marketId];
    match.editOdds[marketId][specifier] == null
      ? (match.editOdds[marketId][specifier] = {})
      : {};
    match.editOdds[marketId][specifier] == null
      ? (match.editOdds[marketId][specifier] = {})
      : {};
    match.editOdds[marketId][specifier][oddsId] == null
      ? (match.editOdds[marketId][specifier][oddsId] = {})
      : {};
    match.editOdds[marketId][specifier][oddsId]["odds"] = odds;

    this.setState({ userTableValue: this.state.userTableValue });
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      return userTableValue.map((contact: any, i: any) => {
        return (
          <ProgressMatchTable
            mode={"pro"}
            game={contact}
            key={contact._id}
            selectBalanceType={contact.selectBalanceType}
            handleUpdateOdds={this.handleUpdateOdds}
            handleUpdateActive={this.handleUpdateActiveGame}
            handleUpdateFix={this.handleUpdateFixOdds}
            handleEditOdds={this.handleEditOdds}
            handleChangeToStause={this.handleChangeToStause}
            handleSelectMatch={this.handleSelectMatch}
            handleSelectWinGame={() => { }}
          ></ProgressMatchTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="p-col-12  p-md-12">
                    <div className="card-title">진행중인 스포츠 게임</div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-8">
                        <div className="p-grid form-group">
                          <div className="p-col-12 p-md-3">
                            <Calendar
                              placeholder="Begin"
                              showIcon={true}
                              dateFormat="yy-mm-dd"
                              value={this.state.begin}
                              showWeek={false}
                              onChange={(e: any) =>
                                this.setState({ begin: e.value })
                              }
                              locale={ConvertCalendar()}

                            />
                          </div>
                          <div className="p-col-12 p-md-3">
                            <Calendar
                              placeholder="End"
                              showIcon={true}
                              dateFormat="yy-mm-dd"
                              value={this.state.end}
                              showWeek={false}
                              onChange={(e: any) =>
                                this.setState({ end: e.value })
                              }
                              locale={ConvertCalendar()}

                            />
                          </div>
                          <div className="p-col-12 p-md-3">
                            <Dropdown
                              style={{ width: "100px" }}
                              options={this.state.cities}
                              value={this.state.findText}
                              onChange={(event) =>
                                this.setState({ findText: event.value })
                              }
                            />
                          </div>
                          <div className="p-col-12 p-md-3">
                            <span className="md-inputfield">
                              <InputText
                                onChange={(event: any) => {
                                  this.setState({
                                    searchText: event.target.value,
                                  });
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <div className="p-col-12 p-md-6">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.get_progress_match_game(
                                  0,
                                  this.props.selectViewType,
                                  this.state.selectSortType,

                                  this.state.selectSports
                                );
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="p-grid form-group">
                      <SelectButton
                        value={this.state.selectSports}
                        options={this.state.sports}
                        onChange={(event) => {
                          if (event.value != null) {
                            this.setState({
                              selectSports: event.value,
                              page: 1,
                            });

                            this.get_progress_match_game(
                              0,
                              this.props.selectViewType,
                              this.state.selectSortType,
                              event.value
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-2">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.setState({ selectViewType: event.value });
                            this.get_progress_match_game(
                              0,
                              event.value,
                              this.state.selectSortType,
                              this.state.selectSports
                            );
                          }}
                        />
                      </div>

                      <div className="p-col-12 p-md-2">
                        <SelectButton
                          value={this.state.selectSortType}
                          options={this.state.sortType}
                          onChange={(event) => {
                            console.log(event.value);
                            if (event.value == null) {
                              return;
                            }
                            this.setState({ selectSortType: event.value });
                            this.get_progress_match_game(
                              0,
                              this.state.selectViewType,
                              event.value,
                              this.state.selectSports
                            );
                          }}
                        />
                      </div>

                      <div className="p-col-12 p-md-2">
                        <Button
                          label={"미배팅 게임 삭제"}
                          onClick={(event) => {
                            this.del_match_game();
                          }}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <Menu
                model={this.items}
                popup
                ref={(el) => (this.menu = el)}
                id="popup_menu1"
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <div className="p-col-12 p-md-2">
                  <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-warning "
                    onClick={(event) => {
                      this.menu.toggle(event);
                    }}
                  // onMouseOver={(event) => {
                  //   this.menu.toggle(event);
                  // }}
                  />
                </div>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
