import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import AudioPlayer from "react-h5-audio-player";
import { AdminService } from "./service/admin.service";

interface MenuState {
  activeIndex: any;
}

interface MenuProps {
  items: any;
  topInfo: any;

  root: boolean;
  mega: boolean;

  className: string;
  menuActive: boolean;
  horizontal: null;
  parentMenuItemActive: boolean;

  onRootItemClick: (par1: any) => any;
  onMenuItemClick: (par1: any) => any;

  isHorizontalMenuActive: () => any;
}

class AppSubmenu extends Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  onMenuItemClick(event: any, item: any, index: any) {
    //avoid processing disabled items

    window.location.hash.indexOf(item.to) >= 0 && window.location.reload()

    if (item.disabled) {
      event.preventDefault();
      return true;
    }


    if (this.props.root && this.props.onRootItemClick) {
      this.props.onRootItemClick({
        originalEvent: event,
        item: item,
      });
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  }

  onMenuItemMouseEnter(index: any) {
    if (
      this.props.root &&
      this.props.menuActive &&
      this.props.isHorizontalMenuActive()
    ) {
      this.setState({ activeIndex: index });
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.parentMenuItemActive === false) {
      return {
        activeIndex: null,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (
      this.props.horizontal &&
      prevProps.menuActive &&
      !this.props.menuActive
    ) {
      this.setState({ activeIndex: null });
    }
  }

  badgeCount(topInfo: any, items: any) {
    let count = 0;
    for (let key of Object.values(items) as any) {
      if (topInfo[key] != null) {
        count = count + topInfo[key];
      }
    }
    return count;
  }

  renderLinkContent(topInfo: any, item: any) {
    let submenuIcon = item.items && (
      <i className="material-icons layout-submenu-toggler">
        keyboard_arrow_down
      </i>
    );

    let badge1 = <></>;
    if (
      topInfo != null &&
      item.badge &&
      Object.values(item).length >= 1 &&
      topInfo[item.badge[0]] != null &&
      topInfo[item.badge[0]] > 0
    ) {
      badge1 = (
        <span
          className="menuitem-badge"
          onClick={() => {
            console.log("111", window.location.hash, item.quickUrl[0]);

            if (window.location.hash.indexOf(item.quickUrl[0]) >= 0) {
              window.location.reload();
            } else {
              window.location.hash = `${item.quickUrl[0]}`;
            }
          }}
        >
          {topInfo[item.badge[0]]}
        </span>
      );
    }

    let badge2 = <></>;
    if (
      topInfo != null &&
      item.badge &&
      Object.values(item).length >= 2 &&
      topInfo[item.badge[1]] != null &&
      topInfo[item.badge[1]] > 0
    ) {
      badge2 = (
        <span
          className="menuitem-badge"
          onClick={() => {
            if (window.location.hash.indexOf(item.quickUrl[1]) >= 0) {
              window.location.reload();
            } else {
              window.location.hash = `${item.quickUrl[1]}`;
            }
          }}
          style={{ background: "red" }}
        >
          {topInfo[item.badge[1]]}
        </span>
      );
    }

    return (
      <React.Fragment>
        <i className="material-icons">{item.icon}</i>
        <span className="menuitem-text">{item.label}</span>
        {submenuIcon}
        {badge1}
        {badge2}
      </React.Fragment>
    );
  }

  renderLink(topInfo: any, item: any, i: any) {
    let content = this.renderLinkContent(topInfo, item);

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-menuitem-routerlink"
          to={item.to}
          onClick={(e: any) => {
            this.onMenuItemClick(e, item, i)
          }}
          exact
          target={item.target}
          onMouseEnter={(e: any) => this.onMenuItemMouseEnter(i)}
          className={classNames("ripplelink", item.styleClass)}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          className={classNames("ripplelink", item.styleClass)}
          //href={item.url}
          onClick={(e) => this.onMenuItemClick(e, item, i)}
          target={item.target}
          onMouseEnter={(e) => this.onMenuItemMouseEnter(i)}
        >
          {content}
        </a>
      );
    }
  }

  render() {
    var items =
      this.props.items &&
      this.props.items.map((item: any, i: any) => {
        let active = this.state.activeIndex === i;
        let styleClass = classNames(item.badgeStyleClass, {
          "active-menuitem": active,
        });
        let containerClass = classNames("layout-submenu-container", {
          "layout-submenu-megamenu-container": item.mega,
        });
        let submenuClass = classNames("layout-submenu", {
          "layout-megamenu": item.mega,
        });

        return (
          <li className={styleClass} key={i}>
            {this.renderLink(this.props.topInfo, item, i)}
            {!this.props.root && this.props.mega && (
              <span className="layout-megamenu-submenu-text">{item.label}</span>
            )}
            {item.items && (
              <div
                className={containerClass}
                style={{ padding: active ? "" : "0" }}
              >
                <AppSubmenu
                  items={item.items}
                  topInfo={item.topInfo}
                  className={submenuClass}
                  onMenuItemClick={this.props.onMenuItemClick}
                  horizontal={this.props.horizontal}
                  menuActive={this.props.menuActive}
                  mega={item.mega}
                  parentMenuItemActive={active}
                  isHorizontalMenuActive={this.props.isHorizontalMenuActive}
                  root={false}
                  model={null}
                  onSidebarClick={() => { }}
                  onRootMenuItemClick={() => { }}
                  onRootItemClick={() => { }}
                />
              </div>
            )}
          </li>
        );
      });

    return <ul className={this.props.className}>{items}</ul>;
  }
}

interface MenuProps {
  model: any;
  topInfo: any;

  root: boolean;
  mega: boolean;
  className: string;
  menuActive: boolean;
  horizontal: null;

  onSidebarClick: (par1: any) => any;
  isHorizontalMenuActive: () => any;
  onMenuItemClick: (par: any) => any;
  onRootMenuItemClick: (par: any) => any;
}

interface State {
  soundFiles: any;
  top: any;

  // userReg: any;
  // depositReg: any;
  // withdrawReg: any;
  // helpReg: any;
  // agentBalanceCountReg: any;
}

export class AppMenu extends Component<MenuProps, State> {
  audio: any = new Audio();
  adminService: any = new AdminService();

  // userReg: any = new Audio("/assets/sound/reg.MP3");
  // depositReg: any = new Audio("/assets/sound/ch.MP3");
  // withdrawReg: any = new Audio("/assets/sound/ex.MP3");
  // helpReg: any = new Audio("/assets/sound/qa.MP3");
  // agentBalanceCountReg: any = new Audio("/assets/sound/agent.MP3");

  constructor(props: any) {
    super(props);
    this.state = {
      // userReg: new Audio("/assets/sound/reg.mp3"),
      // depositReg: new Audio("/assets/sound/ch.mp3"),
      // withdrawReg: new Audio("/assets/sound/ex.mp3"),
      // helpReg: new Audio("/assets/sound/qa.mp3"),
      // agentBalanceCountReg: new Audio("/assets/sound/agent.mp3"),

      soundFiles: [
        { name: "userReg", file: "/assets/sound/reg.mp3" },
        { name: "depositReg", file: "/assets/sound/ch.mp3" },
        { name: "withdrawReg", file: "/assets/sound/ex.mp3" },
        { name: "helpReg", file: "/assets/sound/qa.mp3" },
        { name: "agentReg", file: "/assets/sound/agent.wav" },
        {
          name: "agentBalanceCountReg",
          file: "/assets/sound/agentbalance.mp3",
        },
      ],
      top: {},
    };
  }

  componentDidMount() {
    // this.state.userReg.load();
    // this.state.depositReg.load();
    // this.state.withdrawReg.load();
    // this.state.helpReg.load();
    // this.state.agentBalanceCountReg.load();

    // this.updateTopInfo();

  }

  playAudio() {
    const playPromise = this.audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          console.log("ok");
        })
        .catch(function (error) {
          console.error("Failed to start your sound, retrying.");
          console.log(error);
        });
    }
  }

  // updateTopInfo = () => {
  //   this.adminService.admin_top_info().then((data) => {
  //     if (data.status == "success") {
  //       this.setState({
  //         top: data.top,
  //       });

  //       let isPlay: boolean = false;
  //       let soundFiles = this.state.soundFiles;
  //       for (let key of Object.keys(data.top)) {
  //         if (data.top[key] != null && data.top[key] > 0) {
  //           let sound = soundFiles.find((s) => s.name == key);

  //           if (sound != null) {
  //             console.log("topInfo", sound, key);

  //             // if (key == "userReg") {
  //             //   this.state.userReg.play();
  //             // } else if (key == "depositReg") {
  //             //   this.state.depositReg.play();
  //             // } else if (key == "withdrawReg") {
  //             //   this.state.withdrawReg.play();
  //             // } else if (key == "helpReg") {
  //             //   this.state.helpReg.play();
  //             // } else if (key == "agentBalanceCountReg") {
  //             //   this.state.agentBalanceCountReg.play();
  //             // }

  //             // isPlay = true;
  //             this.audio = new Audio(sound.file);
  //             this.audio.load();
  //             this.playAudio();
  //           }
  //         }
  //       }
  //     }
  //   });
  // };

  render() {
    return (
      <div
        className="layout-menu-container"
        onClick={this.props.onSidebarClick}
      >
        {/* <div>
          <AudioPlayer
            autoPlay
            src="http://example.com/audio.mp3"
            onPlay={e => console.log("onPlay")}
            // other props here
          />
        </div> */}
        {/* <audio src="/assets/sound/reg.MP3" ref={(green) => {}} autoPlay></audio>{" "}
        <audio ref={(green) => {}} autoPlay>
          <source src="/assets/sound/ch.MP3" type="audio/mpeg"></source>
        </audio>
        <audio ref={(green) => {}} autoPlay>
          <source src="/assets/sound/qa.MP3" type="audio/mpeg"></source>
        </audio>
        <audio ref={(green) => {}} autoPlay>
          <source src="/assets/sound/ex.MP3" type="audio/mpeg"></source>
        </audio>
        <audio ref={(green) => {}} autoPlay>
          <source src="/assets/sound/helpReg.MP3" type="audio/mpeg"></source>
        </audio>
        <audio
          src="/assets/sound/agentbalance.MP3"
          ref={(green) => {}}
          autoPlay
        >
          <source
            src="/assets/sound/agentbalance.MP3"
            type="audio/mpeg"
          ></source>
        </audio> */}
        <div className="layout-menu-wrapper">
          <AppSubmenu
            items={this.props.model}
            topInfo={this.state.top}
            root={true}
            mega={false}
            className="layout-menu"
            menuActive={this.props.menuActive}
            horizontal={this.props.horizontal}
            parentMenuItemActive={true}
            onRootItemClick={this.props.onRootMenuItemClick}
            onMenuItemClick={this.props.onMenuItemClick}
            isHorizontalMenuActive={this.props.isHorizontalMenuActive}
            model={null}
            onSidebarClick={() => { }}
            onRootMenuItemClick={() => { }}
          // items: any;
          // root: boolean;
          // mega: boolean;

          // className: string;
          // menuActive: boolean;
          // horizontal: null;
          // parentMenuItemActive: boolean;
          />
        </div>
      </div>
    );
  }
}
