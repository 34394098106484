import axios from "axios";

import { AxiosMag } from "./axios.service";

export class FxService {
  admin_get_fx_matchs = async (
    skip: any,
    currency: any,
    selectViewType: any,
    selectSortType: any,
    selectMinuteType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/fx/admin_get_fx_matchs", {
      currency,
      skip,
      selectViewType,
      selectSortType,
      selectMinuteType,
    });
  };

  del_fx_match = async () => {
    return await AxiosMag.SendMessageByPost("/fx/admin_del_fx_match", {});
  };

  edit_game_result = async (idx, result) => {
    return await AxiosMag.SendMessageByPost("/fx/edit_game_result", { idx, result });
  };


  admin_get_fx_matchs_end = async (
    skip: any,
    currency: any,
    selectMinute: any,
    selectViewType: any,
    selectSortType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/fx/admin_get_fx_matchs_end", {
      currency,
      skip,
      selectMinute,
      selectViewType,
      selectSortType,
    });
  };

  admin_get_fx_matchs_bet = async (
    skip: any,
    currency: any,
    begin: any,
    end: any,
    selectCurrency: any,
    selectViewType: any,
    selectSortType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/fx/admin_get_fx_matchs_bet", {
      skip,
      currency,
      begin,
      end,
      selectCurrency,
      selectSortType,
      selectViewType,
    });
  };

  admin_get_set_fx = async () => {
    return await AxiosMag.SendMessageByPost("/fx/admin_get_fx_matchs_set", {});
  };

  admin_update_fx = async (currency, minute, rate) => {
    return await AxiosMag.SendMessageByPost("/fx/admin_update_fx", {
      currency,
      minute,
      rate,
    });
  };

  admin_get_gameonoff = async () => {
    return await AxiosMag.SendMessageByPost("/fx/admin_get_gameonoff", {});
  };

  admin_update_gameonoff = async (name, used) => {
    return await AxiosMag.SendMessageByPost("/fx/admin_update_gameonoff", {
      name,
      used,
    });
  };

  admin_do_bet_cancel = async (_id) => {
    return await AxiosMag.SendMessageByPost("/fx/doBetCancel", {
      _id,
    });
  };

  editBet = async (data) => {
    return await AxiosMag.SendMessageByPost("/fx/editBet", data);
  };


}
