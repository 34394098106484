import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { OverlayPanel } from "primereact/overlaypanel";
import ReactJson from "react-json-view";

// import { Tooltip } from "primereact/tooltip";

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
} from "reactstrap";

import {
  ConvertContext,
  ConvertBenefits,
  ConvertResult
} from "../../utility/context";


//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';
import { isMobile, isMobileOnly } from "react-device-detect";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  OpenUserDetail,


} from "../../utility/help";

interface CasinoProp {
  mode: any;
  bets: any;
}

interface CasinoState {
  active: any;
}

export class BetCasinoTable extends Component<CasinoProp, CasinoState> {
  [x: string]: any;
  constructor(props: any) {
    super(props);
    this.state = {
      active: 0,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => { };

  handlePageChange = (e: any) => {
    console.log(e);
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bets;

    return (
      <DataTable
        value={bets}
        paginatorPosition="both"
        rows={50}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "200px" }}
          field="bet"
          header={<div><div>IDX</div><div>배팅일</div></div>}
          body={(rowData: any, column: any) => {
            return <div><div style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>{rowData.transID}</div>
              <div style={{ color: getDepositColor() }}>
                {convertDate(rowData.betTime)}
              </div>
              <div>{rowData.thirdParty}</div>
            </div>;
          }
          }
        />
        <Column
          style={{ textAlign: "center", width: "120px" }}
          header={<div><div>아이디</div><div>에이전트</div></div>}
          field="id"
          // body={(rowData: any, column: any) => {
          //   return <div style={{ color: rowData.color }}>{rowData.id}</div>;
          // }}
          body={(rowData: any, column: any) => {
            return (
              <div
                style={{ color: rowData.color }}
                onClick={() => {
                  OpenUserDetail(rowData.userID);
                }}
              >
                {rowData.userID}
                <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                <div>{rowData.agent}</div>
              </div>
            );
          }}
        />

        < Column
          style={{ textAlign: "center", width: "120px" }}
          field="bet"
          header={<div><div>배팅금</div><div>이긴금액</div><div>차액</div></div>}
          body={(rowData: any, column: any) => {
            return <div>
              <div style={{ borderBottom: '1px solid #d8d8d8', }}>{numTomoeny(rowData.balanceBet)}</div>
              <div style={{ borderBottom: '1px solid #d8d8d8', }}>{numTomoeny(rowData.balanceWin)}</div>
              <div style={{ color: rowData.balanceWin - rowData.balanceBet > 0 ? 'blue' : 'red', }} >{numTomoeny(rowData.balanceWin - rowData.balanceBet)}</div>
            </div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          field="win"
          header={<div>남은금액</div>}

          body={(rowData: any, column: any) => {
            return (
              <div>
                {numTomoeny(rowData.balanceEnd)}{" "}
              </div>
            );
          }}
        />
        {/* 
        <Column
          style={{ textAlign: "center", width: "80px" }}
          field=""
          header=""
          body={(rowData: any, column: any) => {
            return <div>{rowData.userRolling} / {(rowData.userPercent)} </div>;
          }}
        /> */}

        {/* < Column
          style={{ textAlign: "center", width: "150px" }}
          header={<div><div>회사/기록</div><div>게임명</div></div>}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <div>{rowData.gameName}</div>
                <div>{rowData.userRolling} / {(rowData.userPercent)} </div>
              </div>
            );
          }}
        /> */}


        < Column
          style={{ textAlign: "center", width: "250px" }}
          header="에이전트/지급/퍼센트/보유"
          body={(rowData: any, column: any) => {
            if (rowData.benefits !== null) {
              return <div dangerouslySetInnerHTML={{ __html: ConvertBenefits(rowData.benefits, rowData.userID, rowData.userRolling, rowData.userPercent) }} />
            }

            return <div>{ }</div>;
          }}
        />

        < Column
          style={{ textAlign: "center", width: "250px" }}
          header="정보"
          body={(rowData: any, column: any) => {
            if (rowData.context !== null) {

              return <div dangerouslySetInnerHTML={{ __html: ConvertContext(rowData) }} />
            }
            if (rowData.transContent !== null) {
              return <div>{JSON.stringify(rowData.transContent)}</div>;
            }
            return <div>{ }</div>;
          }}
        />
        < Column
          style={{ textAlign: "center", width: "250px" }}
          header="결과"
          body={(rowData: any, column: any) => {
            if (rowData.context !== null) {

              return <div dangerouslySetInnerHTML={{ __html: ConvertResult(rowData) }} />
            }
            if (rowData.transContent !== null) {
              return <div>{JSON.stringify(rowData.transContent)}</div>;
            }
            return <div>{ }</div>;
          }}
        />
      </DataTable >
    );
  };
}
