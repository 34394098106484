import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
} from "../../utility/help";
import { config } from "@fullcalendar/core";

interface Props {
  mode: any;
  game: any[];

  handleOpenDetail: (matchId: string) => any;
  handleMiniDo: (matchId: string) => any;
  
  handleEndBet: () => any;

  handleSelectWinGame: (
    par1: any,
    par2: any,
    par3: any,
    par4: any,
    par5: any,
    par6: any
  ) => any;
}

interface State {
  active: any;
  miniFinishp: any;
  finished: any;
  deposits: any;
  page: any;
  pages: any;
  field: any;
  expandedRows: any;
}

export class ProgressMiniTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      miniFinishp: {},
      finished: {},
      deposits: [],
      page: 0,
      pages: {},
      field: "",
      expandedRows: null,
    };
  }

  componentDidMount = () => {};

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const games = this.props.game == undefined ? [] : this.props.game;
    // const handleOpenDetail = this.props.handleOpenDetail;

    let handleSelectWinGame =
      this.props.handleSelectWinGame == undefined
        ? () => {}
        : this.props.handleSelectWinGame;

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    if (mode == "end") {
      customHeaderStyle = { backgroundColor: "#ffC107", textAlign: "center" };
    }

    const RenderOuntcome = (
      _id: any,
      categoryId: any,
      episode: any,
      gameId: any,
      outcomes: any,
      length: any
    ) => {
      // data.categoryId,
      // data.episode,
      // contact.id,
      // contact.outcomes,
      // markets.length
      return (
        <Column
          style={{ textAlign: "center" }}
          body={(rowData: any, column: any) => {
            return (
              <div className="p-grid">
                <div className="p-col-12 p-md-2">{rowData.name}</div>
                <div className="p-col-12 p-md-2">{rowData.odds}</div>
                <div className="p-col-12 p-md-2">{rowData.result}</div>
                <div className="p-col-12 p-md-2">
                  {numTomoeny(rowData.betBalance)}
                </div>

                <div className="p-col-12 p-md-2">
                  <RadioButton
                    value={rowData.name}
                    inputId={gameId}
                    onChange={(event) => {
                      this.state.finished[`${episode}.${gameId}`] = event.value;
                      this.setState({ finished: this.state.finished });

                      if (handleSelectWinGame != undefined) {
                        handleSelectWinGame(
                          _id,
                          categoryId,
                          episode,
                          gameId,
                          rowData.id,
                          length
                        );
                      }
                    }}
                    checked={
                      this.state.finished[`${episode}.${gameId}`] ===
                      rowData.name
                    }
                  />
                </div>
              </div>
            );
          }}
        />
      );
    };

    const rowExpansionTemplate = (data: any): any => {
      let markets = Object.values(data.markets);

      return markets.map((contact: any, i: any) => {
        return (
          <DataTable
            value={contact.outcomes}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            className="warn"
            key={`${data._id}_${data.categoryId}`}
            header={<div>{contact.name}</div>}
          >
            {RenderOuntcome(
              data._id,
              data.categoryId,
              data.episode,
              contact.id,
              contact.outcomes,
              markets.length
            )}
          </DataTable>
        );
      });
    };

    return (
      <DataTable
        value={games}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
      >
        <Column expander={true} style={{ width: "2em" }} />
        <Column
          style={{ textAlign: "center", width: "240px" }}
          header="게임시간"
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return <div>{convertDate(rowData.gamedate)} </div>;
          }}
        />
        <Column
          style={{ textAlign: "center" }}
          header="게임타입"
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return <div>{rowData.categoryName}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="회차"
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return <div>{rowData.episode}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="상태"
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return <div>{rowData.status}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header="배팅금"
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.betBalance)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center" }}
          header={mode == "end" ? "복원" : "취소"}
          headerStyle={customHeaderStyle}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <Button
                  label={mode == "end" ? "복원" : "취소"}
                  className="p-button-warning"
                  onClick={() => {
                    if (this.props.handleMiniDo != null) {
                      this.props.handleMiniDo(rowData._id);
                    }
                  }}
                />
                       <Button
                        label="배팅마감"
                        onClick={() => {
                          this.props.handleEndBet();
                          // this.handlePageChange(0);
                        }}
                      />
              </div>
            );
          }}
        />
      </DataTable>
    );
  };
}
