import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal, GetYesterDay, ConvertCalendar } from "../utility/help";

interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    begin: any;
    end: any;
    agentInfo: any[];
    viewType: any;
    totalCalBalance: number;
    higtBalance: number;

    higtDeposit: number;
    higtWithdraw: number;
    higtTotalBalance: number;

}

export class CalculateSubagentKcrown extends Component<Props, BalanceState> {
    firstLoad: boolean = true
    adminService: AdminService = new AdminService();

    constructor(props: Props) {
        super(props);


        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},


            viewType: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            totalCalBalance: 0,
            higtBalance: 0,
            higtDeposit: 0,
            higtWithdraw: 0,
            higtTotalBalance: 0,

        };


    }

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_sub_agent(this.props.agentId, begin, end).then((data) => {
            if (data.status == "success") {


                let agents = JSON.parse(JSON.stringify(data.agentCombine)).sort(function (a, b) { return b.level - a.level; });

                const downBalance = {}

                for (let info of agents) {
                    let losingPersistent = 0
                    let CasionPersistent = 0
                    let SlotPersistent = 0

                    const TotlalBalance = (info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) -
                        (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                        (info.totalYDReadInBalance - info.totalRealInBalance)

                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalLosingBalance == null) {
                            main.totalLosingBalance = 0
                        }
                        main.totalLosingBalance += ((info.realDepositBalance - info.realWithdrawBalance)) * (main.benefitLosing - losingPersistent)

                        if (main.totalSlotBalance == null) {
                            main.totalSlotBalance = 0
                        }

                        if (downBalance[tree] == null) {
                            downBalance[tree] = 0
                        }

                        main.totalSlotBalance += ((info.totalRealSlotBet / 100)) * (main.benefitSlotRolling - SlotPersistent)

                        downBalance[tree] += ((info.totalRealSlotBet / 100)) * (main.benefitSlotRolling - SlotPersistent)


                        if (main.totalCasinoBalance == null) {
                            main.totalCasinoBalance = 0
                        }
                        main.totalCasinoBalance += ((info.totalRealCasinoBet / 100)) * (main.benefitCasinoRolling - CasionPersistent)
                        downBalance[tree] += ((info.totalRealCasinoBet / 100)) * (main.benefitCasinoRolling - CasionPersistent)





                        if (main.totalBalance == null) {
                            main.totalBalance = 0
                        }

                        if (main.totalCasion == null) {
                            main.totalCasion = 0
                        }
                        if (main.totalSlot == null) {
                            main.totalSlot = 0
                        }



                        // main.totalBalance += (((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) - (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance)) * 
                        // (main.benefitLosing - losingPersistent)) / 100

                        //  if (com1.level < 4 && com1.totalBalance > 0) {
                        //      com1.totalBalance -= com1.totalCasinoBalance
                        //      com1.totalBalance -= com1.totalSlotBalance
                        //  }


                        main.totalBalance += (((
                            (TotlalBalance) -
                            (downBalance[tree]))) *
                            (main.benefitLosing - losingPersistent)) / 100


                        main.totalSlot += (main.totalRealSlotBet * (main.benefitSlotRolling - SlotPersistent)) / 100

                        const bal = (((
                            (TotlalBalance) -
                            (downBalance[tree]))) *
                            (main.benefitLosing - losingPersistent)) / 100


                        if (info.trees == null) {
                            info.trees = []
                        }
                        info.trees.push({
                            tree: tree,
                            balance: bal,
                            totalBalance: main.totalBalance,
                            rolling: downBalance[tree],
                            SlotRolling: (main.benefitSlotRolling - SlotPersistent),
                            CasinoRolling: (main.benefitCasinoRolling - CasionPersistent),
                            losing: ((main.benefitLosing - losingPersistent)) / 100,
                            userBanace: ((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) -
                                (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                                (info.totalYDReadInBalance - info.totalRealInBalance)
                            )
                        });

                        // console.log(tree,
                        //     ((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) -
                        //         (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance) +
                        //         (info.totalYDReadInBalance - info.totalRealInBalance)).toFixed(0)

                        //     , (main.totalCasinoBalance + main.totalSlotBalance).toFixed(0), (downBalance[tree]).toFixed(0), main.totalBalance.toFixed(0))

                        downBalance[tree] = 0

                        // rowData.realGiveDepositBalance + rowData.realDepositBalance + rowData.realAdmindeposit) -
                        //                                 (rowData.realWithdrawBalance + rowData.realGiveWithdrawBalance + rowData.realAdminwithdraw) + (rowData.totalYDReadInBalance - rowData.totalRealInBalance)
                        // numTomoeny(((rowData.totalSlotBet)) * rowData.benefitSlotRolling / 100
                        // if (isNaN(main.totalBalance)) {
                        //     console.log(main.totalBalance, (((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance), (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance)), (main.benefitLosing - losingPersistent)))

                        // }

                        // {numTomoeny((rowData.realGiveDepositBalance + rowData.realDepositBalance + rowData.realAdmindeposit) -
                        //     (rowData.realWithdrawBalance + rowData.realGiveWithdrawBalance + rowData.realAdminwithdraw) + (rowData.totalYDReadInBalance - rowData.totalRealInBalance))}</div>



                        losingPersistent = main.benefitLosing

                        CasionPersistent = main.benefitCasinoRolling
                        SlotPersistent = main.benefitSlotRolling
                    }

                }




                let kBalanceTotal = 0
                const combin = Object.values(data.agentCombine)

                for (const com1 of combin as any) {


                    // if (com1.level < 4 && com1.totalBalance > 0) {
                    //     com1.totalBalance -= com1.totalCasinoBalance
                    //     com1.totalBalance -= com1.totalSlotBalance
                    // }

                    kBalanceTotal += com1.totalBalance

                }

                for (let info of agents) {
                    console.log(info.trees)
                }

                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })

                let higtBalance = 0
                let higtDeposit = 0
                let higtWithdraw = 0
                let higtTotalBalance = 0
                const higt = combin.find((sss: any) => sss.level === 1) as any
                if (higt != null) {

                    console.log(higt)


                    higtBalance = ((((higt.totalDeposit + higt.totalGiveDeposit + higt.totalAdmindeposit - higt.totalWithdraw - higt.totalGiveWithdraw - higt.totalAdminwithdraw +
                        (higt.totalYDInBalance - higt.totalInBalance))
                        - ((higt.totalSlotBet / 100) * higt.benefitSlotRolling)
                        - ((higt.totalCasinoBet / 100) * higt.benefitCasinoRolling)
                    ) * higt.benefitLosing) / 100)

                    higtDeposit = (higt.totalDeposit + higt.totalGiveDeposit + higt.totalAdmindeposit)
                    higtWithdraw = (higt.totalWithdraw + higt.totalGiveWithdraw + higt.totalAdminwithdraw)

                    higtTotalBalance = (higt.totalDeposit + higt.totalGiveDeposit + higt.totalAdmindeposit)
                        - (higt.totalWithdraw + higt.totalGiveWithdraw + higt.totalAdminwithdraw) + (higt.totalYDInBalance - higt.totalInBalance)
                }

                this.setState({
                    agentInfo: combin, totalCalBalance: kBalanceTotal, higtBalance: higtBalance,
                    higtDeposit,
                    higtWithdraw,
                    higtTotalBalance,

                });

            }
        });
    }

    componentDidMount() {
        if (this.firstLoad === true) {
            this.firstLoad = false
            this.handlegetSubAgentCalculate(this.state.begin, this.state.end);
        }
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }


        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">부본사 {this.props.agentId}</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>


                                        <div className="p-col-12 p-md-4">
                                            <SelectButton
                                                value={this.state.viewType}
                                                options={
                                                    [
                                                        { label: "입출금 정산", value: "balance" },
                                                        { label: "카지노 Win-Lose 정산", value: "casino" },
                                                    ]
                                                }
                                                onChange={(event) => {
                                                    this.setState({ viewType: event.target.value });
                                                }}
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>

                            {
                                this.state.viewType === 'balance' ? (
                                    <div className="card">
                                        <div className="datatable-style-demo">

                                            <DataTable
                                                value={this.state.agentInfo}
                                                paginatorPosition="both"
                                                rows={99999}
                                                sortOrder={-1}
                                                scrollable
                                                scrollHeight="600px"
                                                style={{ width: isMobile ? '1600px' : '100%' }}

                                            >
                                                <Column
                                                    style={{
                                                        textAlign: "center", borderRight: '1px solid #d8d8d8'
                                                    }}
                                                    header="본사"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 1) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                </a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;


                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="부본사"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 2) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="총판"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 3) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="매장"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 4) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="닉네임"
                                                    body={(rowData: any, column: any) => {

                                                        return <>
                                                            <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div>
                                                        <div>{numTomoeny(this.state.higtDeposit)}</div>
                                                    </div>}



                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                                        </div>;

                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div>
                                                        <div>{numTomoeny(this.state.higtWithdraw)}</div>


                                                    </div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                                        </div>;

                                                    }}
                                                />



                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>본유저 입금</div><div>본총판 지급</div><div>본센터 지급</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realDepositBalance)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realGiveDepositBalance)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realAdmindeposit)}</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>본유저 출금</div><div>본총판 회수</div><div>본센터 회수</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realWithdrawBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realGiveWithdrawBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAdminwithdraw)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>회원 루징</div>
                                                        <div>{numTomoeny(this.state.higtTotalBalance)}</div>
                                                    </div>}
                                                    body={(rowData: any, column: any) => {


                                                        return <div>
                                                            <div style={{
                                                                color: (rowData.realAgentChangeBalance + rowData.realDepositBalance + rowData.realAdmindeposit) -
                                                                    (rowData.realWithdrawBalance + rowData.realGiveDepositBalance + rowData.totalWithdraw) > 0 ? 'red' : 'blue'
                                                            }}>
                                                                {numTomoeny((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) -
                                                                    (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw) +
                                                                    (rowData.totalYDInBalance - rowData.totalInBalance))}</div>
                                                        </div>;
                                                    }}
                                                />



                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>슬롯 배팅금</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalRealSlotBet)}</div>
                                                        </div>;
                                                    }}
                                                />





                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>카지노 배팅금</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        // <div style={{ color: rowData.totalCasion > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalCasinoBalance)}</div>
                                                        // <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                        return <div>
                                                            <div style={{ color: rowData.totalCasinoBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalRealCasinoBet)}</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>유저보유금</div><div>전일 보유</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: '#ffc107' }}>{numTomoeny(rowData.totalRealInBalance)}</div>
                                                            <div style={{ color: '#ffc107' }}>{numTomoeny(rowData.totalYDReadInBalance)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>슬롯 롤링</div><div>롤링 프로테이지%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalSlot > 0 ? '#ffc107' : '#ffc107' }}>
                                                                {numTomoeny(rowData.totalSlotBalance)}
                                                            </div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                        </div>;
                                                    }}
                                                />



                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>카지노 롤링</div><div>롤링 프로테이지%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalCasion > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalCasinoBalance)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                                        </div>;
                                                    }}
                                                />

                                                {/* console.log(tree, main.totalCasinoBalance, main.totalSlotBalance) */}

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>
                                                        <div>정산금</div> {
                                                            this.state.higtBalance - this.state.totalCalBalance > 0 &&
                                                            <div>{numTomoeny(this.state.higtBalance - this.state.totalCalBalance)}</div>
                                                        }
                                                    </div>}
                                                    body={(rowData: any, column: any) => {

                                                        const lose = rowData.totalBalance

                                                        return <div>
                                                            <div style={{ color: (lose) > 0 ? 'red' : 'blue' }}>{numTomoeny(lose)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitLosing}%</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>롤링변환 to 벨런스</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>파트너 충전</div><div>파트너 환전</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realAgentChangeBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                                        </div>;
                                                    }}
                                                />

                                            </DataTable>

                                        </div>
                                    </div>
                                ) : (

                                    <div className="card">
                                        <div className="datatable-style-demo">

                                            <DataTable
                                                value={this.state.agentInfo}
                                                paginatorPosition="both"
                                                rows={99999}
                                                sortOrder={-1}
                                                scrollable
                                                scrollHeight="600px"

                                                style={{ width: isMobile ? '1600px' : '100%' }}
                                            >
                                                <Column
                                                    style={{
                                                        textAlign: "center", borderRight: '1px solid #d8d8d8'
                                                    }}
                                                    header="본사"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 1) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                </a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;


                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="부본사"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 2) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="총판"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 3) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="매장"
                                                    body={(rowData: any, column: any) => {
                                                        if (rowData.level === 4) return (
                                                            <>
                                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                                <br />

                                                            </>
                                                        )
                                                        return <></>;
                                                    }}
                                                />
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    header="닉네임"
                                                    body={(rowData: any, column: any) => {

                                                        return <>
                                                            <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                                <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                            </a>
                                                        </>
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div>
                                                        <div>{numTomoeny(this.state.higtDeposit)}</div>
                                                    </div>}



                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                                        </div>;

                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div>
                                                        <div>{numTomoeny(this.state.higtWithdraw)}</div>


                                                    </div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                                        </div>;

                                                    }}
                                                />



                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>본유저 입금</div><div>본총판 지급</div><div>본센터 지급</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realDepositBalance)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realGiveDepositBalance)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realAdmindeposit)}</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>본유저 출금</div><div>본총판 회수</div><div>본센터 회수</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realWithdrawBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realGiveWithdrawBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAdminwithdraw)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>회원 루징</div>
                                                        <div>{numTomoeny(this.state.higtTotalBalance)}</div>
                                                    </div>}
                                                    body={(rowData: any, column: any) => {


                                                        return <div>
                                                            <div style={{
                                                                color: (rowData.realAgentChangeBalance + rowData.realDepositBalance + rowData.realAdmindeposit) -
                                                                    (rowData.realWithdrawBalance + rowData.realGiveDepositBalance + rowData.totalWithdraw) > 0 ? 'red' : 'blue'
                                                            }}>
                                                                {numTomoeny((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) -
                                                                    (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw) +
                                                                    (rowData.totalYDInBalance - rowData.totalInBalance))}</div>
                                                        </div>;
                                                    }}
                                                />





                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>카지노 배팅</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>카지노 롤링</div><div>롤링 프로테이지%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalCasion > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>
                                                        <div>정산금</div> {
                                                            this.state.higtBalance - this.state.totalCalBalance > 0 &&
                                                            <div>{numTomoeny(this.state.higtBalance - this.state.totalCalBalance)}</div>
                                                        }
                                                    </div>}
                                                    body={(rowData: any, column: any) => {
                                                        const total = (((rowData.totalCasinoBet - rowData.totalCasinoWin)
                                                            - ((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)
                                                        ) * rowData.benefitCasinoLosing) / 100


                                                        return <div>
                                                            <div style={{ color: (total) > 0 ? 'red' : 'blue' }}>{numTomoeny(total)}</div>
                                                            <div style={{ color: 'green' }}>{rowData.benefitLosing}%</div>
                                                        </div>;
                                                    }}
                                                />


                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>롤링변환 to 벨런스</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                                        </div>;
                                                    }}
                                                />

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>파트너 충전</div><div>파트너 환전</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.realAgentChangeBalance)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                                        </div>;
                                                    }}
                                                />

                                            </DataTable>

                                        </div>
                                    </div>

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
