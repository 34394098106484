import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { BetService } from "../service/bet.service";

import { ProgressMiniTable } from "../share/progress/progress-mini-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";
import { isElementOfType } from "react-dom/test-utils";

import { confirmAlert } from "react-confirm-alert";

interface State {
  begin: Date;
  end: Date;
  miniFinish: any;
  miniOption: any[];
  userTableValue: any[];
  details: any;
  selectType: string;
  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  cities: any[];

  selectViewType: string;
  selectSortType: string;

  viewType: any[];
  summary?: any;
}

export class ProgressMini extends Component<any, State> {
  betService = new BetService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      miniFinish: {},
      miniOption: [],
      userTableValue: [],
      details: {},
      selectType: "all",
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      cities: [
        { label: "선택", value: null },
        { label: "종목", value: "sportName" },
        { label: "팀명", value: "team" },
        { label: "리그명", value: "tournamentName" },
      ],

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],

      selectSortType: "dateOfMatchTimeStamp",
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);
    // this.handleGetMiniGameList = this.handleGetMiniGameList.bind(this);
    // this.headerSelectType = this.headerSelectType.bind(this);
    // this.handleNoneBet = this.handleNoneBet.bind(this);
    // this.handleEndBet = this.handleEndBet.bind(this);
    // this.handleSelectWinGame = this.handleSelectWinGame.bind(this);

    this.handleGetMiniGameList();
    this.handleGetProgressMiniGame(
      0,
      "categoryId",
      "",
      this.state.selectViewType
    );
  }

  componentDidMount() {}
  handleEndBet = () => {
    var isCheckAll = true;
    for (let episode of Object.keys(this.state.miniFinish)) {
      let finish = this.state.miniFinish[episode];
      if (finish.won.length != finish.length) {
        isCheckAll = false;
      }
    }

    if (isCheckAll == false) {
      confirmAlert({
        title: "미니 게임",
        message: "선택 되지 않은 게임타입이 있습니다",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();

            },
          },
        ],
      });
      return;
    }

    this.betService
      .admin_do_finish_mini_game(this.state.miniFinish)
      .then((s) => {
        if (s.status == "success") {
          confirmAlert({
            title: "미니 게임",
            message: "마감 완료되었습니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();

                },
              },
            ],
          });
          return;
        }
      });
  };

  handleMiniCancel = (id: string) => {
    confirmAlert({
      title: "미니 게임",
      message: "미니게임 배팅을 적특 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.betService.admin_mini_cancel(id).then((data) => {
              if(data.status === 'success'){
                window.location.reload();
              }
              else{
                confirmAlert({
                  title: "미니 게임",
                  message: "해당게임은 이미 완료된게임입니다 완료된 게임은 취소할수 없습니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    }
                  ],
                });
              }
            });
          },
        },
        {
          label: "취소",
          onClick: () => {
            window.location.reload();

          },
        },
      ],
    });
  };

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.handleGetProgressMiniGame(
      page,
      "categoryId",
      this.state.selectType,
      this.state.selectViewType
    );
  };

  headerSelectType = (select: any) => {
    this.setState({ selectType: select });
    this.handleGetProgressMiniGame(
      0,
      "categoryId",
      select,
      this.state.selectViewType
    );
  };

  handleGetMiniGameList = () => {
    this.betService.get_mini_game_list().then((data) => {
      let options = [{ label: "전체", value: "all" }];
      for (let option of Object.values(data.miniOption) as any) {
        options.push({ label: option.categoryName, value: option.categoryId });
      }
      this.setState({ miniOption: options });
    });
  };

  handleNoneBet = () => {
    this.betService.progress_none_bet_del().then((data) => {
      window.location.reload();
    });
  };

  handleGetProgressMiniGame = (
    page: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    let item = searchText;
    if (searchText == "all") {
      item = "";
    }
    this.betService
      .get_progress_mini_game(page, findText, item, mode)
      .then((data) => {
        this.setState({
          userTableValue: data.minis,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  handleSelectWinGame = (
    id: any,
    categoryId: any,
    episode: any,
    gameId: any,
    wonId: any,
    length: any
  ) => {
    let name = categoryId + "_" + episode;

    if (this.state.miniFinish[name] == undefined) {
      this.state.miniFinish[name] = {
        id: id,
        categoryId: categoryId,
        episode: episode,
        gameId: gameId,
        wonId: wonId,
        length: length,
        won: [],
      };
    }

    let finish = this.state.miniFinish[name].won.find(
      (s: any) => s.gameId == gameId
    );

    if (finish == undefined) {
      this.state.miniFinish[name].won.push({ gameId: gameId, wonId: wonId });
    } else {
      finish.gameId = gameId;
      finish.wonId = wonId;
    }
  };

  orderListTemplate = (car: any) => {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  };

  handleOpenDetail = (matchId: any) => {
    this.betService.get_match_details(matchId);
  };

  render = () => {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      return (
        <ProgressMiniTable
          mode="pro"
          game={userTableValue}
          handleOpenDetail={this.handleOpenDetail}
          handleSelectWinGame={this.handleSelectWinGame}
          handleMiniDo={this.handleMiniCancel}
          handleEndBet={this.handleEndBet}
          
        ></ProgressMiniTable>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12 ">
              <div className="card card-w-title" style={{ padding: "0px" }}>
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-1"></div>

                  <div className="p-col-12 p-md-7">
                    <SelectButton
                      value={this.state.selectViewType}
                      options={this.state.viewType}
                      onChange={(event) => {
                        this.setState({ selectViewType: event.value });
                        this.handleGetProgressMiniGame(
                          0,
                          "categoryId",
                          this.state.selectType,
                          event.value
                        );
                      }}
                    />
                    <SelectButton
                      className={"p-buttonset-1"}
                      options={this.state.miniOption}
                      value={this.state.selectType}
                      onChange={(event) => {
                        this.setState({ selectType: event.value });

                        this.headerSelectType(event.value);
                      }}
                    />
                  </div>
{/* 
                  <div className="p-col-12 p-md-2">
                    <span className="md-inputfield">
                      <Button
                        label="배팅마감"
                        onClick={() => {
                          this.handleEndBet();
                          //this.handlePageChange(0);
                        }}
                      />
                    </span>
                  </div> */}

                  <div className="p-col-12 p-md-2">
                    <span className="md-inputfield">
                      <Button
                        label="미배팅삭제"
                        onClick={() => {
                          this.handleNoneBet();
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">진행중인 미니 게임</div>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
