import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  OpenUserDetail,
  ConvertGameText,
  ConvertNick,
} from "../../utility/help";


import {
  ConvertContext,
  ConvertBenefits
} from "../../utility/context";


import { SlotService } from "../../service/slot.service";

interface SlotProps {
  mode: any;
  slots: any;
  // OnEditItem: any;
  // OnSelectItem: any;
  // const mode = this.props.mode;
  // const bets = this.props.bets;
  // const OnEditItem = this.props.OnEditItem;
  // const OnSelectItem = this.props.OnSelectItem;
}

export class BetSlotTable extends Component<SlotProps> {
  slotService = new SlotService();
  constructor(props: SlotProps) {
    super(props);
    this.state = {
      active: 0,
      slots: [],
      page: 0,
      field: "",
    };
  }



  openDetail = async (code: any) => {
    var detail = await this.slotService.admin_slot_bet_detail(code);

    if (detail.status === 'success') {
      var win = window.open(
        detail.url,
        "_blank",
        "width=1600,height=1000"
      );

    }

  }

  componentDidMount = () => { };

  render = () => {
    const mode = this.props.mode;
    const slots = this.props.slots;
    // const OnEditItem = this.props.OnEditItem;
    // const OnSelectItem = this.props.OnSelectItem;

    return (
      <DataTable
        value={slots}
        paginatorPosition="both"
        rows={50}
        sortOrder={-1}
        scrollable={true}
      >
        <Column
          style={{ textAlign: "center", width: "140px" }}
          field="bet"
          header="idx(디테일/일부)"
          body={(rowData: any, column: any) => {
            return <div style={{
              display: 'inline-block',
              width: '140px',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }} onClick={() => this.openDetail(rowData.transID)}>{convertDate(rowData.betTime)}</div>;
          }}
        />
        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="아이디"
          field="id"
          // body={(rowData: any, column: any) => {
          //   return <div style={{ color: rowData.color }}>{rowData.id}</div>;
          // }}
          body={(rowData: any, column: any) => {
            return (
              <div
                style={{
                  color: rowData.color,

                }}
                onClick={() => {
                  OpenUserDetail(rowData.userID);
                }}
              >
                {rowData.userID}
                <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
              </div>
            );
          }
          }
        />


        <Column
          style={{ textAlign: "center", width: "80px" }}
          field="win"
          header="배팅"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.balanceBet)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "80px" }}
          field="win"
          header="이긴금액"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.balanceWin)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "80px" }}
          field="win"
          header="남은금액/일부"
          body={(rowData: any, column: any) => {
            return <div>{numTomoeny(rowData.balanceEnd)}</div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "80px" }}
          field=""
          header="유저 롤링/ 퍼센트"
          body={(rowData: any, column: any) => {
            return <div>{rowData.userRolling} / {(rowData.userPercent)} </div>;
          }}
        />

        <Column
          style={{ textAlign: "center", width: "80px" }}
          field="win"
          header="진행게임/일부"
          body={(rowData: any, column: any) => {
            return <div>{rowData.gamename}</div>;
          }}
        />



        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="회사명"
          body={(rowData: any, column: any) => {
            return <div>{rowData.thirdParty}</div>;
          }}
        />

        < Column
          style={{ textAlign: "center", width: "250px" }}
          header="에이전트/지급/퍼센트/보유"
          body={(rowData: any, column: any) => {
            if (rowData.benefits !== null) {
              return <div dangerouslySetInnerHTML={{ __html: ConvertBenefits(rowData.benefits, rowData.userID, rowData.userRolling, rowData.userPercent) }} />
            }

            return <div>{ }</div>;
          }}
        />

        <Column
          style={{ textAlign: "left", width: "200px" }}
          bodyStyle={{ textAlign: "left", width: "200px" }}
          header="총판"
          body={(rowData: any, column: any) => {
            return <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
          }}
        />

      </DataTable >
    );
  };
}
