
import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetToday, ConvertCalendar, GetYesterDay } from "../utility/help";

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];
    viewType: any;

    totalBalance: any;


}

export class CalculateMainagentCasino extends Component<any, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: any) {
        super(props);

        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            totalBalance: {},
            viewType: "balance",
        };


    }

    handleUpdateCalculateBalance() {
        this.adminService.admin_update_user_calculate_balance().then((data) => {
            if (data.status == "success") {

            }
        })
    }


    handlegetTopAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_main_agent(begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

                let totalBalance = {}
                for (let balances of Object.values(data.agentCombine) as any) {
                    for (let key of Object.keys(balances as [])) {

                        if (typeof (balances[key]) !== 'number') {
                            continue;
                        }

                        if (totalBalance[key] == null) {
                            totalBalance[key] = 0
                        }
                        totalBalance[key] += Number(balances[key] as Number)



                    }

                    if (totalBalance['total'] == null) {
                        totalBalance['total'] = 0
                    }


                    totalBalance['total'] += (balances['totalDeposit'] + balances['totalGiveDeposit'] + balances['totalAdmindeposit'] - balances['totalWithdraw'] - balances['totalGiveWithdraw'] - balances['totalAdminwithdraw']
                        + balances['totalYDInBalance'] - balances['totalInBalance'])



                    if (totalBalance['winlose'] == null) {
                        totalBalance['winlose'] = 0
                    }

                    if (totalBalance['totalCasinoRolling'] == null) {
                        totalBalance['totalCasinoRolling'] = 0
                    }

                    if (totalBalance['totalCasinoRolling'] < ((totalBalance['totalCasinoBet'] / 100) * balances['benefitSlotRolling'])) {
                        totalBalance['totalCasinoRolling'] = ((totalBalance['totalCasinoBet'] / 100) * balances['benefitCasinoRolling'])
                    }
                }

                if (totalBalance['winlose'] == null) {
                    totalBalance['winlose'] = 0
                }

                // console.log(totalBalance['totalSlotRolling'])

                totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']

                totalBalance['totalSlotRolling'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])

                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);

                totalBalance['totalBalance'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {

                    const total = ((cur['totalDeposit'] + cur['totalGiveDeposit'] + cur['totalAdmindeposit'] - cur['totalWithdraw'] - cur['totalGiveWithdraw'] - cur['totalAdminwithdraw']
                        + (cur['totalYDInBalance'] - cur['totalInBalance'])) / 100 * cur['benefitLosing']) - ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])
                    return acc + (Number.isNaN(total) ? 0 : total)
                }, 0);




                if (process.env.REACT_APP_API_CALCULATE_CASINO === 'Y') {

                    totalBalance['totalBalance'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {

                        const total = ((((cur['totalDeposit'] + cur['totalGiveDeposit'] + cur['totalAdmindeposit'] - cur['totalWithdraw'] - cur['totalGiveWithdraw'] - cur['totalAdminwithdraw']
                            + (cur['totalYDInBalance'] - cur['totalInBalance'])))
                            - ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])
                            - ((cur['totalCasinoBet'] / 100) * cur['benefitCasinoRolling']))

                            / 100) * cur['benefitLosing']
                        return acc + (Number.isNaN(total) ? 0 : total)
                    }, 0);




                    // const total = (((rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit - rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw +
                    //     (rowData.totalYDInBalance - rowData.totalInBalance))
                    //     - (Number.isNaN((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling) ? 0 : (rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)
                    //     - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)
                    // ) / 100)
                    //     * rowData.benefitLosing


                }

                totalBalance['totalCasinoLosing'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = ((((cur['totalCasinoBet'] - cur['totalCasinoWin']) - ((cur['totalCasinoBet'] / 100) * cur['benefitCasinoRolling'])) * cur['benefitCasinoLosing']) / 100)
                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);

                totalBalance['totalCasinoWinLose'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = (cur['totalCasinoBet'] - cur['totalCasinoWin'])
                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);


                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })



                this.setState({ agentInfo: combin, totalBalance: totalBalance });
            }
        });
    }

    // handlegetTopAgentCalculate(begin: any, end: any) {
    //     this.adminService.admin_get_main_agent(begin, end).then((data) => {
    //         if (data.status == "success") {
    //             this.setState({ agentInfo: data.agentCombine });
    //         }
    //     });
    // }

    componentDidMount() {
        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.handlegetTopAgentCalculate(begin, end);
    }

    render() {


        return (

            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">에이전트 정산</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}

                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetTopAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>

                                        <div className="p-col-12 p-md-4">
                                            <SelectButton
                                                value={this.state.viewType}
                                                options={
                                                    [
                                                        { label: "입출금 정산", value: "balance" },
                                                        { label: "카지노 Win-Lose 정산", value: "casino" },
                                                    ]
                                                }
                                                onChange={(event) => {
                                                    this.setState({ viewType: event.target.value });
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card">


                                <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={99999}
                                    sortOrder={-1}
                                    scrollable
                                    scrollHeight="600px"
                                    style={{ width: isMobile ? '1600px' : '100%' }}
                                    headerColumnGroup={(
                                        <ColumnGroup>
                                            <Row>
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="에이전트" />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 수익</div><div>총판 수익</div></div>} />


                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>Casino Bet</div><div>Casino Win</div></div>} />





                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>총회원 루징</div>} field="thisYearSale" />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>카지노 롤링</div></div>} />

                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 보유</div><div>전일 보유</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>정산금</div><div>루징프로테이지%</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>에이전트 충전</div><div>에이전트 환전</div></div>} />
                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>롤롱케쉬전환</div>} />

                                                <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>유저 카지노 롤링</div>} />

                                            </Row>
                                            {
                                                this.state.totalBalance && this.state.totalBalance['idx'] !== null && (

                                                    <Row >
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header="정산 합계" />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalDeposit'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalGiveDeposit'])}</div></div>} />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalWithdraw'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalGiveWithdraw'])}</div></div>} />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div> {numTomoeny(this.state.totalBalance['totalDeposit'] - this.state.totalBalance['totalWithdraw'])}</div>
                                                            <div> {numTomoeny(this.state.totalBalance['totalGiveDeposit'] - this.state.totalBalance['totalGiveWithdraw'])}</div></div>} />

                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalCasinoBet'])}</div>
                                                            <div>{numTomoeny(this.state.totalBalance['totalCasinoWin'])}</div></div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div>{numTomoeny(this.state.totalBalance['total'])}</div></div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            {numTomoeny(this.state.totalBalance['totalCasinoRolling'])}</div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            {/* //유저 보유 */}
                                                        </div>} />


                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            {numTomoeny(this.state.totalBalance['totalBalance'])}
                                                        </div>} />
                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(this.state.totalBalance['totalAgentChangeBalance'])}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(this.state.totalBalance['totalAgentExchangeBalance'])}</div>
                                                        </div>} />

                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['exchangeBalance'])}</div>} />

                                                        <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(0)}</div>} />

                                                    </Row>
                                                )

                                            }


                                        </ColumnGroup >
                                    )}

                                >
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <>
                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                </a>
                                                <br />
                                                <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                </a>
                                            </>

                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>

                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                            </div>;

                                        }}
                                    />




                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            // 총회원 루징


                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny((rowData.totalDeposit + rowData.totalGiveDeposit
                                                    + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw) + (rowData.totalYDInBalance - rowData.totalInBalance)
                                                )}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {

                                            return <div>
                                                <div style={{ color: '#ffc107' }}>{numTomoeny((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            {/* 유저보유 */ }

                                            return <div style={{ color: '#2196f3' }}>
                                                <div>{numTomoeny(rowData.totalInBalance)}</div>
                                                <div>{numTomoeny(rowData.totalYDInBalance)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            {/* 정산 */ }


                                            const total = ((((rowData.totalCasinoBet - rowData.totalCasinoBet) * rowData.benefitLosing) / 100)
                                                - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling))


                                            return <div>
                                                <div style={{ color: total > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny(total)}</div>

                                                <div>{rowData.benefitLosing} %</div>
                                            </div>;


                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAgentChangeBalance)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAgentExchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>유저 카지노 롤링</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                                            </div>;
                                        }}
                                    />

                                </DataTable>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
