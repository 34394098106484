import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { UserService } from "../../service/user.service";
import { AlertYesNo } from "../alert-yes-no";
import { confirmAlert } from "react-confirm-alert";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  ConvertNick
} from "../../utility/help";

interface Props {
  user: any;
  management: any;
  handleUserChange: (e: any) => any;
}

interface State {
  userTableValue: any;
  page: any;
  pages: any;
  field: any;
  checkboxValue: any;
}

export class UserBasic extends Component<Props, State> {
  userService: UserService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 0,
      pages: {},
      field: "",
      checkboxValue: [],
    };

  }

  render() {
    let user = this.props.user;
    let handleUserChange = this.props.handleUserChange;

    const SaveUser = () => {
      return (
        <AlertYesNo
          type="success"
          className="p-button-success"
          btnLabel="저장하기"
          title="유저 저장"
          message="유저 정보 를 변경하시겠습니까 "
          call={() => {
            this.userService
              .save_user_detail({
                id: user.id,

                pw: user.pw,
                nick: user.nick,

                exchange_pw: user.exchange_pw,
                level: user.level,
                phone: user.phone,
                friendId: user.friendId,
                bankname: user.bankname,
                banknum: user.banknum,
                bankowner: user.bankowner,
                color: user.color,
                simplememo: user.simplememo,
                memo: user.memo,
                status: user.status,
                lockedsportone: user.lockedsportone,
                lockedsporttwo: user.lockedsporttwo,
                lockedsportall: user.lockedsportall,
                livesportall: user.livesportall,
                agent: user.agent,



                rollingSlot: user.rollingSlot,
                rollingCasino: user.rollingCasino,
                lockedminngame: user.lockedminngame,
                lockedcasino: user.lockedcasino,
                lockedslot: user.lockedslot,
              })
              .then((data) => {
                if (data.status == "success") {
                  confirmAlert({
                    title: "확인",
                    message: "유저 정보가 변경되었습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status == "params") {
                  confirmAlert({
                    title: "확인",
                    message: "잘못된 데이터가 포함되어 있습니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status == "roll") {
                  confirmAlert({
                    title: "확인",
                    message: "롤링 프로테이지를 확인해주세요",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "확인",
                    message: "관리자에게 문의 부탁드림니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          }}
        ></AlertYesNo>
      );
    };

    const management = this.props.management

    return (
      <div>
        <div className="p-grid">


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">닉네임</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="nick"
              value={user.nick}
              placeholder="닉네임"
              onChange={handleUserChange}
            />
          </div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">비밀번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="pw"
              value={user.pw}
              placeholder="비밀번호"
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">환전비번</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="exchange_pw"
              value={user.exchange_pw}
              placeholder="환전비번"
              onChange={handleUserChange}
            />
          </div>



          <div className="p-col-12 p-md-4">
            <label htmlFor="input">회원레벨</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="level"
              placeholder="회원레벨"
              value={user.level}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">전화번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="phone"
              placeholder="전화번호"
              value={user.phone}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">추천아이디</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="friendId"
              placeholder="추천아이디"
              value={user.friendId}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">은행명</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="bankname"
              placeholder="은행명"
              value={user.bankname}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">통장번호</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="banknum"
              placeholder="통장번호"
              value={user.banknum}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">예금주</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="bankowner"
              placeholder="예금주"
              value={user.bankowner}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">아이디 색상</label>
          </div>
          <div className="p-col-12 p-md-8">
            <div className="p-grid form-group">
              <InputText
                id="color"
                placeholder="아이디 색상"
                value={user.color}
                onChange={handleUserChange}
              />

              <Button
                style={{ backgroundColor: "#007bff" }}
                label="#007bff"
                onClick={() => {
                  let color = "#007bff";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
              <Button
                style={{ backgroundColor: "#28a745" }}
                label="#28a745"
                onClick={() => {
                  let color = "#28a745";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />

              <Button
                style={{ backgroundColor: "#fd7e14" }}
                label="#fd7e14"
                onClick={() => {
                  let color = "#fd7e14";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
              <Button
                style={{ backgroundColor: "#dc3545" }}
                label="#dc3545"
                onClick={() => {
                  let color = "#dc3545";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />

              <Button
                style={{ backgroundColor: "#000000" }}
                label="#000000"
                onClick={() => {
                  let color = "#000000";
                  handleUserChange({
                    target: { id: "color", value: color },
                  });
                }}
              />
            </div>
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">가입일</label>
          </div>
          <div className="p-col-12 p-md-8">{convertDate(user.regdate)}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">최근접속일</label>
          </div>
          <div className="p-col-12 p-md-8">{convertDate(user.logindate)}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">가입IP</label>
          </div>
          <div className="p-col-12 p-md-8">{user.regip}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">최근IP</label>
          </div>
          <div className="p-col-12 p-md-8">{user.loginip}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">마지막충전일</label>
          </div>
          <div className="p-col-12 p-md-8">{convertDate(user.lastDeposit)}</div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">마지막환전일</label>
          </div>
          <div className="p-col-12 p-md-8">
            {convertDate(user.lastWithdraw)}
          </div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">슬롯 롤링</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="rollingSlot"
              value={user.rollingSlot}
              onChange={handleUserChange}
            />
          </div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">카지노 롤링</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="rollingCasino"
              value={user.rollingCasino}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">간단메모</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputText
              id="simplememo"
              value={user.simplememo}
              onChange={handleUserChange}
            />
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">메모</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputTextarea
              id="memo"
              rows={3}
              cols={30}
              autoResize={true}
              field="memo"
              value={user.memo}
              onChange={handleUserChange}
            ></InputTextarea>{" "}
          </div>

          <div className="p-col-12 p-md-4">
            <label htmlFor="input">회원 상태</label>
          </div>
          <div className="p-col-12 p-md-8">
            <div className="p-grid">
              <div className="p-col-12">
                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="used"
                    id="status"
                    inputId="status"
                    onChange={handleUserChange}
                    checked={user.status === "used"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    사용중
                  </label>
                </div>
                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="stop"
                    id="status"
                    inputId="status"
                    onChange={handleUserChange}
                    checked={user.status === "stop"}
                  />
                  <label htmlFor="status" className="p-radiobutton-label">
                    사용정지
                  </label>
                </div>

                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="graduation"
                    inputId="status"
                    id="status"
                    onChange={handleUserChange}
                    checked={user.status === "graduation"}
                  />
                  <label htmlFor="status" className="p-radiobutton-label">
                    졸업
                  </label>
                </div>

                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="wait"
                    inputId="status"
                    id="status"
                    onChange={handleUserChange}
                    checked={user.status === "wait"}
                  />
                  <label htmlFor="status" className="p-radiobutton-label">
                    사용대기
                  </label>
                </div>
              </div>
            </div>
          </div>


          <div className="p-col-12 p-md-4">
            <label htmlFor="input">배팅 잠금</label>
          </div>
          <div className="p-col-12 p-md-8">
            <div className="p-grid">
              <div className="p-col-12">
                {
                  management && management.sportpre === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            id="lockedsportone"
                            inputId="lockedsportone"
                            onChange={handleUserChange}
                            checked={user.lockedsportone !== "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            단폴더 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            id="lockedsportone"
                            inputId="lockedsportone"
                            onChange={handleUserChange}
                            checked={user.lockedsportone === "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            단폴더 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>
                }
                {
                  management && management.sportpre === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            id="lockedsporttwo"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedsporttwo !== "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            두폴더 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            id="lockedsporttwo"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedsporttwo === "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            두폴더 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                {
                  management && management.sportpre === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            id="lockedsportall"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedsportall !== "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            스포츠 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            id="lockedsportall"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedsportall === "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            스포츠 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                {
                  management && management.sportlive === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            id="livesportall"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.livesportall !== "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            라스포츠 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            id="livesportall"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.livesportall === "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            라스포츠 미사용
                          </label>
                        </div>
                      </div>
                    </div>) : <></>}

                {
                  management && management.mini === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            id="lockedminngame"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedminngame !== "y"}
                          />
                          <label htmlFor="status" className="p-radiobutton-label">
                            미니게임 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            id="lockedminngame"
                            inputId="status"
                            onChange={handleUserChange}
                            checked={user.lockedminngame === "y"}
                          />
                          <label htmlFor="rb2" className="p-radiobutton-label">
                            미니게임 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                {
                  management && management.casino === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            inputId="status"
                            id="lockedcasino"
                            onChange={handleUserChange}
                            checked={user.lockedcasino !== "y"}
                          />
                          <label htmlFor="status" className="p-radiobutton-label">
                            카지노 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            inputId="status"
                            id="lockedcasino"
                            onChange={handleUserChange}
                            checked={user.lockedcasino === "y"}
                          />
                          <label htmlFor="status" className="p-radiobutton-label">
                            카지노 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>}
                {
                  management && management.slot === 'y' ? (
                    <div className="p-col-12 p-md-6">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="n"
                            inputId="status"
                            id="lockedslot"
                            onChange={handleUserChange}
                            checked={user.lockedslot !== "y"}
                          />
                          <label htmlFor="status" className="p-radiobutton-label">
                            슬롯 사용
                          </label>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <RadioButton
                            value="y"
                            inputId="status"
                            id="lockedslot"
                            onChange={handleUserChange}
                            checked={user.lockedslot === "y"}
                          />
                          <label htmlFor="status" className="p-radiobutton-label">
                            슬롯 미사용
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : <></>}
              </div>
            </div>
          </div>



          <div className="p-col-12 p-md-4">
            <label htmlFor="input">아이디 트리</label>
          </div>
          <div className="p-col-12 p-md-8">
            {/* {ConvertNick(user.lastWithdraw)} */}
            <span dangerouslySetInnerHTML={{ __html: ConvertNick(user.agent_tree, user.agent_tree) }} ></span>
          </div>
          <div className="p-col-12 p-md-4">
            <label htmlFor="input">총판 트리</label>
          </div>
          <div className="p-col-12 p-md-8">
            {/* {ConvertNick(user.lastWithdraw)} */}
            <span dangerouslySetInnerHTML={{ __html: ConvertNick(user.agent_tree, user.agent_tree_ko) }} ></span>
          </div>

          <div className="p-col-12 p-md-4"></div>
          <div className="p-col-12 p-md-4">
            {SaveUser()}
            {/* <Button
              label="저장하기"
              icon="pi-md-launch"
              onClick={() => SaveUser()}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
