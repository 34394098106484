import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { Panel } from "primereact/panel";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";

// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { CommunityService } from "../service/community.service";
import { HelpStatus } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  note: any[];
  notice: any;
  activePage: number;
  maxCount: number;
}

export class HelpTemplate extends Component<any, State> {
  communityService = new CommunityService();

  constructor(props: any) {
    super(props);

    this.state = {
      note: [],
      activePage: 1,
      maxCount: 1,
      notice: {
        contents: "",
        title: "",
        userId: "",
      },
    };
  }

  componentDidMount() {
    this.handleTemplate(1);
  }

  handleTemplate = (skip: any) => {
    this.setState({ activePage: skip });
    this.communityService
      .admin_get_help_template_list(skip)
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({ note: data.note, maxCount: data.count });
        }
      });
  };

  handleWirteHelp = () => {
    this.communityService
      .admin_wirte_help_template(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "고객센터 템플릿",
            message: "고객센터 템플릿 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "고객센터 템플릿",
            message: "고객센터 템플릿 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };


  handleDelHelp = () => {
    this.communityService
      .admin_del_help_template(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "고객센터 템플릿",
            message: "고객센터 템플릿 삭제 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "고객센터 템플릿",
            message: "고객센터 템플릿 삭제 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    let items = this.state.note;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ notice: rowData });
            }}
          ></Button>
        </div>
      );
    };

    const RenderHelp = () => {
      if (this.state.notice.userId !== null) {
        return (
          <div className="p-col-12 p-md-8">
            <div className="card">
              <div className="p-col-12">
                <span className="p-float-label">
                  <InputText
                    id="in"
                    style={{
                      width: "70%",
                    }}
                    value={this.state.notice.title}
                    onChange={(e: any) => {
                      this.state.notice.title = e.target.value;
                      this.setState({ notice: this.state.notice });
                    }}
                  />
                  <label htmlFor="in">Title</label>
                </span>
              </div>
              <div className="p-col-12">
                <Editor
                  style={{ height: "320px" }}
                  value={this.state.notice.contents}
                  onTextChange={(e) => {
                    this.state.notice.contents = e.htmlValue;

                    this.setState({ notice: this.state.notice });
                  }}
                />
              </div>
              {/* <div className="p-col-12">
                <div className="p-col-12 p-md-4">
                  <RadioButton
                    value="y"
                    inputId="rb1"
                    onChange={event => {
                      this.state.notice.del = event.value;

                      this.setState({ notice: this.state.notice });
                    }}
                    checked={this.state.notice.del === "y"}
                  />
                  <label htmlFor="cb1" className="p-checkbox-label">
                    숨김
                  </label>
                  {"      "}
                  <RadioButton
                    value="n"
                    inputId="rb1"
                    onChange={event => {
                      this.state.notice.del = event.value;
                      this.setState({ notice: this.state.notice });
                    }}
                    checked={this.state.notice.del === "n"}
                  />

                  <label htmlFor="cb2" className="p-checkbox-label">
                    보기
                  </label>
                </div>
              </div> */}
              <div className="p-col-12">
                <Button
                  label="저장 하기"
                  onClick={() => {
                    this.handleWirteHelp();
                    //this.handleEditNotice(this.state.notice);
                    //this.handleUpdateBenefit();
                  }}
                />
                <Button
                  label="삭제 하기"
                  onClick={() => {
                    this.handleDelHelp();
                    //this.handleEditNotice(this.state.notice);
                    //this.handleUpdateBenefit();
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">고객센터 템플릿</div>
                <div className="p-md-6">
                  {/* <Button
                    label="저장 하기"
                    onClick={() => {
                    }}
                  />

                  <Button
                    label="레벨 생성"
                    onClick={() => {
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleTemplate}
                ></CustomPagination>
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={30}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타이틀"
                    body={(rowData: any, column: any) => {
                      return rowData.title;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="상태"
                    body={(rowData: any, column: any) => {
                      return HelpStatus(rowData.status);
                    }}
                  />
                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleTemplate}
                ></CustomPagination>{" "}
              </div>
            </div>

            {RenderHelp()}
          </div>
        </div>
      </div>
    );
  }
}
