import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";
import { InputText } from "primereact/inputtext";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail
} from "../utility/help";
import { CONNREFUSED } from "dns";

interface State {
  maxCount: number;
  userTableValue: any[];
  selectBox: any[];
  dropdownItem: any;

  page: number;
  pages: any;
  search: string;
  find: string;
  kmenu?: any;
}

export class UserIP extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      maxCount: 1,
      dropdownItem: null,
      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "IP 검색", value: "loginip" }
      ]
    };
  }

  componentDidMount() {
    this.GetIPList(0, this.state.find, this.state.search);
  }

  GetIPList = (page: number, find: string, search: string) => {
    this.userService.admin_get_user_ip(page, find, search).then(data => {
      let ips: any = [];
      for (let ip of data.ips) {
        for (let key of Object.keys(ip.connects)) {
          ips.push({
            ip: ip.ip,
            id: key,
            date: ip.connects[key],
            count: ip.count
          });
        }
      }

      console.log(ips)

      this.setState({
        userTableValue: ips,
        page: page,
        maxCount: data.maxCount
      });
    });
  };

  //   SortTable = (sortType: any, sort: any) => {
  //     this.setState({ sortType: sortType, sort: sort });
  //     this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  //   };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetIPList(1, find, search);
  };

  MoveSel = (page: any) => {
    this.GetIPList(page, this.state.find, this.state.search);
  };

  render() {
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="p-md-6">
                  <div className="p-col-12 ">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <Dropdown
                            style={{ width: "200px" }}
                            options={this.state.selectBox}
                            value={this.state.dropdownItem}
                            onChange={event =>
                              this.setState({ dropdownItem: event.value })
                            }
                            autoWidth={false}
                          />{" "}
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <InputText
                            value={this.state.search}
                            onChange={(e: any) =>
                              this.setState({ search: e.target.value })
                            }
                            onKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                this.SearchData(
                                  this.state.dropdownItem,
                                  this.state.search
                                );
                              } else {
                              }
                            }}
                          />
                          <label>검색</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <Button
                            label="검색"
                            onClick={() => {
                              this.SearchData(
                                this.state.dropdownItem,
                                this.state.search
                              );
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
              {/* 
              <UserTable
                userTableValue={this.state.userTableValue}
                SortTable={this.SortTable}
                mode="used"
                handleUseStauseChange={() => {}}
              ></UserTable> */}
              <DataTable
                // value={this.state.userTableValue}
                // paginatorPosition="both"
                // rows={20}
                // sortOrder={-1}
                value={this.state.userTableValue}
                sortField="ip"
                sortOrder={1}
                groupField="ip"
                rowGroupMode="rowspan"
                style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
              >
                <Column
                  field="ip"
                  header="아이피"
                  style={{ border: "1px solid #c8c8c8" }}
                />
                {/* <Column
                  field="id"
                  header="아이디"
                  style={{ border: "1px solid #c8c8c8" }}
                /> */}
                <Column
                  style={{ textAlign: "center" }}
                  header="아이디"
                  body={rowData => {
                    return (
                      <div>
                        <div
                          onClick={() => {
                            OpenUserDetail(rowData.id);
                            // Object.values(rowData.connects);
                          }}
                        >
                          {rowData.id}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                        </div>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ textAlign: "center" }}
                  header="접속일"
                  body={rowData => {
                    return <div>{rowData.date}</div>;
                  }}
                />

              </DataTable>
              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
