import React, { Component } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

import { BalanceService } from "../service/balance.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";

// import {
//   numTomoeny,
//   convertIP,
//   convertDate,
//   getDepositColor,
//   getWithdrawColor,
//   getDeposiOrWithdrawColor,
//   converBalanceStatus
// } from "../utility/help";

interface State {
  withdraws: any[];
  page: number;
  pages: any;
  field: string;
}

export class Withdraw extends Component<any, State> {
  balanceService = new BalanceService();

  constructor(props: any) {
    super(props);
    this.state = {
      withdraws: [],
      page: 0,
      pages: {},
      field: ""
    };

    this.OnEditItem = this.OnEditItem.bind(this);
    this.OnSelectItem = this.OnSelectItem.bind(this);

    this.send_message_server = this.send_message_server.bind(this);

    this.get_wait_withdraw(0);
  }

  componentDidMount() { }

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find(s => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find(s => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };

  get_wait_withdraw = (page: any) => {
    this.balanceService.get_wait_withdraw(page).then(data => {
      this.setState({ withdraws: data.withdraws });
    });
  };

  send_message_server = (mode: any) => {
    let ex: any[] = [];

    for (let deposit of this.state.withdraws) {
      if (deposit.sel == "y") {
        ex.push(deposit);
      }
    }

    // if (ex.length == 0) {
    //   alert("선택된 아이템이 존재하지 않습니다");
    //   window.location.reload();

    //   return;
    // }

    if (mode == "success" && ex.length > 0) {
      if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
        this.balanceService.set_withdraw_success_one_balance(ex).then(s => {
          if (s.status == "success") {
            alert("환전이 완료되어 습니다.");
            window.location.reload();
          } else if (s.status == "deposits") {
            alert("선택된 아이템이 존재하지 않습니다");
            window.location.reload();
          } else if (s.status == "already") {
            alert("환전완료 또는환전취소 정보가 있습니다");
            window.location.reload();
          } else {
            alert("관리자에게 문의 부탁드림니다");
            window.location.reload();
          }
        });

      } else {
        this.balanceService.set_withdraw_success(ex).then(s => {
          if (s.status == "success") {
            alert("환전이 완료되어 습니다.");
            window.location.reload();
          } else if (s.status == "deposits") {
            alert("선택된 아이템이 존재하지 않습니다");
            window.location.reload();
          } else if (s.status == "already") {
            alert("환전완료 또는환전취소 정보가 있습니다");
            window.location.reload();
          } else {
            alert("관리자에게 문의 부탁드림니다");
            window.location.reload();
          }
        });
      }

    } else if (mode == "wait" && ex.length > 0) {
      this.balanceService.set_withdraw_wait(ex).then(s => {
        if (s.status == "success") {
          alert("환전 대기상태로 변경되었습니다 .");
          window.location.reload();
        } else if (s.status == "deposits") {
          alert("선택된 아이템이 존재하지 않습니다");
          window.location.reload();
        } else if (s.status == "already") {
          alert("환전완료 또는환전취소 정보가 있습니다");
          window.location.reload();
        } else {
          alert("관리자에게 문의 부탁드림니다");
          window.location.reload();
        }
      });
    } else if (mode == "cancel" && ex.length > 0) {
      this.balanceService.set_withdraw_cancel(ex).then(s => {
        if (s.status == "success") {
          alert("환전을 취소 하였습니다.");
          window.location.reload();
        } else if (s.status == "deposits") {
          alert("선택된 아이템이 존재하지 않습니다");
          window.location.reload();
        } else if (s.status == "already") {
          alert("환전완료 또는환전취소 정보가 있습니다");
          window.location.reload();
        } else {
          alert("관리자에게 문의 부탁드림니다");
          window.location.reload();
        }
      });
    }
  };

  render() {
    let header = (up: any, down: any) => {
      return (
        <div>
          <span>{up}</span>
          <br />
          <span>{down}</span>
          <br />
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">출금 신청</div>

                <div className="p-md-6">
                  <div className="p-grid form-group">
                    <AlertYesNo
                      type="success"
                      className="p-button-success"
                      btnLabel="완료"
                      title="출금 신청 / 완료"
                      message="출금 신청을 완료 하시겠습니까 ?"
                      call={this.send_message_server}
                    ></AlertYesNo>
                    <AlertYesNo
                      type="wait"
                      className="md-inputfield"
                      btnLabel="대기"
                      title="출금 신청 / 대기"
                      message="출금 신청을 대기 상태로 변경하시겠습니까 ?"
                      call={this.send_message_server}
                    ></AlertYesNo>
                    <AlertYesNo
                      type="cancel"
                      className="p-button-danger"
                      btnLabel="취소"
                      title="출금 신청 / 취소"
                      message="출금 신청을 취소 상태로 변경하시겠습니까 ?"
                      call={this.send_message_server}
                    ></AlertYesNo>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="p-col-12">
              <BalanceTable
                mode="withdraw"
                balances={this.state.withdraws}
                OnEditItem={this.OnEditItem}
                OnSelectItem={this.OnSelectItem}
              ></BalanceTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
