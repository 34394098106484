import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal, GetYesterDay, ConvertCalendar } from "../utility/help";

interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    begin: any;
    end: any;
    agentInfo: any[];
    viewType: any;
}

export class CalculatesubAgentDaw extends Component<Props, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: Props) {
        super(props);


        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},


            viewType: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
        };

        this.handlegetSubAgentCalculate(begin, end);

    }

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_sub_agent(this.props.agentId, begin, end).then((data) => {
            if (data.status == "success") {
                console.log(data.agentCombine)

                let agents = JSON.parse(JSON.stringify(data.agentCombine)).sort(function (a, b) { return b.level - a.level; });


                for (let info of agents) {
                    let losingPersistent = 0

                    for (let tree of info.agent_tree.split('|').reverse()) {
                        if (tree == null || tree == '') {
                            continue;
                        }
                        let main = data.agentCombine.find(s => s.agent == tree)
                        if (main == null) {
                            continue;
                        }
                        if (main.totalLosingBalance == null) {
                            main.totalLosingBalance = 0
                        }
                        main.totalLosingBalance += ((info.totalRealSlotBet - info.totalRealSlotWin)) * (main.benefitLosing - losingPersistent)

                        if (main.totalBalance == null) {
                            main.totalBalance = 0
                        }


                        main.totalBalance += (((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance) - (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance)) * (main.benefitLosing - losingPersistent)) / 100

                        if (isNaN(main.totalBalance)) {
                            console.log(main.totalBalance, (((info.realGiveDepositBalance + info.realAdmindeposit + info.realDepositBalance), (info.realGiveWithdrawBalance + info.realAdminwithdraw + info.realWithdrawBalance)), (main.benefitLosing - losingPersistent)))

                        }

                        // main.totalBalance += (((info.totalDeposit + info.totalGiveDeposit + info.totalAdmindeposit) - (info.totalWithdraw + info.totalGiveWithdraw + info.totalAdminwithdraw)) * (main.benefitLosing - losingPersistent)) / 100



                        // console.log(`${info.realGiveDepositBalance}  ${info.realAdmindeposit} ${info.realDepositBalance}  `)
                        // console.log(`${main.totalBalance} ${main.totalWithdraw} ${main.totalGiveWithdraw}  ${main.totalAdminwithdraw}`)
                        // console.log(` ${info.realGiveWithdrawBalance}  ${info.realAdminwithdraw}  ${info.realWithdrawBalance} `)
                        // console.log(main.totalBalance)
                        // // <div style={{ color: 'red' }}>{numTomoeny(rowData.totalRealSlotBet)}</div>
                        // <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalRealSlotWin)}</div>

                        // body={(rowData: any, column: any) => {
                        //     return <div>
                        //         <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit)}</div>

                        //     return <div>
                        //         <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)}</div>

                        losingPersistent = main.benefitLosing
                    }
                }


                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })


                this.setState({ agentInfo: combin });

            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }


        // let headerGroup = (
        //     <ColumnGroup>
        //         {/* <Row>
        //             <Column header="Product" rowSpan={4} />
        //             <Column header="Sale Rate" colSpan={4} />
        //         </Row> */}
        //         <Row>
        //             <Column header="아이디" colSpan={4} />
        //             <Column header="Sales" colSpan={2} />
        //             <Column header="Profits" colSpan={2} />
        //         </Row>
        //         <Row>
        //             <Column header="Last Year" sortable field="lastYearSale" />
        //             <Column header="This Year" sortable field="thisYearSale" />
        //             <Column header="Last Year" sortable field="lastYearProfit" />
        //             <Column header="This Year" sortable field="thisYearProfit" />
        //         </Row>
        //     </ColumnGroup>
        // );
        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">부본사 {this.props.agentId}</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>

                                    <SelectButton
                                        value={this.state.viewType}
                                        options={
                                            [
                                                { label: "입출금 정산", value: "balance" },
                                                { label: "Bet-Win 정산", value: "betwin" },
                                            ]
                                        }
                                        onChange={(event) => {
                                            this.setState({ viewType: event.target.value });
                                            // this.handlePageChange(0, event.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="card">
                                <div className="datatable-style-demo">

                                    <DataTable
                                        value={this.state.agentInfo}
                                        paginatorPosition="both"
                                        rows={99999}
                                        sortOrder={-1}
                                        rowClassName={rowClass}
                                        scrollable
                                        style={{ width: isMobile ? '1600px' : '100%' }}

                                    // headerColumnGroup={headerGroup}
                                    >
                                        <Column
                                            style={{
                                                textAlign: "center", borderRight: '1px solid #d8d8d8'
                                            }}
                                            header="부본사"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.level === 1) return (
                                                    <>
                                                        <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                        </a>
                                                        <br />

                                                    </>
                                                )
                                                return <></>;


                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            header="총판"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.level === 2) return (
                                                    <>
                                                        <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                        <br />

                                                    </>
                                                )
                                                return <></>;
                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            header="매장"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.level === 3) return (
                                                    <>
                                                        <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                        <br />

                                                    </>
                                                )
                                                return <></>;
                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            header="매장?"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.level === 4) return (
                                                    <>
                                                        <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                        <br />

                                                    </>
                                                )
                                                return <></>;
                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            header="닉네임"
                                            body={(rowData: any, column: any) => {

                                                return <>
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            }}
                                        />


                                        {
                                            this.state.viewType === 'balance' && (
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>입금</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                                        </div>;
                                                    }}
                                                />


                                            )
                                        }

                                        {
                                            this.state.viewType === 'balance' && (
                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>출금</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>
                                                        </div>;
                                                    }}
                                                />


                                            )
                                        }

                                        {
                                            this.state.viewType === 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>수익(루징금)</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny(rowData.totalDeposit - rowData.totalWithdraw)}</div>
                                                            <div style={{ color: rowData.totalGiveDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny(rowData.totalGiveDeposit - rowData.totalGiveWithdraw)}</div>
                                                            <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny(rowData.totalAdmindeposit - rowData.totalAdminwithdraw)}</div>
                                                        </div>;
                                                    }}
                                                />

                                            )
                                        }

                                        {
                                            this.state.viewType === 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>루징금 * 루징%</div>}
                                                    body={(rowData: any, column: any) => {

                                                        const lose = rowData.totalBalance
                                                        return <div>
                                                            <div style={{ color: rowData.totalDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw) * rowData.benefitLosing) / 100)}</div>
                                                            <div style={{ color: rowData.totalGiveDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny((rowData.totalGiveDeposit - rowData.totalGiveWithdraw * rowData.benefitLosing) / 100)}</div>
                                                            <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny((rowData.totalAdmindeposit - rowData.totalAdminwithdraw * rowData.benefitLosing) / 100)}</div>
                                                        </div>;
                                                    }}
                                                />

                                            )
                                        }






                                        {
                                            this.state.viewType !== 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>Bet</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                        </div>;

                                                    }}
                                                />
                                            )
                                        }

                                        {
                                            this.state.viewType !== 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div>Win</div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                        </div>;

                                                    }}
                                                />
                                            )
                                        }

                                        {

                                            this.state.viewType !== 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>총회원 루징</div><div>(BET - WIN) </div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalRealSlotBet - rowData.totalRealSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalRealSlotBet - rowData.totalRealSlotWin)}</div>
                                                        </div>;
                                                    }}
                                                />)

                                        }

                                        {/* {
                                            this.state.viewType !== 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>총회원 루징</div><div>BET - WIN</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                                        </div>;
                                                    }}
                                                />
                                            )
                                        } */}

                                        {
                                            this.state.viewType !== 'balance' && (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>루징금*루징%</div></div>}
                                                    body={(rowData: any, column: any) => {
                                                        return <div>
                                                            <div style={{ color: rowData.totalLosingBalance > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalLosingBalance / 100)}</div>
                                                        </div>;
                                                    }}
                                                />
                                            )
                                        }

                                        {
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>롤링 정산금</div><div>롤링 프로테이지%</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: rowData.totalRollingBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.totalRollingBalance)}</div>
                                                        <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                                    </div>;
                                                }}
                                            />
                                        }
                                        {
                                            // this.state.viewType !== 'balance' ? (

                                            //     // process.env.REACT_APP_API_CALCULATE_ROLLING_OFF !== 'Y' && < Column
                                            //     //     style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            //     //     bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            //     //     header={<div><div>정산금</div><div>루징프로테이지%</div></div>}
                                            //     //     body={(rowData: any, column: any) => {

                                            //     //         return <div>
                                            //     //             <div style={{ color: rowData.totalBalance - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                            //     //                 {numTomoeny((rowData.totalBalance) - (rowData.totalRollingBalance))}</div>
                                            //     //             <div>{rowData.benefitLosing} %</div>
                                            //     //         </div>;
                                            //     //     }}
                                            //     // />

                                            // ): 
                                            this.state.viewType !== 'balance' &&
                                            (

                                                <Column
                                                    style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                    header={<div><div>정산금</div></div>}
                                                    body={(rowData: any, column: any) => {



                                                        // const lose = ((((rowData.totalGiveDeposit + rowData.totalDeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw)) * rowData.benefitLosing) / 100) - (rowData.totalRollingBalance)
                                                        return <div>
                                                            <div style={{ color: rowData.totalDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw) * rowData.benefitLosing) / 100)}</div>
                                                            <div style={{ color: rowData.totalGiveDeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny((rowData.totalGiveDeposit - rowData.totalGiveWithdraw * rowData.benefitLosing) / 100)}</div>
                                                            <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw ? 'red' : 'blue' }}>{numTomoeny((rowData.totalAdmindeposit - rowData.totalAdminwithdraw * rowData.benefitLosing) / 100)}</div>
                                                        </div>;
                                                    }}
                                                />

                                            )
                                        }


                                        {
                                            // <Column
                                            //     style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                            //     bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                            //     header={<div>롤링 유저지급</div>}
                                            //     body={(rowData: any, column: any) => {
                                            //         return <div>
                                            //             <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                            //         </div>;
                                            //     }}
                                            // />



                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div>롤링변환 to 벨런스</div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                                    </div>;
                                                }}
                                            />
                                        }
                                    </DataTable>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
