import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { confirmAlert } from "react-confirm-alert";

import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { UserService } from "../../service/user.service";
import { CustomPagination } from "../custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  converBalanceType,
  GetToday,
  convertSimpleDate,
} from "../../utility/help";
import { WindowScrollController } from "@fullcalendar/core";

interface Props {
  id: string;
}

interface State {
  id: string;
  logs: any;
  maxCount: number;
  activePage: number;

  begin: any;
  end: any;
}

export class UserLogUserLogin extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    let today = GetToday();
    today.begin.setDate(today.begin.getDate() - 31);

    this.state = {
      id: this.props.id,
      logs: [],
      maxCount: 1,
      activePage: 0,

      begin: today.begin,
      end: today.end,
    };

    // this.headerDepositAndWithdraw = this.headerDepositAndWithdraw.bind(this);
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {
    let id = this.props.id;
    this.headerUserLoginLog(id, 0);
  };

  handlePageChange = (page) => {
    this.headerUserLoginLog(this.state.id, page);
  };

  headerUserLoginLog = (id: string, page: any) => {
    this.userService.get_user_login_log_list(id, page).then((data) => {
      if (data.status == "success") {
        this.setState({
          activePage: page,
          logs: data.logs,
          maxCount: data.maxCount,
        });
      }
    });
  };

  render = () => {
    const logs = this.state.logs;

    return (
      <div>
        <div className="p-col-12">
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>

          <DataTable
            value={logs}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
          >
            {/* <Column
              style={{ textAlign: "center" }}
              header="입금/출금	"
              body={(rowData: any, column: any) => {
                return rowData.type;
              }}
            /> */}
            <Column
              style={{ textAlign: "center" }}
              header="OS"
              body={(rowData: any, column: any) => {
                let text = ''
                if (rowData.os != null) {
                  text = `${rowData.os.name}`
                }

                return text;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="browser"
              body={(rowData: any, column: any) => {
                let text = ''
                if (rowData.browser != null) {
                  text = text + ` ${rowData.browser.name}`
                }

                return text;
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="아이피"
              body={(rowData: any, column: any) => {
                return rowData.ip;
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="도매인"
              body={(rowData: any, column: any) => {
                return rowData.domain;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"요청시간"}
              body={(rowData: any, column: any) => {
                return <div>{convertDate(rowData.regDate)}</div>;
              }}
              field="regDate"
            />

            <Column
              style={{ textAlign: "center" }}
              header={"결과"}
              body={(rowData: any, column: any) => {
                return <div>{rowData.status}</div>;
              }}
            />
          </DataTable>
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>
        </div>
      </div>
    );
  };
}
