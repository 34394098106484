import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SelectButton } from "primereact/selectbutton";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday, ConvertCalendar } from "../utility/help";

interface MatchState {
  details: any;
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  page: any;
  maxCount: any;
  summary: any;
  option: any;


  veiwSelectType: any;
  viewType: any[];
}

export class BetMatch extends Component<any, MatchState> {
  betService: BetService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      summary: {},
      userTableValue: [],
      details: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      veiwSelectType: "all",

      option: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
        { label: "아이피", value: "ip" },
      ],

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "대기중배팅", value: "reg" },
        { label: "낙점", value: "lose" },
        { label: "당첨", value: "win" },
      ],
    };

    this.betService = new BetService();

    this.handlePageChange = this.handlePageChange.bind(this);

    this.get_match_bet_list(1, '', this.state.veiwSelectType);
  }

  componentDidMount() { }

  get_match_bet_list = (page: any, searchText: any, veiwSelectType: any) => {
    this.betService
      .get_match_bet_list(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        searchText,
        veiwSelectType
      )
      .then((data) => {
        for (let match of data.matchs) {
          this.betService
            .get_match_detail_by_betId(match._id)
            .then((detailData) => {
              this.state.details[detailData.betId] = detailData.detail;

              this.setState({
                details: this.state.details,
                maxCount: data.maxCount,
                summary: data.summary,
              });
            });
        }

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_match_bet_list(page, this.state.searchText, this.state.veiwSelectType);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      return userTableValue.map((contact: any, i: any) => {
        return (
          <BetMatchTable
            bet={contact}
            key={contact._id}
            details={this.state.details[contact._id]}
            mode={"bet"}
          ></BetMatchTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">스포츠 배팅정보</div>

                  <div className="p-col-12 ">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="Begin"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.begin}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ begin: event.value })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="End"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.end}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ end: event.value })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>
                      <div className="p-col-12 p-md-1">
                        <span className="md-inputfield">
                          <Dropdown
                            style={{ width: "100px" }}
                            options={this.state.option}
                            value={this.state.findText}
                            onChange={(event) => {
                              this.setState({ findText: event.value })
                            }}
                            autoWidth={false}
                          />
                        </span>
                      </div>
                      <div className="p-col-12 p-md-1">
                        <span className="md-inputfield">
                          <InputText
                            value={this.state.searchText}
                            onChange={(e: any) => this.setState({ searchText: e.target.value })}
                            onKeyPress={(e: any) => {
                              if (e.key === "Enter") {
                                this.handlePageChange(0);
                              } else {
                              }
                            }}
                          />
                          <label>검색</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handlePageChange(0);
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <DataTable
                          style={{ padding: "0px" }}
                          value={this.state.summary}
                          paginatorPosition="both"
                          rows={20}
                        >
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="error" text="BET" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalBet)}</span>
                                </div>
                              );
                            }}
                          />
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="info" text="WIN" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalWin)}</span>
                                </div>
                              );
                            }}
                          />
                        </DataTable>
                      </div>
                    </div>
                    <div className="p-col-12 ">
                      <SelectButton
                        className={"p-buttonset-1"}
                        options={this.state.viewType}
                        value={this.state.veiwSelectType}
                        onChange={(event) => {
                          this.setState({ veiwSelectType: event.value });
                          this.get_match_bet_list(1, this.state.searchText, event.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={10}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={10}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
