import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { Editor } from "primereact/editor";
import { confirmAlert } from "react-confirm-alert";

import { BetMatchTable } from "../bet/bet-match-table";
import { BetMiniTable } from "../bet/bet-mini-table";
import { BetSlotTable } from "../bet/bet-slot-table";
import { BetCasinoTable } from "../bet/bet-casino-table";

// import { BetService } from "../../service/bet.service";
import { CommunityService } from "../../service/community.service";
import { CustomPagination } from "../custom-pagination";

import { GetToday, numTomoeny, convertDate } from "../../utility/help";

interface Props {
  id: any;
}

interface State {
  notices: any;
  page: any;
  pages: any;
  maxCount: number;
  activePage: number;
  notice: any;
  expandedRows: any;
}

export class UserNote extends Component<Props, State> {
  communityService: CommunityService;
  constructor(props: Props) {
    super(props);

    this.state = {
      maxCount: 0,
      page: 0,
      activePage: 0,

      notices: [],
      pages: {},
      expandedRows: null,
      notice: {
        mode: "user",
        del: "n",
        text: "",
        title: "",
        userId: this.props.id,
      },
    };

    this.communityService = new CommunityService();
  }

  componentDidMount = () => {
    // this.setState({ id: this.props.id });
    this.handleNotes(0);

    //this.setState({ begin: today.begin, end: today.end });
  };

  handleNotes = (page: any) => {
    this.communityService
      .admin_get_user_note_by_id(page, this.props.id)
      .then((data) => {
        this.setState({
          notices: data.notes,
          maxCount: data.maxCount,
        });
      });
  };

  handleWirteNote = () => {
    if (
      this.state.notice.title == null ||
      this.state.notice.title == "" ||
      this.state.notice.text == null ||
      this.state.notice.text == ""
    ) {
      confirmAlert({
        title: "유저 쪽지",
        message: "메세지 타이틀 밎 메세지를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
      return;
    }
    this.communityService
      .admin_wirte_user_note(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "유저 쪽지",
            message: "메세지 전송 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "유저 쪽지",
            message: "찾을수 없는 유저 아이디입니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    const RenderWrite = () => {
      return (
        <div className="p-col-12">
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                id="in"
                style={{
                  width: "70%",
                }}
                value={this.state.notice.title}
                onChange={(e: any) => {
                  this.state.notice.title = e.target.value;
                  this.setState({ notice: this.state.notice });
                }}
              />
              <label htmlFor="in">Title</label>
            </span>
          </div>
          <div className="p-col-12">
            <Editor
              style={{ height: "200px" }}
              value={this.state.notice.text}
              onTextChange={(e) => {
                this.state.notice.text = e.htmlValue;

                this.setState({ notice: this.state.notice });
              }}
            />
          </div>

          <div className="p-col-12">
            <Button
              label="저장 하기"
              onClick={() => {
                this.handleWirteNote();
              }}
            />
          </div>
        </div>
      );
    };

    const rowExpansionTemplate = (data: any): any => {
      return (
        <div className="p-grid">
          <div className="p-col-12 p-md-12">
            <div dangerouslySetInnerHTML={{ __html: data.text }} />
          </div>
        </div>
      );
    };

    const RenderTable = () => {
      return (
        <div className="p-col-12">
          <CustomPagination
            active={this.state.activePage}
            take={10}
            count={this.state.maxCount}
            handlePageChange={this.handleNotes}
          ></CustomPagination>
          <DataTable
            value={this.state.notices}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={this.state.expandedRows}
            onRowToggle={(e: any) => {
              console.log(e.data);
              this.setState({ expandedRows: e.data });
            }}
            scrollable={true}
          >
            <Column expander={true} style={{ width: "2em" }} />
            {/* 
            <Column
              style={{ textAlign: "center" }}
              header="아이디"
              body={(rowData: any, column: any) => {
                return rowData.userId;
              }}
            /> */}

            <Column
              style={{ textAlign: "center" }}
              header="타이틀"
              body={(rowData: any, column: any) => {
                return rowData.title;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="확인/미확인"
              body={(rowData: any, column: any) => {
                if (rowData.isRead == "n") return "미확인";
                return (
                  <div>
                    <div>{"확인"}</div>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="전송일"
              body={(rowData: any, column: any) => {
                return (
                  <div>
                    <div>{convertDate(rowData.regDate)}</div>
                  </div>
                );
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="확인일"
              body={(rowData: any, column: any) => {
                if (rowData.isRead == "n") return "미확인";
                return <div>{convertDate(rowData.readDate)}</div>;
              }}
            />
          </DataTable>
          <CustomPagination //
            active={this.state.activePage}
            take={10}
            count={this.state.maxCount}
            handlePageChange={this.handleNotes}
          ></CustomPagination>{" "}
        </div>
      );
    };
    return (
      <div className="card">
        <div className="p-col-12">
          {RenderWrite()}
          {RenderTable()}
        </div>
      </div>
    );
  }
}
