import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import { GetToday, GetNow } from "../utility/help";

interface State {
  findDay: any;
  begin: any;
  end: any;

  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
}

export class UserGraduationList extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();
    // let begin = now.setDate(now.getDate() + 5);
    // let end = now.setDate(now.getDate() + 10);

    end.setDate(begin.getDate() - 5);
    begin.setDate(end.getDate() - 10);

    this.state = {
      findDay: "5",
      begin: begin,
      end: end,

      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1
    };
  }

  componentDidMount() {
    this.GetGhostUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort,
      this.state.begin,
      this.state.end
    );
  }

  GetGhostUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number,
    begin: Date,
    end: Date
  ) => {
    this.userService
      .get_graduation_user_list(
        page,
        find,
        search,
        sortType,
        sort,
        begin,
        end,
        "graduation"
      )
      .then(data => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount
        });
      });
  };

  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetGhostUserList(
      0,
      this.state.find,
      this.state.search,
      sortType,
      sort,
      this.state.begin,
      this.state.end
    );
  };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetGhostUserList(
      0,
      find,
      search,
      this.state.sortType,
      this.state.sort,
      this.state.begin,
      this.state.end
    );
  };

  MoveSel = (page: any) => {
    this.GetGhostUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort,
      this.state.begin,
      this.state.end
    );
  };

  render() {
    let options: any[] = [
      { label: "5일 미접속", value: "5" },
      { label: "10일 미접속", value: "10" },
      { label: "15일 미접속", value: "15" },
      { label: "20일 미접속", value: "20" },
      { label: "30일 이상", value: "30" }
    ];
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="p-md-6">
                  <HelpBox SearchData={this.SearchData}></HelpBox>
                </div>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>

              <UserTable
                userTableValue={this.state.userTableValue}
                SortTable={this.SortTable}
                mode="used"
                handleUseStauseChange={() => {}}
              ></UserTable>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
