import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Menu } from "primereact/menu";
import { SelectButton } from "primereact/selectbutton";


import { isMobile, isMobileOnly } from "react-device-detect";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import { SubPaging } from "../share/sub-paging";

import {
  OpenAgentDetail,
  GetAgentTreeByLevel,
  converAgentStatus,
  convertDate,
  numTomoeny,
  ConverAgentExBalanceType,
  ConverAgentExBalanceColor,
  GetTodayToString,
  convertSimpleDate,
  ConvertNick,
  ConvertCalendar
} from "../utility/help";

interface AgentState {
  balance: any;
  maxCount: any;
  activePage: any;

  begin: any;
  end: any;
  dropdownFind: any;
  searchText: any;
  cities: any;
  total: any

  selectViewType: any;
  viewType: any;

}

export class AgentWithdraw extends Component<any, AgentState> {
  AgentService: AgentService = new AgentService();
  menu: any;

  constructor(props: any) {
    super(props);
    let today = GetTodayToString()

    this.state = {
      balance: [],
      maxCount: 1,
      activePage: 1,
      begin: today.begin,
      end: today.end,
      dropdownFind: '',
      searchText: '',
      total: null,
      cities: [
        { label: "선택", value: '' },
        // { label: "아이디", value: "id" },
        // { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
        { label: "에이전트 트리", value: "agent_tree" },
      ],

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "충전", value: "deposit" },
        { label: "환전", value: "withdraw" },
      ],
      selectViewType: "all",

    };
    //this.handleAgentBalance = this.handleAgentBalance.bind(this);



  }

  componentDidMount() {
    this.handleAgentWithdrawBalance(1, this.state.selectViewType);
  }

  handleMoveSel = (page: any) => {
    this.setState({ activePage: page })
    this.handleAgentWithdrawBalance(page, this.state.selectViewType)
  };

  handleAgentWithdrawBalance = (page, siewType) => {
    this.AgentService.admin_get_agnet_balance_withdraw(page, this.state.begin.toLocaleString('sv-SE'), this.state.end.toLocaleString('sv-SE'), siewType, this.state.dropdownFind, this.state.searchText).then((s: any) => {
      this.setState({
        balance: s.balance,
        maxCount: s.maxCount,
        total: s.total
      });
    });
  };

  handleAgentBanlanceCansel = (idx: any) => {
    this.AgentService.admin_agnet_balance_cancel(idx).then((s: any) => {
      window.location.reload();
    });
  };

  handleAgentBanlanceWait = (idx: any) => {
    this.AgentService.admin_agnet_banace_wait(idx).then((s: any) => {
      window.location.reload();
    });
  };

  handleAgentBanlanceAccept = (idx: any) => {
    if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {

      this.AgentService.admin_agnet_banace_accept_one_balance(idx).then((s: any) => {
        window.location.reload();
      });

    }
    else {
      this.AgentService.admin_agnet_banace_accept(idx).then((s: any) => {
        window.location.reload();
      });
    }


  };

  render = () => {

    let total = this.state.total ? this.state.total[0] : { withdrawCount: 0, withdraw: 0, deposit: 0, depositCount: 0 }


    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">

              <div className="card card-w-title" style={{ padding: "0px" }}>
                <div className="p-col-12 ">
                  <div className="card-title">입급/출금 완료</div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-3">
                      <Calendar
                        showTime
                        hourFormat="24"

                        locale={ConvertCalendar()}
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.begin)}
                        onChange={(e) => this.setState({ begin: e.value })}
                        showWeek={false}
                      />
                      <Calendar
                        showTime
                        hourFormat="24"

                        locale={ConvertCalendar()}
                        placeholder="End"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={new Date(this.state.end)}
                        onChange={(e) => this.setState({ end: e.value })}
                        showWeek={false}
                      />
                    </div>
                    <div className="p-col-12 p-md-3">
                      <div className="p-grid form-group">

                        <div style={{ paddingLeft: 20, paddingTop: 7 }}>
                          <Dropdown
                            style={{ width: 100 }}

                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>

                        <div className="p-inputgroup" style={{ paddingLeft: 20 }}>
                          <InputText
                            style={{ width: 100 }}
                            placeholder="검색"
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value,
                              });
                            }}
                          />
                          <Button
                            icon="pi pi-search"
                            className="p-button-warning"
                            onClick={() => {
                              this.handleAgentWithdrawBalance(
                                0,
                                this.state.selectViewType
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="p-col-12 p-md-6">
                      <div className="p-grid form-group">
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(total.deposit)} readOnly />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>충전 {total.depositCount}</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(total.withdraw)} readOnly />
                          <label htmlFor="inputtext" style={{ color: 'blue' }}>환전 {total.withdrawCount * -1}</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(total.deposit + total.withdraw)} readOnly />
                          <label htmlFor="inputtext">수익</label>
                        </span>
                      </div>

                    </div>

                  </div>


                  <div className="p-col-12 p-md-6">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-6">
                        {/*

                        <Button
                          icon="pi pi-cog"
                          className="p-button-rounded p-button-warning "
                          onClick={(event) => {
                            this.menu.toggle(event);
                          }}

                        /> */}

                      </div>
                      <div className="p-col-12 p-md-6">

                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.state.selectViewType;
                            this.setState({ selectViewType: event.value });

                            this.handleAgentWithdrawBalance(0, event.value);
                          }}
                        />
                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

            <div className="p-col-12">
              <div className="card">
                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.balance}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                // onSort={this.onSortChange}
                // header={header}
                // sortField={this.state.sortField}
                // sortOrder={this.state.sortOrder}
                >

                  <Column
                    style={{ textAlign: "center", width: "100px", }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="충/환전금액"
                    body={(agent: any) => {
                      return <div style={{ color: ConverAgentExBalanceColor(agent.type) }}>{ConverAgentExBalanceType(agent.type)}</div>;
                    }}
                    field="phone"
                  // sortFunction={ }
                  />
                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="에이전트 아이디"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                          style={{ color: agent.color }}
                        >
                          {agent.agent}
                          <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px", orderRight: '1px solid #d8d8d8' }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="idx"
                    body={(agent: any) => {
                      return agent.idx;
                    }}
                    field="phone"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px", orderRight: '1px solid #d8d8d8' }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="충/환전금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                    field="phone"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="은행명"
                    body={(agent: any) => {
                      return agent.bankname;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="입금자명"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "100px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="계좌번호"
                    body={(agent: any) => {
                      return agent.banknum;
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "160px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="등록일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "160px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.setdate);
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "160px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="에이전트 트리"
                    body={(agent: any) => {
                      return <span dangerouslySetInnerHTML={{ __html: ConvertNick(agent.agent_tree, agent.agent_tree_ko) }} ></span>
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="상태"
                    body={(agent: any) => {
                      return converAgentStatus(agent.status);
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="완료"
                    body={(agent: any) => {
                      if (
                        agent.status == "already" ||
                        agent.status == "cansel"
                      ) {
                        return <></>;
                      }
                      return (
                        <Button
                          label="완료"
                          className="p-button-success"
                          onClick={() => {
                            this.handleAgentBanlanceAccept(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="대기"
                    body={(agent: any) => {
                      if (
                        agent.status == "already" ||
                        agent.status == "cansel"
                      ) {
                        return <></>;
                      }

                      return (
                        <Button
                          label="대기"
                          className="p-button-warning"
                          onClick={() => {
                            this.handleAgentBanlanceWait(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "80px" }}
                    bodyStyle={{ orderRight: '1px solid #d8d8d8' }}
                    header="취소"
                    body={(agent: any) => {
                      if (
                        agent.status == "already" ||
                        agent.status == "cansel"
                      ) {
                        return <></>;
                      }

                      return (
                        <Button
                          label="취소"
                          className="p-button-danger"
                          onClick={() => {
                            this.handleAgentBanlanceCansel(agent.idx);
                          }}
                        />
                      );
                    }}
                    field="rolling"

                  // handleAgentDel = (idx: any) => {};
                  // handleAgentWait = (idx: any) => {};
                  // handleAgentAccept = (idx: any) => {};
                  />
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
