import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";

import { AdminService } from "../service/admin.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  markets: any[];
  activePage: number;
  maxCount: number;
  benefits: any;
}

export class MarketsEdit extends Component<any, State> {
  adminService = new AdminService();

  constructor(props: any) {
    super(props);

    this.state = {
      markets: [],
      activePage: 1,
      maxCount: 1,
      benefits: {}
    };

    this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.handleGetMarkets(1);
  }

  handleGetMarkets = (skip: any) => {
    console.log(skip);
    this.adminService.admin_get_markets_item(skip).then(data => {
      if (data.status == "success") {
        this.setState({
          markets: data.markets,
          maxCount: data.maxCount,
          activePage: skip
        });
      }
    });
  };

  render() {
    let markets = this.state.markets;
    const handelChange = (idx: any, percent: any) => {
      console.log(idx, percent);

      let ben = this.state.benefits.find((s: any) => s.idx == idx);
      if (ben != undefined) {
        ben.percent = percent;
        this.setState({ benefits: this.state.benefits });
      }
    };

    let locale = "en";

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">마켓 설정</div>

                <div>
                  <div className="card card-w-title">
                    <div className="p-md-6">
                      <Button
                        label="저장 하기"
                        onClick={() => {
                          //this.handleUpdateBenefit();
                        }}
                      />

                      <Button
                        label="레벨 생성"
                        onClick={() => {
                          //this.handleMakeUserLevel();
                        }}
                      />
                    </div>
                  </div>
                </div>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleGetMarkets}
                ></CustomPagination>

                {/* {RenderBet()} */}

                <DataTable
                  value={markets}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}
                  style={{ width: "1600px" }}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="설명"
                    body={(rowData: any, column: any) => {
                      return rowData.active;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="이름"
                    body={(rowData: any, column: any) => {
                      return rowData.id;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="퍼센테이지"
                    body={(rowData: any, column: any) => {
                      return (
                        <InputText
                          value={rowData.groups}
                          onChange={(even: any) => {
                            handelChange(rowData.idx, even.target.value);
                          }}
                        ></InputText>
                      );
                    }}
                  />
                  {/* 
                  <Column
                    style={{ textAlign: "center" }}
                    header="Groups"
                    body={(rowData, column) => {
                      return rowData.groups;
                    }}
                  /> */}

                  <Column
                    style={{ textAlign: "center", width: "400px" }}
                    header="스포츠 종목"
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <JSONInput
                            id="a_unique_id"
                            placeholder={rowData.sports}
                            locale={locale}
                            height="100px"
                            width="350px"
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "400px" }}
                    header="ID"
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <JSONInput
                            id="a_unique_id"
                            placeholder={rowData.name}
                            locale={locale}
                            height="100px"
                            width="350px"
                          />
                        </div>
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleGetMarkets}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
