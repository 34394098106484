import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetService } from "../service/bet.service";

import { BetMiniTable } from "../share/bet/bet-mini-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface MiniProps {
  categoryId: any;
  episode: any;
}

interface MiniState {
  begin: any;
  end: any;
  userTableValue: any;
  details: any;

  findText: any;
  searchText: any;
  page: any;
  maxCount: any;
  cities: any;
}

export class DetailMini extends Component<MiniProps, MiniState> {
  betService: BetService;
  constructor(props: MiniProps) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 1,
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
    };

    this.betService = new BetService();

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);
    this.handleGetMiniGameByCategoryIdAndEpisode = this.handleGetMiniGameByCategoryIdAndEpisode.bind(
      this
    );
  }

  componentDidMount() {
    this.handleGetMiniGameByCategoryIdAndEpisode(
      0,
      this.props.categoryId,
      this.props.episode
    );
  }

  handleGetMiniGameByCategoryIdAndEpisode = (
    page: any,
    categoryId: any,
    episode: any
  ) => {
    this.betService
      .get_mini_game_by_categoryid_and_episode(page, categoryId, episode)
      .then((data) => {
        this.setState({
          userTableValue: data.minis,
          maxCount: data.maxCount,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    // this.betService.get_progress_live_game(page, null, null, null, null);
  };

  render() {
    let activePage = this.state.page == undefined ? 1 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    // const RenderBet = () => {
    //   return userTableValue.map((contact, i) => {
    //     return <BetMiniTable bet={contact} key={contact._id}></BetMiniTable>;
    //   });
    // };

    console.log(userTableValue);

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">스포츠 배팅정보</div>
                <div>
                  <div
                    className="card card-w-title"
                    style={{ padding: "0px" }}
                  ></div>
                </div>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <BetMiniTable
                  bets={userTableValue}
                ></BetMiniTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
