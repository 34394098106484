import React, { Component } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Message } from "primereact/message";

import { BalanceService } from "../service/balance.service";
import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  GetToday,
  ConvertCalendar
} from "../utility/help";

interface WithdrawsState {
  balances: any;
  withdraws: any;
  activePage: any;
  field: any;
  begin: any;
  end: any;
  total: any;
  maxCount: number;
  searchText: any;
  findText: any;
  dropdownFind: any;
  cities: any;
}

export class deporsitandwithdrawAdmin extends Component<any, WithdrawsState> {
  balanceService: BalanceService = new BalanceService()
  constructor(props: any) {
    super(props);

    let today = GetToday();

    this.state = {
      maxCount: 1,
      activePage: 1,
      balances: 0,
      withdraws: [],
      field: "",
      begin: today.begin,
      end: today.end,
      total: [],

      searchText: "",
      findText: "",
      dropdownFind: "",
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "bankowner" },
        { label: "에이전트", value: "agent" },
      ],
    };


    // this.OnEditItem = this.OnEditItem.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.get_deposit_and_withdraw(0);
  }

  handlePageChange = (page) => {
    this.setState({ activePage: page });
    this.get_deposit_and_withdraw(page);
  };

  OnEditItem = (idx: any, name: any, value: any) => {
    if (name == "bonus" || name == "rolling") {
      return;
    }

    let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
    if (withdraw != undefined) {
      withdraw[name] = value;
      this.setState({ withdraws: this.state.withdraws });
    }
  };

  OnSelectItem = (idx: any) => {
    let element = this.state.withdraws.find((s: any) => s.idx == idx);

    element.sel = element.sel == "y" ? "n" : "y";

    this.setState({ withdraws: this.state.withdraws });
  };

  get_deposit_and_withdraw = (page: any) => {
    console.log("get_deposit_and_withdraw");
    let begin = this.state.begin;
    let end = this.state.end;
    let searchText = this.state.searchText;
    let findText = this.state.dropdownFind;

    this.balanceService
      .get_deposit_and_withdrawAdmin(page,
        begin.toLocaleString('sv-SE'),
        end.toLocaleString('sv-SE'),
        searchText, findText)
      .then((data: any) => {
        this.setState({
          maxCount: data.maxCount,
          balances: data.balances,
          total: data.total,
          searchText: searchText,
          findText: findText,
        });
      });
  };

  send_message_server = (mode: any) => {
    let ex: any[] = [];

    for (let deposit of this.state.withdraws) {
      if (deposit.sel == "y") {
        ex.push(deposit);
      }
    }

    if (mode == "success" && ex.length > 0) {
      this.balanceService.set_withdraw_success(ex).then((s) => {
        if (s.status == "success") {
          alert("환전이 완료되어 습니다.");
          window.location.reload();
        } else if (s.status == "deposits") {
          alert("선택된 아이템이 존재하지 않습니다");
          window.location.reload();
        } else if (s.status == "already") {
          alert("환전완료 또는환전취소 정보가 있습니다");
          window.location.reload();
        } else {
          alert("관리자에게 문의 부탁드림니다");
          window.location.reload();
        }
      });
    } else if (mode == "wait" && ex.length > 0) {
      this.balanceService.set_withdraw_wait(ex).then((s) => {
        if (s.status == "success") {
          alert("환전 대기상태로 변경되었습니다 .");
          window.location.reload();
        } else if (s.status == "deposits") {
          alert("선택된 아이템이 존재하지 않습니다");
          window.location.reload();
        } else if (s.status == "already") {
          alert("환전완료 또는환전취소 정보가 있습니다");
          window.location.reload();
        } else {
          alert("관리자에게 문의 부탁드림니다");
          window.location.reload();
        }
      });
    } else if (mode == "cancel" && ex.length > 0) {
      this.balanceService.set_withdraw_cancel(ex).then((s) => {
        if (s.status == "success") {
          alert("환전을 취소 하였습니다.");
          window.location.reload();
        } else if (s.status == "deposits") {
          alert("선택된 아이템이 존재하지 않습니다");
          window.location.reload();
        } else if (s.status == "already") {
          alert("환전완료 또는환전취소 정보가 있습니다");
          window.location.reload();
        } else {
          alert("관리자에게 문의 부탁드림니다");
          window.location.reload();
        }
      });
    }
  };

  render() {
    let header = (up: any, down: any) => {
      return (
        <div>
          <span>{up}</span>
          <br />
          <span>{down}</span>
          <br />
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title" style={{ padding: "0px" }}>
                  <div className="p-col-12 ">
                    <div className="p-md-11">
                      <div className="card-title">입급/출금 완료</div>

                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-3">
                          <Calendar
                            showTime
                            hourFormat="24"

                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.begin}
                            onChange={(e) => this.setState({ begin: e.value })}
                            locale={ConvertCalendar()}
                            showWeek={false}
                          />
                        </div>
                        <div className="p-col-12 p-md-3">
                          <Calendar
                            showTime
                            hourFormat="24"

                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.end}
                            onChange={(e) => this.setState({ end: e.value })}
                            locale={ConvertCalendar()}
                            showWeek={false}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <div className="p-inputgroup">
                            <InputText
                              placeholder="검색"
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                            <Button
                              icon="pi pi-search"
                              className="p-button-warning"
                              onClick={() => {
                                this.get_deposit_and_withdraw(0);
                              }}
                            />
                          </div>

                        </div>

                        <div className="p-col-12 p-md-4 ">
                          <DataTable
                            style={{ padding: "0px" }}
                            value={this.state.total}
                            paginatorPosition="both"
                            rows={20}
                          >
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="error" text="충전" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.deposit)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="info" text="환전" />}
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.withdraw)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={
                                <Message severity="info" text="충전 -환전" />
                              }
                              body={(rowData: any, column: any) => {
                                return (
                                  <div>
                                    <span>
                                      {numTomoeny(
                                        rowData.deposit - rowData.withdraw
                                      )}
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          </DataTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="p-col-12 ">
                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                  <BalanceTable
                    mode="deporsitAndwithdraw"
                    balances={this.state.balances}
                    OnEditItem={this.OnEditItem}
                    OnSelectItem={this.OnSelectItem}
                  ></BalanceTable>

                  <CustomPagination
                    active={this.state.activePage}
                    take={20}
                    count={this.state.maxCount}
                    handlePageChange={this.handlePageChange}
                  ></CustomPagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
