import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { BetService } from "../service/bet.service";

import { ProgressLiveTable } from "../share/progress/progress-live-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday, ConvertCalendar } from "../utility/help";

interface State {
  begin: Date;
  end: Date;
  userTableValue: any[];
  details: any;

  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  cities: any[];
  viewType: any[];
  summary: any;
  sortType: any[];


  balanceType: any[],
  selectBalanceType: string,

  selectViewType: string;
  selectSortType: string;
}

export class ProgressLive extends Component<any, State> {
  betService = new BetService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 1,

      cities: [
        { label: "선택", value: null },
        { label: "종목", value: "sportName" },
        { label: "팀명", value: "team" },
        { label: "리그명", value: "tournamentName" },
      ],

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],
      summary: {},

      sortType: [
        { label: "시간", value: "dateOfMatchTimeStamp" },
        { label: "배팅금액", value: "preBetBalance" },
      ],
      selectViewType: "all",
      selectSortType: "dateOfMatchTimeStamp",


      balanceType: [
        { label: "미배팅", value: "dateOfMatchTimeStamp" },
        { label: "배팅중", value: "preBetBalance" },
      ],
      selectBalanceType: "dateOfMatchTimeStamp",
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);

    this.get_progress_live_game(
      0,
      this.state.selectViewType,
      this.state.selectSortType
    );
  }

  componentDidMount() { }

  get_progress_live_game = (
    page: any,
    selectViewType: any,
    selectSortType: any
  ) => {
    this.betService
      .get_progress_live_game(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText,
        selectViewType,
        selectSortType
      )
      .then((data) => {

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  get_match_details = (matchId: any) => {
    this.betService.get_match_details(matchId).then((data) => {
      if (data.live != undefined) {
        let matchID = data.live._id;

        this.state.details[matchID] = data.live;

        this.setState({
          details: this.state.details,
        });
      }
    });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_progress_live_game(
      page,
      this.state.selectViewType,
      this.state.selectSortType
    );
  };

  handleOpenDetail = (matchId: any) => {
    console.log(matchId);
    this.get_match_details(matchId);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      return userTableValue.map((contact, i) => {
        return (
          <ProgressLiveTable
            mode="live"
            game={contact}
            key={contact._id}
            selectBalanceType={contact.selectBalanceType}

            //handleOpenDetail={this.handleOpenDetail}
            //details={this.state.details[contact._id]}
            handleSelectWinGame={() => { }}
            handleUpdateOdds={() => { }}
          ></ProgressLiveTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12 ">
              <div className="card card-w-title" style={{ padding: "0px" }}>
                <div className="p-md-11">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.begin}
                        showWeek={false}
                        onChange={(e: any) => this.setState({ begin: e.value })}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Calendar
                        showWeek={false}
                        placeholder="End"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.end}
                        onChange={(e: any) => this.setState({ end: e.value })}
                        locale={ConvertCalendar()}

                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <Dropdown
                        style={{ width: 100 }}
                        options={this.state.cities}
                        value={this.state.findText}
                        onChange={(event) =>
                          this.setState({ findText: event.value })
                        }
                        autoWidth={true}

                      />
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <InputText
                          onChange={(event: any) => {
                            this.setState({
                              searchText: event.target.value,
                            });
                          }}
                        />
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handlePageChange(0);
                          }}
                        />
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <DataTable
                        style={{ padding: "0px" }}
                        value={this.state.summary}
                        paginatorPosition="both"
                        rows={20}
                      >
                        <Column
                          style={{ textAlign: "center", padding: "0px" }}
                          header={<Message severity="error" text="BET" />}
                          body={(rowData: any, column: any) => {
                            return (
                              <div>
                                <span>{numTomoeny(rowData.totalBet)}</span>
                              </div>
                            );
                          }}
                        />
                        <Column
                          style={{ textAlign: "center", padding: "0px" }}
                          header={<Message severity="info" text="WIN" />}
                          body={(rowData: any, column: any) => {
                            return (
                              <div>
                                <span>{numTomoeny(rowData.totalWin)}</span>
                              </div>
                            );
                          }}
                        />
                      </DataTable>
                    </div>
                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <SelectButton
                        value={this.state.selectViewType}
                        options={this.state.viewType}
                        onChange={(event) => {
                          this.setState({ selectViewType: event.value });
                          this.get_progress_live_game(
                            0,
                            event.value,
                            this.state.selectSortType
                          );
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <SelectButton
                        value={this.state.selectSortType}
                        options={this.state.sortType}
                        onChange={(event) => {
                          console.log(event.value);
                          if (event.value == null) {
                            return;
                          }
                          this.setState({ selectSortType: event.value });
                          this.get_progress_live_game(
                            0,
                            this.state.selectViewType,
                            event.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
