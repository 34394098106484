import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from "primereact/radiobutton";
import { confirmAlert } from "react-confirm-alert";
import { SelectButton } from "primereact/selectbutton";


import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";
import { BalanceService } from "../service/balance.service";
import { AdminService } from "../service/admin.service";


import * as AgentData from "../interface/agent";

import { OpenAgentDetail, numTomoeny, ConvertNick } from "../utility/help";

interface AgentState {
  org: any;
  balances: any;
  activePage: any;
  maxCount: any;
  management: any;

  mode: any
  balance: number
  agentId: string
  nickfilter: string
  treefilter: string
  level: any
  sumBalance: number
}

export class AgentBalanceList extends Component<any, AgentState> {
  agentService: AgentService = new AgentService()
  balanceService: BalanceService = new BalanceService();
  adminService: any = new AdminService();

  giveBalance = React.createRef() as any;
  agentTable = React.createRef() as any;

  constructor(props: any) {
    super(props);

    this.state = {
      org: [],
      balances: [],
      activePage: 1,
      maxCount: 1,
      mode: 'charge',
      balance: 0,
      agentId: '',
      nickfilter: '',
      treefilter: '',
      level: 0,
      sumBalance: 0,
      management: null
    };

    this.adminService.admin_get_management().then((data) => {
      if (data.status == "success") {

        this.setState({ management: data.management });

        console.log(data.management)
      }
    })

    this.handleAgentBalance(1);
  }

  componentDidMount() {
  }

  handleAgentBalance = (skip) => {


    this.agentService.admin_get_agent_balance_list(skip).then((ss) => {

      if (ss != null && ss.agents != null) {

        for (const agent of ss.agents) {
          if (ss.benefits !== null) {
            for (const benefit of ss.benefits.filter(s => s.agent == agent.agent)) {
              agent[`${benefit.benefit_type}`] = benefit.percent
            }
          }
        }

        this.state.balances.push(...ss.agents)
        this.setState({ balances: this.state.balances, org: this.state.balances });

        if (ss.agents.length === 20) {
          this.handleAgentBalance(skip + 1)
        }
        else {
          const sumBalance = this.state.balances.reduce(function add(sum, currValue) {
            return sum + currValue.balance;
          }, 0);

          this.setState({ sumBalance: sumBalance });


        }
      }
    });
  };

  handleMoveSel = (page: any) => { };



  handleDoDeposit() {
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 에이전트에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {

            this.balanceService
              .admin_agent_give_balance(this.state.agentId, this.state.balance, 'admin_do_deposit', '관리자 지급')
              .then((s: any) => {
                console.log(s);
                if (s.status === "success") {
                  confirmAlert({
                    title: "지급",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          // this.handleBalanceUpdate(userID);
                          window.location.reload()
                          //   this.handlePageChange(
                          //     1,
                          //     this.state.findText,
                          //     this.state.searchText
                          //   );
                        },
                      },
                    ],
                  });
                } else if (s.status === "balance") {
                  confirmAlert({
                    title: "지급",
                    message: "보유중인 벨런스를 확인해주세요.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => { },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "지급",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => { },
                      },
                    ],
                  });
                }
              });

          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }

  handleDoWithdraw() {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 에이전트에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
              this.balanceService
                .admin_agent_give_balance_one_balance(this.state.agentId, Number(this.state.balance) * -1, 'admin_do_withdraw', '관리자 지급')
                .then((s: any) => {
                  if (s.status === "success") {
                    confirmAlert({
                      title: "회수",
                      message: "정상적으로 처리되었습니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => {
                            window.location.reload()

                          },
                        },
                      ],
                    });
                  } else if (s.status === "balance") {
                    confirmAlert({
                      title: "회수",
                      message: "에이전트 벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else if (s.status === "main_balance") {
                    confirmAlert({
                      title: "회수",
                      message: "매인벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "회수",
                      message:
                        "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  }
                });
            } else {
              this.balanceService
                .admin_agent_give_balance(this.state.agentId, this.state.balance * -1, 'admin_do_withdraw', '관리자 지급')
                .then((s: any) => {
                  if (s.status === "success") {
                    confirmAlert({
                      title: "회수",
                      message: "정상적으로 처리되었습니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => {
                            window.location.reload()

                          },
                        },
                      ],
                    });
                  } else if (s.status === "balance") {
                    confirmAlert({
                      title: "회수",
                      message: "에이전트 벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "회수",
                      message:
                        "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  }
                });
            }

          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }


  handleAgentStop(agentId: string) {
    confirmAlert({
      title: "에이전트",
      message: "해당 에이전트를 중시시키면 하단 에이전트가 모두 중지됩니다 중지 시키겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.agentService
              .admin_agent_stop(agentId)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "에이전트",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          window.location.reload()

                          //   this.handlePageChange(
                          //     1,
                          //     this.state.findText,
                          //     this.state.searchText
                          //   );
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "에이전트",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => { },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }



  handleFindAgent(level: number) {
    let agents = this.state.org.filter(s => s.level == level)
    if (level == 0) {
      agents = this.state.org
    }

    if (agents != null) {
      this.setState({ balances: agents })
    }
  }


  render = () => {

    // const nickFilter = <InputText value={value} onChange={(e) => setValue(e.target.value)} />
    const nickFilter = <InputText value={this.state.nickfilter} onChange={(e: any) => {

      this.agentTable.current.filter(e.target.value, 'nick', 'custom');
      this.setState({ nickfilter: e.target.value })
    }} />

    const treeFilter = <InputText value={this.state.treefilter} onChange={(e: any) => {

      this.agentTable.current.filter(e.target.value, 'agent_tree_ko', 'custom');
      this.setState({ treefilter: e.target.value })
    }} />

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 벨런스</div>


                <OverlayPanel ref={this.giveBalance} id="overlay_panel" style={{ width: '500px' }} showCloseIcon={false}>

                  <div className="md-inputfield">
                    <span className="p-float-label" style={{ padding: '20px' }}>
                      <div className="p-grid form-group" >
                        <div className="p-field-radiobutton" style={{ paddingRight: '50px' }}>
                          <RadioButton inputId="city1" name="city1" value="charge" onChange={(e) => this.setState({ mode: 'charge' })} checked={this.state.mode === 'charge'} />
                          <label htmlFor="city1" style={{ paddingTop: '15px' }}>지급</label>
                        </div>
                        <div className="p-field-radiobutton" style={{ paddingRight: '50px' }}>
                          <RadioButton inputId="city2" name="city2" value="exchange" onChange={(e) => this.setState({ mode: 'exchange' })} checked={this.state.mode === 'exchange'} />
                          <label htmlFor="city2" style={{ paddingLeft: '80px', paddingTop: '15px' }}>회수</label>
                        </div>

                        <InputText id="username" onChange={(e: any) => this.setState({ balance: e.target.value })} value={this.state.balance} />
                        <Button className="p-button-sm p-button-success" label="지급/회수" onClick={() => {
                          console.log(this.state.mode)

                          if (this.state.mode === 'charge') {
                            this.handleDoDeposit()
                          }
                          else if (this.state.mode === 'exchange') {
                            this.handleDoWithdraw()
                          }

                        }} />
                      </div>
                    </span>

                    <div className="p-grid form-group" style={{ padding: '20px' }} >
                      <Button className="p-button-sm p-button-outlined" label="10,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(10000) }) }} />
                      <Button className="p-button-sm p-button-outlined" label="50,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(50000) }) }} />
                      <Button className="p-button-sm p-button-outlined" label="100,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(100000) }) }} />
                      <Button className="p-button-sm p-button-outlined" label="500,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(500000) }) }} />
                      <Button className="p-button-sm p-button-outlined" label="1,000,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(1000000) }) }} />
                      <Button className="p-button-sm p-button-danger" label="정정" onClick={() => { this.setState({ balance: 0 }) }} />
                    </div>
                  </div>

                </OverlayPanel>

                <SelectButton
                  options={
                    [
                      { label: "모두", value: "0" },
                      { label: "최상위", value: "1" },
                      { label: "부본사", value: "2" },
                      { label: "총판", value: "3" },
                      { label: "에이전트", value: "4" },
                    ]
                  }
                  value={this.state.level}
                  onChange={(event) => {
                    this.handleFindAgent(Number(event.target.value))
                  }}
                />

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.balances}
                  paginatorPosition="both"
                  rows={99999}
                  sortOrder={-1}
                  ref={this.agentTable}
                  scrollable
                  scrollHeight="800px"
                >
                  <Column
                    filter
                    filterPlaceholder="에이전트 아이디"
                    style={{ textAlign: "center", width: "120px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header="ID"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                          style={{ color: agent.color }}
                        >
                          {agent.agent}
                          <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    filter
                    filterElement={nickFilter}
                    style={{ textAlign: "center", width: "120px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header="닉네임"
                    body={(agent: any) => {
                      return agent.nick;
                    }}
                    field="nick"
                    sortable
                    filterFunction={(value, filter) => {



                      return value.indexOf(filter) >= 0
                    }}
                  />


                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                    header="지급/회수"
                    body={(agent: any) => {

                      return (
                        <Button type="button" label={'지급/회수'} onClick={(e: any) => {

                          this.setState({ agentId: agent.agent })
                          this.giveBalance.current.toggle(e)

                        }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                      );
                    }}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header={`보유알 ${numTomoeny(this.state.sumBalance)}`}
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                    sortable={true}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header="루징"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance_losing);
                    }}
                    sortable={true}
                    field="losing"
                  />


                  {
                    this.state.management != null && this.state.management.casinoLosing === 'y' && (

                      < Column
                        style={{ textAlign: "center", width: "60px" }}
                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                        header="카지노 루징"
                        body={(agent: any) => {
                          return `${numTomoeny(agent.agent_casino_losing)}%`
                        }}
                        sortable={true}
                        field="losing"
                      />
                    )
                  }

                  {
                    this.state.management != null && this.state.management.slotLosing === 'y' && (

                      < Column
                        style={{ textAlign: "center", width: "60px" }}
                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                        header="슬롯 루징"
                        body={(agent: any) => {
                          return `${numTomoeny(agent.agent_slot_losing)}%`
                        }}
                        sortable={true}
                        field="losing"
                      />
                    )
                  }


                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header="롤링"
                    body={(agent: any) => {
                      return numTomoeny(agent.rolling);
                    }}
                    sortable={true}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                    header="슬롯 롤링 %"
                    body={(agent: any) => {
                      return <div style={{ color: 'green' }}>{agent.agent_slot_rolling}%</div>;
                    }}
                    sortable={true}
                    field="agent_slot_rolling"
                  />


                  {
                    process.env.REACT_APP_API_CALCULATE_CASINO === 'Y' && (
                      <Column
                        style={{ textAlign: "center", width: "60px" }}
                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                        header="카지노 롤링 %"
                        body={(agent: any) => {
                          return <div style={{ color: 'green' }}>{agent.agent_casino_rolling}%</div>;
                        }}
                        sortable={true}
                        field="agent_casino_rolling"
                      />

                    )
                  }


                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                    header="중지"
                    body={(agent: any) => {

                      return (
                        <Button type="button" label={'중지'} onClick={(e: any) => {
                          this.handleAgentStop(agent.agent)
                        }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                        // <OverlayBalance></OverlayBalance>
                      );
                    }}
                    field="balance"
                  />




                  <Column
                    filter
                    filterPlaceholder="에이전트 아이디"
                    bodyStyle={{ borderRight: '1px solid #d8d8d8' }}
                    style={{ textAlign: "center", width: "220px" }}
                    header="에이전트/트리"

                    body={(agent: any) => {

                      return <span dangerouslySetInnerHTML={{ __html: ConvertNick(agent.agent_tree, agent.agent_tree_ko) }} ></span>
                    }}
                    sortable={true}
                    field="agent_tree_ko"
                    filterElement={treeFilter}

                    filterFunction={(value, filter) => {



                      return value.indexOf(filter) >= 0
                    }}
                  />

                  {/* <Column body={this.actionTemplate} style={{textAlign:'center', width: '6em'}}/> */}
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
