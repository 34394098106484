import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import cookie from "react-cookies";
import { Offline, Online } from "react-detect-offline";

import { InputText } from "primereact/components/inputtext/InputText";
import { AppMenu } from "./AppMenu";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { AdminService } from "./service/admin.service";
import { Console } from "console";
import useSound from 'use-sound';

import ReactAudioPlayer from 'react-audio-player';


import { uuidv4 } from "./utility/help";
import { SetOdds } from "./main/setodds";


const soundReg = require('./assist/sound/reg.mp3');
const soundCh = require('./assist/sound/ch.mp3');
const soundEx = require('./assist/sound/ex.mp3');
const sounQa = require('./assist/sound/qa.mp3');
const soundAgent = require('./assist/sound/agent.wav');
const soundAgentBalance = require('./assist/sound/agentbalance.mp3');


import SoundPlays from "./SoundPlay";


// import ping from '../public/assets/sound/reg.mp3';


interface BarProps {
  menuActive: any;
  topbarUserMenuActive: any;

  model: any;
  topInfo: any;
  horizontal: any;
  onRootMenuItemClick: (event: any) => any;
  onMenuItemClick: (event: any) => any;
  onSidebarClick: (event: any) => any;
  onRootItemClick: (par1: any) => any;
  onMenuButtonClick: (par1: any, par2: any) => any;
  onTopbarUserMenuButtonClick: (par1: any) => any;
  onTopbarUserMenuClick: (par1: any) => any;

  isHorizontalMenuActive: () => any;
}

interface State {
  soundState: any
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}


// function vid_play_pause(name) {
//     // setTimeout(async () => {
//     console.log(name)
//     var myVideo = document.getElementById(name) as HTMLAudioElement;
//     console.log(myVideo)

//     myVideo.play()
//     // })
//   }


export class AppTopbar extends Component<BarProps, State> {




  audio: any = new Audio();

  constructor(props: BarProps) {

    super(props);
    this.state = {
      soundState: {
        depositCount: 0,
        withdrawCount: 0,
        userCount: 0,
        helpAgentReg: 0,
        agentReg: 0,
        agentBalanceCount: 0,
        agentBalanceCountReg: 0,
        userReg: 0,
        agentCount: 0,
        helpCount: 0,
        helpAgentCount: 0,
      }
    };


    setImmediate(async () => {

      const adminService = new AdminService();

      const matchSound = {
        userReg: 'userId',
        depositReg: 'depositId',
        withdrawReg: 'withdrawId',
        helpReg: 'helpId',
        agentReg: 'agentId',
        agentBalanceCountReg: 'agentBalanceId',
        helpAgentReg: 'helpAgentReg',

      }


      const matchURL = {
        userReg: '#/userreglist',
        depositReg: '#/deposit',
        withdrawReg: '#/withdraw',
        helpReg: '#/help',
        agentReg: '#/agentreg',
        agentBalanceCountReg: '#/agentwithdraw',
      }


      const soundFiles = [
        { name: "userReg", file: soundReg },
        { name: "depositReg", file: soundCh },
        { name: "withdrawReg", file: soundEx },
        { name: "helpReg", file: sounQa },
        { name: "agentReg", file: soundAgent },
        { name: "agentReg", file: soundAgent },
        {
          name: "agentBalanceCountReg",
          file: soundAgentBalance,
        },
        {
          name: "helpAgentReg",
          file: soundAgentBalance,
        },


      ]
      // cookie.save(`${window.location.hash}`, windowID, { path: '/' })
      let time = (new Date()).getTime() / 1000;

      for (; ;) {
        try {

          let now = (new Date()).getTime() / 1000;

          // let time = cookie.load('time')

          if (now - Number(time) < 10) {
            await sleep(1000);
            continue
          }

          time = now

          // cookie.save('time', ((new Date()).getTime() / 1000), { path: '/' })



          const topInfo = await adminService.admin_top_info();

          if (JSON.stringify(this.state.soundState) != JSON.stringify(topInfo.top)) {
            this.setState({ soundState: topInfo.top })
          }


          let soundplay = cookie.load('soundplay')
          if (soundplay === 'off') {
            continue
          }


          for (let key of Object.keys(topInfo.top)) {
            if (topInfo.top[key] != null && topInfo.top[key] > 0) {


              let sound = soundFiles.find((s) => s.name == key);


              if (sound != null) {


                let soundIndex = cookie.load(key)
                if (soundIndex != null && soundIndex === topInfo.top[matchSound[key]].toString()) {
                  continue
                }

                SoundPlays('userReg')
              }
            }
          }



          // if (props.sound === "On") {
          //   updateTopInfo();
          // }
        }
        catch (ex) {

        }


      }
    }, 10000)

  }


  playAudio() {
    const playPromise = this.audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          console.log("ok");
        })
        .catch(function (error) {
          console.error("Failed to start your sound, retrying.");
          console.log(error);
        });
    }
  }

  componentDidMount() {



  }

  render() {
    let isMenuButtonActive =
      !this.props.isHorizontalMenuActive() && this.props.menuActive;
    let topbarMenuClassName = classNames("layout-profile-menu fadeInDown ", {
      "layout-profile-menu-active": this.props.topbarUserMenuActive,
    });
    let menuButtonClassName = classNames("layout-menubutton ", {
      "layout-menubutton-active": isMenuButtonActive,
    });

    let options = ["on", "off"];

    const soundState = this.state.soundState

    let soundplay = cookie.load('soundplay')

    return (
      <div className="layout-topbar"

      >


        <div className="p-selectbutton p-buttonset p-component p-buttonset-3">
          {
            (soundState.depositCount > 0) ?
              <Button type="button" label={`충전 ${soundState.depositCount}`} className="p-button-success" badge="8"
                onClick={() => {
                  window.location.hash.indexOf('deposit') >= 0 ? window.location.reload() : window.location.hash = 'deposit';
                }}
              /> :
              <Button type="button" label={`충전 ${soundState.depositCount}`} className="p-button-warning" badge="8"
                onClick={() => {
                  window.location.hash.indexOf('deposit') >= 0 ? window.location.reload() : window.location.hash = 'deposit';
                }}
              />
          }

          {
            (soundState.withdrawCount > 0) ?
              <Button type="button" label={`환전 ${soundState.withdrawCount}`} className="p-button-success" badge="8"
                onClick={() => {
                  window.location.hash.indexOf('withdraw') == 2 ? window.location.reload() : window.location.hash = 'withdraw';

                }}
              /> :
              <Button type="button" label={`환전 ${soundState.withdrawCount}`} className="p-button-warning" badge="8"
                onClick={() => {
                  window.location.hash.indexOf('withdraw') == 2 ? window.location.reload() : window.location.hash = 'withdraw';
                }}
              />
          }


          {
            (soundState.userCount > 0 || soundState.userReg > 0) ?
              <Button type="button" label={`가입 ${soundState.userCount}`} className="p-button-success" badge="8" onClick={() => {
                window.location.hash.indexOf('userreglist') >= 0 ? window.location.reload() : window.location.hash = 'userreglist';

              }} /> :
              <Button type="button" label={`가입 ${soundState.userCount}`} className="p-button-warning" badge="8" onClick={() => {
                window.location.hash.indexOf('userreglist') >= 0 ? window.location.reload() : window.location.hash = 'userreglist';
              }} />
          }

          {
            (soundState.helpCount > 0 || soundState.helpReg > 0) ?
              <Button type="button" label={`고객센터 ${soundState.helpReg}`} className="p-button-success" badge="8" onClick={() => {
                window.location.hash.indexOf('/help') >= 0 ? window.location.reload() : window.location.hash = 'help';
              }} /> :
              <Button type="button" label={`고객센터 ${soundState.helpCount}`} className="p-button-warning" badge="8" onClick={() => {
                window.location.hash.indexOf('/help') >= 0 ? window.location.reload() : window.location.hash = 'help';

              }} />
          }

          {
            (soundState.agentBalanceCount > 0 || soundState.agentBalanceCountReg > 0) ?
              <Button type="button" label={`에이전트 충/환전 ${soundState.agentBalanceCount}`} className="p-button-success" badge="8" onClick={() => {
                window.location.hash.indexOf('agentwithdraw') >= 0 ? window.location.reload() : window.location.hash = 'agentwithdraw';
              }} /> :
              <Button type="button" label={`에이전트 충/환전 ${soundState.agentBalanceCount}`} className="p-button-warning" badge="8" onClick={() => {
                window.location.hash.indexOf('agentwithdraw') >= 0 ? window.location.reload() : window.location.hash = 'agentwithdraw';

              }} />
          }

          {
            (soundState.agentReg > 0 || soundState.agentCount > 0) ?
              <Button type="button" label={`에이전트 가입 ${soundState.agentCount}`} className="p-button-success" badge="8" onClick={() => {
                window.location.hash.indexOf('agentreg') >= 0 ? window.location.reload() : window.location.hash = 'agentreg';
              }} /> :
              <Button type="button" label={`에이전트 가입 ${soundState.agentCount}`} className="p-button-warning" badge="8" onClick={() => {
                window.location.hash.indexOf('agentreg') >= 0 ? window.location.reload() : window.location.hash = 'agentreg';

              }} />
          }

          {
            (soundState.helpAgentReg > 0 || soundState.helpAgentCount > 0) ?
              <Button type="button" label={`에이전트 문의 ${soundState.helpAgentCount}`} className="p-button-success" badge="8" onClick={() => {
                window.location.hash.indexOf('agenthelp') >= 0 ? window.location.reload() : window.location.hash = 'agenthelp';
              }} /> :
              <Button type="button" label={`에이전트 문의 ${soundState.helpAgentCount}`} className="p-button-warning" badge="8" onClick={() => {
                window.location.hash.indexOf('agenthelp') >= 0 ? window.location.reload() : window.location.hash = 'agenthelp';
              }} />
          }

        </div>

        <button
          className={menuButtonClassName}
          onClick={(e) => this.props.onMenuButtonClick(e, isMenuButtonActive)}
        >
          <div className="layout-menubutton-icon" />
        </button>

        <div className="layout-topbar-grid">


          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <button
              className="layout-logo p-link"
              onClick={() => {
                window.location.reload();
              }}
            >
              <h2 style={{ color: 'black' }}>
                {
                  process.env.REACT_APP_API_NAME
                }
              </h2>
              <img
                src="assets/layout/images/logo-white.svg"
                alt="sapphire-layout"
              />
              {/* <img
                src="assets/layout/images/logo-white.svg"
                alt="sapphire-layout"
              /> */}
            </button>
          </div>



          <div className="layout-topbar-grid-column">
            <AppMenu
              items={[]}
              root={false}
              mega={false}
              className={""}
              model={this.props.model}
              topInfo={this.props.topInfo}
              horizontal={this.props.horizontal}
              menuActive={this.props.menuActive}
              isHorizontalMenuActive={this.props.isHorizontalMenuActive}
              onMenuItemClick={this.props.onMenuItemClick}
              onRootMenuItemClick={this.props.onRootMenuItemClick}
              onSidebarClick={this.props.onSidebarClick}
              parentMenuItemActive={false}
              onRootItemClick={() => { }}
            />
          </div>

          <div className="layout-topbar-grid-column layout-topbar-grid-column-fixed">
            <SelectButton
              value={soundplay}
              options={options}
              onChange={(e) => {
                cookie.save('soundplay', e.value, { path: '/' })
              }}
            />

            <button
              className="p-link profile-menu-button"
              onClick={this.props.onTopbarUserMenuButtonClick}
            >
              <img src="assets/layout/images/avatar.png" alt="Profile" />
            </button>
            <ul
              className={topbarMenuClassName}
              onClick={this.props.onTopbarUserMenuClick}
            >
              <li className="layout-profile-menu-search">
                <span className="md-inputfield">
                  <InputText type="text" />
                  <label>Search</label>
                </span>
              </li>

              <li role="menuitem">
                <button className="p-link ripplelink">
                  <i className="material-icons">account_circle</i>
                  <span>Profile</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link ripplelink">
                  <i className="material-icons">inbox</i>
                  <span>Inbox</span>
                </button>
              </li>
              <li role="menuitem">
                <button className="p-link ripplelink">
                  <i className="material-icons">settings</i>
                  <span>Settings</span>
                </button>
              </li>
              <li role="menuitem">
                <button
                  className="p-link ripplelink"
                  onClick={() => {
                    cookie.remove("session", { path: "/" });

                    window.location.reload();
                  }}
                >
                  <i className="material-icons">cancel</i>
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* <div id="userReg" style={{ display: 'none' }}></div>
        <div id="depositReg" style={{ display: 'none' }}></div>
        <div id="withdrawReg" style={{ display: 'none' }}></div>
        <div id="helpReg" style={{ display: 'none' }}></div>
        <div id="agentReg" style={{ display: 'none' }}></div>
        <div id="agentBalanceCountReg" style={{ display: 'none' }}></div> */}


        {/* {playSound(soundState)} */}
        {/* <PlaySound soundState={soundState} ></PlaySound> */}
      </div>
    );
  }
}
