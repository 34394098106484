import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";

// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { CommunityService } from "../service/community.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
    evens: any[];
  notice: any;
  activePage: number;
  maxCount: number;
}

export class NoteEvent extends Component<any, State> {
  communityService = new CommunityService();

  constructor(props: any) {
    super(props);

    this.state = {
        evens: [],
        activePage: 1,
        maxCount: 1,
        notice: {

        mode: "user",

        del: "n",
        contents: "",
        title: "",
        titleColor: "",
        userId: "",
      },
    };

    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleEvenNote(1);
  }

  handleEvenNote = (skip: any) => {
    this.setState({ activePage: skip });
    this.communityService.admin_get_notice_even(skip).then((data: any) => {
      if (data.status == "success") {
        this.setState({ evens: data.evens });
      }
    });
  };

  handleWirte = () => {
    this.communityService
      .admin_even_wirte(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "이벤트 공지",
            message: "이벤트 공지 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "이벤트 공지",
            message: "이벤트 공지 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  
  handleDel = () => {
    this.communityService
      .admin_even_del(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "이벤트 공지",
            message: "이벤트 공지 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "이벤트 공지",
            message: "이벤트 공지 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    let items = this.state.evens;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ notice: rowData });
            }}
          ></Button>
        </div>
      );
    };

    const RenderUser = () => {
      if (this.state.notice.mode == "user") {
        return (
          <div className="p-col-12">
            <span className="p-float-label">
              <InputText
                id="in"
                style={{
                  width: "70%",
                }}
                value={this.state.notice.user}
                onChange={(e: any) => {
                  this.state.notice.userId = e.target.value;
                  this.setState({ notice: this.state.notice });
                }}
              />
              <label htmlFor="in">user</label>
            </span>
          </div>
        );
      }
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">이벤트 공지</div>
                <div className="p-md-6">
                  {/* <Button
                    label="저장 하기"
                    onClick={() => {
                    }}
                  />

                  <Button
                    label="레벨 생성"
                    onClick={() => {
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleEvenNote}
                ></CustomPagination>
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타이틀"
                    body={(rowData: any, column: any) => {
                      return rowData.title;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="보기/숨김"
                    body={(rowData: any, column: any) => {
                      return rowData.del == "y" ? "숨김" : "보기";
                    }}
                  />
                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleEvenNote}
                ></CustomPagination>{" "}
              </div>
            </div>
            <div className="p-col-12 p-md-8">
              <div className="card">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "70%",
                      }}
                      value={this.state.notice.title}
                      onChange={(e: any) => {
                        this.state.notice.title = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">Title</label>
                  </span>

                  <br />
                </div>
                <div className="p-col-12">
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.notice.contents}
                    onTextChange={(e) => {
                      this.state.notice.contents = e.htmlValue;

                      this.setState({ notice: this.state.notice });
                    }}
                  />
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <RadioButton
                      value="y"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;

                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "y"}
                    />
                    <label htmlFor="cb1" className="p-checkbox-label">
                      숨김
                    </label>
                    {"      "}
                    <RadioButton
                      value="n"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;
                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "n"}
                    />

                    <label htmlFor="cb2" className="p-checkbox-label">
                      보기
                    </label>
                  </div>
                </div>
                <div className="p-col-12">
                  <Button
                    label="저장 하기"
                    onClick={() => {
                      this.handleWirte();
                    }}
                  />
                   <Button
                    label="삭제 하기"
                    onClick={() => {
                      this.handleDel();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
