import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { isMobile, isMobileOnly } from "react-device-detect";
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from "primereact/inputtext";
import { RadioButton } from 'primereact/radiobutton';
import { AlertYesNo } from "../alert-yes-no";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertNick,
  uuidv4
} from "../../utility/help";
import { BalanceService } from "../../service/balance.service";
import { UserService } from "../../service/user.service";

import { confirmAlert } from "react-confirm-alert"; // Import


interface Props {
  //dataType: any;
  mode: string;
  userTableValue?: any;
  handleUseStauseChange: (userId: string, stause: string) => any;
  SortTable: (field: any, sort: any) => any;
}

interface State {
  uuid: string
  balances: any
  balance: any
  mode: any
  userID: any
}

export class UserTable extends Component<Props, State> {
  balanceService = new BalanceService();
  userService = new UserService();

  giveBalance = React.createRef() as any;


  constructor(props: Props) {
    super(props);
    this.state = {
      uuid: uuidv4(),
      userID: '',
      mode: 'charge',
      balance: 0,
      balances: [
        { name: "10,000", value: 10000 },
        { name: "50,000", value: 50000 },
        { name: "100,000", value: 100000 },
        { name: "500,000", value: 500000 },
        { name: "1,000,000", value: 1000000 },
        { name: "초기화 ", value: 0 },
      ],
    }
  }

  componentDidMount() {
    //    this.GetUserList(0, this.props.dataType, 1);
  }

  handleBalanceUpdate = (id: string) => {
    let user = this.props.userTableValue.find((data) => data.id == id);
    if (user != null) {
      user.refresh = true;
    }

    this.balanceService.get_in_game_balance(id).then((s: any) => {
      if (s.status === "success") {
        let user = this.props.userTableValue.find((data) => data.id == s.id);
        if (user != null) {
          user.balance = s.balance;
          user.in_balance = 0;
          user.refresh = false;
          this.setState({})
        }
      }
    });
  };

  handleSaveUser = (user: any) => {
    user.status = 'used'
    return (
      confirmAlert({
        title: "유저 저장",
        message: "유저 정보 를 변경하시겠습니까 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.userService
                .save_user_detail({
                  id: user.id,

                  pw: user.pw,
                  nick: user.nick,
                  exchange_pw: user.exchange_pw,
                  level: user.level,
                  phone: user.phone,
                  friendId: user.friendId,
                  bankname: user.bankname,
                  banknum: user.banknum,
                  bankowner: user.bankowner,
                  color: user.color,
                  simplememo: user.simplememo,
                  memo: user.memo,
                  status: user.status,
                  lockedsportone: user.lockedsportone,
                  lockedsporttwo: user.lockedsporttwo,
                  lockedsportall: user.lockedsportall,
                  livesportall: user.livesportall,

                  lockedminngame: user.lockedminngame,
                  lockedcasino: user.lockedcasino,
                  lockedslot: user.lockedslot,
                  rollingSlot: user.rollingSlot,
                  rollingCasino: user.rollingCasino,
                })
                .then((data) => {
                  if (data.status == "success") {
                    confirmAlert({
                      title: "확인",
                      message: "유저 정보가 변경되었습니다.",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {
                            window.location.reload();
                          },
                        },
                      ],
                    });
                  } else if (data.status == "params") {
                    confirmAlert({
                      title: "확인",
                      message: "잘못된 데이터가 포함되어 있습니다",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {
                            window.location.reload();
                          },
                        },
                      ],
                    });
                  } else if (data.status == "roll") {
                    confirmAlert({
                      title: "확인",
                      message: "롤링 프로테이지를 확인해주세요",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {
                            window.location.reload();
                          },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "확인",
                      message: "관리자에게 문의 부탁드림니다",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {
                            window.location.reload();
                          },
                        },
                      ],
                    });
                  }
                });

            },
          },
          {
            label: "취소",
            onClick: () => {
            },
          },
        ],

      })
    );
  };

  handleDoDeposit(userId: string) {

    // this.BalanceService.admin_give_balance(
    //   this.state.uuid,
    //   id,
    //   type,
    //   balance,
    //   rolling,
    //   text
    // )
    confirmAlert({
      title: "지급",
      message: "해당 벨런스를 유저에게 지급하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
     
              this.balanceService
                .admin_give_balance(this.state.uuid, userId, 'deposit', this.state.balance, 0, '관리자 지급')
                .then((s: any) => {
                  if (s.status === "success") {
                    confirmAlert({
                      title: "지급",
                      message: "정상적으로 처리되었습니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => {
                            this.handleBalanceUpdate(this.state.userID);
                            this.setState({ balance: 0 })

                            window.location.reload();

                            //   this.handlePageChange(
                            //     1,
                            //     this.state.findText,
                            //     this.state.searchText
                            //   );
                          },
                        },
                      ],
                    });
                  } else if (s.status === "main_balance") {
                    confirmAlert({
                      title: "지급",
                      message: "매인 벨런스가 부족합니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else if (s.status === "balance") {
                    confirmAlert({
                      title: "지급",
                      message: "보유중인 벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else if (s.status === "ms") {
                    confirmAlert({
                      title: "지급",
                      message: s.ms,
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "지급",
                      message:
                        "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  }
                });



          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }

  handleDoWithdraw(userId: string) {
    confirmAlert({
      title: "회수",
      message: "해당 벨런스를 유저에게 회수하시겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
              this.balanceService
                .admin_give_balance_one_balance(this.state.uuid, userId, 'withdraw', this.state.balance, 0, '관리자 지급')
                .then((s: any) => {
                  if (s.status === "success") {
                    confirmAlert({
                      title: "회수",
                      message: "정상적으로 처리되었습니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => {
                            this.handleBalanceUpdate(this.state.userID);
                            this.setState({ balance: 0 })
                          },
                        },
                      ],
                    });
                  } else if (s.status === "balance") {
                    confirmAlert({
                      title: "회수",
                      message: "유저 벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else if (s.status === "main_balance") {
                    confirmAlert({
                      title: "회수",
                      message: "매인벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else if (s.status === "ms") {
                    confirmAlert({
                      title: "회수",
                      message: s.ms,
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "회수",
                      message:
                        "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  }
                });
            } else {
              this.balanceService
                .admin_give_balance(this.state.uuid, userId, 'withdraw', this.state.balance, 0, '관리자 지급')
                .then((s: any) => {
                  if (s.status === "success") {
                    confirmAlert({
                      title: "회수",
                      message: "정상적으로 처리되었습니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => {
                            this.handleBalanceUpdate(this.state.userID);
                            this.setState({ balance: 0 })
                          },
                        },
                      ],
                    });
                  } else if (s.status === "balance") {
                    confirmAlert({
                      title: "회수",
                      message: "유저 벨런스를 확인해주세요.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "회수",
                      message:
                        "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                      buttons: [
                        {
                          label: "예",
                          onClick: () => { },
                        },
                      ],
                    });
                  }
                });
            }

          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }


  // handleStopUser(userId: string) {
  //   confirmAlert({
  //     title: "정지",
  //     message: "해당유저를 정시시겠습니까?.",
  //     buttons: [
  //       {
  //         label: "예",
  //         onClick: () => {

  //           this.balanceService
  //             .admin_give_balance(this.state.uuid, userId, 'withdraw', this.state.balance, 0, '관리자 지급')
  //             .then((s: any) => {
  //               if (s.status === "success") {
  //                 confirmAlert({
  //                   title: "회수",
  //                   message: "정상적으로 처리되었습니다.",
  //                   buttons: [
  //                     {
  //                       label: "예",
  //                       onClick: () => {
  //                         this.handleBalanceUpdate(this.state.userID);
  //                         this.setState({ balance: 0 })
  //                       },
  //                     },
  //                   ],
  //                 });
  //               }
  //             });
  //         },
  //       },
  //       {
  //         label: "아니오",
  //         onClick: () => { },
  //       },
  //     ],
  //   });
  //   return;
  // }


  render() {
    let RenderReg = () => {
      if (this.props.mode != "reg") {
        return <></>;
      }

      return (
        <Column
          style={{ textAlign: "center" }}
          header="상태 변경"
          body={(rowData) => {
            return (
              <div className="p-grid form-group">
                <Button
                  label="승인"
                  className="p-button-success"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "used");
                  }}
                />
                <Button
                  label="대기"
                  className="p-button-info"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "wait");
                  }}
                />
                <Button
                  label="삭제"
                  className="p-button-warning"
                  onClick={() => {
                    this.props.handleUseStauseChange(rowData.id, "remove");
                  }}
                />
              </div>
            );
          }}
        />
      );
    };
    let headeBalance = (up: any, fieldUp: any) => {
      return (
        <div>
          <span>
            <a
              style={{ color: "red" }}
              onClick={() => this.props.SortTable(fieldUp, 1)}
            >
              ▲
            </a>
            {up}
            <a
              style={{ color: "blue" }}
              onClick={() => this.props.SortTable(fieldUp, -1)}
            >
              ▼
            </a>
          </span>
        </div>
      );
    };
    return (
      <div>

        <OverlayPanel ref={this.giveBalance} id="overlay_panel" style={{ width: '500px' }} showCloseIcon={false}>

          <div className="md-inputfield">
            <span className="p-float-label" style={{ padding: '20px' }}>
              <div className="p-grid form-group" >
                <div className="p-field-radiobutton" style={{ paddingRight: '50px' }}>
                  <RadioButton inputId="city1" name="city1" value="charge" onChange={(e) => this.setState({ mode: 'charge' })} checked={this.state.mode === 'charge'} />
                  <label htmlFor="city1" style={{ paddingTop: '15px' }}>충전</label>
                </div>
                <div className="p-field-radiobutton" style={{ paddingRight: '50px' }}>
                  <RadioButton inputId="city2" name="city2" value="exchange" onChange={(e) => this.setState({ mode: 'exchange' })} checked={this.state.mode === 'exchange'} />
                  <label htmlFor="city2" style={{ paddingLeft: '80px', paddingTop: '15px' }}>환전</label>
                </div>

                <InputText id="username" onChange={(e: any) => this.setState({ balance: e.target.value })} value={this.state.balance} />
                <Button className="p-button-sm p-button-success" label="지급/회수" onClick={() => {
                  if (this.state.mode === 'charge') {
                    this.handleDoDeposit(this.state.userID)
                  }
                  else if (this.state.mode === 'exchange') {
                    this.handleDoWithdraw(this.state.userID)
                  }

                }} />
              </div>
            </span>

            <div className="p-grid form-group" style={{ padding: '20px' }} >
              <Button className="p-button-sm p-button-outlined" label="10,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(10000) }) }} />
              <Button className="p-button-sm p-button-outlined" label="50,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(50000) }) }} />
              <Button className="p-button-sm p-button-outlined" label="100,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(100000) }) }} />
              <Button className="p-button-sm p-button-outlined" label="500,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(500000) }) }} />
              <Button className="p-button-sm p-button-outlined" label="1,000,000" onClick={() => { this.setState({ balance: Number(this.state.balance) + Number(1000000) }) }} />
              <Button className="p-button-sm p-button-danger" label="정정" onClick={() => { this.setState({ balance: 0 }) }} />
            </div>
          </div>

        </OverlayPanel>

        <DataTable
          value={this.props.userTableValue}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
        >


          <Column
            style={{ textAlign: "center", width: "120px" }}
            header={<div><div>아이디</div><div>이름</div></div>}
            body={(rowData) => {
              return (
                <div>
                  <div
                    onClick={() => {
                      OpenUserDetail(rowData.id);
                      this.setState({ userID: rowData.id })
                    }}
                    style={{ color: rowData.color }}
                  >



                    {rowData.id}
                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                    <br />({rowData.bankowner})
                  </div>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "120px" }}
            header={<div><div>닉네임</div><div>폰</div></div>}
            body={(rowData) => {
              return (
                <div>
                  {rowData.nick}
                  <br />
                  {rowData.phone}
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "right", width: "120px" }}
            bodyStyle={{ textAlign: "right", width: "120px" }}
            header={headeBalance("보유금액", "balance")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>
                    {numTomoeny(rowData.balance + rowData.in_balance)}
                  </span>
                  {
                    rowData.refresh == true ? (
                      <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }} ></i>

                    ) : (
                      <i
                        className="pi-md-refresh"
                        onClick={() => {
                          this.handleBalanceUpdate(rowData.id);
                        }}
                      ></i>

                    )
                  }
                  {/* <i
                    className="pi-md-refresh"
                    onClick={() => {
                      this.handleBalanceUpdate(rowData.id);
                    }}
                  ></i> */}
                </div>
              );
            }}
          />



          <Column
            style={{ textAlign: "right", width: "120px" }}
            bodyStyle={{ textAlign: "right", width: "120px" }}
            header={headeBalance("롤링", "rolling")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>
                    {numTomoeny(rowData.rolling)}
                  </span>
                  {/* <i
                    className="pi-md-refresh"
                    onClick={() => {
                      this.handleBalanceUpdate(rowData.id);
                    }}
                  ></i> */}
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "120px" }}
            header={""}
            field="balance"
            body={(rowData) => {
              return (
                <Button type="button" label={'충/환전'} onClick={(e: any) => {


                  this.setState({ userID: rowData.id })
                  this.giveBalance.current.toggle(e)

                }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header={headeBalance("포인트", "point")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{numTomoeny(rowData.point)}</span>
                </div>
              );
            }}
          />
          {/* <Column
            style={{ textAlign: "center", width: "120px" }}
            header={headeBalance("충전 합계", "total_deposit")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{numTomoeny(rowData.total_deposit)}</span>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center", width: "120px" }}
            header={headeBalance("환전 합계", "total_withdraw")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{numTomoeny(rowData.total_withdraw)}</span>
                </div>
              );
            }}
          /> */}

          <Column
            style={{ textAlign: "center", width: "140px" }}
            header={headeBalance("마지막 충전", "lastDeposit")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{convertDate(rowData.lastDeposit)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "140px" }}
            header={headeBalance("마지막 환전", "lastWithdraw")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{convertDate(rowData.lastWithdraw)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "140px" }}
            header={headeBalance("가입일", "regdate")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{convertDate(rowData.regdate)}</span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "140px" }}
            header={headeBalance("접속일", "logindate")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span>{convertDate(rowData.logindate)}</span>
                </div>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "120px" }}
            header={""}
            field=""
            body={(rowData) => {

              if (this.props.mode === 'stop') {

                return (
                  <Button
                    style={{ backgroundColor: "green" }}
                    label="사용"
                    onClick={() => {
                      let color = "#dc3545";
                      this.handleSaveUser(rowData)

                    }}
                  />
                );
              }

              return (
                <Button
                  style={{ backgroundColor: "red" }}
                  label="중지"
                  onClick={() => {
                    let color = "#dc3545";
                    this.handleSaveUser(rowData)

                  }}
                />
              );
            }}
          />


          {/* rollingSlot: user.rollingSlot,
          rollingCasino: user.rollingCasino, */}
          {/* 

          <Column
            style={{ textAlign: "left", width: "400px" }}
            headerStyle={{ textAlign: "left", width: "400px", }}

            header={headeBalance("에이전트", "logindate")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree) }} ></span>
                </div>
              );
            }}
          /> */}

          <Column
            style={{ textAlign: "left", width: "400px" }}
            headerStyle={{ textAlign: "left", width: "400px", }}

            header={headeBalance("에이전트", "logindate")}
            field="balance"
            body={(rowData) => {
              return (
                <div>
                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                </div>
              );
            }}
          />

          {RenderReg()}


        </DataTable>
      </div>
    );
  }
}
