import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";

import { AdminService } from "../service/admin.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  items: any[];
  agents: any[];
  sel_agent: string;
  notice: any;
  activePage: number;
  maxCount: number;
}

export class NoticeAgent extends Component<any, State> {
  adminService = new AdminService();

  constructor(props: any) {
    super(props);

    this.state = {
      items: [],
      agents: [{ name: "모두", value: "" }],
      sel_agent: "",
      activePage: 1,
      maxCount: 1,
      notice: {
        del: "n",
        contents: "",
        title: "",
      },
    };

    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleGetNotice(1);
    this.handleGetAgent();
  }

  handleEditNotice = (notice: any) => {
    this.adminService.admin_notice_agent_edit(notice).then((data) => {
      if (data.status == "success") {
        this.handleGetNotice(1);
      }
    });
  };

  admin_del_notice_agent = (notice: any) => {
    this.adminService.admin_del_notice_agent(notice).then((data) => {
      if (data.status == "success") {
        this.handleGetNotice(1);
      }
    });
  };

  handleGetNotice = (skip: any) => {
    this.adminService.admin_get_notice_agent({ skip: skip }).then((data) => {
      if (data.status == "success") {
        this.setState({
          items: data.notices,
          maxCount: data.maxCount,
          activePage: skip
        });
      }
    });
  };

  handleGetAgent = () => {
    this.adminService.admin_get_agent_list().then((data) => {
      if (data.status == "success") {
        let agents = this.state.agents;
        for (let agent of data.agents) {
          agents.push({
            name: agent.agent + " / " + agent.nick,
            value: agent.agent,
          });
        }
        this.setState({
          agents: agents,
        });
      }
    });
  };

  render() {
    let items = this.state.items;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ notice: rowData });
            }}
          ></Button>
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">에이전트 공지</div>
                <div className="p-md-6">
                  {/* <Button
                    label="저장 하기"
                    onClick={() => {
                    }}
                  />

                  <Button
                    label="레벨 생성"
                    onClick={() => {
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="번호"
                    body={(rowData: any, column: any) => {
                      return rowData.idx;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="에이전트"
                    body={(rowData: any, column: any) => {
                      return rowData.agent;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="타이틀"
                    body={(rowData: any, column: any) => {
                      return rowData.title;
                    }}
                  />

                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(page) => {
                    this.handleGetNotice(page)
                  }}
                ></CustomPagination>
              </div>
            </div>
            <div className="p-col-12 p-md-8">
              <div className="card">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{ width: "70%" }}
                      value={this.state.notice.title}
                      onChange={(e: any) => {
                        this.state.notice.title = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">Title</label>
                  </span>
                </div>
                <div className="p-col-12">
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.notice.contents}
                    onTextChange={(e) => {
                      this.state.notice.contents = e.htmlValue;

                      this.setState({ notice: this.state.notice });
                    }}

                  />


                </div>
                <div className="p-col-12">
                  <br />
                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "8%",
                      }}
                      value={this.state.notice.posX}
                      onChange={(e: any) => {
                        this.state.notice.posX = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">Pos X</label>
                  </span>
                  <br />

                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "8%",
                      }}
                      value={this.state.notice.posY}
                      onChange={(e: any) => {
                        this.state.notice.posY = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">Pos Y</label>
                  </span>

                  <br />
                </div>

                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <RadioButton
                      value="y"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;

                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "y"}
                    />
                    <label htmlFor="cb1" className="p-checkbox-label">
                      숨김
                    </label>
                    {"      "}
                    <RadioButton
                      value="n"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;
                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "n"}
                    />

                    <label htmlFor="cb2" className="p-checkbox-label">
                      보기
                    </label>
                  </div>
                </div>
                {/* <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <Dropdown
                      value={this.state.sel_agent}
                      options={this.state.agents}
                      onChange={(e: any) => {
                        console.log(e.value.value);
                        this.state.notice.agent = e.value.value;

                        this.setState({
                          sel_agent: e.value,
                          notice: this.state.notice,
                        });
                      }}
                      placeholder="모두"
                      optionLabel="name"
                      style={{ width: "300px" }}
                    />
                  </div>
                </div> */}

                <div className="p-col-12">
                  <Button
                    label="저장 하기"
                    onClick={() => {
                      this.handleEditNotice(this.state.notice);
                      //this.handleUpdateBenefit();
                    }}
                  />
                  
                  <Button
                    label="삭제 하기"
                    onClick={() => {
                      this.admin_del_notice_agent(this.state.notice);
                      //this.handleUpdateBenefit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
