import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  convertToCSV,
  GetNow
} from "../utility/help";
import { CONNREFUSED } from "dns";

interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
}

export class UserList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();

  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1,
    };

    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  componentDidMount() {

  }

  ExportUser = (state) => {
    this.fileService
      .exportUser(state)
      .then((data) => {

        for (let user of Object.values(data.users) as any) {
          delete user.level
          delete user.idx
          delete user.pw
          delete user.exchange_pw
          delete user.simplememo
          delete user.memo
          delete user.simplememo
          delete user.in_balance
          delete user.rolling
          delete user.point
          delete user.betmoney
          delete user.winmoney
          delete user.master
          delete user.color
          delete user.session
          delete user.friendId
          delete user.friendId
          delete user.friendId
          delete user.friendId
          delete user.lastDeposit
          delete user.lastWithdraw
          delete user.today_deposit
          delete user.today_withdraw
          delete user.week_deposit
          delete user.week_withdraw
          delete user.month_deposit
          delete user.month_withdraw
          delete user.month_withdraw
          delete user.total_withdraw
          delete user.total_deposit
          delete user.outside_balance
          delete user.regcode
          delete user.friendLosing
          delete user.agent_tree


        }

        let csv = convertToCSV(data.users)
        csv = '아이디,전화번호,보유금,총판,상태,은행,계좌,이름,가입일,접속일,가입IP,접속IP, \r\n' + csv

        const link = window.document.createElement('a');
        link.charset = 'euc-kr';
        link.setAttribute('href', 'data:text/csv; charset=euc-kr,' + csv);

        link.setAttribute('download', `user_${convertDate(GetNow())}.csv`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();

      });

  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .get_user_list(page, find, search, sortType, sort, "used")
      .then((data) => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount,
        });
      });
  };

  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    console.log(search, find);
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  MoveSel = (page: any) => {
    console.log(page);
    this.setState({ page: page });
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };

  render() {
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="p-md-6">
                  <HelpBox SearchData={this.SearchData}></HelpBox>
                </div>
                <div className="p-md-6">

                  <Menu
                    model={[
                      {
                        label: "유저 저장",
                        icon: "pi pi-fw pi-file",

                        command: () => {
                          this.ExportUser('used');
                        },
                      },
                      {
                        label: "정지 저장",
                        icon: "pi pi-fw pi-file",

                        command: () => {
                          this.ExportUser('stop');
                        },
                      },
                      {
                        label: "졸업 저장",
                        icon: "pi pi-fw pi-file",

                        command: () => {
                          this.ExportUser('remove');
                        },
                      },
                      // {
                      //   label: "유령 저장",
                      //   icon: "pi pi-fw pi-file",

                      //   command: () => {
                      //     this.ExportUser('ghost');
                      //   },
                      //},
                    ]}
                    popup
                    ref={(el) => {
                      if (el != null) {
                        this.menu = el
                      }
                    }}
                    id="popup_menu1"
                  />

                  <Button
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-warning "
                    onClick={(event) => {
                      this.menu.toggle(event);
                    }}

                  />
                </div>

              </div>
              <div className="p-grid">
                <div className="p-col-12">
                  <SubPaging
                    activePage={this.state.page}
                    maxCount={this.state.maxCount}
                    limit={20}
                    MoveSel={(page: number) => {
                      this.MoveSel(page);
                    }}
                  ></SubPaging>

                  <UserTable
                    userTableValue={this.state.userTableValue}
                    SortTable={this.SortTable}
                    mode="used"
                    handleUseStauseChange={() => { }}
                  ></UserTable>

                  <SubPaging
                    activePage={this.state.page}
                    maxCount={this.state.maxCount}
                    limit={20}
                    MoveSel={(page: number) => {
                      this.MoveSel(page);
                    }}
                  ></SubPaging>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
