import axios from "axios";

import { AxiosMag } from "./axios.service";

export class AdminService {
  admin_get_outside_balance = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_outside_balance", {});
  };

  admin_get_one_balance = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_one_balance", {});
  };

  admin_check_health = async () => {
    return await AxiosMag.SendMessageByPost("/user/admin_check_health", {});
  };

  admin_top_info = async () => {
    return await AxiosMag.SendMessageByPost("/calculate/admin_top_info", {});
  };

  admin_get_management = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_management", {});
  };

  admin_make_bank_account_level = async (level: any) => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_make_bank_account_level",
      {
        maxlevel: level,
      }
    );
  };

  admin_get_make_bank_account_level = async () => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_make_bank_account_level",
      {}
    );
  };

  admin_save_bank_account = async (banks) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_save_bank_account", {
      banks: banks,
    });
  };

  admin_make_benefit_level = async (level: any, benefits: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_make_benefit_level", {
      maxlevel: level,
      benefits: benefits,
    });
  };



  admin_delete_benefit = async (index: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_benefit_remove", {
      idx: index,
    });
  };

  handleMakeLimitLevel = async (level: any, games: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_make_bet_limit", {
      maxlevel: level,
      games: games,
    });
  };

  admin_get_limit_list = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_bet_limits", {});
  };

  admin_get_benefit_list = async () => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_benefit_list",
      {}
    );
  };

  admin_update_benefit = async (benefits: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_update_benefit", {
      benefits: benefits,
    });
  };

  admin_update_bet_limit = async (limits: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_update_bet_limit", {
      limits: limits,
    });
  };

  admin_get_markets_item = async (skip: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_markets_item", {
      skip: skip,
    });
  };

  admin_edit_markets_item = async (skip: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_markets_item", {
      skip: skip,
    });
  };

  admin_get_mini_games_setting = async () => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_mini_game_setting",
      {}
    );
  };

  admin_save_mini_games_setting = async (save: any) => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_save_mini_games_setting",
      { saves: save }
    );
  };

  admin_get_categories = async (
    page: any,
    searchText: any,
    findText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/utility/admin_get_categories", {
      skip: page,
      searchText: searchText,
      findText: findText,
      mode: mode,
    });
  };

  admin_update_categories = async (
    _id: any,
    SportID: any,
    CategoryId: any,
    ko: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_update_categories",
      {
        _id: _id,
        SportID: SportID,
        CategoryId: CategoryId,
        ko: ko,
      }
    );
  };

  admin_get_tournaments = async (
    page: any,
    searchText: any,
    findText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/utility/get_tournaments", {
      skip: page,
      searchText: searchText,
      findText: findText,
      mode: mode,
    });
  };

  admin_update_tournaments = async (
    ID: any,
    Active: any,
    Quick: any,
    ko: any
  ) => {
    return await AxiosMag.SendMessageByPost("/utility/edit_tournaments", {
      tournamentid: ID,
      active: Active,
      quick: Quick,
      ko: ko,
    });
  };

  admin_get_teams = async (page: any, searchText: any, findText: any) => {
    return await AxiosMag.SendMessageByPost("/utility/get_teams", {
      skip: page,
      searchText: searchText,
      findText: findText,
    });
  };

  admin_update_teams = async (TeamID: any, TeamName: any) => {
    return await AxiosMag.SendMessageByPost("/utility/edit_teams", {
      teamid: TeamID,
      teamname: TeamName,
    });
  };

  admin_get_markets = async (page: any, searchText: any, findText: any) => {
    return await AxiosMag.SendMessageByPost("/utility/get_markets", {
      skip: page,
      searchText: searchText,
      findText: findText,
    });
  };

  admin_update_markets = async (
    id: any,
    groups: any,
    separate: any,
    nameko: any
  ) => {
    return await AxiosMag.SendMessageByPost("/utility/admin_edit_markets", {
      id,
      groups,
      separate,
      nameko,
    });
  };

  admin_get_market_locks = async (page: any) => {
    return await AxiosMag.SendMessageByPost("/utility/get_market_locks", {
      skip: page,
    });
  };

  admin_update_games = async () => {
    return await AxiosMag.SendMessageByPost("/utility/update_games", {});
  };

  admin_get_custom_odds = async (tournamentid: any) => {
    return await AxiosMag.SendMessageByPost("/utility/get_custom_odds", {
      tournamentid: tournamentid,
    });
  };

  get_sports = async () => {
    return await AxiosMag.SendMessageByPost("/utility/get_sport", {});
  };

  admin_update_custom_odds = async (tournamentid: any, odds: any) => {
    return await AxiosMag.SendMessageByPost("/utility/update_custom_odds", {
      tournamentid: tournamentid,
      odds: odds,
    });
  };

  admin_get_bundle_list = async () => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_get_bundle_list",
      {}
    );
  };

  admin_get_bundle_base = async () => {
    return await AxiosMag.SendMessageByPost("/utility/get_bundle_base", {});
  };

  admin_save_bundle = async (save) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_save_bet_bundle",
      save
    );
  };

  admin_delete_bet_bundle = async (id) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_delete_bet_bundle",
      {
        idx: id,
      }
    );
  };

  // admin_get_calculate_manager = async () => {
  //   return await AxiosMag.SendMessageByPost(
  //     "/admin/admin_get_calculate_manager",
  //     {}
  //   );
  // };

  admin_get_calculate_manager = async (begin: Date, end: Date) => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_calculate_manager",
      { begin: begin, end: end }
    );
  };

  admin_get_minigame_list = async () => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_minigame_list",
      {}
    );
  };

  admin_get_main_agent = async (begin, end) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_main_agent", { begin, end }
    );
  };

  admin_update_user_calculate_balance = async () => {
    return await AxiosMag.SendMessageByPost(
      "/calculate/admin_update_user_calculate_balance",
      {
      }
    );
  };




  admin_get_sub_agent = async (agent, begin, end) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_sub_agent",
      {
        agent, begin, end
      }
    );
  };


  admin_get_agnet_list = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_agnet_list", {});
  };

  admin_get_calculate_agent = async (agent: string, begin: Date, end: Date) => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_get_calculate_agent",
      { begin: begin, end: end, agent: agent }
    );
  };

  admin_notice_user_edit = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/admin/admin_notice_user_edit",
      notice
    );
  };

  admin_get_notice_user = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_notice_user", {});
  };

  admin_del_notice_user = async (notice) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_del_notice_user", notice);
  };


  admin_del_notice_agent = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_del_notice_agent",
      notice
    );
  };

  admin_notice_agent_edit = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_notice_agent_edit",
      notice
    );
  };

  admin_get_notice_agent = async (params) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_get_notice_agent",
      params
    );
  };

  admin_get_agent_list = async () => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_list", {});
  };

  admin_update_slot_games = async () => {
    return await AxiosMag.SendMessageByPost("/utility/admin_update_casion", { gameCompany: process.env.REACT_APP_API_REMOVE_GAME_COMPANY, company: process.env.REACT_APP_API_REMOVE_COMPANY });
  };


  admin_get_ignored_count = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_ignored_count", {});
  };

  admin_set_ignored_count = async (count) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_set_ignored_count", { count: count });
  };

  admin_get_casino_main_games = async (
    page: any,
    searchText: any,
    findText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_get_casino_main_games",
      {
        skip: page,
        searchText: searchText,
        findText: findText,
        mode: mode,
      }
    );
  };

  admin_get_slot_main_games = async (
    page: any,
    searchText: any,
    findText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_get_slot_main_games",
      {
        skip: page,
        searchText: searchText,
        findText: findText,
        mode: mode,
      }
    );
  };

  admin_get_slotgames = async (
    page: any,
    searchText: any,
    findText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_get_slot_game_list",
      {
        skip: page,
        searchText: searchText,
        findText: findText,
        mode: mode,
      }
    );
  };


  admin_update_main_casino = async (casino: any) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_update_main_casino",
      casino
    );
  };

  admin_update_slot = async (slot: any) => {
    return await AxiosMag.SendMessageByPost("/utility/admin_update_slot", slot);
  };

  admin_update_main_slot = async (slot: any) => {
    return await AxiosMag.SendMessageByPost(
      "/utility/admin_update_main_slot",
      slot
    );
  };

  admin_get_messenger = async () => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_messenger ", {});
  };

  admin_update_messenger = async (name: any, id: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_update_messenger ", {
      name,
      id,
    });
  };
}
