import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";
import { SelectButton } from "primereact/selectbutton";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import { SubPaging } from "../share/sub-paging";

import {
  OpenAgentDetail,
  GetAgentTreeByLevel,
  converAgentStatus,
  convertDate,
  numTomoeny,
} from "../utility/help";

interface AgentState {
  agents: any;
  agentId: any;
  maxCount: any;
  activePage: any;
  selectViewType: string,
  selectBox: any;
  dropdownItem: string;

}

export class AgentRemove extends Component<any, AgentState> {
  agentService: AgentService = new AgentService();
  viewType: any[] = [
    { label: "모두 보기", value: "all" },
    { label: "삭제", value: "remove" },
    { label: "중지", value: "stop" },
  ]

  constructor(props: any) {
    super(props);

    this.state = {
      agentId: '',
      selectViewType: 'all',
      agents: [],
      maxCount: 1,
      activePage: 1,
      dropdownItem: "agent",

      selectBox: [
        { label: "선택", value: null },
        { label: "에이전트", value: "agent" },
      ],

    };
    //this.handleAgentBalance = this.handleAgentBalance.bind(this);
  }

  componentDidMount() {
    this.handleAgentWithdrawBalance(1, this.state.selectViewType, '', '',);
  }

  handleMoveSel = (page: any) => {
    this.handleAgentWithdrawBalance(page, this.state.selectViewType, '', '',);
  };

  handleAgentWithdrawBalance = (page, status, type, id) => {
    this.agentService.admin_agnet_remove_list(page, status, type, id).then((s: any) => {
      this.setState({
        activePage: page,
        agents: s.agent,
        maxCount: s.maxCount,
      });
    });
  };


  handleAgentUsed(agentId: string) {
    confirmAlert({
      title: "에이전트",
      message: "해당 에이전트를 활성화시키면 하단 에이전트가 모두 활성화 됩니다 활성화 시키겠습니까?.",
      buttons: [
        {
          label: "예",
          onClick: () => {
            this.agentService
              .admin_agent_used(agentId)
              .then((s: any) => {
                if (s.status === "success") {
                  confirmAlert({
                    title: "에이전트",
                    message: "정상적으로 처리되었습니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => {
                          window.location.reload()

                          //   this.handlePageChange(
                          //     1,
                          //     this.state.findText,
                          //     this.state.searchText
                          //   );
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "에이전트",
                    message:
                      "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                    buttons: [
                      {
                        label: "예",
                        onClick: () => { },
                      },
                    ],
                  });
                }
              });
          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }


  render = () => {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">에이전트 삭제</div>

                <SelectButton
                  value={this.state.selectViewType}
                  options={this.viewType}
                  onChange={(event) => {
                    this.setState({ selectViewType: event.value });
                    this.handleAgentWithdrawBalance(1, event.value, '', '')
                  }}
                />



                <div className="p-md-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          style={{ width: "100px" }}
                          options={this.state.selectBox}
                          value={this.state.dropdownItem}
                          onChange={(event) =>
                            this.setState({ dropdownItem: event.value })
                          }
                          autoWidth={false}
                        />
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText

                          value={this.state.agentId}
                          onChange={(e: any) =>
                            this.setState({ agentId: e.target.value })
                          }
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handleAgentWithdrawBalance(1, this.state.selectViewType, 'agent', this.state.agentId)
                          }}
                        />
                      </span>
                    </div>


                  </div>
                </div>
              </div>

            </div>
            <div className="p-col-12">
              <div className="card">
                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.agents}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                // onSort={this.onSortChange}
                // header={header}
                // sortField={this.state.sortField}
                // sortOrder={this.state.sortOrder}
                >
                  <Column
                    style={{ textAlign: "center", width: "200px" }}
                    header="에이전트 아이디"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                          <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="연락처"
                    body={(agent: any) => {
                      return agent.phone;
                    }}
                    field="phone"

                  // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="환전금액"
                    body={(agent: any) => {
                      return numTomoeny(agent.balance);
                    }}
                    field="phone"
                  // sortFunction={}
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="은행명"
                    body={(agent: any) => {
                      return agent.bankname;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="입금자명"
                    body={(agent: any) => {
                      return agent.bankowner;
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="계좌번호"
                    body={(agent: any) => {
                      return agent.banknum;
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "200px" }}
                    header="등록일"
                    body={(agent: any) => {
                      return convertDate(agent.regdate);
                    }}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "200px" }}
                    header="완료일"
                    body={(agent: any) => {
                      return convertDate(agent.setdate);
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "200px" }}
                    header="트리"
                    body={(agent: any) => {
                      return agent.agent_tree;
                    }}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="상태"
                    body={(agent: any) => {
                      return converAgentStatus(agent.status);
                    }}
                    field="rolling"
                  />


                  <Column
                    style={{ textAlign: "center", width: "60px" }}
                    bodyStyle={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                    header="사용"
                    body={(agent: any) => {

                      return (
                        <Button type="button" label={'사용'} onClick={(e: any) => {
                          this.handleAgentUsed(agent.agent)
                        }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                        // <OverlayBalance></OverlayBalance>
                      );
                    }}
                    field="balance"
                  />

                </DataTable>

                <SubPaging
                  limit={999}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.handleMoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
