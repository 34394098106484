import React, { Component, AnchorHTMLAttributes, forwardRef } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";
import { isMobile, isMobileOnly } from "react-device-detect";

import { numTomoeny, balanceColor, GetToday, ConvertCalendar } from "../utility/help";
import { CONNREFUSED } from "dns";


interface Prop { }

interface BalanceState {
  details: any;
  findText: any;
  searchText: any;
  select: any;
  selectItem: any;

  options: any[];
  options_type: string;
  begin: any;
  end: any;
  expandedRows: any;
  management: any;
  isFirst: any;
  totalBalance: any

}

export class Calculate extends Component<Prop, BalanceState> {
  adminService: AdminService = new AdminService();
  constructor(props: Prop) {
    super(props);

    const today = GetToday()
    const begin = today.begin;
    const end = today.begin;


    // const end = new Date(
    //   `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
    // );

    this.state = {
      isFirst: false,
      begin: begin,
      end: end,
      findText: "",
      searchText: "",
      selectItem: {},
      expandedRows: null,
      totalBalance: {},
      details: [],
      management: [],
      options: [{ label: "입출금/통계", value: "balance", }, { label: "총판/지급", value: "agentGive", }],

      options_type: "balance",

      select: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
    };
  }

  handleCalculate(begin: Date, end: Date) {
    this.adminService.admin_get_calculate_manager(begin, end).then((data) => {
      if (data.status == "success") {
        let detail = Object.values(data.calculate);
        detail.sort(function (a: any, b: any) {
          return Date.parse(a.date) > Date.parse(b.date) ? -1 : 1;
        });

        if (this.state.isFirst === false) {
          if (data.management.sportpre === "y") {
            this.state.options.push({ label: "스포츠", value: "sport_pre" });
          }
          if (data.management.sportlive === "y") {
            this.state.options.push({
              label: "라이브스포츠",
              value: "sport_live",
            });
          }
          if (data.management.casino === "y") {
            this.state.options.push({ value: "casino", label: "casino" });
          }
          if (data.management.slot === "y") {
            this.state.options.push({ label: "slot", value: "slot" });
          }
          if (data.management.fx === "y") {
            this.state.options.push({ label: "fx", value: "fx" });
          }
        }


        let totalBalance = {}
        for (let balances of Object.values(data.calculate) as any) {
          console.log(balances)
          for (let key of Object.keys(balances as [])) {

            if (typeof (balances[key]) === 'number') {
              if (totalBalance[key] == null) {
                totalBalance[key] = 0
              }
              totalBalance[key] += Number(balances[key] as Number)
            }
            else if (typeof (balances[key]) === 'object') {

              for (let key2 of Object.keys(balances[key] as [])) {

                if (typeof (balances[key][key2]) === 'number') {
                  if (totalBalance[`${key}.${key2}`] == null) {
                    totalBalance[`${key}.${key2}`] = 0
                  }

                  totalBalance[`${key}.${key2}`] += Number(balances[key][key2] as Number)

                }

              }
            }
          }
        }

        console.log(totalBalance)

        this.setState({
          isFirst: true,
          details: detail,
          totalBalance: totalBalance,
          management: data.management,
          options: this.state.options,
        });
      }
    });
  }

  handleMiniGame() {
    this.adminService.admin_get_minigame_list().then((data) => {
      if (data.status == "success") {
        let options = this.state.options;
        for (let mini of data.miniGames) {
          options.push({ value: mini.categoryId, label: mini.categoryName });
        }

        //this.setState({ options: options });
      }
    });
  }

  componentDidMount() {
    this.handleMiniGame();
    this.handleCalculate(this.state.begin, this.state.end);
    // this.handleCalculate();
  }

  render() {
    //let logs = this.state.logs;
    //C:\WORK\eBetting\admin\src\main\calculate.tsx



    const pad2 = (n) => {
      return (n < 10 ? "0" : "") + n;
    };



    const total = (this.state.totalBalance['balance.depositBalance'] + this.state.totalBalance['agentChangeBalance'] + this.state.totalBalance['agentGive.depositBalance']) -
      (this.state.totalBalance['balance.withdrawBalance'] + this.state.totalBalance['agentExchangeBalance'] + this.state.totalBalance['agentGive.withdrawBalance'])

    let headerGroup = (
      <ColumnGroup>


        <Row>
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="에이전트" />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>센터충전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>센터환전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>에이젼트 지급</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>에이젼트 회수</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>회원 잔액</div>} />

          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>배팅</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>당첨</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>배팅 - 당첨</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>에이전트 충전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>에이전트 환전</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>에이전트 잔액</div>} />
          <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>매출액</div>} />



        </Row>
        {
          (

            <Row >
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header="정산 합계" />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['balance.depositBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['balance.withdrawBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentGive.depositBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentGive.withdrawBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div> </div>} />


              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betDoBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betWinBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['betting.betDoBalance'] - this.state.totalBalance['betting.betWinBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentChangeBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(this.state.totalBalance['agentExchangeBalance'])}</div>} />
              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{ }</div>} />

              <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>{numTomoeny(total)}</div>} />



            </Row>
          )

        }


      </ColumnGroup >
    );

    const RenderBalance = () => {

      return (
        <DataTable
          // value={this.state.agentInfo}
          paginatorPosition="both"
          rows={99999}
          sortOrder={-1}
          // rowClassName={rowClass}
          headerColumnGroup={headerGroup}

          value={this.state.details}
          scrollable={true}
        // headerColumnGroup={headerGroup}
        >


          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="날짜"
            body={(rowData: any, column: any) => {
              let date = new Date(rowData.date);

              return (
                pad2(date.getMonth() + 1) +
                "-" +
                pad2(date.getDate()) +
                " " +
                pad2(date.getHours())
              );
              //return rowData.date;
            }}
          />



          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "red" }}>
                  {numTomoeny(rowData.balance.depositBalance)}
                </span>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "blue" }}>
                  {numTomoeny(rowData.balance.withdrawBalance)}
                </span>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "red" }}>
                  {numTomoeny(rowData.agentGive.depositBalance)}
                </span>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "blue" }}>
                  {numTomoeny(rowData.agentGive.withdrawBalance)}
                </span>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "green" }}>
                  {numTomoeny(rowData.inBalance)}
                </span>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {

              if (rowData.detail == null || rowData.detail.slot == null) {
                return <span style={{ color: "#e91224" }}>0</span>
              }

              return (
                <span style={{ color: "#e91224" }}>
                  {rowData.detail.slot.bet && numTomoeny(rowData.detail.slot.bet.balance)}
                </span>
              );
            }}
          />
          {/* 
          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "#e91224" }}>
                  {numTomoeny(rowData.betting.betDoBalance)}
                </span>
              );
            }}
          /> */}
          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {

              if (rowData.detail == null || rowData.detail.slot == null) {
                return <span style={{ color: "#e91224" }}>0</span>
              }

              return (
                <span style={{ color: "#e91224" }}>
                  {numTomoeny(rowData.detail.slot.win.balance)}
                </span>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <div>
                  <span
                    style={{
                      color: balanceColor(
                        rowData.detail.slot.bet.balance -
                        rowData.detail.slot.win.balance
                      ),
                    }}
                  >
                    {numTomoeny(
                      rowData.detail.slot.bet.balance -
                      rowData.detail.slot.win.balance
                    )}
                  </span>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "red" }}>
                  {numTomoeny(rowData.agentChangeBalance)}
                </span>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "blue" }}>
                  {numTomoeny(rowData.agentExchangeBalance)}
                </span>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {
              return (
                <span style={{ color: "green" }}>
                  {numTomoeny(rowData.agentBalance)}
                </span>
              );
            }}
          />


          <Column
            style={{ textAlign: "center", width: "80px", borderRight: '1px solid #d8d8d8' }}
            body={(rowData: any, column: any) => {

              const total = (rowData.balance.depositBalance + rowData.agentGive.depositBalance + rowData.agentChangeBalance) -
                (rowData.balance.withdrawBalance + rowData.agentGive.withdrawBalance + rowData.agentExchangeBalance)
              return (
                <span style={{ color: "green" }}>
                  {numTomoeny(total)}
                </span>
              );
            }}
          />





        </DataTable>

      );
    };

    return (
      <div className="content" >
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card card-w-title">
              <div className="card-title">정산 기록</div>

              <div className="p-col-12 ">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-3">
                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      view="date"
                      locale={ConvertCalendar()}
                      value={this.state.begin}
                      onChange={(e) => this.setState({ begin: e.value })}
                      showWeek={false}
                    />
                  </div>
                  <div className="p-col-12 p-md-3">
                    <Calendar
                      placeholder="Begin"
                      showIcon={true}
                      dateFormat="yy-mm-dd"
                      view="date"
                      locale={ConvertCalendar()}

                      value={this.state.end}
                      onChange={(e) => this.setState({ end: e.value })}
                      showWeek={false}
                    />
                  </div>
                  <div className="p-col-12 p-md-3">
                    <Button
                      label="검색"
                      onClick={() => {
                        this.handleCalculate(
                          this.state.begin,
                          this.state.end
                        );
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="card">
              {RenderBalance()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
