import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";
import { CustomPagination } from "../custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  convertAgentLogText,
  GetMonth,
  convertAgentBalanceText,
  ConvertCalendar
} from "../../utility/help";
import { spawn } from "child_process";

interface Props {
  id: string;
}

interface State {
  logs: any[];
  total: any[];
  page: number;
  pages: any;
  field: string;
  activePage: number;
  maxCount: number;
  selectDay: string;
  selectType: string;
  giveType: string;
  giveBalance: string;
  giveMS: string;
  balanceTypes: any;
  balanceType: any;
  begin: any;
  end: any;
  totalBalance: number;

}

export class AgentBalance extends Component<Props, State> {
  BalanceService = new BalanceService();

  constructor(props: any) {
    super(props);

    let today = GetMonth();

    console.log(today)
    this.state = {
      logs: [],
      total: [],
      page: 1,
      pages: {},
      field: "",
      balanceType: 'all',
      activePage: 1,
      maxCount: 1,
      totalBalance: 0,

      selectDay: "all",
      selectType: "all",
      giveType: "admin_do_deposit",
      giveBalance: "0",
      giveMS: "",
      begin: today.begin,
      end: today.end,

      balanceTypes: [
        { label: "모두", value: "all" },
        { label: "벨런스", value: "balance" },
        { label: "롤링", value: "rolling" },
        { label: "포인트", value: "point" },
        { label: "루징", value: "losing" },
      ],
    };
  }

  componentDidMount = () => {
    this.headerDepositAndWithdraw('all', this.props.id, 0);
  };

  handlePageChange = (page: any) => {
    this.setState({ activePage: page });
    this.headerDepositAndWithdraw(this.state.balanceType, this.props.id, page);
  };

  headerDepositAndWithdraw = (balanceType: string, id: string, page: any) => {
    this.BalanceService.admin_get_balance_deposit_and_withdraw(balanceType, id, page, this.state.begin,
      this.state.end,
    ).then(
      (data) => {
        if (data.status == "success") {
          this.setState({
            logs: data.logs,
            maxCount: data.maxCount,
            totalBalance: data.totalBalance,

          });
        }
      }
    );
  };

  headerGiveAgentBalance = () => {
    if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
      this.BalanceService.admin_agent_give_balance_one_balance(
        this.props.id,
        this.state.giveBalance,
        this.state.giveType,
        this.state.giveMS
      ).then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "지급/회수",
            message: "지급/회수에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else if (data.status == "main_success") {
          confirmAlert({
            title: "지급/회수",
            message: "지급/회수에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "예러",
            message: "예러가 계속 발성되면 문의주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });

    } else {
      this.BalanceService.admin_agent_give_balance(
        this.props.id,
        this.state.giveBalance,
        this.state.giveType,
        this.state.giveMS
      ).then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "지급/회수",
            message: "지급/회수에 성공하였습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "예러",
            message: "예러가 계속 발성되면 문의주세요.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });

    }

  };

  render = () => {
    const logs = this.state.logs;

    return (
      <div>
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-12 p-md-5">
              <div className="p-grid">
                <div className="p-col-3">
                  <RadioButton
                    value="admin_do_deposit"
                    inputId="rb1"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "admin_do_deposit"}
                  />
                  <label htmlFor="rb1" className="p-radiobutton-label">
                    지급/회수
                  </label>
                </div>
                <div className="p-col-3">
                  <RadioButton
                    value="point"
                    inputId="rb2"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "point"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    포인트
                  </label>
                </div>
                <div className="p-col-3">
                  <RadioButton
                    value="rolling"
                    inputId="rb2"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "rolling"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    롤링
                  </label>
                </div>
                <div className="p-col-3">
                  <RadioButton
                    value="losing"
                    inputId="rb2"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "losing"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    루징
                  </label>
                </div>
              </div>
            </div>


            <div className="p-col-12 p-md-2">
              <span className="md-inputfield">
                <InputText
                  className="p-error"
                  placeholder="지급금액"
                  onChange={(event: any) =>
                    this.setState({ giveBalance: event.target.value })
                  }
                />
              </span>
            </div>
            {"    "}
            <div className="p-col-12 p-md-2">
              <span className="md-inputfield">
                <InputText
                  className="p-error"
                  placeholder="내용"
                  onChange={(event: any) =>
                    this.setState({ giveMS: event.target.value })
                  }
                />
              </span>
            </div>

            <div className="p-col-12 p-md-2">
              <Button
                label={"확 인"}
                onClick={() => {
                  this.headerGiveAgentBalance();
                }}
              />
            </div>

            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-2">
                  <Calendar
                    placeholder="Begin"
                    showIcon={true}
                    dateFormat="yy-mm-dd"
                    value={this.state.begin}
                    onChange={(e) => this.setState({ begin: e.value })}
                    showWeek={false}
                    locale={ConvertCalendar()}

                  />
                </div>
                <div className="p-col-2">
                  <Calendar
                    placeholder="End"
                    showIcon={true}
                    dateFormat="yy-mm-dd"
                    value={this.state.end}
                    onChange={(e) => this.setState({ end: e.value })}
                    showWeek={false}
                    locale={ConvertCalendar()}

                  />
                </div>
                <div className="p-col-4">
                  <SelectButton
                    value={this.state.balanceType}
                    options={this.state.balanceTypes}
                    onChange={(event) => {
                      if (event.value != null) {
                        this.setState({ balanceType: event.value });
                        this.headerDepositAndWithdraw(event.value, this.props.id, 0);
                      }
                    }}
                  />
                </div>
                <div className="p-col-2">
                  <InputText
                    className="p-susses"
                    placeholder="내용"
                    value={numTomoeny(this.state.totalBalance)}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12">
          <CustomPagination
            active={this.state.activePage}
            take={10}
            count={this.state.maxCount}
            handlePageChange={(page: any) => {
              this.handlePageChange(page);
            }}
          ></CustomPagination>

          <DataTable
            value={logs}
            paginatorPosition="both"
            rows={10}
            sortOrder={-1}
            scrollable={true}
          >
            <Column
              style={{ textAlign: "center" }}
              header="입금/출금	"
              body={(rowData: any, column: any) => {
                return numTomoeny(rowData.balance);
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"남은금액"}
              body={(rowData: any, column: any) => {
                return numTomoeny(rowData.afterbalance);
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="벨런스타입"
              body={(rowData: any, column: any) => {
                return convertAgentBalanceText(rowData.balanceType);
              }}
            />



            <Column
              style={{ textAlign: "center" }}
              header={"메세지"}
              body={(rowData: any, column: any) => {
                return <div>{convertAgentLogText(rowData.logType)}</div>;
              }}
            />


            <Column
              style={{ textAlign: "center" }}
              header={"메세지"}
              body={(rowData: any, column: any) => {
                return <div>{rowData.simplememo}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"요청시간"}
              body={(rowData: any, column: any) => {
                return <div>{convertDate(rowData.regdate)}</div>;
              }}
              field="depositdate"
            />
          </DataTable>
          <CustomPagination
            active={this.state.activePage}
            take={10}
            count={this.state.maxCount}
            handlePageChange={(page: any) => {
              this.handlePageChange(page);
            }}
          ></CustomPagination>
        </div>
      </div>
    );
  };
}
