import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";

import { Growl } from "primereact/growl";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  markets: any;
  maxCount: any;
  activePage: number;
  expandedRows?: any;

  selectBox: any;
  searchText: any;
  findText: any;
}

export class SetMaketLocks extends Component<any, State> {
  [x: string]: any;
  adminService = new AdminService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      markets: [],
      maxCount: 1,
      activePage: 1,
      searchText: "",
      findText: "",

      selectBox: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "이름", value: "name" },
        { label: "필터", value: "filter" },
      ],
    };
  }

  componentDidMount() {
    this.handleGetMiniGameSet(1);
  }

  handleGetMiniGameSet = (page: any) => {
    this.adminService.admin_get_market_locks(page).then((data: any) => {
      if (data.status == "success") {
        this.setState({
          markets: data.markets,
          // maxCount: data.maxCount,
          // activePage: page,
        });
      }
    });
  };

  handleEditMarket = (id: any, groups: any, separate: any, nameko: any) => {
    this.adminService
      .admin_update_markets(id, groups, separate, nameko)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
        //        this.setState({ markets: data.markets, maxCount: data.maxCount });
      });
  };

  render() {
    let markets = this.state.markets;

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">마켓 설정 잠그기</div>
                  {/* 
                  <div className="p-md-6">
                    <Button label="저장 하기" onClick={() => {}} />
                  </div> */}

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.selectBox}
                          value={this.state.searchText}
                          onChange={(event) =>
                            this.setState({ searchText: event.value })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(e: any) =>
                            this.setState({ findText: e.target.value })
                          }
                          onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                              this.handleGetMiniGameSet(1);
                            } else {
                            }
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-2">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handleGetMiniGameSet(1);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handleGetMiniGameSet(event);
                  }}
                ></CustomPagination>
                <DataTable
                  value={markets}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column expander={true} style={{ width: "2em" }} />

                  <Column
                    style={{ textAlign: "center" }}
                    header="스포츠 타입"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.sportId = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={rowData.sportId}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="마켓 번호"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.marketId = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={rowData.marketId}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="최대 값"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.min = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={JSON.stringify(rowData.min)}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="최소 값"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              let market = markets.find(
                                (s) => s._id == rowData._id
                              );
                              if (market != null) {
                                market.max = even.target.value;
                                this.setState({ markets: markets });
                              }
                            }}
                            value={JSON.stringify(rowData.max)}
                          />
                        </div>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="저장"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <Button
                            className="p-button-raised p-button-warning"
                            label="저장하기 "
                            onClick={() => {
                              this.handleEditMarket(
                                rowData.id,
                                rowData.groups,
                                rowData.separate,
                                rowData.name.ko
                              );
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handleGetMiniGameSet(event);
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
