import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { confirmAlert } from "react-confirm-alert";

import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { BalanceService } from "../../service/balance.service";
import { CustomPagination } from "../custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  converBalanceType,
  GetToday,
  convertSimpleDate,
  uuidv4,
  ConvertCalendar
} from "../../utility/help";
import { WindowScrollController } from "@fullcalendar/core";

interface Props {
  id: string;
}

interface State {
  id: string;
  balances: any[];
  page: number;
  pages: any;
  field: string;
  activePage: number;
  maxCount: number;
  selectDay: string;
  selectType: string;
  giveType: string;
  giveBalance: string;
  giveRolling: string;
  giveText: string;

  begin: any;
  end: any;
  total: any;
  uuid: string
}

export class UserBalance extends Component<Props, State> {
  BalanceService = new BalanceService();

  constructor(props: any) {
    super(props);
    let today = GetToday();
    this.state = {
      uuid: uuidv4(),
      id: "",
      balances: [],
      page: 1,
      pages: {},
      field: "",
      activePage: 1,
      maxCount: 1,
      selectDay: "all",
      selectType: "all",
      giveType: "deposit",
      giveBalance: "0",
      giveRolling: "0",
      giveText: "",
      begin: today.begin,
      end: today.end,

      total: [
        {
          withdraw: 0,
          deposit: 0,
        },
      ],
    };

    // this.headerDepositAndWithdraw = this.headerDepositAndWithdraw.bind(this);
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {
    let id = this.props.id;
    this.setState({ id: id });
    this.headerDepositAndWithdraw(
      id,
      0,
      "all",
      this.state.begin.toLocaleString('sv-SE'),
      this.state.end.toLocaleString('sv-SE')
    );
  };

  handlePageChange = (page: any) => { };
  headerDepositAndWithdraw = (
    id: string,
    page: any,
    selectType: any,
    begin: any,
    end: any
  ) => {
    this.BalanceService.get_usre_deposit_and_withdraw(
      id,
      page,
      selectType,
      begin.toString(),
      end.toString()
    ).then((data) => {
      if (data.status == "success") {
        this.setState({
          balances: data.balances,
          id: id,
          page: page,
          total: data.total,
        });
      }
    });
  };

  headerGiveBanace = (type: any, balance: any, rolling: any, text: any) => {
    let id = this.props.id;
    if (process.env.REACT_APP_API_ONEBALANCE === 'Y') {
      this.BalanceService.admin_give_balance_one_balance(
        this.state.uuid,
        id,
        type,
        balance,
        rolling,
        text
      ).then((data) => {
        let button: Array<{
          label: string;
          onClick: () => void;
        }> = [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ];
        if (data.status == "success") {
          confirmAlert({
            title: "유저 디테일",
            message: "관리자 지급/회수/쿠폰이 정산처리되었습니다",
            buttons: button,
          });
        } else if (data.status == "main_balance") {
          confirmAlert({
            title: "유저 디테일",
            message: "매인 벨런스가 부족합니다",
            buttons: button,
          });
        } else {
          confirmAlert({
            title: "유저 디테일",
            message: "정상 처리되지 않음. 관리자에게 문의 부탁드림니다",
            buttons: button,
          });
        }
      });
    } else {
      this.BalanceService.admin_give_balance(
        this.state.uuid,
        id,
        type,
        balance,
        rolling,
        text
      ).then((data) => {
        let button: Array<{
          label: string;
          onClick: () => void;
        }> = [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ];
        if (data.status == "success") {
          confirmAlert({
            title: "유저 디테일",
            message: "관리자 지급/회수/쿠폰이 정산처리되었습니다",
            buttons: button,
          });
          // this.setState({});
        } else {
          confirmAlert({
            title: "유저 디테일",
            message: "정상 처리되지 않음. 관리자에게 문의 부탁드림니다",
            buttons: button,
          });
        }
      });
    }

  };

  render = () => {
    const balances = this.state.balances;

    return (
      <div>
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-12 p-md-3">
              <div className="p-grid">
                <div className="p-col-4">
                  <RadioButton
                    value="deposit"
                    inputId="rb1"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "deposit"}
                  />
                  <label htmlFor="rb1" className="p-radiobutton-label">
                    지급
                  </label>
                </div>
                <div className="p-col-4">
                  <RadioButton
                    value="withdraw"
                    inputId="rb2"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "withdraw"}
                  />
                  <label htmlFor="rb2" className="p-radiobutton-label">
                    회수
                  </label>
                </div>
                <div className="p-col-4">
                  <RadioButton
                    value="point"
                    inputId="rb3"
                    onChange={(event) =>
                      this.setState({ giveType: event.value })
                    }
                    checked={this.state.giveType === "point"}
                  />
                  <label htmlFor="rb3" className="p-radiobutton-label">
                    포인트
                  </label>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-2">
              <span className="md-inputfield">
                <InputText
                  className="p-error"
                  placeholder="지급금액"
                  onChange={(event: any) => {
                    this.setState({ giveBalance: event.target.value });
                  }}
                />
              </span>
            </div>{" "}
            <div className="p-col-12 p-md-1">
              <span className="md-inputfield">
                <InputText
                  className="p-error"
                  placeholder="롤링"
                  onChange={(event: any) => {
                    this.setState({ giveRolling: event.target.value });
                  }}
                />
              </span>
            </div>
            <div className="p-col-12 p-md-4">
              <span className="md-inputfield">
                <InputText
                  className="p-error"
                  placeholder="내용"
                  onChange={(event: any) => {
                    this.setState({ giveText: event.target.value });
                  }}
                />
              </span>
            </div>
            <div className="p-col-12 p-md-2">
              <Button
                label={"확 인"}
                onClick={() => {
                  this.headerGiveBanace(
                    this.state.giveType,
                    this.state.giveBalance,
                    this.state.giveRolling,
                    this.state.giveText
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-8">
              <SelectButton
                className={"p-buttonset-5"}
                options={[
                  { label: "전체", value: "all" },
                  { label: "충전", value: "deposit" },
                  { label: "환전", value: "withdraw" },
                  { label: "운영자+-", value: "admin" },
                  { label: "쿠폰", value: "coupon" },
                ]}
                value={this.state.selectType}
                onChange={(event) => {
                  this.setState({ selectType: event.value });

                  console.log(this.state.selectType, event.value);
                  this.headerDepositAndWithdraw(
                    this.state.id,
                    0,
                    event.value,
                    this.state.begin,
                    this.state.end
                  );
                }}
              />
            </div>
          </div>

          <div className="p-col-12">
            <div className="p-grid">
              <div className="p-col-4">
                <Calendar
                  placeholder="Begin"
                  showIcon={true}
                  dateFormat="yy-mm-dd"
                  value={this.state.begin}
                  onChange={(e) => this.setState({ begin: e.value })}
                  showWeek={false}
                  locale={ConvertCalendar()}

                />
                <Calendar
                  placeholder="End"
                  showIcon={true}
                  dateFormat="yy-mm-dd"
                  value={this.state.end}
                  onChange={(e) => this.setState({ end: e.value })}
                  showWeek={false}
                  locale={ConvertCalendar()}

                />
                <Button
                  label={"확 인"}
                  onClick={() => {
                    console.log(this.state.begin, this.state.end);
                    this.headerDepositAndWithdraw(
                      this.state.id,
                      0,
                      this.state.selectType,
                      this.state.begin,
                      this.state.end
                    );
                  }}
                />
              </div>

              <div className="p-col-4">
                <SelectButton
                  className={"p-buttonset-4"}
                  options={[
                    { label: "전체", value: "all" },
                    { label: "오늘", value: "today" },
                    { label: "최근일주일", value: "week" },
                    { label: "최근한달", value: "month" },
                  ]}
                  value={this.state.selectDay}
                  onChange={(event) => {
                    this.setState({ selectDay: event.value });

                    let today = GetToday();

                    if (event.value === "all") {
                      today.begin.setDate(today.begin.getDate() - 900);
                    } else if (event.value === "week") {
                      today.begin.setDate(today.begin.getDate() - 7);
                    } else if (event.value === "month") {
                      today.begin.setDate(today.begin.getDate() - 30);
                    }

                    this.setState({ begin: today.begin, end: today.end });

                    this.headerDepositAndWithdraw(
                      this.state.id,
                      0,
                      this.state.selectType,
                      today.begin,
                      today.end
                    );
                  }}
                />
              </div>
              <div className="p-col-4">
                <DataTable
                  style={{ padding: "0px" }}
                  value={this.state.total}
                  paginatorPosition="both"
                  rows={20}
                >
                  <Column
                    style={{ textAlign: "center", padding: "0px" }}
                    header={<Message severity="error" text="충전" />}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <span>{numTomoeny(rowData.deposit)}</span>
                        </div>
                      );
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", padding: "0px" }}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <span>{numTomoeny(rowData.withdraw)}</span>
                        </div>
                      );
                    }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12">
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>

          <DataTable
            value={balances}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
          >
            {/* <Column
              style={{ textAlign: "center" }}
              header="입금/출금	"
              body={(rowData: any, column: any) => {
                return rowData.type;
              }}
            /> */}
            <Column
              style={{ textAlign: "center" }}
              header="입금/출금	"
              body={(rowData: any, column: any) => {
                return numTomoeny(rowData.balance);
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header="보너스	"
              body={(rowData: any, column: any) => {
                return numTomoeny(rowData.bonus);
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="롤링"
              body={(rowData: any, column: any) => {
                return numTomoeny(rowData.rolling);
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"요청시간"}
              body={(rowData: any, column: any) => {
                return <div>{convertDate(rowData.setdate)}</div>;
              }}
              field="depositdate"
            />

            <Column
              style={{ textAlign: "center" }}
              header={"상태"}
              body={(rowData: any, column: any) => {
                return <div>{converBalanceStatus(rowData.status)}</div>;
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header={"타입"}
              body={(rowData: any, column: any) => {
                return <div>{converBalanceType(rowData.type)}</div>;
              }}
            />
          </DataTable>
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>
        </div>
      </div>
    );
  };
}
