import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
// import { Spinner } from "primereact/spinner";

import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

// import { MatchDetail } from "./match-detail";

//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  ConvertSpecifiersGame,
  ConverBTC
} from "../../utility/help";
import { config } from "@fullcalendar/core";

interface Props {
  mode: any;
  game: any;
  // handleOpenDetail: any;
  handleSelectWinGame: (id: string, edit: string) => any;
  // handleUpdateOdds: any;
}

interface State {
  active: number;
  deposits: any[];
  page: number;
  pages: any;
  field: string;
  details: any[][];
  detailsBet: any;
}

export class FxTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      details: [],
      page: 0,
      pages: {},
      field: "",
      detailsBet: {},
    };
  }

  componentDidMount = () => { };

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };
  handlePlusOddsChange = (e: any) => {
    console.log(e);
  };

  handlePageChange = (e: any) => {
    console.log(e);
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const game = this.props.game;
    const details = this.state.details;
    const matchId = game.matchId;

    // const handleOpenDetail = this.props.handleOpenDetail;
    // const handleSelectWinGame = this.props.handleSelectWinGame;

    let customHeaderStyle = { textAlign: "center" };

    // if (mode == "end") {
    //   customHeaderStyle = { backgroundColor: "#cecece", textAlign: "center" };
    // }

    const RenderMode = () => {
      if (mode == "end") {
        return (
          <Column
            style={{ textAlign: "center" }}
            header={"결과"}
            body={(rowData: any, column: any) => {
              return <div>{rowData.result === 'buy' ? 'UP' : 'DOWN'}</div>;
            }}
          />
        );
      }

      return <></>;
    };

    return (
      <div>
        <br />
        <DataTable
          value={game}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
        >
          <Column
            style={{ textAlign: "center" }}
            header="단위/분"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {ConverBTC(rowData.currency)} ({rowData.minute})
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="시작시간"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.beginDate)}</div>;
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="종료"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.endDate)}</div>;
            }}
          />
          {/* <Column
            style={{ textAlign: "center" }}
            field="win"
            header="게임단위"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{rowData.minute}</div>;
            }}
          />*/}
          <Column
            style={{ textAlign: "center" }}
            header="상태"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return <div>{rowData.status}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="open"
            body={(rowData: any, column: any) => {
              return <div>{rowData.open}</div>;
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="close"
            body={(rowData: any, column: any) => {
              return <div>{rowData.close}</div>;
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="bid"
            body={(rowData: any, column: any) => {
              return <div>{rowData.bid}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="차"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              let pid =
                (rowData.open == null ? 0 : rowData.open * rowData.correction) -
                (rowData.bid == null ? 0 : rowData.bid * rowData.correction);
              return (
                <div>
                  {pid}
                  {`(${rowData.point})`}
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="매수"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {numTomoeny(
                    rowData.buyBalance == null ? 0 : rowData.buyBalance
                  )}
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="매도"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {numTomoeny(
                    rowData.sellBalance == null ? 0 : rowData.sellBalance
                  )}
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="edit"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              if (rowData.edit === 'sell') {
                return '매도'
              }
              if (rowData.edit === 'buy') {
                return '매수'
              }


              return (
                <div>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="매수"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <Button
                  label={"매수"}

                  className="p-button-danger flat-btn"
                  onClick={(event) => {

                    console.log(rowData._id)
                    this.props.handleSelectWinGame(rowData._id, 'buy')

                  }}
                ></Button>
              );
            }}


          />
          <Column
            style={{ textAlign: "center" }}
            header="매도"
            bodyStyle={customHeaderStyle}
            body={(rowData: any, column: any) => {
              return (
                <Button
                  className="flat-btn"
                  label={"매도"}

                  onClick={(event) => {
                    console.log(rowData._id)
                    this.props.handleSelectWinGame(rowData._id, 'sell')

                  }}
                ></Button>
              );
            }}


          />


          {RenderMode()}
        </DataTable>
      </div>
    );
  };
}
