import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  setting: any[];
  benefits?: any;
  expandedRows: any;
}

export class LevelMini extends Component<any, State> {
  adminService = new AdminService();

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      setting: [],
      expandedRows: null,
    };

    this.handleGetMiniGameSet = this.handleGetMiniGameSet.bind(this);
    this.handleMiniGameSave = this.handleMiniGameSave.bind(this);
    this.handelChangeTime = this.handelChangeTime.bind(this);
    this.handelChangeDetail = this.handelChangeDetail.bind(this);
  }

  componentDidMount() {
    this.handleGetMiniGameSet();
  }

  handelChangeTime = (categoryId: any, time: any) => {
    let setting = this.state.setting.find((s) => s.categoryId == categoryId);
    if (setting != undefined) {
      setting.stopTime = time;
      this.setState({ setting: this.state.setting });
    }
  };

  handelChangeDetail = (
    categoryId: string,
    detailId: string,
    outcomeId: string,
    key: string,
    value: any
  ) => {
    let setting = this.state.setting.find((s) => s.categoryId == categoryId);
    if (setting != undefined) {
      if (setting.markets[detailId] != undefined) {
        let outCome = setting.markets[detailId].outcomes.find(
          (s: any) => s.id == outcomeId
        );

        if (outCome != undefined) {
          outCome[key] = value;
          this.setState({ setting: this.state.setting });
        }
      }
    }
  };

  handleGetMiniGameSet = () => {
    this.adminService.admin_get_mini_games_setting().then((data: any) => {
      this.setState({ setting: data.setting });
    });
  };

  handleMiniGameSave = () => {
    this.adminService
      .admin_save_mini_games_setting(this.state.setting)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "미니게임 배당",
            message: "미니게임 배당정보가 변경되었습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "미니게임 배당",
            message: "미니게임 배당정보가 변경되었습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    let setting = this.state.setting;

    const RenderOuntcome = (
      categoryId: string,
      marketId: string,
      outcomeOdds: any
    ) => {
      return (
        <Column
          style={{ textAlign: "center", width: "240px" }}
          body={(rowData: any, column: any) => {
            return (
              <div className="p-grid">
                <div className="p-col-12 p-md-3">{rowData.id}</div>
                <div className="p-col-12 p-md-3">
                  <InputText
                    placeholder="stop Time"
                    value={rowData.name}
                    onChange={(even: any) => {
                      this.handelChangeDetail(
                        categoryId,
                        marketId,
                        rowData.id,
                        "name",
                        even.target.value
                      );
                    }}
                  />
                </div>
                <div className="p-col-12 p-md-3">
                  <InputText
                    placeholder="stop Time"
                    value={rowData.odds}
                    onChange={(even: any) => {
                      this.handelChangeDetail(
                        categoryId,
                        marketId,
                        rowData.id,
                        "odds",
                        even.target.value
                      );
                    }}
                  />
                </div>
                <div className="p-col-12 p-md-3">
                  <InputText
                    placeholder="stop Time"
                    value={rowData.order}
                    onChange={(even: any) => {
                      this.handelChangeDetail(
                        categoryId,
                        marketId,
                        rowData.id,
                        "order",
                        even.target.value
                      );
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
      );
    };

    const rowExpansionTemplate = (data: any): any => {
      if (data.markets == undefined) {
        return <div></div>;
      }

      let markets = Object.values(data.markets);

      return markets.map((contact: any, i: any) => {
        return (
          <DataTable
            value={contact.outcomes}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            className="success"
            header={contact.name}
          >
            {RenderOuntcome(data.categoryId, contact.id, contact.outcomes)}
          </DataTable>
        );
      });
    };

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">미니게임 설정</div>

                  <div className="p-md-6">
                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleMiniGameSave();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                <DataTable
                  // value={setting}
                  // paginatorPosition="both"
                  // rows={20}
                  // sortOrder={-1}
                  // rowExpansionTemplate={rowExpansionTemplate}
                  // expandedRows={this.state.expandedRows}
                  // onRowToggle={(e: any) =>
                  //   this.setState({ expandedRows: e.data })
                  // }

                  value={setting}
                  paginatorPosition="both"
                  rows={100}
                  sortOrder={-1}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={this.state.expandedRows}
                  onRowToggle={(e: any) => {
                    this.setState({ expandedRows: e.data });
                  }}
                  scrollable={true}
                >
                  <Column expander={true} style={{ width: "2em" }} />
                  <Column
                    style={{ textAlign: "center", width: "240px" }}
                    header="categoryId"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return <div>{rowData.categoryId} </div>;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", width: "240px" }}
                    header="게임타입"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return <div>{rowData.categoryName}</div>;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "240px" }}
                    header="배팅 종료초"
                    headerStyle={customHeaderStyle}
                    body={(rowData: any, column: any) => {
                      return (
                        <div>
                          <InputText
                            placeholder="stop Time"
                            onChange={(even: any) => {
                              this.handelChangeTime(
                                rowData.categoryId,
                                even.target.value
                              );
                            }}
                            value={rowData.stopTime}
                          />
                        </div>
                      );
                    }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
