import React, { Component } from "react";

import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Menu } from "primereact/menu";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Password } from "primereact/password";
import { InputMask } from "primereact/inputmask";
// // import { Spinner } from "primereact/spinner";
import { RadioButton } from "primereact/radiobutton";
import { Slider } from "primereact/slider";
import { MultiSelect } from "primereact/multiselect";
import { ListBox } from "primereact/listbox";

import { confirmAlert } from "react-confirm-alert";

import { AlertYesNo } from "../share/alert-yes-no";
import { AgentService } from "../service/agent.service";
import { AdminService } from "../service/admin.service";

import {
  numTomoeny,
  convertDate,
  convertAgentLogText,
  convertAgentBalanceText,
  OpenUserDetail,
  OpenAgentDetail,
} from "../utility/help";

interface Prop {
}

interface NewAgent {
  parentId: string;
  agent: string;
  nick: string;
  pass: string;
  phone: string;
  bankname: string;
  banknum: string;
  bankowner: string;

  items: NewAgent[];
}

interface AgentState {
  newAgent: any;
  topAgents: any;
  management: any;
}

export class Agent extends Component<Prop, AgentState> {
  agentService: AgentService = new AgentService()
  adminService: any = new AdminService();

  constructor(props: any) {

    // management={this.state.management}
    super(props);

    this.state = {
      newAgent: {},
      topAgents: [],
      management: {},
    };
    console.log('constructor');

  }

  async componentWillMount() {
    console.log('handleGetTopAgent')
    const data = await this.adminService.admin_get_management()
    if (data.status == "success") {
      this.setState({ management: data.management });
    }

    await this.handleGetTopAgent();
  }


  handleMakeBenefit = async () => {

    const benefit = await this.agentService.admin_make_agnet_benefit()

  }



  handleUpdateTree = async () => {

    const benefit = await this.agentService.admin_agnet_tree_update()

  }

  handleGetAgentTree = (agent_tree: any, level: any) => {
    this.agentService.admin_get_down_agent(agent_tree, level).then((s) => {
      if (s.status == "success") {
        let topAgents = this.state.topAgents;
        let agentTree = s.agent_tree.split("|");
        for (let index in agentTree) {
          let tree = agentTree[index];

          if (tree != "" && Number(index) == 0) {
            topAgents = topAgents.find((s: any) => s.agent == tree);
          }
          if (tree != "" && Number(index) > 0) {
            topAgents = topAgents.items.find((s: any) => s.agent == tree);
          }
        }

        for (let element of s.downAgents) {
          if (element.level > 4) {
            continue
          }

          element.label = element.agent + " (" + element.nick + ")";
          element.icon = "exposure-plus-1";

          element.items = [
            {
              label: element.level == 2 ? '총판 추가' : (element.level == 3 ? '대리점 추가' : '매장'),

              id: "new",
              parentId: element.agent,
              level: element.level,
              isAdd: true,

              icon: "pi-md-plus",
              command: (event: any) => {

                if (element.level >= 4) {
                  return
                }

                this.setState({ newAgent: { parentId: event.item.parentId, level: event.item.level, isAdd: true, } });
              },
            },
          ];
          element.command = (event: any) => {
            this.setState({ newAgent: element });

            this.handleGetAgentTree(element.agent_tree, element.level);
          };

          let item = topAgents.items.find((s: any) => s.agent == element.agent);
          if (item == undefined) {
            topAgents.items.push(element);
          }
        }

        this.setState({ topAgents: this.state.topAgents });
      }
    });
  };

  handleGetTopAgent = async () => {
    const data = await this.agentService.admin_get_top_agent(this.state.newAgent).then((s => s))
    if (data.status == "success") {
      for (let element of data.topAgents) {
        if (element.level >= 5) {
          continue
        }

        element.label = element.agent + " (" + element.nick + ")";
        element.icon = "exposure-plus-1";

        element.items = [
          {
            label: "부본사 추가",
            id: "new",
            parentId: element.agent,
            level: element.level,
            isAdd: true,

            icon: "pi-md-plus",
            command: (event: any) => {
              this.setState({ newAgent: { parentId: event.item.parentId, level: event.item.level, isAdd: true } });
            },
          },
        ];
        element.command = (event: any) => {
          this.setState({ newAgent: element });

          this.handleGetAgentTree(element.agent_tree, element.level);
        };
      }

      this.setState({ topAgents: data.topAgents });
    }
  };


  handleEditAgent = () => {

    if (this.state.newAgent == null || this.state.newAgent.agent == null) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 정보를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }


    if (this.state.newAgent.agent.length <= 3) {
      confirmAlert({
        title: "에이전트",
        message: "아이디는 4자리 부터 사용가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }

    const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    if (regex.test(this.state.newAgent.agent)) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 아이디에는 한글을 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }


    if (process.env.REACT_APP_API_NONE_PASSWORD !== 'Y') {

      var pattern1 = /[0-9]/; // 숫자
      var pattern2 = /[a-zA-Z]/;// 문자
      var pattern3 = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      if (!pattern1.test(this.state.newAgent.pass) || !pattern2.test(this.state.newAgent.pass) || !pattern3.test(this.state.newAgent.pass)) {
        confirmAlert({
          title: "에이전트",
          message: "비밀번호는 영문, 숫자, 특수문자로 구성하여야 합니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },

          ],
        });
        return;
      }
    }


    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
    this.state.newAgent.agent = this.state.newAgent.agent.replace(regExp, "");


    this.agentService.admin_edit_agent(this.state.newAgent).then((s) => {
      if (s.status == "success") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트  수정 에 성공하셨습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });


      } else if (s.status == "casino_percent") {
        confirmAlert({
          title: "에이전트",
          message: "카지노 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "slot_percent") {
        confirmAlert({
          title: "에이전트",
          message: "슬롯 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "mini_percent") {
        confirmAlert({
          title: "에이전트",
          message: "미니게임 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "slot_losing_percent") {
        confirmAlert({
          title: "에이전트",
          message: "슬롯 루리징 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "casino_losing_percent") {
        confirmAlert({
          title: "에이전트",
          message: "카지노 루징 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "agent") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트 아이디를 입력해주세요 또는 최소 입력은 4자리 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "already") {
        confirmAlert({
          title: "에이전트",
          message: "해당 아이디는 사용할수 없는 아이디입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "pass") {
        confirmAlert({
          title: "에이전트",
          message: "비밀번호를 입력해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "percent") {
        confirmAlert({
          title: "에이전트",
          message: "프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else {

        confirmAlert({
          title: "에이전트",
          message: "관리자에게 문의 부탁드림니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => { },
            },
          ],
        });
      }
    });
  };


  handleMakeTopAgent = () => {

    if (this.state.newAgent == null || this.state.newAgent.agent == null) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 정보를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }


    if (this.state.newAgent.agent.length <= 3) {
      confirmAlert({
        title: "에이전트",
        message: "아이디는 4자리 부터 사용가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }

    const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    if (regex.test(this.state.newAgent.agent)) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 아이디에는 한글을 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }


    if (process.env.REACT_APP_API_NONE_PASSWORD !== 'Y') {

      var pattern1 = /[0-9]/; // 숫자
      var pattern2 = /[a-zA-Z]/;// 문자
      var pattern3 = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
      if (!pattern1.test(this.state.newAgent.pass) || !pattern2.test(this.state.newAgent.pass) || !pattern3.test(this.state.newAgent.pass)) {
        confirmAlert({
          title: "에이전트",
          message: "비밀번호는 영문, 숫자, 특수문자로 구성하여야 합니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },

          ],
        });
        return;
      }
    }


    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
    this.state.newAgent.agent = this.state.newAgent.agent.replace(regExp, "");


    this.agentService.admin_make_agent(this.state.newAgent).then((s) => {
      if (s.status == "success") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트 생성 / 수정 에 성공하셨습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });


      } else if (s.status == "casino_percent") {
        confirmAlert({
          title: "에이전트",
          message: "카지노 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "slot_percent") {
        confirmAlert({
          title: "에이전트",
          message: "슬롯 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "mini_percent") {
        confirmAlert({
          title: "에이전트",
          message: "미니게임 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "slot_losing_percent") {
        confirmAlert({
          title: "에이전트",
          message: "슬롯 루리징 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "casino_losing_percent") {
        confirmAlert({
          title: "에이전트",
          message: "카지노 루징 프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "agent") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트 아이디를 입력해주세요 또는 최소 입력은 4자리 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "already") {
        confirmAlert({
          title: "에이전트",
          message: "해당 아이디는 사용할수 없는 아이디입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "pass") {
        confirmAlert({
          title: "에이전트",
          message: "비밀번호를 입력해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else if (s.status == "percent") {
        confirmAlert({
          title: "에이전트",
          message: "프로테이지를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },
          ],
        });
      } else {

        confirmAlert({
          title: "에이전트",
          message: "관리자에게 문의 부탁드림니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => { },
            },
          ],
        });
      }
    });
  };

  render() {
    let newAgent = this.state.newAgent;


    // if (this.props.management === null) {
    //   return <></>
    // }

    const RenderDetail = () => {
      if (newAgent.agent) {
        return (
          <Button
            className="p-button-raised p-button-warning"
            label="세부정보"
            onClick={() => {
              OpenAgentDetail(newAgent.agent)
            }}
          />
        );
      }
    };
    const RenderTitle = () => {


      if (newAgent.isAdd == true) {


        if (newAgent.level == 1) {
          return (
            <div className="card-title">{newAgent.parentId} 아래 부본사 추가</div>
          );
        }
        if (newAgent.level == 2) {
          return (
            <div className="card-title">{newAgent.parentId} 아래 총판 추가 </div>
          );
        } else if (newAgent.level == 3) {
          return (
            <div className="card-title">{newAgent.parentId} 아래 대리점 추가</div>
          );
        }
        else if (newAgent.level >= 4) {
          return (
            <div className="card-title">-</div>
          );
        }


        return (
          <div className="card-title">{newAgent.parentId} {newAgent.level}본사 추가</div>
        );

      }
      else {

        if (newAgent.level == 1 || newAgent.level == null) {
          return (
            <div className="card-title p-button-text">{newAgent.parentId} 본사</div>
          );
        }
        if (newAgent.level == 2) {
          return (
            <div className="card-title p-button-text">{newAgent.parentId} 부본사</div>
          );
        } else if (newAgent.level == 3) {
          return (
            <div className="card-title">{newAgent.parentId} 총판</div>
          );
        }

        return (
          <div className="card-title">{newAgent.parentId} 매장 </div>
        );
      }
    };

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="p-grid form-group">
                  <div className="p-col-12 p-md-6">
                    <AlertYesNo
                      type="success"
                      className="p-button-success"
                      btnLabel="최상위 에이전트 생성"
                      title="최상위 에이전트"
                      message="에이전트를 생성하시겠습니까 ?"
                      call={() => {
                        this.handleMakeTopAgent();
                      }}
                    ></AlertYesNo>
                  </div>

                  <div className="p-col-12 p-md-3">
                  </div>

                  <div className="p-col-12 p-md-4">
                    <div className="p-grid form-group">
                      <AlertYesNo
                        type="error"
                        className="p-button-error"
                        btnLabel="베네핏 누르지마세요"
                        title="베네핏 생성"
                        message="누르지마세요 "
                        call={() => {
                          this.handleMakeBenefit();
                        }}
                      ></AlertYesNo>
                      <AlertYesNo
                        type="error"
                        className="p-button-error"
                        btnLabel="업데이트 누르지마세요"
                        title="업데이트 생성"
                        message="누르지마세요 "
                        call={() => {
                          this.handleUpdateTree();
                        }}
                      ></AlertYesNo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card card-w-title">
                <div className="content-section implementation">
                  <PanelMenu
                    model={this.state.topAgents}
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-8">
              {
                <div className="card card-w-title">
                  {RenderTitle()}
                  <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">아이디</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        placeholder="아이디"
                        value={newAgent.agent || ""}
                        style={{ width: "320px" }}
                        onChange={(even: any) => {
                          newAgent.agent = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">닉네임</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        placeholder="닉네임"
                        style={{ width: "320px" }}
                        value={newAgent.nick || ""}
                        onChange={(even: any) => {
                          newAgent.nick = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">비밀번호</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        style={{ width: "320px" }}
                        placeholder="비밀번호"
                        value={newAgent.pass || ""}
                        onChange={(even: any) => {
                          newAgent.pass = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">전화번호</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        style={{ width: "320px" }}
                        placeholder="전화번호"
                        value={newAgent.phone || ""}
                        onChange={(even: any) => {
                          newAgent.phone = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">은행명</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        style={{ width: "320px" }}
                        placeholder="은행명"
                        value={newAgent.bankname || ""}
                        onChange={(even: any) => {
                          newAgent.bankname = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">계좌번호</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        style={{ width: "320px" }}
                        placeholder="계좌번호"
                        value={newAgent.banknum || ""}
                        onChange={(even: any) => {
                          newAgent.banknum = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-2">
                      <label htmlFor="input">입금자명</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                      <InputText
                        id="input"
                        style={{ width: "320px" }}
                        placeholder="입금자명"
                        value={newAgent.bankowner || ""}
                        onChange={(even: any) => {
                          newAgent.bankowner = even.target.value;
                          this.setState({ newAgent: newAgent });
                        }}
                      />
                    </div>
                    {
                      newAgent.status == null &&
                      <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">루징</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="루징"
                            value={newAgent.losing_percent || ""}
                            onChange={(even: any) => {
                              newAgent.losing_percent = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }

                    {
                      newAgent.status == null && this.state.management != null && this.state.management.slot === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">슬롯 롤링</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="슬롯"
                            value={newAgent.slot_percent || ""}
                            onChange={(even: any) => {
                              newAgent.slot_percent = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }
{/* 
                    {
                      newAgent.status == null && this.state.management != null && this.state.management.slotLosing === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">슬롯 루징</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="슬롯 루징"
                            value={newAgent.agent_slot_losing || ""}
                            onChange={(even: any) => {
                              newAgent.agent_slot_losing = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    } */}

                    {
                      newAgent.status == null && this.state.management != null && this.state.management.casino === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">카지노 롤링</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="카지노 롤링"
                            value={newAgent.casino_percent || ""}
                            onChange={(even: any) => {
                              newAgent.casino_percent = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }
                    {
                      newAgent.status == null && this.state.management != null && this.state.management.casinoLosing === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">카지노 루징</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="카지노 루징"
                            value={newAgent.casino_losing_percent || ""}
                            onChange={(even: any) => {
                              newAgent.casino_losing_percent = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }

                    {
                      newAgent.status == null && this.state.management != null && this.state.management.mini === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">파워볼 단폴</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="파워볼 롤링"
                            value={newAgent.mini_nomal || ""}
                            onChange={(even: any) => {
                              newAgent.mini_nomal = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }
                    {
                      newAgent.status == null && this.state.management != null && this.state.management.mini === 'y' && <>
                        <div className="p-col-12 p-md-2">
                          <label htmlFor="input">파워볼 조합</label>
                        </div>
                        <div className="p-col-12 p-md-10">
                          <InputText
                            id="input"
                            style={{ width: "320px" }}
                            placeholder="파워볼 롤링"
                            value={newAgent.mini_double || ""}
                            onChange={(even: any) => {
                              newAgent.mini_double = even.target.value;
                              this.setState({ newAgent: newAgent });
                            }}
                          />
                        </div>
                      </>
                    }
                    <div className="p-col-12 p-md-2"></div>
                    <div className="p-col-12 p-md-10">
                      <div className="p-inputgroup">
                        <div className="p-col-12 p-md-4">
                          <Button
                            label="저장 하기"
                            icon="pi-md-launch"
                            onClick={() => newAgent.idx == null ? this.handleMakeTopAgent() : this.handleEditAgent()}
                          />
                        </div>
                        <div className="p-col-12 p-md-4">
                          {RenderDetail()}
                          {/* <Button /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
    //
  }
  send_message_server() {
    throw new Error("Method not implemented.");
  }
}
