import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";
// import { RadioButton } from "primereact/radiobutton";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";
import { ListBox } from "primereact/listbox";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

import { BundlelSteps } from "../share/setbundle/bundlel-steps";

interface State {
  bundles: any[];
}

export class SetBundleBet extends Component<any, State> {
  adminService: AdminService;
  growl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      bundles: []
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetBundle();
  }

  //   handlePageChange = (page: any, searchText: any, findText: any, mode: any) => {
  //     this.setState({ activePage: page });
  //     this.handleGetTournaments(page, searchText, findText, mode);
  //   };

  handleDeleteBundle = id => {
    this.adminService.admin_delete_bet_bundle(id).then((data: any) => {
      if (data.status == "success") {
        window.location.reload();
      }
    });
  };

  handleGetBundle = () => {
    this.adminService.admin_get_bundle_list().then((data: any) => {
      if (data.status == "success") {
        this.setState({
          bundles: data.bundles
        });
      }
    });
  };

  handleSaveBundle = (save: any) => {
    this.adminService.admin_save_bundle(save).then((data: any) => {
      if (data.status == "success") {
        confirmAlert({
          title: "묶음배팅",
          message: "묶음 배팅금지에 등록이 되었습니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetBundle();
                //window.location.reload();
              }
            }
          ]
        });
      } else if (data.status == "already") {
        confirmAlert({
          title: "묶음배팅",
          message: "이미 등록된 설정이 있습니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              }
            }
          ]
        });
      } else {
        confirmAlert({
          title: "묶음배팅",
          message: "관리자에게 문의 부탁드림니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              }
            }
          ]
        });
      }
    });
  };

  render() {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <BundlelSteps handleSaveBundle={this.handleSaveBundle} />
                {/* <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-3">
                      <Dropdown
                        value={this.state.sel_sport}
                        options={this.state.sports}
                        onChange={e => {
                          let chose = this.state.allmarkets.filter(s => {
                            return (
                              s.sports != null &&
                              s.sports[`${e.value.sportId}`] == 1
                            );
                          });

                          let sort = chose.sort(function(a, b) {
                            var nameA = a.name.ko.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.name.ko.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }

                            return 0;
                          });

                          this.setState({
                            chosemarkets: sort,
                            sel_sport: e.value
                          });
                          // chosemarkets.
                        }}
                        optionLabel="en"
                      />
                      <label htmlFor="input">스포츠</label>
                    </div>
                    <div className="p-col-12 p-md-3">
                      <Dropdown
                        value={this.state.sel_markets1}
                        options={this.state.chosemarkets}
                        optionLabel="name.ko"
                        onChange={e => {
                          this.setState({
                            sel_markets1: e.value
                          });
                        }}
                      />
                      <label htmlFor="input">게임 타입</label>
                    </div>

                    <div className="p-col-12 p-md-3">
                      <Dropdown
                        value={this.state.sel_markets1}
                        options={this.state.chosemarkets}
                        optionLabel="name.ko"
                        onChange={e => {
                          this.setState({
                            sel_markets1: e.value
                          });
                        }}
                      />
                      <label htmlFor="input">게임 타입</label>
                    </div>

                    <div className="p-col-12 p-md-4">
                      <Dropdown
                        value={this.state.sel_markets2}
                        options={this.state.chosemarkets}
                        optionLabel="name.ko"
                        onChange={e => {
                          this.setState({
                            sel_markets2: e.value
                          });
                        }}
                      />

                      <Dropdown
                        value={this.state.sel_markets1}
                        options={this.state.chosemarkets}
                        optionLabel="name.ko"
                        onChange={e => {
                          this.setState({
                            sel_markets1: e.value
                          });
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-6">
                      <span className="md-inputfield">
                        {/* <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={event => {
                            this.setState({ selectViewType: event.value });

                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              event.value
                            );
                          }}
                        /> */}
                      </span>
                    </div>

                    {/* <div className="p-md-6">
                      <Button
                        label="카테고리 업데이트"
                        onClick={() => {
                          this.handleUpdateGame();
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <CustomPagination
                active={this.state.activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={this.handlePageChange}
              ></CustomPagination> */}

              <DataTable
                value={this.state.bundles}
                paginatorPosition="both"
                rows={200}
                sortOrder={-1}
                scrollable={true}
                style={{ width: "1600px" }}
              >
                <Column
                  style={{ textAlign: "center" }}
                  header="스포츠 종류"
                  body={(rowData: any, column: any) => {
                    return rowData.sportName;
                  }}
                />

                <Column
                  style={{ textAlign: "center" }}
                  header="게임 타입 1"
                  body={(rowData: any, column: any) => {
                    return rowData.marketName1;
                  }}
                />

                <Column
                  style={{ textAlign: "center" }}
                  header="종류 1"
                  body={(rowData: any, column: any) => {
                    return rowData.teamId1;
                  }}
                />

                <Column
                  style={{ textAlign: "center" }}
                  header="게임 타입 2"
                  body={(rowData: any, column: any) => {
                    return rowData.marketName2;
                  }}
                  field="depositdate"
                />

                <Column
                  style={{ textAlign: "center" }}
                  header="종류 2"
                  body={(rowData: any, column: any) => {
                    return rowData.teamId2;
                  }}
                  field="depositdate"
                />

                <Column
                  style={{ textAlign: "center" }}
                  header="삭제"
                  body={(rowData: any, column: any) => {
                    return (
                      <Button
                        label="삭제"
                        className="p-button-danger"
                        onClick={() => {
                          confirmAlert({
                            title: "묶음배팅",
                            message: "묶음배팅 설정을 삭제하시겠습니까 ?",
                            buttons: [
                              {
                                label: "확인",
                                onClick: () => {
                                  this.handleDeleteBundle(rowData._id);
                                }
                              },
                              {
                                label: "취소",
                                onClick: () => {}
                              }
                            ]
                          });
                        }}
                      />
                    );
                  }}
                  field="depositdate"
                />
              </DataTable>
              {/* <CustomPagination
                active={this.state.activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={this.handlePageChange}
              ></CustomPagination> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
