import React, { Component } from "react";
import { Steps } from "primereact/steps";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";

import { AdminService } from "../../service/admin.service";

interface Props {
  handleSaveBundle: (save: any) => any;
}

interface State {
  stepText: any[];
  sports: any[];
  allmarkets: any[];
  chosemarkets: any[];
  sel_sport: any;
  sel_markets1: any;
  sel_markets2: any;
  sel_team1: any;
  sel_team2: any;

  activeIndex: number;
}

export class BundlelSteps extends Component<Props, State> {
  adminService: AdminService;

  growl: any;
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      sports: [],
      allmarkets: [],
      chosemarkets: [],

      sel_sport: "",
      sel_markets1: {},
      sel_markets2: {},
      sel_team1: {},
      sel_team2: {},
      stepText: [
        {
          title: "스포츠 설정",
          text: "묶음 배팅을 금지할 스포츠를 설정해주세요"
        },
        {
          title: "게임 종류 설정1",
          text: "묶음  배팅을 금지할 첫번쨰 게임을 선택해주세요"
        },
        {
          title: "팀 종류1",
          text:
            "묶음  배팅을 금지할 종류를 선택해주세요 / 미선택시  종류로 자동설정"
        },
        {
          title: "게임 종류 설정2",
          text: "묶음  배팅을 금지할 첫번쨰 게임을 선택해주세요"
        },
        {
          title: "팀 종류2",
          text:
            "묶음  배팅을 금지할 종류를 선택해주세요 / 미선택시  종류로 자동설정"
        },
        {
          title: "저장 하기",
          text: "서버에 데이터 전송"
        }
      ]
    };
    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetSport();
  }

  handleGetSport = () => {
    this.adminService.admin_get_bundle_base().then((data: any) => {
      if (data.status == "success") {
        console.log(data.sports);
        for (let market of data.markets) {
          market.teamName = market.name.ko + " / " + market.id;
        }
        this.setState({
          sports: data.sports,
          allmarkets: data.markets
        });
      }
    });
  };

  render() {
    const items = [
      {
        label: "스포츠 설정",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "First Step",
            detail: event.item.label
          });
        }
      },
      {
        label: "게임 종류 설정1",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "Seat Selection",
            detail: event.item.label
          });
        }
      },
      {
        label: "게임 팀1",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "Pay with CC",
            detail: event.item.label
          });
        }
      },
      {
        label: "게임 종류 설정2",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "Last Step",
            detail: event.item.label
          });
        }
      },
      {
        label: "게임 팀2",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "Last Step",
            detail: event.item.label
          });
        }
      },
      {
        label: "저장",
        command: event => {
          this.growl.show({
            severity: "info",
            summary: "Last Step",
            detail: event.item.label
          });
        }
      }
    ];

    const RenderStepText = () => {
      let step = this.state.stepText[this.state.activeIndex];

      return (
        <div className="content-section introduction">
          <div className="feature-intro">
            <h1>{step.title}</h1>
            <p>{step.text}</p>
          </div>
        </div>
      );
    };

    const carTemplate = option => {
      if (!option.value) {
        return option.label;
      } else {
        // var logoPath =
        //   "showcase/resources/demo/images/car/" + option.label + ".png";

        return (
          <div className="p-clearfix">
            <img
              alt={option.label}
              // src={logoPath}
              style={{
                display: "inline-block",
                margin: "5px 0 0 5px",
                color: "#red"
              }}
              width="24"
            />
            <span
              style={{
                float: "right",
                margin: ".5em .25em 0 0",
                color: "#red"
              }}
            >
              {option.label}
            </span>
          </div>
        );
      }
    };

    const RenderStepViewer = () => {
      console.log(this.state.activeIndex);
      if (this.state.activeIndex == 0) {
        return (
          <div className="grid">
            <label htmlFor="input">스포츠 선택</label>

            <Dropdown
              value={this.state.sel_sport}
              options={this.state.sports}
              optionLabel="en"
              onChange={e => {
                let chose = this.state.allmarkets.filter(s => {
                  return (
                    s.sports != null && s.sports[`${e.value.sportId}`] == 1
                  );
                });

                let sort = chose.sort(function (a, b) {
                  var nameA = a.name.ko.toUpperCase(); // ignore upper and lowercase
                  var nameB = b.name.ko.toUpperCase(); // ignore upper and lowercase
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }

                  return 0;
                });

                this.setState({
                  chosemarkets: sort,
                  sel_sport: e.value
                });
              }}
            />
          </div>
        );
      } else if (this.state.activeIndex == 1) {
        return (
          <div className="grid">
            <label htmlFor="input">게임타입 선택</label>

            <Dropdown
              value={this.state.sel_markets1}
              options={this.state.chosemarkets}
              optionLabel="teamName"
              filterBy="name.ko"
              filter={true}
              // showClear={true}
              // itemTemplate={carTemplate}
              onChange={e => {
                this.setState({
                  sel_markets1: e.value
                });
              }}
            />
          </div>
        );
      } else if (this.state.activeIndex == 2) {
        if (this.state.sel_markets1.outcomes == null) {
          return (
            <div className="grid">
              <label htmlFor="input">설정할수 있는 값이 없습니다</label>
            </div>
          );
        }

        let outcomes: any = Object.values(this.state.sel_markets1.outcomes);
        for (let key in outcomes) {
          outcomes[key].id = key;
        }
        return (
          <div className="grid">
            <label htmlFor="input">배팅 타입 선택 {"      "}</label>

            <Dropdown
              value={this.state.sel_team1}
              options={outcomes}
              optionLabel="ko"
              onChange={e => {
                this.setState({
                  sel_team1: e.value
                });
              }}
            />
          </div>
        );
      } else if (this.state.activeIndex == 3) {
        return (
          <div className="grid">
            <label htmlFor="input">게임타입 선택</label>

            <Dropdown
              value={this.state.sel_markets2}
              options={this.state.chosemarkets}
              optionLabel="teamName"
              filterBy="name.ko"
              filter={true}
              onChange={e => {
                this.setState({
                  sel_markets2: e.value
                });
              }}
            />
          </div>
        );
      } else if (this.state.activeIndex == 4) {
        if (this.state.sel_markets1.outcomes == null) {
          return (
            <div className="grid">
              <label htmlFor="input">설정할수 있는 값이 없습니다</label>
            </div>
          );
        }

        let outcomes: any = Object.values(this.state.sel_markets1.outcomes);
        for (let key in outcomes) {
          outcomes[key].id = key;
        }

        return (
          <div className="grid">
            <label htmlFor="input">배팅 타입 선택 </label>

            <Dropdown
              value={this.state.sel_team2}
              options={outcomes}
              optionLabel="ko"
              onChange={e => {
                this.setState({
                  sel_team2: e.value
                });
              }}
            />
          </div>
        );
      } else if (this.state.activeIndex == 5) {
        return (
          <Button
            label="저장하기"
            onClick={() => {
              // sel_sport: "",
              // sel_markets1: {},
              // sel_markets2: {},
              // sel_team1: {},
              // sel_team2: {},
              let save = {
                sportId: this.state.sel_sport.sportId,
                sportName: this.state.sel_sport.en,
                marketId1: this.state.sel_markets1.id,
                marketId2: this.state.sel_markets2.id,
                marketName1: this.state.sel_markets1.name.ko,
                marketName2: this.state.sel_markets2.name.ko,
                teamId1: this.state.sel_team1.id,
                teamId2: this.state.sel_team2.id,
                teamName1: this.state.sel_team1.ko,
                teamName2: this.state.sel_team2.ko
              };

              console.log(save);
              this.props.handleSaveBundle(save);

              //this.handleUpdateGame();
            }}
          />
        );
      }

      return <div></div>;
    };

    const RenderStepBtn = () => {
      if (this.state.activeIndex == 5) {
        return <div></div>;
      }

      return (
        <Button
          label="계속"
          onClick={() => {
            //this.handleUpdateGame();

            console.log(Object.values(this.state.sel_sport));
            console.log(Object.values(this.state.sel_sport).length);
            if (
              this.state.activeIndex == 0 &&
              Object.values(this.state.sel_sport).length == 0
            ) {
              this.growl.show({
                severity: "스포츠 종목",
                summary: "스포츠 종목을 선택후 다음으로 넘어갈수 있습니다."
              });

              return;
            }

            if (
              this.state.activeIndex == 1 &&
              Object.values(this.state.sel_markets1).length == 0
            ) {
              this.growl.show({
                severity: "게임 종목",
                summary: "게임 종목을 선택후 다음으로 넘어갈수 있습니다."
              });

              return;
            }

            if (
              this.state.activeIndex == 3 &&
              Object.values(this.state.sel_markets2).length == 0
            ) {
              this.growl.show({
                severity: "게임 종목",
                summary: "게임 종목을 선택후 다음으로 넘어갈수 있습니다."
              });

              return;
            }

            if (
              this.state.activeIndex == 3 &&
              this.state.sel_markets1.id == this.state.sel_markets2.id
            ) {
              this.growl.show({
                severity: "게임 종목",
                summary: "같은 게임종목을 선택할수 없습니다 ."
              });

              return;
            }

            let index = this.state.activeIndex + 1;
            console.log(this.state.activeIndex);
            this.setState({ activeIndex: index });
            //onSelect={e => this.setState({ activeIndex: e.index })}
          }}
        />
      );
    };
    return (
      <div>
        <div className="content-section implementation">
          <Growl
            ref={el => {
              this.growl = el;
            }}
          ></Growl>
          {/* {RenderStepText()} */}
          {/* <Steps
            model={items}
            activeIndex={this.state.activeIndex}
            //readOnly={false}
          /> */}
          <div className="p-col-12 ">
            <div className="p-grid form-group">
              <div className="p-col-12 p-md-3">
                <label htmlFor="input">스포츠 선택</label>

                <ListBox
                  value={this.state.sel_sport}
                  options={this.state.sports}
                  optionLabel="en"
                  listStyle={{ maxHeight: "300px" }}
                  // onChange={e => this.setState({ city: e.value })}

                  onChange={e => {
                    let chose = this.state.allmarkets.filter(s => {
                      return (
                        s.sports != null && s.sports[`${e.value.sportId}`] == 1
                      );
                    });

                    let sort = chose.sort(function (a, b) {
                      var nameA = a.name.ko.toUpperCase(); // ignore upper and lowercase
                      var nameB = b.name.ko.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                    });

                    this.setState({
                      chosemarkets: sort,
                      sel_sport: e.value
                    });
                  }}
                />
              </div>
              <div className="p-col-12 p-md-3">
                <label htmlFor="input">게임타입 선택</label>
                <ListBox
                  filter={true}
                  value={this.state.sel_markets1}
                  options={this.state.chosemarkets}
                  optionLabel="teamName"
                  style={{ width: "300px" }}
                  listStyle={{ maxHeight: "300px" }}
                  // showClear={true}
                  // itemTemplate={carTemplate}
                  onChange={e => {
                    this.setState({
                      sel_markets1: e.value
                    });
                  }}
                />
              </div>

              <div className="p-col-12 p-md-3">
                <label htmlFor="input">게임타입 선택</label>
                <ListBox
                  filter={true}
                  value={this.state.sel_markets2}
                  options={this.state.chosemarkets}
                  optionLabel="teamName"
                  style={{ width: "300px" }}
                  listStyle={{ maxHeight: "300px" }}
                  // showClear={true}
                  // itemTemplate={carTemplate}
                  onChange={e => {
                    this.setState({
                      sel_markets2: e.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="grid">
            <label htmlFor="input">배팅 타입 선택 {"      "}</label>

            <Dropdown
              value={this.state.sel_team1}
              options={outcomes}
              optionLabel="ko"
              onChange={e => {
                this.setState({
                  sel_team2: e.value
                });
              }}
            />
          </div> */}

          {/* 
          <div className="grid">
            <label htmlFor="input">배팅 타입 선택 </label>

            <Dropdown
              value={this.state.sel_team2}
              options={outcomes}
              optionLabel="ko"
              onChange={e => {
                this.setState({
                  sel_team2: e.value
                });
              }}
            />
          </div>
  */}
          <Button
            label="저장하기"
            onClick={() => {
              // sel_sport: "",
              // sel_markets1: {},
              // sel_markets2: {},
              // sel_team1: {},
              // sel_team2: {},
              let save = {
                sportId: this.state.sel_sport.sportId,
                sportName: this.state.sel_sport.en,
                marketId1: this.state.sel_markets1.id,
                marketId2: this.state.sel_markets2.id,
                marketName1: this.state.sel_markets1.name.ko,
                marketName2: this.state.sel_markets2.name.ko,
                teamId1: this.state.sel_team1.id,
                teamId2: this.state.sel_team2.id,
                teamName1: this.state.sel_team1.ko,
                teamName2: this.state.sel_team2.ko
              };

              console.log(save);
              this.props.handleSaveBundle(save);

              //this.handleUpdateGame();
            }}
          />
        </div>

        {/* <div className="p-col-12 p-md-5">{RenderStepViewer()}</div> */}

        {/* <div className="p-col-12 p-md-1">{RenderStepBtn()}</div> */}
      </div>
    );
  }
}
