import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { BetService } from "../service/bet.service";

import { ProgressLiveTable } from "../share/progress/progress-live-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday, ConvertCalendar } from "../utility/help";

interface Props { }

interface State {
  begin: Date;
  end: Date;
  userTableValue: any[];
  details: any;

  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  cities: any[];
  selectViewType: string;
  viewType: any[];
  summary?: any;


  balanceType: any[],
  selectBalanceType: string,

}

export class EndLive extends Component<Props, State> {
  betService: BetService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      cities: [
        { label: "선택", value: null },
        { label: "종목", value: "sportName" },
        { label: "팀명", value: "team" },
        { label: "리그명", value: "tournamentName" },
      ],

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],
      balanceType: [
        { label: "미배팅", value: "dateOfMatchTimeStamp" },
        { label: "배팅중", value: "preBetBalance" },
      ],
      selectBalanceType: "dateOfMatchTimeStamp",
    };

    this.betService = new BetService();

    this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);

    this.get_end_live_game(0, this.state.selectViewType);
  }

  componentDidMount() { }

  get_end_live_game = (page: any, mode: any) => {
    this.betService
      .get_end_live_game(
        page,
        this.state.begin,
        this.state.end,
        this.state.findText,
        this.state.searchText,
        mode
      )
      .then((data) => {

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  get_match_live_details = (matchId: string) => {
    this.betService.get_live_details(matchId).then((data) => {
      if (data.status == "success") {
        let matchID = data.liveId;

        this.state.details[matchID] = data.live;

        this.setState({
          details: this.state.details,
        });
      }
    });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_end_live_game(page, "end");
  };

  // handleOpenDetail = (matchId: any) => {
  //   console.log(matchId);
  //   this.get_match_live_details(matchId);
  // };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      return userTableValue.map((contact, i) => {
        return (
          <ProgressLiveTable
            mode={"end"}
            game={contact}
            key={contact._id}
            selectBalanceType={contact.selectBalanceType}

            // handleOpenDetail={this.handleOpenDetail}
            handleSelectWinGame={() => { }}
            handleUpdateOdds={() => { }}
          //details={this.state.details[contact._id]}
          ></ProgressLiveTable>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title" style={{ padding: "0px" }}>
                <div className="p-col-12 ">
                  <div className="card-title">종료된 라이브 게임</div>

                  <div className="p-md-11">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          showWeek={false}
                          placeholder="Begin"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.begin}
                          onChange={(e) =>
                            this.setState({ begin: e.value as Date })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          showWeek={false}
                          placeholder="End"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.end}
                          onChange={(e) =>
                            this.setState({ end: e.value as Date })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Dropdown
                          style={{ width: "100px" }}
                          options={this.state.cities}
                          value={this.state.findText}
                          onChange={(event) =>
                            this.setState({ findText: event.value })
                          }
                          autoWidth={true}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <span className="md-inputfield">
                          <InputText
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value,
                              });
                            }}
                          />
                        </span>
                      </div>
                      <div className="p-col-12 p-md-2">
                        <span className="md-inputfield">
                          <Button
                            label="검색"
                            onClick={() => {
                              this.handlePageChange(0);
                            }}
                          />
                        </span>
                      </div>

                      <div className="p-col-12 p-md-2">
                        <DataTable
                          style={{ padding: "0px" }}
                          value={this.state.summary}
                          paginatorPosition="both"
                          rows={20}
                        >
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="error" text="BET" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalBet)}</span>
                                </div>
                              );
                            }}
                          />
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="info" text="WIN" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalWin)}</span>
                                </div>
                              );
                            }}
                          />
                        </DataTable>
                      </div>
                    </div>
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-6">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.setState({
                              selectViewType: event.value,
                            });
                            this.get_end_live_game(0, event.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <CustomPagination
                active={activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={this.handlePageChange}
              ></CustomPagination>

              {RenderBet()}

              <CustomPagination
                active={activePage}
                take={20}
                count={this.state.maxCount}
                handlePageChange={this.handlePageChange}
              ></CustomPagination>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
