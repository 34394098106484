import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";

// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { CommunityService } from "../service/community.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  note: any[];
  notice: any;
  activePage: number;
  maxCount: number;
}

export class NotePopup extends Component<any, State> {
  communityService = new CommunityService();

  constructor(props: any) {
    super(props);

    this.state = {
      note: [],
      activePage: 1,
      maxCount: 1,
      notice: {
        posX: 0,
        posY: 0,
        width: 300,
        height: 240,

        mode: "user",

        del: "n",
        contents: "",
        title: "",
        titleColor: "",
        userId: "",
      },
    };

    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleUserNote(1);
  }

  handleUserNote = (skip: any) => {
    this.setState({ activePage: skip });
    this.communityService.admin_get_notice_popup(skip).then((data: any) => {
      if (data.status == "success") {
        this.setState({ note: data.note });
      }
    });
  };

  handleWirteNote = () => {
    this.communityService
      .admin_wirte_notice_popup(this.state.notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  handleUpdateNote = (notice) => {
    this.communityService
      .admin_wirte_notice_popup(notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  handleDelNote = (notice) => {
    this.communityService
      .admin_del_notice_popup(notice)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 삭제하기.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 삭제하기 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    let items = this.state.note;

    
      const renderHeader = () => {
        return (
            // <span className="ql-formats">
            //     <button className="ql-bold" aria-label="Bold"></button>
            //     <button className="ql-italic" aria-label="Italic"></button>
            //     <button className="ql-underline" aria-label="Underline"></button>
            // </span>
            <select title="Font Size" className="ql-size">
            <option value="small">small</option>
            <option value="normal">normal</option>
            <option value="large">large</option>
            <option value="huge">huge</option>
         </select>
            );
    }

    const header = renderHeader();

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">팝업 공지</div>
                <div className="p-md-6">
                </div>
              </div>
            </div>

            <div className="p-col-12 p-md-4">
              <div className="card">
                <div className="p-col-12">
                  <span className="p-float-label">

                    <div className="p-inputgroup">
                      <label htmlFor="in">Title</label>

                      <InputText
                        id="in"
                        style={{
                          width: "70%",
                        }}
                        value={this.state.notice.title}
                        onChange={(e: any) => {
                          this.state.notice.title = e.target.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      />


                      <ColorPicker
                        value={this.state.notice.titleColor}
                        onChange={(event: any) => {
                          console.log(event.value);
                          this.state.notice.titleColor = event.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      ></ColorPicker>
                    </div>

                  </span>

                  <br />
                  <div className="p-inputgroup">
                    <span className="p-float-label">

                      <InputText
                        id="in"

                        value={this.state.notice.posX}
                        onChange={(e: any) => {
                          this.state.notice.posX = e.target.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      />
                      <label htmlFor="in">Pos X</label>
                    </span>

                    <span className="p-float-label">
                      <InputText
                        id="in"

                        value={this.state.notice.posY}
                        onChange={(e: any) => {
                          this.state.notice.posY = e.target.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      />

                      <label htmlFor="in">Pos Y</label>
                    </span>
                  </div>

                  <br />

                  <div className="p-inputgroup">
                    <span className="p-float-label">
                      <InputText
                        id="in"
                        value={this.state.notice.width}
                        onChange={(e: any) => {
                          this.state.notice.width = e.target.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      />
                      <label htmlFor="in">Width</label>
                    </span>

                    <span className="p-float-label">
                      <InputText
                        id="in"
                        value={this.state.notice.height}
                        onChange={(e: any) => {
                          this.state.notice.height = e.target.value;
                          this.setState({ notice: this.state.notice });
                        }}
                      />
                      <label htmlFor="in">Height</label>
                    </span>
                  </div>



                </div>


                <br />
                <div className="p-col-12">

                  <span className="p-float-label">

                    <InputText
                      id="in"

                      style={{
                        width: "100%",
                      }}

                      value={this.state.notice.domain}
                      onChange={(e: any) => {
                        this.state.notice.domain = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">도메인</label>
                  </span>


                </div>
                <div className="p-col-12">
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.notice.contents}
                    onTextChange={(e) => {
                      this.state.notice.contents = e.htmlValue;

                      this.setState({ notice: this.state.notice });
                    }}
                  />
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <RadioButton
                      value="y"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;

                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "y"}
                    />
                    <label htmlFor="cb1" className="p-checkbox-label">
                      숨김
                    </label>
                    {"      "}
                    <RadioButton
                      value="n"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.notice.del = event.value;
                        this.setState({ notice: this.state.notice });
                      }}
                      checked={this.state.notice.del === "n"}
                    />

                    <label htmlFor="cb2" className="p-checkbox-label">
                      보기
                    </label>
                  </div>
                </div>
                <div className="p-col-12">
                  <Button
                    label="저장 하기"
                    onClick={() => {
                      this.handleWirteNote();
                    }}
                  />


                </div>
              </div>
            </div>


            {

              items.map(ss => (

                <div className="p-col-12 p-md-4">
                  <div className="card">
                    <div className="p-col-12">
                      <span className="p-float-label">

                        <div className="p-inputgroup">

                          <InputText
                            id="in"
                            style={{ width: "70%" }}
                            value={ss.title}
                            onChange={(e: any) => {


                              const item = items.find(kk => kk.idx == ss.idx)

                              if (item != null) {
                                item.title = e.target.value;

                                this.setState({ notice: items });
                              }
                            }}
                          />


                          <ColorPicker
                            value={ss.titleColor}
                            onChange={(event: any) => {

                              const item = items.find(kk => kk.idx == ss.idx)
                              if (item != null) {
                                ss.titleColor = event.value;

                                this.setState({ notice: items });
                              }

                            }}
                          ></ColorPicker>
                        </div>

                      </span>

                      <br />
                      <div className="p-inputgroup">
                        <span className="p-float-label">

                          <InputText
                            id="in"

                            value={ss.posX}
                            onChange={(e: any) => {

                              const item = items.find(kk => kk.idx == ss.idx)
                              if (item != null) {
                                ss.posX = e.target.value;

                                this.setState({ notice: items });
                              }

                            }}
                          />
                          <label htmlFor="in">Pos X</label>
                        </span>

                        <span className="p-float-label">
                          <InputText
                            id="in"

                            value={ss.posY}
                            onChange={(e: any) => {

                              const item = items.find(kk => kk.idx == ss.idx)
                              if (item != null) {
                                ss.posY = e.target.value;

                                this.setState({ notice: items });
                              }


                            }}
                          />

                          <label htmlFor="in">Pos Y</label>
                        </span>
                      </div>

                      <br />
                      <div className="p-inputgroup">
                        <span className="p-float-label">
                          <InputText
                            id="in"
                            value={ss.width}
                            onChange={(e: any) => {

                              const item = items.find(kk => kk.idx == ss.idx)
                              if (item != null) {
                                ss.width = e.target.value;

                                this.setState({ notice: items });
                              }


                            }}
                          />
                          <label htmlFor="in">Width</label>
                        </span>

                        <span className="p-float-label">
                          <InputText
                            id="in"
                            value={ss.height}
                            onChange={(e: any) => {

                              const item = items.find(kk => kk.idx == ss.idx)
                              if (item != null) {
                                ss.height = e.target.value;

                                this.setState({ notice: items });
                              }

                            }}
                          />
                          <label htmlFor="in">Height</label>
                        </span>
                      </div>


                    </div>


                    <br />
                    <div className="p-col-12">

                      <span className="p-float-label">

                        <InputText
                          id="in"

                          style={{
                            width: "100%",
                          }}

                          value={ss.domain}
                          onChange={(e: any) => {
                            const item = items.find(kk => kk.idx == ss.idx)
                            if (item != null) {
                              ss.domain = e.target.value;

                              this.setState({ notice: items });
                            }
                          }}
                        />
                        <label htmlFor="in">도메인</label>
                      </span>


                    </div>
                    <div className="p-col-12">
                      <Editor
                        // headerTemplate={header}
                        style={{ height: "320px" }}
                        value={ss.contents}
                        onTextChange={(e) => {

                          const item = items.find(kk => kk.idx == ss.idx)
                          if (item != null) {
                            ss.contents = e.htmlValue;

                            this.setState({ notice: items });
                          }


                        }}
                      />
                    </div>
                    <div className="p-col-12">
                      <div className="p-col-12 p-md-4">
                        <RadioButton
                          value="y"
                          inputId="rb1"
                          onChange={(event) => {
                            const item = items.find(kk => kk.idx == ss.idx)
                            if (item != null) {
                              ss.del = event.value;

                              this.setState({ notice: items });
                            }

                          }}
                          checked={ss.del === "y"}
                        />
                        <label htmlFor="cb1" className="p-checkbox-label">
                          숨김
                        </label>
                        {"      "}
                        <RadioButton
                          value="n"
                          inputId="rb1"
                          onChange={(event) => {
                            const item = items.find(kk => kk.idx == ss.idx)
                            if (item != null) {
                              ss.del = event.value;

                              this.setState({ notice: items });
                            }

                          }}
                          checked={ss.del === "n"}
                        />

                        <label htmlFor="cb2" className="p-checkbox-label">
                          보기
                        </label>
                      </div>
                    </div>
                    <div className="p-col-12">
                      <Button
                        label="저장 하기"
                        onClick={() => {
                          const item = items.find(kk => kk.idx == ss.idx)
                          if (item != null) {
                            this.handleUpdateNote(item);
                          }
                        }}
                      />

                      <Button
                        label="삭제 하기"
                        onClick={() => {
                          const item = items.find(kk => kk.idx == ss.idx)
                          if (item != null) {
                            this.handleDelNote(item);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

              ))



            }


          </div>
        </div>
      </div>
    );
  }
}
