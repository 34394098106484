import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return <div className="layout-footer">
            <div className="p-grid">
                <div className="p-col">
                    <img src="assets/layout/images/logo-white.svg" alt="sapphire-layout" />
                    <div className="layout-footer-appname">PrimeReact Premium Template</div>

                    {process.env.REACT_APP_API_ONEBALANCE === 'Y' && <div className="layout-footer-appname">One BALANCE Version</div>}
                    {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY' && <div className="layout-footer-appname">CALCULATE REAL ROLLING Version</div>}
                    {process.env.REACT_APP_API_CALCULATE_WIN_LOSE === 'Y' && <div className="layout-footer-appname">CALCULATE WIN LOSE Version</div>}
                    {process.env.REACT_APP_API_CALCULATE_TOTAL_BANLACE === 'Y' && <div className="layout-footer-appname">CALCULATE TOATAL BALANCE Version</div>}

                    {<div className="layout-footer-appname">{process.env.REACT_APP_API_CALCULATE_REAL_ROLLING}</div>}

                </div>
                <div className="p-col p-col-align-right">
                    <span className="pi-md-copyright"></span>
                    <span>All Rights Reserved</span>
                </div>
            </div>
        </div>
    }
}