import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { confirmAlert } from "react-confirm-alert";

//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';
import { BetService } from "../../service/bet.service";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  OpenUserDetail,
} from "../../utility/help";
import { AnyTxtRecord } from "dns";

interface MatchProps {
  mode: any;
  details: any;
  bet: any;
  key: any;

  // details: any;
}

export class BetMatchTable extends Component<MatchProps> {
  betService: BetService = new BetService();

  constructor(props: MatchProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      details: [],

      page: 0,
      field: "",
    };
  }

  componentDidMount = () => { };

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    this.setState({ active: e });
  };

  hadleMatchBet = (betId) => {
    confirmAlert({
      title: "배팅취소",
      message: "배팅 취소한 게임은 복원할수가 없습니다.",
      buttons: [
        {
          label: "확인",
          onClick: () => {


            this.betService.admin_cancel_match_bet(betId).then((data) => {

              if (data.status == "success") {
                confirmAlert({
                  title: "배팅",
                  message: "배팅취소 성공.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    },
                  ],
                });
              } else if (data.status == "alrady") {
                confirmAlert({
                  title: "배팅",
                  message: "이미 배팅취소된 게임입니다.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    },
                  ],
                });
              } else if (data.status == "alrady") {
                confirmAlert({
                  title: "배팅",
                  message: "이미 종료된 게임이거나 경기가 시작된 게임은 취소 할수 없습니다.",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        window.location.reload();
                      },
                    },
                  ],
                });
              }

            })

          },
        },
        {
          label: "취소",
          onClick: () => {
          }
        }
      ],
    });

    return;
  }

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bet == undefined ? [] : [this.props.bet];
    const details = this.props.details == undefined ? [] : this.props.details;
    const tdetails = Object.values(details);

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };
    /*if (bets.balance >= 300000) {
      //customHeaderStyle["backgroundColor"] = "oringe";
      customHeaderStyle = { backgroundColor: "red", textAlign: "center" };
    }*/

    return (
      <div>
        <DataTable
          value={tdetails}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
          style={{ marginRight: "0px" }}
        >
          <Column
            style={{ textAlign: "center", width: "120px" }}
            // style={{ textAlign: "center", width: "10%" }}
            header="게임시간"
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.dateOfMatch)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "60px" }}
            // style={{ textAlign: "center", width: "120px" }}
            field="bet"
            header="스포츠"
            body={(rowData: any, column: any) => {
              return <div>{rowData.sportName}</div>;
            }}
          />
          <Column
            // style={{ textAlign: "center", width: "120px" }}
            style={{ textAlign: "center", width: "120px" }}
            field="win"
            header="리그"
            body={(rowData: any, column: any) => {
              return <div>{rowData.tournamentName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="홈"
            body={(rowData: any, column: any) => {
              return <div>{rowData.homeName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "220px" }}
            header="원정"
            body={(rowData: any, column: any) => {
              return <div>{rowData.awayName}</div>;
            }}
          />

          <Column
            // style={{ textAlign: "center", width: "120px" }}
            style={{ textAlign: "center", width: "100px" }}
            header="게임 타입"
            body={(rowData: any, column: any) => {
              return <div>{rowData.outComeName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="배팅타입"
            body={(rowData: any, column: any) => {
              return <div>{rowData.oddsName}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "60px" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="기준"
            body={(rowData: any, column: any) => {
              return <div>{convertSpecifiers(rowData.specifiers)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "60px" }}
            // style={{ textAlign: "center", width: "150px" }}
            header="배당"
            body={(rowData: any, column: any) => {
              return <div>{rowData.odds}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "80px" }}
            // style={{ textAlign: "center", width: "150px" }}
            header="결과"
            body={(rowData: any, column: any) => {
              return <div>{converBetStatus(rowData.status)}</div>;
            }}
          />
        </DataTable>

        <DataTable
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          value={bets}
          className="success"
          scrollable={true}
          style={{ marginRight: "0px" }}
        >
          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="배팅일"
            body={(rowData: any, column: any) => {
              return <div>{convertDate(rowData.regDate)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            field="id"
            header="ID"
            body={(rowData: any, column: any) => {
              return (
                <div
                  style={{ color: rowData.color }}
                  onClick={() => {
                    OpenUserDetail(rowData.id);
                  }}
                >
                  {rowData.id}
                  <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            field="agent"
            header="총판"
          />


          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="배팅금"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.balance)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="예상당점금"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {numTomoeny(rowData.beforeWinBalance)} ({" "}
                  {numTomoeny(rowData.afterWinBalance)} )
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="상태"
            body={(rowData: any, column: any) => {
              return <div>
                {converBetStatus(rowData.status)}

              </div>;
            }}
          />


          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="아이피"
            field="ip"
          />

          <Column
            style={{ textAlign: "center", width: "150px" }}
            headerStyle={customHeaderStyle}
            bodyStyle={customHeaderStyle}
            header="취소하기"
            body={(rowData: any, column: any) => {
              return <div>

                <Button
                  label="취소"
                  className="p-button-warning"
                  onClick={() => {
                    this.hadleMatchBet(rowData._id)

                  }}
                />
              </div>;
            }}
          />
        </DataTable>
      </div>
    );
  };
}
