import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import { SubPaging } from "../share/sub-paging";

import { OpenAgentDetail, convertDate } from "../utility/help";

interface AgentState {
    domains: any;
    maxCount: number;
    activePage: number;
    dropdownItem: string;
    seach: string;
    newCode: any;
    newDomain: any;
}

export class UserDomain extends Component<any, AgentState> {
    AgentService: AgentService;
    constructor(props: any) {
        super(props);

        this.state = {
            domains: [],
            maxCount: 1,
            activePage: 1,

            dropdownItem: "",
            seach: "",

            newCode: "",
            newDomain: "",


        };

        //this.handleAgentBalance = this.handleAgentBalance.bind(this);

        this.AgentService = new AgentService();
    }

    componentDidMount() {
        this.handleAgentCode(0);
    }

    MoveSel = (page: any) => {
        this.handleAgentCode(page);
    };

    handleAgentCode = (page) => {
        this.AgentService.admin_get_reg_domain(page).then((s: any) => {
            this.setState({
                domains: s.domains,
                activePage: page,
                maxCount: s.maxCount,
            });
        });
    };

    handleEditAgentCode = (obj: any) => {
        this.AgentService.admin_edit_agent_code(obj).then((s: any) => {
            if (s.status == "success") {
                confirmAlert({
                    title: "가입 도메인",
                    message: "가입 도메인를 수정하였습니다. ",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                location.reload();
                            },
                        },
                    ],
                });
            } else {
                confirmAlert({
                    title: "가입 도메인",
                    message: "관리자에게 문의 부탁드림니다. ",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                location.reload();
                            },
                        },
                    ],
                });
            }
        });
    };


    handleDelAgentDomain = (domain: any) => {
        this.AgentService.admin_del_reg_domain(domain).then((s: any) => {
            if (s.status == "success") {
                confirmAlert({
                    title: "가입 도메인",
                    message: "가입 도메인를 삭제하였습니다. ",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                location.reload();
                            },
                        },
                    ],
                });
            } else {
                confirmAlert({
                    title: "가입 도메인",
                    message: "관리자에게 문의 부탁드림니다. ",
                    buttons: [
                        {
                            label: "확인",
                            onClick: () => {
                                location.reload();
                            },
                        },
                    ],
                });
            }
        });
    };


    handleSaveCode = () => {
        let code = this.state.newCode;
        let newDomain = this.state.newDomain;

        if (code == "") {
            confirmAlert({
                title: "가입 도메인",
                message: "가입 도메인 코드를 입력해주세요",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
            return;
        }

        if (newDomain == "") {
            confirmAlert({
                title: "가입 도메인",
                message: "가입 도메인을 입력해주세요",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
            return;
        }


        this.AgentService.admin_add_reg_domain(
            newDomain,
            code).then((s: any) => {
                if (s.status == "success") {
                    confirmAlert({
                        title: "가입 도메인",
                        message: "가입 도메인를 생성하였습니다. ",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    location.reload();
                                },
                            },
                        ],
                    });
                } else if (s.status == "agent") {
                    confirmAlert({
                        title: "가입 도메인",
                        message: "찾을수 없는 에이전트입니다. ",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    location.reload();
                                },
                            },
                        ],
                    });
                } else if (s.status == "code") {
                    confirmAlert({
                        title: "가입 도메인",
                        message: "찾을수 없는 가입코드입니다 가입코드 생성후 등록해주세요. ",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    location.reload();
                                },
                            },
                        ],
                    });
                } else {
                    confirmAlert({
                        title: "가입 도메인",
                        message: "관리자에게 문의 부탁드림니다. ",
                        buttons: [
                            {
                                label: "확인",
                                onClick: () => {
                                    location.reload();
                                },
                            },
                        ],
                    });
                }
                //   this.setState({
                //     codes: s.codes,
                //     maxCount: s.maxCount
                //   });
            });
    };

    render = () => {
        return (
            <div>
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card">
                                <div className="card-title">가입 도매인 등록</div>

                                <div className="p-grid">


                                    <div className="p-col-12 p-md-1">
                                        <span className="md-inputfield">
                                            <InputText
                                                value={this.state.newCode}
                                                onChange={(e: any) =>
                                                    this.setState({ newCode: e.target.value })
                                                }
                                            />
                                            <label>가입 코드</label>
                                        </span>
                                    </div>

                                    <div className="p-col-12 p-md-3">
                                        <span className="md-inputfield">
                                            <InputText
                                                value={this.state.newDomain}
                                                onChange={(e: any) =>
                                                    this.setState({ newDomain: e.target.value })
                                                }
                                            />
                                            <label>도메인</label>
                                        </span>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <span className="md-inputfield">
                                            <Button
                                                label="도메인 등록"
                                                className="p-button-success"
                                                onClick={() => {
                                                    this.handleSaveCode();
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card">
                                <SubPaging
                                    limit={20}
                                    activePage={this.state.activePage}
                                    maxCount={this.state.maxCount}
                                    MoveSel={(page) => {
                                        this.MoveSel(page);
                                    }}
                                />
                                <DataTable
                                    value={this.state.domains}
                                    paginatorPosition="both"
                                    rows={20}
                                    sortOrder={-1}
                                    scrollable={true}
                                >


                                    {/* <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="에이전트"
                                        body={(agent: any) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        OpenAgentDetail(agent.agent);
                                                    }}
                                                >
                                                    {agent.agent}
                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                </div>
                                            );
                                        }}
                                        field="agent"
                                    /> */}


                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="도메인"
                                        // body={(agent: any) => {
                                        //   return agent.code;
                                        // }}
                                        body={(agent: any) => {
                                            return agent.domain;
                                        }}
                                        sortable={true}
                                        field="level"
                                    />

                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="코드"
                                        // body={(agent: any) => {
                                        //   return agent.code;
                                        // }}
                                        body={(agent: any) => {
                                            return agent.code;
                                        }}
                                        sortable={true}
                                        field="level"
                                    />



                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="생성일"
                                        body={(agent: any) => {
                                            return convertDate(agent.regdate);
                                        }}
                                        sortable={true}
                                        field="rolling"
                                    />

                                    <Column
                                        style={{ textAlign: "center", width: "120px" }}
                                        header="삭제"
                                        body={(agent: any) => {
                                            return (
                                                <Button
                                                    label="삭제"
                                                    className="p-button-danger"
                                                    onClick={() => this.handleDelAgentDomain(agent.domain)}
                                                />
                                            );
                                        }}
                                        sortable={true}
                                        field="rolling"
                                    />
                                </DataTable>

                                <SubPaging
                                    limit={20}
                                    activePage={this.state.activePage}
                                    maxCount={this.state.maxCount}
                                    MoveSel={(page) => {
                                        this.MoveSel(page);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
