import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { AgentService } from "../../service/agent.service";
import { AlertYesNo } from "../alert-yes-no";
import { confirmAlert } from "react-confirm-alert";

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  convertAgentBenefitBalanceText,
} from "../../utility/help";

interface Props {
  agentId: any;
}

interface State {
  checkboxValue: any;
  benefits: any[];
}

export class AgentBenefit extends Component<Props, State> {
  agentService: AgentService;
  constructor(props: Props) {
    super(props);
    this.state = {
      benefits: [],
      checkboxValue: [],
    };

    this.agentService = new AgentService();
  }

  componentDidMount() {
    this.agentService
      .admin_get_agent_benefit_list(this.props.agentId)
      .then((benefit: any) => {
        if (benefit.status == "success") {
          this.setState({ benefits: benefit.benefits });
        }
      });
  }

  render() {
    const SaveBalance = (benefit_type: string, percent: number) => {
      confirmAlert({
        title: "에이전트 저장",
        message: "에이전트 루징/롤링을 수정하시겠습니까 ?",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService
                .admin_agent_benefit_edit(
                  this.props.agentId,
                  benefit_type,
                  percent
                )
                .then((data) => {
                  if (data.status == "success") {
                    confirmAlert({
                      title: "확인",
                      message:
                        "에이전트 루징/롤링 프로테이지가 변경되었습니다.",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => { },
                        },
                      ],
                    });
                  } else {
                    confirmAlert({
                      title: "확인",
                      message: "관리자에게 문의 부탁드림니다",
                      buttons: [
                        {
                          label: "확인",
                          onClick: () => {
                            window.location.reload();
                          },
                        },
                      ],
                    });
                  }
                });
            },
          },
          {
            label: "취소",
            onClick: () => { },
          },
        ],
      });
    };

    const RenderBenefit = () => {
      return this.state.benefits.map((contact: any, i: any) => {
        console.log(contact);

        return (
          <div className="p-col-12 p-md-12" key={contact.benefit_type}>
            <div className="p-grid">
              <div className="p-col-12 p-md-2">
                <label htmlFor="input">
                  {convertAgentBenefitBalanceText(contact.benefit_type)}
                </label>
              </div>
              <div className="p-col-12 p-md-4">
                <InputText
                  placeholder="프로테이지"
                  id="pass"
                  value={contact.percent}
                  onChange={(e: any) => {
                    contact.percent = e.target.value;
                    this.setState({ benefits: this.state.benefits });
                  }}
                />
              </div>
              <div className="p-col-12 p-md-1">
                <Button
                  label="수정"
                  onClick={() => {
                    SaveBalance(contact.benefit_type, contact.percent);
                  }}
                />
              </div>
            </div>
          </div>
        );
      });
    };

    return (
      <div>
        <div className="p-grid">
          {RenderBenefit()}
          {/* <div className="p-col-12 p-md-4">
            <label htmlFor="input">메모</label>
          </div>
          <div className="p-col-12 p-md-8">
            <InputTextarea
              id="memo"
              rows={5}
              cols={30}
              autoResize={true}
              field="memo"
            ></InputTextarea>{" "}
          </div> */}
        </div>
      </div>
    );
  }
}
