import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { UserService } from "../service/user.service";

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";

import { Menu } from "primereact/menu";
import { InputText } from "primereact/inputtext";

import { SubPaging } from "../share/sub-paging";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertGameText
} from "../utility/help";
import { CONNREFUSED } from "dns";

interface State {
  maxCount: number;
  blackips: any[];
  dropdownItem: any;

  page: number;
  pages: any;
  search: string;
  ip: string;
  text: string;
}

export class BlockIP extends Component<any, State> {
  userService = new UserService();
  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      blackips: [],
      page: 1,
      pages: {},
      search: "",
      ip: "",
      text: "",
      maxCount: 1,
      dropdownItem: null,

    };
  }

  componentDidMount() {
    this.GetBlockIPList(0);
  }

  GetBlockIPList = (page: number) => {
    this.userService.admin_get_block_ip(page).then(data => {
      this.setState({
        blackips: data.blackips[0],
        page: page,
        maxCount: data.blackips[1]
      });
    });
  };

  AddBlockIP = (ip: string, text: string) => {
    this.userService.admin_set_user_block_ip(ip, text).then(data => {

      window.location.reload();

    });
  }


  RemoveBlockIP = (idx: string) => {
    this.userService.admin_remove_user_block_ip(idx).then(data => {

      window.location.reload();

    });
  }


  MoveSel = (page: any) => {
    this.GetBlockIPList(page);
  };

  render() {
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="p-md-6">
                  <div className="p-col-12 ">
                    <div className="p-grid form-group">

                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <InputText
                            value={this.state.ip}
                            onChange={(e: any) =>
                              this.setState({ ip: e.target.value })
                            }
                          />
                          <label>아이피</label>
                        </span>

                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <InputText
                            value={this.state.text}
                            onChange={(e: any) =>
                              this.setState({ text: e.target.value })
                            }
                          />
                          <label>내용</label>
                        </span>
                      </div>
                      <div className="p-col-12 p-md-4">
                        <span className="md-inputfield">
                          <Button
                            label="저장"
                            onClick={() => {
                              this.AddBlockIP(this.state.ip, this.state.text);
                              //   this.SearchData(
                              //     this.state.dropdownItem,
                              //     this.state.search
                              //   );
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>

              <DataTable

                value={this.state.blackips}
                groupField="ip"
                style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
              >
                <Column

                  field="ip"
                  header="아이피"
                  style={{ textAlign: "center", border: "1px solid #c8c8c8", width: "200px" }}
                  body={rowData => {
                    return (
                      <div>
                        {rowData.ip}
                      </div>
                    );
                  }}
                />

                <Column
                  style={{ textAlign: "center", border: "1px solid #c8c8c8" }}
                  header="내용"
                  body={rowData => {
                    return (
                      <div>
                        {rowData.contents}
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ textAlign: "center", border: "1px solid #c8c8c8", width: "150px" }}
                  header="접속일"
                  body={rowData => {
                    return <div>{convertDate(rowData.regdate)}</div>;
                  }}
                />

                <Column
                  style={{ textAlign: "center", border: "1px solid #c8c8c8", width: "100px" }}
                  header="삭제"
                  body={rowData => {
                    return <Button
                      label={"삭 제"}
                      onClick={() => {
                        this.RemoveBlockIP(rowData.idx)
                      }}
                    />
                  }}
                />

              </DataTable>
              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
