import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  OpenUserDetail,
  OpenAgentDetail,
  ConvertNick
} from "../../utility/help";

interface BalanceProps {
  mode: any;
  balances: any;

  OnEditItem: (par1: any, par2: any, par3: any) => any;
  OnSelectItem: (par: any) => any;
}

interface State {
  bonus: any;

  bonusValue: any;
}

export class BalanceTable extends Component<BalanceProps, State> {
  constructor(props: BalanceProps) {
    super(props);
    this.state = {
      bonusValue: {},
      bonus: [
        { name: "3%", value: 3 },
        { name: "5%", value: 5 },
        { name: "7%", value: 7 },
        { name: "10%", value: 10 },
        { name: "15%", value: 15 },
        { name: "20% ", value: 20 },
      ],
    };
    this.header = this.header.bind(this);
  }

  componentDidMount = () => { };

  header = (up: any, down: any) => {
    return (
      <div>
        <span>{up}</span>
        <br />
        <span>{down}</span>
        <br />
      </div>
    );
  };

  render = () => {
    const mode = this.props.mode;
    const balances = this.props.balances;
    const OnEditItem = this.props.OnEditItem;
    const OnSelectItem = this.props.OnSelectItem;
    const CheckRender = () => {
      if (mode == "deporsitAndwithdraw") {
        return <div style={{ width: "0px" }}></div>;
      }

      return (
        <Column
          style={{ textAlign: "center", width: "3px" }}
          header=""
          body={(rowData: any, column: any) => {
            return (
              <div>
                <Checkbox
                  value="n"
                  inputId={"idx" + rowData.idx}
                  onChange={() => {
                    OnSelectItem(rowData.idx);
                  }}
                  checked={rowData.sel == "y" ? true : false}
                />
                <label htmlFor="cb1" className="p-checkbox-label"></label>
              </div>
            );
          }}
        />
      );
    };
    return (
      <DataTable
        value={balances}
        paginatorPosition="both"
        rows={20}
        sortOrder={-1}
        scrollable={true}
      >
        {CheckRender()}
        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="종류"
          body={(rowData: any, column: any) => {
            if (rowData.type == "deposit") {
              return <Message severity="error" text="충전" />;
            }

            if (rowData.type == "withdraw") {
              return <Message severity="info" text="환전" />;
            }
            if (rowData.type == "admin_deposit") {
              return <Message severity="success" text="지급" />;
            }

            if (rowData.type == "admin_withdraw") {
              return <Message severity="warn" text="회수" />;
            }

            if (rowData.type == "coupon") {
              return <Message severity="success" text="쿠폰" />;
            }

            if (rowData.logType == "bonusfirst" || rowData.logType == "bonussecond") {
              return <Message severity="success" text="보너스" />;
            }

            return "미정의";
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="아이디"
          field="id"
          body={(rowData: any, column: any) => {
            return (
              <div
                style={{ color: rowData.color }}
                onClick={() => {
                  OpenUserDetail(rowData.id);
                }}
              >
                {rowData.id}
                <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          header="닉네임"
          field="id"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: rowData.color }}>
                {rowData.nick}
              </div>
            );
          }}
        />



        <Column
          style={{ textAlign: "center", width: "200px" }}
          header="입금자/출금자"
          field="bankowner"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: rowData.color }}>
                {rowData.bankowner}({rowData.bankname})
                <br />
                {rowData.banknum}
              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "100px" }}
          header="입금/출금"
          body={(rowData: any, column: any) => {
            return (
              <div style={{ color: getDepositColor() }}>
                {numTomoeny(rowData.balance)}
              </div>
            );
          }}
        />
        <Column
          style={{ textAlign: "center", width: "250px" }}
          header={"보너스/롤링"}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <span className="p-col-12 p-md-12">
                  <div className="p-grid form-group">
                    <div className="md-inputfield">
                      <div className="p-grid form-group">

                        <span className="md-inputfield">
                          <InputText
                            style={{ width: "60px" }}
                            id={"bonus"}
                            value={rowData.bonus}
                            onChange={(e: any) => {
                              OnEditItem(rowData.idx, "bonus", e.target.value);
                            }}
                          />
                          <label style={{ left: -50, top: 0 }}>보너스</label>
                        </span>

                        <span className="md-inputfield">
                          <InputText
                            value={rowData.simplememo}
                            onChange={(e: any) => {
                              OnEditItem(rowData.idx, "simplememo", e.target.value);
                            }}
                          />
                          <label>사유</label>
                        </span>
                      </div>

                      <SelectButton
                        style={{ paddingTop: 10 }}
                        optionLabel="name"
                        options={this.state.bonus}
                        onChange={(e) => {
                          let bonus =
                            (Number(rowData.balance) / 100) * Number(e.value);

                          OnEditItem(rowData.idx, "bonus", bonus);
                        }}
                      />
                    </div>


                  </div>
                </span>
                <br />
              </div>
            );
          }}
          field="bet"
        />

        <Column
          style={{ textAlign: "center", width: "120px" }}
          header={this.header("금일충전", "금일환전")}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <span style={{ color: getDepositColor() }}>
                  {numTomoeny(rowData.today_change)}
                </span>
                <br />
                <span style={{ color: getWithdrawColor() }}>
                  {numTomoeny(rowData.today_exchange)}
                </span>
              </div>
            );
          }}
          field="regdate"
        />
        <Column
          style={{ textAlign: "center", width: "140px" }}
          header={this.header("신청날짜", "완료날짜")}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <span>{convertDate(rowData.regdate)}</span>
                <br />
                <span>{convertDate(rowData.setdate)}</span>
              </div>
            );
          }}
        />
        <Column
          style={{ textAlign: "center", width: "200px" }}
          header={this.header("소속", "소속트리")}
          body={(rowData: any, column: any) => {
            return (
              <div>
                <span onClick={() => OpenAgentDetail(rowData.agent)} >
                  <Message severity="success" text={rowData.agent} />
                  <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                </span>
                <br />
                <div className="p-tag">

                  <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree_ko) }} ></span>

                </div>

              </div>
            );
          }}
        />

        <Column
          style={{ textAlign: "center", width: "80px" }}
          header={"상태"}
          body={(rowData: any, column: any) => {
            return converBalanceStatus(rowData.status);
          }}
        />
      </DataTable>
    );
  };
}
