import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetService } from "../service/bet.service";

import { BetMiniTable } from "../share/bet/bet-mini-table";
import { CustomPagination } from "../share/custom-pagination";
import { BetMatchTable } from "../share/bet/bet-match-table";

import { numTomoeny, GetToday } from "../utility/help";

interface MiniProps {
  matchId: any;
  gameId: any;
  specifiers: any;
}

interface MiniState {
  begin: any;
  end: any;
  userTableValue: any;
  details: any;

  findText: any;
  searchText: any;
  page: any;
  maxCount: any;
  cities: any;
}

export class DetailMatchView extends Component<MiniProps, MiniState> {
  betService: BetService;
  constructor(props: MiniProps) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      details: {},

      findText: "",
      searchText: "",
      page: 1,
      maxCount: 1,
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "id" },
        { label: "에이전트", value: "agent" },
      ],
    };

    this.betService = new BetService();

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);
    this.handleGetMiniGameByCategoryIdAndEpisode = this.handleGetMiniGameByCategoryIdAndEpisode.bind(
      this
    );
  }

  componentDidMount() {
    this.handleGetMiniGameByCategoryIdAndEpisode(
      this.props.matchId,
      this.props.gameId,
      this.props.specifiers
    );
  }

  handleGetMiniGameByCategoryIdAndEpisode = (
    matchId: any,
    oddsId: any,
    specifiers: any
  ) => {
    this.betService
      .get_match_game_by_matchId_and_gameId(matchId, oddsId, specifiers)
      .then((data) => {
        for (let match of data.matchs) {
          this.betService
            .get_match_detail_by_betId(match._id)
            .then((detailData) => {
              this.state.details[detailData.betId] = detailData.detail;

              this.setState({
                details: this.state.details,
                maxCount: data.maxCount,
              });
            });
        }

        this.setState({
          userTableValue: data.matchs,
          maxCount: data.maxCount,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    // this.betService.get_progress_live_game(page, null, null, null, null);
  };

  render() {
    let activePage = this.state.page == undefined ? 1 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;


    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      return userTableValue.map((contact: any, i: any) => {
        return (
          <BetMatchTable
            bet={contact}
            key={contact._id}
            details={this.state.details[contact._id]}
            mode={"bet"}
          ></BetMatchTable>
        );
      });
    };

    const RenderInfo = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      let totalBetCount = 0;
      let totalBet = 0;
      let totalWin = 0;
      let totalLose = 0;
      for (let value of userTableValue) {
        totalBet += value.balance;
        if (value.status != "lose") {
          totalLose += value.beforeWinBalance;
        }

        totalWin += value.afterWinBalance;
        totalBetCount += 1;
      }

      // return userTableValue.map((contact: any, i: any) => {
      return (
        <div>
          <DataTable
            value={[1]}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
            scrollable={true}
          >
            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="배팅 인원"
              body={(rowData: any, column: any) => {
                return <div>{numTomoeny(totalBetCount)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "120px" }}
              header="배팅 금액"
              body={(rowData: any, column: any) => {
                return <div>{numTomoeny(totalBet)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "120px" }}
              field="bet"
              header="예상 당첨금"
              body={(rowData: any, column: any) => {
                return <div>{numTomoeny(totalLose)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center", width: "120px" }}
              field="bet"
              header="당첨금"
              body={(rowData: any, column: any) => {
                return <div>{numTomoeny(totalWin)}</div>;
              }}
            />
          </DataTable>
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">스포츠 배팅정보</div>
                <div>
                  <div
                    className="card card-w-title"
                    style={{ padding: "0px" }}
                  ></div>
                </div>

                {RenderInfo()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
