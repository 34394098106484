import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  slots: any[];
  count: number;

  maxCount: number;
  activePage: number;

  selectItem: any;
  select: any;
  searchText: any;
  findText: any;
  selectViewType: string;
  viewType: any[];
}

export class SetSlotMain extends Component<any, State> {
  adminService: AdminService = new AdminService();
  growl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      slots: [],
      count: 0,
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      searchText: "",
      findText: "",

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "카테고리", value: "in" },
      ],

      select: [{ label: "리그", value: "agent" }],
    };

  }

  componentDidMount() {
    this.handleGetSlotGame(1, "", "", "all");
    this.handleGetignoredCount();

  }

  handlePageChange = (page: any, searchText: any, findText: any, mode: any) => {
    console.log(page);
    this.setState({ activePage: page });
    this.handleGetSlotGame(page, searchText, findText, mode);
  };

  handleChangeIgnored = () => {
    confirmAlert({
      title: "배팅",
      message: "배팅 내역의 갯수만큼 제외합니다.",
      buttons: [
        {
          label: "확인'",

          onClick: () => {
            this.adminService.admin_set_ignored_count(this.state.count).then((data: any) => {
              let msg = {
                severity: "success",
                summary: "게임이 ",
                detail: "업데이트 되습니다",
              };
              this.growl.show(msg);
            });
          },
        },
      ],
    });
  };



  handleUpdateSlotGame = () => {
    confirmAlert({
      title: "게임 업데이트",
      message: "신규 게임은 한달에 한번 업데이트 됨니다",
      buttons: [
        {
          label: "확인'",

          onClick: () => {
            this.adminService.admin_update_slot_games().then((data: any) => {
              let msg = {
                severity: "success",
                summary: "게임이 ",
                detail: "업데이트 되습니다",
              };
              this.growl.show(msg);
            });
          },
        },
      ],
    });
  };



  handleGetignoredCount = () => {
    this.adminService
      .admin_get_ignored_count()
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({
            count: data.count,
          });
        }
      });
  };


  handleGetSlotGame = (page, searchText, findText, mode) => {
    this.adminService
      .admin_get_slot_main_games(page, searchText, findText, mode)
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({
            slots: data.slots,
            maxCount: Number(data.maxCount),
          });
        }
      });
  };

  render() {
    let slots = this.state.slots;
    let activePage = this.state.activePage;
    let adminService = this.adminService;
    let growl: any;

    const handleEditSlot = (slot: any) => {
      adminService.admin_update_main_slot(slot).then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: data.name,
            detail: "가 수정되었습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: data.tournamentid + " - " + data.name,
            detail: "예러 발생 되었습니다 관리자에게 문의",
          };
          this.growl.show(msg);
        }
      });
    };

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">슬롯 게임 설정</div>
                {/* 
                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.select}
                          value={this.state.searchText}
                          onChange={(event: any) =>
                            this.setState({
                              searchText: event.value
                            })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(event: any) => {
                            this.setState({
                              findText: event.target.value
                            });
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={(event: any) => {
                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              this.state.selectViewType
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="p-grid form-group">

                  <div className="p-col-6">
                    <div className="p-grid form-group">
                      {/* <div className="p-col-6">
                      <span className="md-inputfield">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={event => {
                            this.setState({ selectViewType: event.value });

                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              event.value
                            );
                          }}
                        />
                      </span>
                    </div> */}

                      <div className="p-md-6">
                        <Button
                          label="슬롯 업데이트"
                          onClick={() => {
                            this.handleUpdateSlotGame();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-col-6">

                    <InputText
                      style={{ width: "80px" }}
                      value={this.state.count}
                      onChange={(even: any) => {

                        this.setState({
                          count: Number(even.target.value),
                        });
                      }}
                    ></InputText>
                    <Button
                      label="롤링갯수빼기"
                      onClick={() => {
                        this.handleChangeIgnored();
                      }}
                    />
                  </div>

                </div>
              </div>

              {/* <Messages
                ref={(el: any) => this.setState({ messages: el })}
                style={{ marginBottom: "1em" }}
              /> */}
              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>

                <DataTable
                  value={slots}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}
                >
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="카테고리"
                    body={(rowData: any, column: any) => {
                      return `${rowData.company}`;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="영어"
                    body={(rowData: any, column: any) => {
                      if (rowData.nameEn == null) return rowData.nameEn;
                      return rowData.nameEn;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", width: "220px" }}
                    header="한국어"
                    body={(rowData: any, column: any) => {
                      if (rowData.nameKo == null) {
                        rowData.nameKo = rowData.nameEn;
                      }

                      return (
                        <InputText
                          style={{ width: "200px" }}
                          value={rowData.nameKo}
                          onChange={(even: any) => {
                            console.log(rowData.nameKo);
                            let tournament = this.state.slots.find(
                              (s: any) => s._id == rowData._id
                            );
                            tournament.nameKo = even.target.value;

                            this.setState({
                              slots: this.state.slots,
                            });
                          }}
                        ></InputText>
                      );
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", width: "220px" }}
                    header="사용"
                    body={(rowData: any, column: any) => {
                      return (
                        <div className="p-grid form-group">
                          <div className="p-col-6">
                            <RadioButton
                              value="Ultima"
                              inputId="cb1"
                              onChange={() => {
                                rowData.used = "y";
                              }}
                              checked={rowData.used == "y"}
                            />
                            <label htmlFor="cb1" className="p-checkbox-label">
                              사용
                            </label>
                          </div>
                          <div className="p-col-6">
                            <RadioButton
                              value="Avalon"
                              inputId="cb2"
                              onChange={() => {
                                rowData.used = "n";
                              }}
                              checked={rowData.used == "n"}
                            />
                            <label htmlFor="cb2" className="p-checkbox-label">
                              미사용
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="정렬 순서"
                    body={(rowData: any, column: any) => {
                      return (
                        <InputText
                          style={{ width: "80px" }}
                          value={rowData.order}
                          onChange={(even: any) => {
                            console.log(rowData.nameKo);
                            let tournament = this.state.slots.find(
                              (s: any) => s._id == rowData._id
                            );
                            tournament.order = even.target.value;

                            this.setState({
                              slots: this.state.slots,
                            });
                          }}
                        ></InputText>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="수정"
                    body={(rowData: any, column: any) => {
                      return (
                        <Button
                          label="확인"
                          onClick={() => {
                            let slot = this.state.slots.find(
                              (s: any) => s._id == rowData._id
                            );

                            handleEditSlot(slot);
                          }}
                        />
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
