import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";
// import { RadioButton } from "primereact/radiobutton";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  tournaments: any;
  maxCount: number;
  activePage: number;
  selectItem: any;
  select: any;
  searchText: any;
  findText: any;
  selectViewType: string;
  viewType: any[];
  expandedRows: any;
  odds: any;
}

export class SetOdds extends Component<any, State> {
  adminService: AdminService;
  growl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      expandedRows: null,
      tournaments: [],
      odds: {},

      maxCount: 1,
      activePage: 1,
      selectItem: {},
      searchText: "",
      findText: "",

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "카테고리", value: "in" },
      ],

      select: [{ label: "리그", value: "agent" }],
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetTournaments(1, "", "", "all");
  }

  handlePageChange = (page: any, searchText: any, findText: any, mode: any) => {
    this.setState({ activePage: page });
    this.handleGetTournaments(page, searchText, findText, mode);
  };

  handleGetCustomOdds = (tournamentid) => {
    this.adminService.admin_get_custom_odds(tournamentid).then((data: any) => {
      if (data.status == "success") {
        this.state.odds[data.tournamentid] = data.odds.odds;

        this.setState({ odds: this.state.odds });
      }
    });
  };

  handleUpdateGame = (tournamentid, odds) => {
    this.adminService
      .admin_update_custom_odds(tournamentid, odds)
      .then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: "게임이 ",
            detail: "업데이트 되습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: "에러발생 ",
            detail: "관리자에 문의 부탁드림니다",
          };
          this.growl.show(msg);
        }
      });
  };

  handleGetTournaments = (page, searchText, findText, mode) => {
    this.adminService
      .admin_get_tournaments(page, searchText, findText, mode)
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({
            tournaments: Object.values(data.tournaments),
            maxCount: Number(data.maxCount),
          });
        }
      });
  };

  render() {
    let activePage = this.state.activePage;

    const rowExpansionTemplate = (data: any): any => {
      if (this.state.odds[data.ID] == null) {
        this.state.odds[data.ID] = {};
        this.handleGetCustomOdds(data.ID);
        return;
      }

      let odds = Object.values(this.state.odds[data.ID]);

      //   return odds.map((contact: any, i: any) => {
      return (
        <DataTable
          value={odds}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          className="success"
          header={""}
          scrollable={true}
          style={{ width: "1600px" }}
        >
          <Column
            style={{ textAlign: "center" }}
            header="마켓타입"
            body={(rowData: any, column: any) => {
              return rowData.markets;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="게임타입"
            body={(rowData: any, column: any) => {
              return (
                <div className="p-col-12">
                  <div className="p-grid  form-group">
                    <div className="p-col-12 p-md-4">
                      <RadioButton
                        value="none"
                        id="status"
                        inputId="status"
                        onChange={() => {
                          rowData.usetype = "none";
                        }}
                        checked={rowData.usetype === "none"}
                      />
                      <label htmlFor="rb2" className="p-radiobutton-label">
                        미사용
                      </label>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <RadioButton
                        value="rate"
                        id="status"
                        inputId="status"
                        onChange={() => {
                          rowData.usetype = "rate";
                        }}
                        checked={rowData.usetype == "rate"}
                      />
                      <label htmlFor="status" className="p-radiobutton-label">
                        환수율
                      </label>
                    </div>

                    <div className="p-col-12 p-md-4">
                      <RadioButton
                        value="sum"
                        inputId="status"
                        id="status"
                        onChange={() => {
                          rowData.usetype = "sum";
                        }}
                        checked={rowData.usetype == "sum"}
                      />
                      <label htmlFor="status" className="p-radiobutton-label">
                        +-
                      </label>
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="환수율"
            body={(rowData: any, column: any) => {
              return (
                <InputText
                  placeholder="stop Time"
                  onChange={(even: any) => {
                    let odds = this.state.odds[data.ID];

                    odds[rowData.markets].rate = even.target.value;

                    this.setState({ odds: this.state.odds });
                  }}
                  value={rowData.rate}
                />
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="+-"
            body={(rowData: any, column: any) => {
              return (
                <InputText
                  placeholder="stop Time"
                  onChange={(even: any) => {
                    let odds = this.state.odds[data.ID];

                    odds[rowData.markets].sum = even.target.value;

                    this.setState({ odds: this.state.odds });
                    console.log(data.ID, rowData);
                  }}
                  value={rowData.sum}
                />
              );
            }}
          />
        </DataTable>
      );
    };
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">토너먼트 배당설정</div>

                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.select}
                          value={this.state.searchText}
                          onChange={(event: any) =>
                            this.setState({
                              searchText: event.value,
                            })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(event: any) => {
                            this.setState({
                              findText: event.target.value,
                            });
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={(event: any) => {
                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              this.state.selectViewType
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-6">
                      <span className="md-inputfield">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.setState({ selectViewType: event.value });

                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              event.value
                            );
                          }}
                        />
                      </span>
                    </div>

                    {/* <div className="p-md-6">
                      <Button
                        label="카테고리 업데이트"
                        onClick={() => {
                          this.handleUpdateGame();
                        }}
                      />
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <Messages
                ref={(el: any) => this.setState({ messages: el })}
                style={{ marginBottom: "1em" }}
              /> */}
              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>

                <DataTable
                  value={this.state.tournaments}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  rowExpansionTemplate={rowExpansionTemplate}
                  expandedRows={this.state.expandedRows}
                  onRowToggle={(e: any) => {
                    this.setState({ expandedRows: e.data });
                  }}
                  scrollable={true}
                  style={{ width: "1600px" }}
                >
                  <Column expander={true} style={{ width: "2em" }} />

                  <Column
                    style={{ textAlign: "center" }}
                    header="번호"
                    body={(rowData: any, column: any) => {
                      return rowData.ID;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="카테고리"
                    body={(rowData: any, column: any) => {
                      if (rowData.CategoryName == null) return "";
                      return rowData.SportName + " - " + rowData.CategoryName;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="영어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.en == null) return rowData.Name;
                      return rowData.Name.en;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="한국어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.ko == null) return rowData.Name.en;
                      return rowData.Name.ko;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="수정"
                    body={(rowData: any, column: any) => {
                      return (
                        <Button
                          label="저장"
                          onClick={() => {
                            console.log(this.state.odds[rowData.CategoryID]);
                            this.handleUpdateGame(
                              rowData.ID,
                              this.state.odds[rowData.ID]
                            );
                            // handleEditTournaments(
                            //   rowData.ID,
                            //   rowData.Active,
                            //   rowData.Quick,
                            //   rowData.Name.ko
                            // );
                          }}
                        />
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
