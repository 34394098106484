import React, { Component } from "react";
import classNames from "classnames";
import { AppTopbar } from "./AppTopbar";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppFooter } from "./AppFooter";
import { Route } from "react-router-dom";
//import { useCookies } from "react-cookie";
import cookie from "react-cookies";

//import { Dashboard } from "./components/Dashboard";
// import { FormsDemo } from "./components/FormsDemo";
// import { SampleDemo } from "./components/SampleDemo";
// import { DataDemo } from "./components/DataDemo";
// import { PanelsDemo } from "./components/PanelsDemo";
// import { OverlaysDemo } from "./components/OverlaysDemo";
// import { MenusDemo } from "./components/MenusDemo";
// import { MessagesDemo } from "./components/MessagesDemo";
// import { ChartsDemo } from "./components/ChartsDemo";
// import { MiscDemo } from "./components/MiscDemo";
import { EmptyPage } from "./components/EmptyPage";
// import { UtilsDemo } from "./components/UtilsDemo";
// import { Documentation } from "./components/Documentation";

import { Dashboard } from "./main/dashboard";
import { UserList } from "./main/userlist";
import { UserDomain } from "./main/userdomain";

import { UserIP } from "./main/userIP";
import { UserTodayList } from "./main/usertodaylist";

import { UserConnectionList } from "./main/userconnectionlist";

import { UserGhostList } from "./main/userghostlist";

import { UserRegList } from "./main/userreglist";
import { StopList } from "./main/userstoplist";

import { UserGraduationList } from "./main/usergraduationlist";

import { Deposit } from "./main/deposit";
import { Withdraw } from "./main/withdraw";

import { DeporsitAndWithdraw } from "./main/deporsitandwithdraw";
import { DeporsitAndWithdrawTotal } from "./main/deporsitandwithdrawtotal";
import { deporsitandwithdrawAdmin } from "./main/deporsitandwithdrawadmin";

import { ProgressMatch } from "./main/progressmatch";
import { ProgressLive } from "./main/progresslive";
import { ProgressMini } from "./main/progressmini";
import { ProgressFx } from "./main/progressfx";

import { EndLive } from "./main/endlive";
import { EndMini } from "./main/endmini";
import { EndMatch } from "./main/endmatch";
import { EndFx } from "./main/endFx";

import { DetailMatch } from "./main/detailmatch";
import { DetailMini } from "./main/detailmini";

//C:\WORK\eBetting\admin\src\main\progressLive.js

//C:\WORK\eBetting\admin\src\main\betLive.js

import { BetLive } from "./main/betlive";
import { BetCasino } from "./main/betcasino";
import { BetSlot } from "./main/betslot";
import { BetMini } from "./main/betmini";
import { BetMatch } from "./main/betmatch";
import { BetFx } from "./main/betFx";

import { UserDetail } from "./main/userdetail";

import { BalanceLog } from "./main/balancelog";

import { Level } from "./main/level";
import { LevelMini } from "./main/levelmini";

import { MarketsEdit } from "./main/marketsedit";

import { Agent } from "./main/agent";
import { NoteAgent } from "./main/noteagent";

import { AgentBalanceList } from "./main/agentbalancelist";
import { AgentBalanceListSty } from "./main/agentbalanceliststy";
import { AgentCode } from "./main/agentcode";
import { AgentReg } from "./main/agentreg";
import { AgentBalanceLog } from "./main/agentbalancelog";
import { AgentDetail } from "./main/agentdetail";
import { AgentWithdraw } from "./main/agentwithdraw";
import { AgentRemove } from "./main/agentremove";

import { AgentHelp } from "./main/agenthelp";


import { SetTournaments } from "./main/settournaments";
import { SetTeams } from "./main/setteams";
import { SetMakets } from "./main/setmakets";
import { SetMaketLocks } from "./main/setmaketlocks";

import { SetOdds } from "./main/setodds";
import { SetBundleBet } from "./main/setbundlebet";
import { SetLimit } from "./main/setlimit";
import { SetManagerAccount } from "./main/setmanageraccount";
import { SetCategories } from "./main/setcategories";
import { SetSlot } from "./main/setslot";

import { SetCasinoMain } from "./main/setcasinomain";
import { SetSlotMain } from "./main/setslotmain";

import { Calculate } from "./main/calculate";
import { CalculateKor } from "./main/calculatekor";
import { CalculateAgent } from "./main/calculateagent";
import { CalculateMainAgent } from "./main/calculatemainagent";
import { CalculateMainAgentKor } from "./main/calculatemainagentkor";
import { CalculateMainAgentNor } from "./main/calculatemainagentnor";
import { CalculateMainAgentLig } from "./main/calculatemainagentlig";
import { CalculateMainagentCasino } from "./main/calculatemainagentcasino";
import { CalculateMainagentCasinoNSlot } from "./main/calculatemainagentcasinonslot";
import { CalculateMainagentCSwinlose } from "./main/calculatemainagentcswinlose";


import { CalculateMainAgentWinLose } from "./main/calculatemainagentwinlose";
import { CalculatemainAgentKcrown } from "./main/calculatemainagentkcrown";
import { CalculateMainAgentKOM } from "./main/calculatemainagentkom";
import { CalculateMainAgentDAW } from "./main/calculatemainagentdaw";
import { CalculateMainAgentKOMPW } from "./main/calculatemainagentkompw";



import { CalculateSubAgent } from "./main/calculatesubagent";

import { CalculateSubAgentSty } from "./main/calculatesubagentsty";
import { CalculatesubAgentKor } from "./main/calculatesubagentkor";
import { CalculatesubAgentNor } from "./main/calculatesubagentnor";
import { CalculateSubAgentLig } from "./main/calculatesubagentlig";
import { CalculatesubAgentCasino } from "./main/calculatesubagentcasino";
import { CalculatesubAgentCasinoNSlot } from "./main/calculatesubagentcasinonslot";
import { CalculatesubagentWinLose } from "./main/calculatesubagentwinlose";
import { CalculateSubagentKcrown } from "./main/calculatesubagentkcrown";
import { CalculatesubAgentKOM } from "./main/calculatesubagentkom";
import { CalculatesubAgentKOMPW } from "./main/calculatesubagentkompw";
import { CalculatesubAgentCSWinLose } from "./main/calculatesubagentcswinlose";
import { Setting } from "./main/setting";


import { CalculatesubAgentDaw } from "./main/calculatesubagentdaw";



import { Help } from "./main/help";
import { HelpTemplate } from "./main/helpTemplate";

import { NoticeUser } from "./main/noticeuser";
import { NoticeAgent } from "./main/noticeagent";
import { NoteUser } from "./main/noteuser";
import { NotePopup } from "./main/notepopup";
import { Advertisement } from "./main/advertisement";

import { NoteEvent } from "./main/noteevent";
import { BlockIP } from "./main/blockIP";

import { DetailMatchView } from "./main/detailmatchView";

import { Messenger } from "./main/messenger";
import { SetFx } from "./main/setfx";

import { withRouter, matchPath } from "react-router";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./ripple.js";
import "./App.css";

import { AppConfig } from "./AppConfig";
import { MatchDetail } from "./share/progress/match-detail";
import { AdminService } from "./service/admin.service";
import { AxiosMag } from "./service/axios.service";

import { throws } from "assert";

import {
  OpenAgentDetail,
  GetAgentTreeByLevel,
  converAgentStatus,
  convertDate,
  numTomoeny,
  GetTimeStemp
} from "./utility/help";


console.log(process.env.REACT_APP_API_CALCULATE_REAL_ROLLING)

interface Props { }
interface State {
  management: any;
  horizontal: boolean;
  topbarSize: string;
  topbarColor: string;
  menuColor: string;
  layoutColor: string;
  themeColor: string;
  menuActive: boolean;
  configDialogActive: boolean;
  topbarUserMenuActive: boolean;
  admin: any;
  top: any;
}

// name: cookies.get('session') || 'Ben'

class App extends Component<Props, State> {
  layoutContainer: any;
  adminService: any = new AdminService();
  menuClick = false;
  configMenuClick = false;
  userMenuClick = false;
  menu: any[] = [];

  constructor(props: any) {
    super(props);

    this.state = {
      admin: {},
      management: {},
      top: {
        depositCount: 0,
        withdrawCount: 0,
        userCount: 0,
        agentCount: 0,
        agentBalanceCount: 0,
        helpCount: 0,
      },

      horizontal: true,
      topbarSize: "large",
      topbarColor: window.localStorage.getItem('topbarColor') == null ? "layout-topbar-blue" : `${window.localStorage.getItem('topbarColor')}`,
      menuColor: window.localStorage.getItem('menuColor') == null ? "layout-menu-light" : `${window.localStorage.getItem('menuColor')}`,
      layoutColor: window.localStorage.getItem('layoutColor') == null ? "blue" : `${window.localStorage.getItem('layoutColor')}`,
      themeColor: window.localStorage.getItem('themeColor') == null ? "blue" : `${window.localStorage.getItem('themeColor')}`,
      menuActive: false,
      configDialogActive: false,
      topbarUserMenuActive: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarUserMenuButtonClick = this.onTopbarUserMenuButtonClick.bind(
      this
    );
    this.onTopbarUserMenuClick = this.onTopbarUserMenuClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    this.changeTopbarSize = this.changeTopbarSize.bind(this);
    this.changeMenuToHorizontal = this.changeMenuToHorizontal.bind(this);
    this.changeMenuTheme = this.changeMenuTheme.bind(this);
    this.changeComponentTheme = this.changeComponentTheme.bind(this);
    this.changePrimaryColor = this.changePrimaryColor.bind(this);
    this.onToggleBlockBodyScroll = this.onToggleBlockBodyScroll.bind(this);
    this.isHorizontalMenuActive = this.isHorizontalMenuActive.bind(this);

    // this.createMenu();
    this.handelInit();

    console.log(process.env.REACT_APP_API_CALCULATE_REAL_ROLLING)
  }


  handelInit = async () => {

    let session = cookie.load("session");
    AxiosMag.SetSession(session);

    const data = await this.adminService.admin_get_management()
    if (data.status == "success") {
      this.setState({ management: data.management });
    }

    const health = await this.adminService.admin_check_health(session)
    if (health.status == "success") {
      this.setState({ admin: health.admin });
    } else {
      this.setState({ admin: null });
    }


  }

  onWrapperClick(event: any) {
    if (!this.menuClick) {
      this.setState({ menuActive: false });

      if (!this.configMenuClick) {
        this.unblockBodyScroll();
      }
    }

    if (!this.userMenuClick) {
      this.setState({ topbarUserMenuActive: false });
    }

    this.userMenuClick = false;
    this.menuClick = false;
    this.configMenuClick = false;
  }

  onMenuButtonClick(event: any, isMenuButtonActive: any) {
    this.menuClick = true;

    if (!this.isHorizontalMenuActive()) {
      this.setState({ menuActive: !isMenuButtonActive }, () => {
        if (this.state.menuActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      });
    }

    event.preventDefault();
  }

  onToggleBlockBodyScroll(add: boolean) {
    if (add) this.blockBodyScroll();
    else this.unblockBodyScroll();

    this.configMenuClick = true;
  }

  blockBodyScroll() {
    let className = `blocked-scroll${this.state.horizontal ? "-horizontal-menu" : ""
      }`;
    if (document.body.classList) {
      document.body.classList.add(className);
    } else {
      document.body.className += ` ${className}`;
    }
  }

  unblockBodyScroll() {
    let className = `blocked-scroll${this.state.horizontal ? "-horizontal-menu" : ""
      }`;
    if (document.body.classList) {
      document.body.classList.remove(className);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + `${className}`.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  onTopbarUserMenuButtonClick(event: any) {
    this.userMenuClick = true;
    this.setState({ topbarUserMenuActive: !this.state.topbarUserMenuActive });

    event.preventDefault();
  }

  onTopbarUserMenuClick(event: any) {
    this.userMenuClick = true;

    if (
      event.target.nodeName === "BUTTON" ||
      event.target.parentNode.nodeName === "BUTTON"
    ) {
      this.setState({ topbarUserMenuActive: false });
    }
    event.preventDefault();
  }

  onRootMenuItemClick(event: any) {
    this.menuClick = true;

    if (this.isHorizontalMenuActive()) {
      this.setState({
        menuActive: !this.state.menuActive,
      });
    }
  }

  onMenuItemClick(event: any) {
    if (!event.item.items) {
      this.setState({ menuActive: false });
    }
  }

  onSidebarClick = (event: any) => {
    this.menuClick = true;
  };

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isHorizontalMenuActive() {
    return this.state.horizontal && !this.isMobile();
  }

  changeTopbarSize(event: any) {
    this.setState({ topbarSize: event.size });
    window.localStorage.setItem('topbarSize', event.size)

    event.originalEvent.preventDefault();
  }

  changeTopbarTheme(event: any) {
    this.setState({ topbarColor: "layout-topbar-" + event.color });


    window.localStorage.setItem('topbarColor', "layout-topbar-" + event.color)

    event.originalEvent.preventDefault();
  }

  changeMenuToHorizontal(event: any) {
    console.log(event.mode)

    this.setState({ horizontal: event.mode });

    window.localStorage.setItem('horizontal', event.mode)

    event.originalEvent.preventDefault();
  }

  changeMenuTheme(event: any) {
    console.log(event.color)

    this.setState({ menuColor: "layout-menu-" + event.color });

    window.localStorage.setItem('menuColor', "layout-menu-" + event.color)


    event.originalEvent.preventDefault();
  }

  changeComponentTheme(event: any) {
    this.setState({ themeColor: event.theme });

    window.localStorage.setItem('themeColor', event.theme)

    let element = document.getElementById("theme-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + event.theme + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  changePrimaryColor(event: any) {

    console.log(event.color)
    this.setState({ layoutColor: event.color });
    window.localStorage.setItem('layoutColor', event.color)

    let element = document.getElementById("layout-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "layout-" + event.color + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  componentDidMount = () => {

  };

  // componentWillUnmount = () => {
  //   console.log("componentWillUnmount");
  // };

  createMenu() {
    let top = this.state.top;

    this.menu = [
      { label: "Dashboard", icon: "dashboard", to: "/dashboard" },
      {
        label: "회원관리",
        icon: "list",
        badge: ["userCount"],
        quickUrl: ["userreglist"],
        items: [
          {
            label: "접속 리스트",
            icon: "desktop_mac",
            to: "/userconnectionlist",
          },
          { label: "유저 리스트", icon: "desktop_mac", to: "/userlist" },
          {
            label: "금일 접속리스트",
            icon: "desktop_mac",
            to: "/usertodaylist",
          },
          // { label: "지인 리스트", icon: "desktop_mac", to: "/friendlist" },
          { label: "유령 리스트", icon: "desktop_mac", to: "/userghostlist" },
          {
            label: "졸업 리스트",
            icon: "desktop_mac",
            to: "/usergraduationlist",
          },
          { label: "정지 리스트", icon: "desktop_mac", to: "/stoplist" },
          { label: "IP 접속자 리스트", icon: "desktop_mac", to: "/userIP" },
          { label: "IP 차단", icon: "desktop_mac", to: "/blockIP" },
          { label: "가입대기 리스트", icon: "desktop_mac", to: "/userreglist" },
          { label: "가입 도메인", icon: "desktop_mac", to: "/userdomain" },

        ],
      },
      {
        label: "충환전관리",
        icon: "list",
        badge: ["depositCount", "withdrawCount"],
        quickUrl: ["deposit", "withdraw"],

        items: [
          { label: "충전대기", icon: "desktop_mac", to: "/deposit" },
          { label: "환전대기", icon: "desktop_mac", to: "/withdraw" },
          {
            label: "관리자 지급/회수",
            icon: "desktop_mac",
            to: "/deporsitandwithdrawAdmin",
          },

          {
            label: "충환전정보",
            icon: "desktop_mac",
            to: "/deporsitAndwithdraw",
          },
        ],
      },
      {
        label: "진행중 게임",
        icon: "list",
        items: [
          {
            label: "스포츠",
            icon: "desktop_mac",
            to: "/ProgressMatch",
            name: "sportpre",
          },
          {
            label: "라이브",
            icon: "desktop_mac",
            to: "/ProgressLive",
            name: "sportlive",
          },
          {
            label: "미니게임",
            icon: "desktop_mac",
            to: "/ProgressMini",
            name: "mini",
          },
          {
            label: "Fx게임",
            icon: "desktop_mac",
            to: "/ProgressFX",
            name: "fx",
          },
        ],
      },

      {
        label: "종료된 게임",
        icon: "list",
        items: [
          {
            label: "스포츠",
            icon: "desktop_mac",
            to: "/endMatch",
            name: "sportpre",
          },
          {
            label: "라이브",
            icon: "desktop_mac",
            to: "/endLive",
            name: "sportlive",
          },
          {
            label: "미니게임",
            icon: "desktop_mac",
            to: "/endMini",
            name: "mini",
          },
          { label: "Fx게임", icon: "desktop_mac", to: "/endFX", name: "fx" },
        ],
      },
      {
        label: "베팅내역",
        icon: "list",
        items: [
          {
            label: "스포츠배팅",
            icon: "desktop_mac",
            to: "/betmatch",
            name: "sportpre",
          },
          {
            label: "라이브배팅",
            icon: "desktop_mac",
            to: "/betlive",
            name: "sportlive",
          },
          {
            label: "미니게임배팅",
            icon: "desktop_mac",
            to: "/betmini",
            name: "mini",
          },
          {
            label: "카지노배팅",
            icon: "desktop_mac",
            to: "/betcasino",
            name: "casino",
          },
          {
            label: "슬롯배팅",
            icon: "desktop_mac",
            to: "/betslot",
            name: "slot",
          },
          { label: "FX배팅", icon: "desktop_mac", to: "/betFX", name: "fx" },
        ],
      },
      {
        label: "매출관리",
        icon: "list",
        items: [
          { label: "일/월별 통계", icon: "desktop_mac", to: "/calculate" },
          {
            label: "매출관리1",
            icon: "desktop_mac",
            to: "/calculateagent",
          },
          { label: "매출관리2", icon: "desktop_mac", to: "/calculatemainagent", },
          // { label: "머니이동기", icon: "desktop_mac", to: "/BalanceLog" },
        ],
      },

      {
        label: "에이전트",
        icon: "list",
        badge: ["agentCount", "agentBalanceCount"],
        quickUrl: ["agentreg", "agentwithdraw"],

        items: [
          { label: "에이전트 추가/변경", icon: "desktop_mac", to: "/agentAdd" },
          { label: "에이전트 벨런스", icon: "desktop_mac", to: "/agentBalanceList", },
          { label: "에이전트 문의", icon: "desktop_mac", to: "/agenthelp" },
          { label: "에이전트 쪽지", icon: "desktop_mac", to: "/noteagent" },



          { label: "가입 코드", icon: "desktop_mac", to: "/agentcode" },
          { label: "에이전트 승인", icon: "desktop_mac", to: "/agentreg" },
          {
            label: "에이전트 기록",
            icon: "desktop_mac",
            to: "/agentbalancelog",
          },
          { label: "에이전트 충전/환전", icon: "desktop_mac", to: "/agentwithdraw" },
          { label: "에이전트 삭제", icon: "desktop_mac", to: "/agentremove" },
          { label: "에이전트공지", icon: "desktop_mac", to: "/noticeagent" },

        ],
      },
      {
        label: "커뮤니티",
        icon: "list",
        badge: ["helpReg", "helpCount"],
        quickUrl: ["help", "help"],

        items: [
          { label: "고객센터", icon: "desktop_mac", to: "/help" },

          { label: "팝업공지", icon: "desktop_mac", to: "/notepopup" },
          { label: "유저공지", icon: "desktop_mac", to: "/noticeuser" },
          { label: "유저쪽지", icon: "desktop_mac", to: "/noteuser" },

          { label: "이벤트", icon: "desktop_mac", to: "/noteEven" },

          { label: "광고설정", icon: "desktop_mac", to: "/advertisement" },

          {
            label: "고객센터 템플릿",
            icon: "desktop_mac",
            to: "/helpTemplate",
          },
        ],
      },
      {
        label: "환경설정",
        icon: "list",
        items: [
          { label: "레벨별 포인트 설정", icon: "desktop_mac", to: "/level" },
          {
            label: "레벨별 배팅 리밋",
            icon: "desktop_mac",
            to: "/setlimit",
          },
          {
            label: "레벨별 통장",
            icon: "desktop_mac",
            to: "/setmanageraccount",
          },
          {
            label: "메신저 설정",
            icon: "desktop_mac",
            to: "/messenger",
          },

          {
            label: "미니게임 설정",
            icon: "desktop_mac",
            to: "/levelMini",
            name: "mini",
          },
          // {
          //   label: "사이트 설정",
          //   icon: "desktop_mac",
          //   to: "/levelMini",
          //   name: "sportpre",
          // },
          {
            label: "팀 설정",
            icon: "desktop_mac",
            to: "/setteams",
            name: "sportpre",
          },

          {
            label: "카테고리 설정",
            icon: "desktop_mac",
            to: "/setcategories",
            name: "sportpre",
          },

          {
            label: "마켓 설정",
            icon: "desktop_mac",
            to: "/setmakets",
            name: "sportpre",
          },

          {
            label: "마켓 필터",
            icon: "desktop_mac",
            to: "/setmaketlocks",
            name: "sportpre",
          },

          {
            label: "토너먼트 설정",
            icon: "desktop_mac",
            to: "/settournaments",
            name: "sportpre",
          },
          ,
          {
            label: "토너먼트 배당",
            icon: "desktop_mac",
            to: "/setodds",
            name: "sportpre",
          },

          {
            label: "묶음 배팅설정",
            icon: "desktop_mac",
            to: "/setbundlebet",
            name: "sportpre",
          },
          {
            label: "카지노 설정",
            icon: "desktop_mac",
            to: "/casinomain",
            name: "casino",
          },

          {
            label: "슬롯 설정",
            icon: "desktop_mac",
            to: "/setslotmain",
            name: "slot",
          },

          {
            label: "슬롯 게임 설정",
            icon: "desktop_mac",
            to: "/setslotSet",
            name: "slot",
          },
          {
            label: "FX 게임 설정",
            icon: "desktop_mac",
            to: "/setfx",
            name: "fx",
          },
          {
            label: "  설정",
            icon: "desktop_mac",
            to: "/setting",
            name: "slot",
          },
        ],
      },
    ];

    let management = this.state.management;

    let newmenu: any = [];

    newmenu.push(this.menu[0]);

    for (let items of this.menu) {
      if (items.items != null && items.items.length >= 1) {
        let submenu: any = [];


        for (let item of Object.values(items.items) as any) {
          if (management.sportpre === "y" && item.name === "sportpre") {
            submenu.push(item);
          } else if (
            management.sportlive === "y" &&
            item.name === "sportlive"
          ) {
            submenu.push(item);
          } else if (management.mini === "y" && item.name === "mini") {
            submenu.push(item);
          } else if (management.casino === "y" && item.name === "casino") {
            submenu.push(item);
          } else if (management.slot === "y" && item.name === "slot") {
            submenu.push(item);
          } else if (management.fx === "y" && item.name === "fx") {
            submenu.push(item);
          } else if (item.name == null) {
            submenu.push(item);
          }
        }

        if (submenu.length > 0) {
          newmenu.push({
            label: items.label,
            icon: items.icon,
            items: submenu,
            badge: items.badge,
            quickUrl: items.quickUrl,
          });
        }
      }
    }

    this.menu = newmenu;
  }



  RenderPopup = () => {

    // const { cookies } = this.props;

    const admin = this.state.admin

    // if (
    //   process.env.REACT_APP_API_NAME === '신세계' || process.env.REACT_APP_API_NAME === 'HI'

    // ) {

    //   let popup = cookie.load("popup") || 0;


    //   if (
    //     GetTimeStemp() - Number(popup) > 60 * 60 * 1000 * 2
    //   ) {
    //     return (
    //       <div className="pop01_popup1 draggable02" id="divpopup20210131045005" style={{ position: 'absolute', zIndex: 1000 }} >
    //         <div className="pop01_popup_wrap">
    //           <div className="pop01_popup_btn_wrap">
    //             <ul>
    //               <li><a ><span className="pop01_popup_btn" onClick={() => {
    //                 // this.props.SetCookie(`pop_${pop.idx}`, GetTimeStemp())
    //                 // this.state.close_popup[`pop_${pop.idx}`] = true
    //                 // this.setState({ close_popup: this.state.close_popup })

    //                 cookie.save('popup', GetTimeStemp(), { path: '/' });
    //               }}>2시간동안 창을 열지 않음</span></a></li>
    //               <li>
    //                 {/* <a href="#"><span className="pop01_popup_btn" onClick={() => {
    //               }}>닫기 X</span></a> */}

    //               </li>
    //             </ul>
    //           </div>
    //           <div className="pop01_popup_box">
    //             <div className="pop01_popup_text" style={{ padding: '30px', width: `${600}px` }}>
    //               <span className="pop01_popup_font1" style={{ borderBottom: '2px solid #fff', marginBottom: '15px', color: '' }}>긴급 공지</span>
    //               <span className="pop01_popup_font2" >
    //                 <div>
    //                   안녕하세요 망고트리 개발실입니다 <br />
    //                   최팀장님 부재인결과 급하신 용무는 저희 텔레그램으로 문의 주시기 바랍니다.<br />
    //                   저희도 연락이 안되고있어서 업무보시는데 어려운 부분이 있으실꺼라 판단하고 메세지드립니다<br />
    //                   텔레그램 아이디 :@Redplanet1004  주시면 응대 드리겠습니다<br />
    //                 </div>
    //               </span>

    //             </div>



    //           </div>
    //         </div>
    //       </div>
    //     )
    //   }
    //   return
    // }



    // if (close_popup[`pop_${pop.idx}`] == true) {
    //   return '';
    // }

    return (

      <div>
      </div>

    );
  };


  render() {
    if (this.state.admin == null) {
      window.location.href = "/";
    }

    const layoutContainerClassName = classNames(
      "layout-container",
      {
        "layout-menu-horizontal": this.state.horizontal,
        "layout-menu-active":
          this.state.menuActive && !this.isHorizontalMenuActive(),
        "layout-top-small": this.state.topbarSize === "small",
        "layout-top-medium": this.state.topbarSize === "medium",
        "layout-top-large": this.state.topbarSize === "large",
      },
      this.state.topbarColor,
      this.state.menuColor
    );

    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

    let hash = window.location.hash;

    // const PostPage = (match: any) => {
    //   return <div>blablabla</div>;
    // };

    if (hash.indexOf("userDetail") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>
          <div className="layout-content">
            <Route
              exact={true}
              path="/userDetail/:userID"
              component={(match: any) => {
                return (
                  <UserDetail userId={match.match.params.userID}></UserDetail>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.indexOf("calculatesubagent") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/calculatesubagent/:agentID"
              component={(match: any) => {

                if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY') {
                  return (
                    <CalculateSubAgentSty
                      agentId={match.match.params.agentID}
                    ></CalculateSubAgentSty>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOR') {
                  return (
                    <CalculatesubAgentKor
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentKor>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOM') {
                  return (
                    <CalculatesubAgentKOM
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentKOM>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOMPW') {
                  return (
                    <CalculatesubAgentKOMPW
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentKOMPW>
                  )
                }

                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'NOR') {
                  return (
                    <CalculatesubAgentNor
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentNor>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'WINLOSE') {
                  return (
                    <CalculatesubagentWinLose
                      agentId={match.match.params.agentID}
                    ></CalculatesubagentWinLose>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'LIG') {
                  return (
                    <CalculateSubAgentLig
                      agentId={match.match.params.agentID}
                    ></CalculateSubAgentLig>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINO') {
                  return (
                    <CalculatesubAgentCasino
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentCasino>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINONSLOT') {
                  return (
                    <CalculateMainagentCasino
                      agentId={match.match.params.agentID}
                    ></CalculateMainagentCasino>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINONSLOT') {
                  return (
                    <CalculateMainagentCasinoNSlot
                      agentId={match.match.params.agentID}
                    ></CalculateMainagentCasinoNSlot>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KCROWN') {
                  return (
                    <CalculateSubagentKcrown
                      agentId={match.match.params.agentID}
                    ></CalculateSubagentKcrown>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'DAW') {
                  return (

                    <CalculatesubAgentDaw
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentDaw>
                  )
                }
                else if (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CSWINLOSE') {
                  return (

                    <CalculatesubAgentCSWinLose
                      agentId={match.match.params.agentID}
                    ></CalculatesubAgentCSWinLose>
                  )
                }
                return (
                  <CalculateSubAgent
                    agentId={match.match.params.agentID}
                  ></CalculateSubAgent>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.indexOf("agentDetail") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/agentDetail/:agentID"
              component={(match: any) => {
                return (
                  <AgentDetail
                    agentId={match.match.params.agentID}
                  ></AgentDetail>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.indexOf("detailMatch") >= 0) {
      return (
        <div>
          <div className="layout-top">
            <AppBreadCrumbWithRouter />
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/detailMatch/:matchId"
              component={(match: any) => {
                return (
                  <DetailMatch matchId={match.params.matchId}></DetailMatch>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.indexOf("detailMini") >= 0) {
      return (
        <div>
          <div className="layout-top">
            {" "}
            <AppBreadCrumbWithRouter />{" "}
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/detailMini/:categoryId/:episode"
              component={(match: any) => {
                return (
                  <DetailMini
                    categoryId={match.params.categoryId}
                    episode={match.params.episode}
                  ></DetailMini>
                );
              }}
            />
          </div>
        </div>
      );
    } else if (hash.toLocaleLowerCase().indexOf("detailmatchview") >= 0) {
      return (
        <div>
          <div className="layout-top">
            {" "}
            <AppBreadCrumbWithRouter />{" "}
          </div>

          <div className="layout-content">
            <Route
              exact={true}
              path="/DetailMatchView/:matchId/:gameId/:specifiers"
              component={(match: any) => {
                return (
                  <DetailMatchView
                    matchId={match.match.params.matchId}
                    gameId={match.match.params.gameId}
                    specifiers={match.match.params.specifiers}
                  ></DetailMatchView>
                );
              }}
            />
          </div>
        </div>
      );
    }

    this.createMenu();

    return (
      <div
        // ref={el => (this.layoutContainer = el)}
        className={layoutContainerClassName}
        onClick={this.onWrapperClick}
      >
        <div className="layout-top">
          <AppTopbar
            topbarUserMenuActive={this.state.topbarUserMenuActive}
            menuActive={this.state.menuActive}
            onMenuButtonClick={this.onMenuButtonClick}
            onTopbarUserMenuButtonClick={this.onTopbarUserMenuButtonClick}
            onTopbarUserMenuClick={this.onTopbarUserMenuClick}
            model={this.menu}
            topInfo={this.state.top}
            horizontal={this.state.horizontal}
            onSidebarClick={this.onSidebarClick}
            onRootMenuItemClick={this.onRootMenuItemClick}
            onMenuItemClick={this.onMenuItemClick}
            isHorizontalMenuActive={this.isHorizontalMenuActive}
            onRootItemClick={() => { }}
          />

          <div className="layout-topbar-separator" />

          <AppBreadCrumbWithRouter />
        </div>

        {this.RenderPopup()}

        <div className="layout-content">
          {/* <Route path="/" exact component={Login} /> */}
          {/* <Route path="/" component={Dashboard} /> */}
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/userconnectionlist" component={UserConnectionList} />
          <Route path="/userlist" component={UserList} />
          <Route path="/usertodaylist" component={UserTodayList} />
          <Route path="/blockIP" component={BlockIP} />



          <Route path="/userdomain" component={UserDomain} />
          <Route path="/userghostlist" component={UserGhostList} />
          <Route path="/usergraduationlist" component={UserGraduationList} />
          <Route path="/userreglist" component={UserRegList} />
          <Route path="/userip" component={UserIP} />
          <Route path="/stoplist" component={StopList} />
          <Route path="/regist" component={UserList} />
          <Route path="/deposit" component={Deposit} />


          <Route path="/withdraw" component={Withdraw} />


          {
            (<Route path="/deporsitAndwithdraw" component={DeporsitAndWithdrawTotal} />)
            // :
            // (<Route path="/deporsitAndwithdraw" component={DeporsitAndWithdraw} />)
          }


          <Route
            path="/deporsitandwithdrawAdmin"
            component={deporsitandwithdrawAdmin}
          />

          <Route path="/progressMatch" component={ProgressMatch} />
          <Route path="/progressLive" component={ProgressLive} />
          <Route path="/progressMini" component={ProgressMini} />
          <Route path="/progressFX" component={ProgressFx} />

          <Route path="/endLive" component={EndLive} />
          <Route path="/endMatch" component={EndMatch} />
          <Route path="/endMini" component={EndMini} />
          <Route path="/endFx" component={EndFx} />

          <Route path="/betmatch" component={BetMatch} />
          <Route path="/betlive" component={BetLive} />
          <Route path="/betcasino" component={BetCasino} />
          <Route path="/betslot" component={BetSlot} />
          <Route path="/betmini" component={BetMini} />
          <Route path="/betfx" component={BetFx} />

          <Route path="/empty" component={EmptyPage} />
          {/* <Route path="/balanceLog" component={BalanceLog} /> */}
          <Route path="/level" component={Level} />
          <Route path="/LevelMini" component={LevelMini} />
          <Route path="/marketsEdit" component={MarketsEdit} />


          {/* ><Agent management={this.state.management}></Agent> */}
          <Route path="/agentAdd" component={Agent} />

          {
            process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY' ? (
              <Route path="/agentBalanceList" component={AgentBalanceListSty} />
            )
              : (
                <Route path="/agentBalanceList" component={AgentBalanceList} />
              )
          }
          <Route path="/agentCode" component={AgentCode} />
          <Route path="/agentreg" component={AgentReg} />
          <Route path="/agentbalancelog" component={AgentBalanceLog} />
          <Route path="/agentwithdraw" component={AgentWithdraw} />
          <Route path="/agentremove" component={AgentRemove} />
          <Route path="/agenthelp" component={AgentHelp} />
          <Route path="/noteagent" component={NoteAgent} />


          <Route path="/detailMatch" component={DetailMatch} />
          <Route path="/setcategories" component={SetCategories} />
          <Route path="/setteams" component={SetTeams} />
          <Route path="/setmakets" component={SetMakets} />
          <Route path="/setmaketlocks" component={SetMaketLocks} />

          <Route path="/settournaments" component={SetTournaments} />
          <Route path="/setodds" component={SetOdds} />
          <Route path="/setbundlebet" component={SetBundleBet} />
          <Route path="/setlimit" component={SetLimit} />
          <Route path="/setmanageraccount" component={SetManagerAccount} />


          <Route path="/casinomain" component={SetCasinoMain} />
          <Route path="/setslotmain" component={SetSlotMain} />

          <Route path="/setslotSet" component={SetSlot} />
          {
            process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'STY' ? (
              <Route path="/calculate" component={Calculate} />
            ) : (
              <Route path="/calculate" component={CalculateKor} />
            )
          }

          <Route path="/calculateagent" component={CalculateAgent} />



          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOR' && <Route path="/calculatemainagent" component={CalculateMainAgentKor} />}

          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOM' && <Route path="/calculatemainagent" component={CalculateMainAgentKOM} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOMPW' && <Route path="/calculatemainagent" component={CalculateMainAgentKOMPW} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'WINLOSE' && <Route path="/calculatemainagent" component={CalculateMainAgentWinLose} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'NOR' && <Route path="/calculatemainagent" component={CalculateMainAgentNor} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'LIG' && <Route path="/calculatemainagent" component={CalculateMainAgentLig} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINO' && <Route path="/calculatemainagent" component={CalculateMainagentCasino} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINONSLOT' && <Route path="/calculatemainagent" component={CalculateMainagentCasinoNSlot} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KCROWN' && <Route path="/calculatemainagent" component={CalculatemainAgentKcrown} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'DAW' && <Route path="/calculatemainagent" component={CalculateMainAgentDAW} />}
          {process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CSWINLOSE' && <Route path="/calculatemainagent" component={CalculateMainagentCSwinlose} />}


          {(process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === '' || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING == 'STY' || process.env.REACT_APP_API_CALCULATE_REAL_ROLLING == null) && <Route path="/calculatemainagent" component={CalculateMainAgent} />}


          <Route path="/help" component={Help} exact />
          <Route path="/helpTemplate" component={HelpTemplate} />
          <Route path="/noticeuser" component={NoticeUser} />
          <Route path="/noticeagent" component={NoticeAgent} />
          <Route path="/noteuser" component={NoteUser} />
          <Route path="/notepopup" component={NotePopup} />
          <Route path="/advertisement" component={Advertisement} />
          <Route path="/noteeven" component={NoteEvent} />

          <Route path="/messenger" component={Messenger} />
          <Route path="/setfx" component={SetFx} />
          <Route path="/setting" component={Setting} />
          
        </div>

        <AppConfig
          topbarColor={this.state.topbarColor}
          horizontal={this.state.horizontal}
          layoutColor={this.state.layoutColor}
          menuColor={this.state.menuColor}
          themeColor={this.state.themeColor}
          topbarSize={this.state.topbarSize}
          changeTopbarTheme={this.changeTopbarTheme}
          changeMenuToHorizontal={this.changeMenuToHorizontal}
          changeMenuTheme={this.changeMenuTheme}
          changeComponentTheme={this.changeComponentTheme}
          changePrimaryColor={this.changePrimaryColor}
          changeTopbarSize={this.changeTopbarSize}
          onToggleBlockBodyScroll={this.onToggleBlockBodyScroll}
        />

        {!this.isHorizontalMenuActive() && this.state.menuActive && (
          <div className="layout-mask" />
        )}

        <AppFooter />

        {/* <video id="myVideo">
          <source src="http://media.w3.org/2010/05/sintel/trailer.mp4" type='video/mp4; codecs="avc1, mp4a"' />
          <source src="http://media.w3.org/2010/05/sintel/trailer.ogv" type='video/ogg; codecs="theora, vorbis"' />
        </video> */}
        {/*
        <div data-role="controlgroup" data-type="horizontal">
          <audio id="userReg" src="/assets/sound/reg.mp3" />
          <audio id="depositReg" src="/assets/sound/ch.mp3" />
          <audio id="withdrawReg" src="/assets/sound/ex.mp3" />
          <audio id="helpReg" src="/assets/sound/qa.mp3" />
          <audio id="agentReg" src="/assets/sound/agent.wav" />
          <audio id="agentBalanceCountReg" src="/assets/sound/agentbalance.mp3" />
        </div> */}
        <div id="userReg" style={{ display: 'none' }}>0</div>
        <div id="depositReg" style={{ display: 'none' }}>0</div>
        <div id="withdrawReg" style={{ display: 'none' }}>0</div>
        <div id="helpReg" style={{ display: 'none' }}>0</div>
        <div id="agentReg" style={{ display: 'none' }}>0</div>
        <div id="agentCount" style={{ display: 'none' }}>0</div>

        <div id="agentBalanceCountReg" style={{ display: 'none' }}>0</div>
        <div id="helpAgentReg" style={{ display: 'none' }}>0</div>
        <div id="kex" style={{ display: 'none' }}>0</div>
        <div id="kall" style={{ display: 'none' }}>0</div>
        <div id="sound10" style={{ display: 'none' }}>0</div>
        <div id="sound20" style={{ display: 'none' }}>0</div>
        <div id="sound30" style={{ display: 'none' }}>0</div>



      </div>
    );
  }
}

export default App;
