import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { SelectButton } from "primereact/selectbutton";

import { HelpBox } from "../share/user/help-box";
import { BetCasinoTable } from "../share/bet/bet-casino-table";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny, GetToday, ConvertCalendar,
} from "../utility/help";


interface CasinoState {
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  page: number;
  maxCount: number;
  summary: any;
  cities: any;
  selectSort: any;
}

export class BetCasino extends Component<any, CasinoState> {
  betService: BetService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      summary: {},
      selectSort: 'betTime',
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "userID" },
        { label: "에이전트", value: "agent" },
        { label: "에이전트(하부포함)", value: "tree" },
      ],
    };

    this.betService = new BetService();

    this.handlePageChange = this.handlePageChange.bind(this);

    this.get_casino_bet_list(0);
  }

  componentDidMount() { }

  get_casino_bet_list = (page: any) => {
    this.betService
      .get_casino_bets(
        page,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE'),
        this.state.findText,
        this.state.searchText,
        this.state.selectSort,
      )
      .then((data: any) => {

        for (var bet of data.bets) {
          bet.transContent = JSON.stringify(bet.transContent)
        }

        this.setState({
          userTableValue: data.bets,
          maxCount: data.maxCount,
        });
      });
  };

  get_casino_bets_search = (page: any) => {
    this.betService
      .get_casino_bets_search(
        page,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE'),
        this.state.findText,
        this.state.searchText
      )
      .then((data: any) => {
        this.setState({
          summary: data.summary,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_casino_bet_list(page);
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title" style={{ padding: "0px" }}>
                  <div className="card-title">카지노 배팅정보</div>
                  <div className="p-col-12 ">
                    <div className="p-md-11">
                      <div className="p-grid form-group">
                        <div className="p-col-12 p-md-3">
                          <Calendar
                            showTime
                            hourFormat="24"
                            placeholder="Begin"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.begin}
                            showWeek={false}
                            onChange={(e: any) =>
                              this.setState({ begin: e.value })
                            }
                            locale={ConvertCalendar()}
                          />
                        </div>
                        <div className="p-col-12 p-md-3">
                          <Calendar
                            showTime
                            hourFormat="24"

                            placeholder="End"
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                            value={this.state.end}
                            showWeek={false}
                            onChange={(e: any) =>
                              this.setState({ end: e.value })
                            }
                            locale={ConvertCalendar()}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <Dropdown
                            style={{ width: '150px' }}
                            options={this.state.cities}
                            value={this.state.findText}
                            onChange={(event: any) =>
                              this.setState({ findText: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <InputText
                              onChange={(event: any) => {
                                this.setState({
                                  searchText: event.target.value,
                                });
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-2">
                          <span className="md-inputfield">
                            <Button
                              label="검색"
                              onClick={() => {
                                this.handlePageChange(0);
                                this.get_casino_bets_search(0);
                              }}
                            />
                          </span>
                        </div>
                        <div className="p-col-12 p-md-4">
                          <DataTable
                            style={{ padding: "0px" }}
                            value={this.state.summary}
                            paginatorPosition="both"
                            rows={20}
                          >
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="error" text="BET" />}
                              body={(rowData: any, colum: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.totalBet)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={<Message severity="info" text="WIN" />}
                              body={(rowData: any, colum: any) => {
                                return (
                                  <div>
                                    <span>{numTomoeny(rowData.totalWin)}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              style={{ textAlign: "center", padding: "0px" }}
                              header={
                                <Message severity="info" text="BET - WIN" />
                              }
                              body={(rowData: any, colum: any) => {
                                return (
                                  <div>
                                    <span>
                                      {numTomoeny(
                                        rowData.totalBet - rowData.totalWin
                                      )}
                                    </span>
                                  </div>
                                );
                              }}
                            />
                          </DataTable>
                        </div>
                        <div className="p-col-12 p-md-5">
                  <SelectButton
                    value={this.state.selectSort}
                    options={
                      [
                        { label: "배팅시간", value: "betTime" },
                        { label: "이긴금액", value: "balanceWin" },
                      ]
                    }
                    onChange={(event) => {
                      this.setState({ selectSort: event.target.value });
                      setTimeout(() => this.handlePageChange(0) , 100);
                    }}
                  />
                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                <BetCasinoTable
                  bets={this.state.userTableValue}
                  mode={"none"}
                ></BetCasinoTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
