import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";
import { isMobile, isMobileOnly } from "react-device-detect";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday, ConvertGameText } from "../utility/help";

interface State {
  begin: Date;
  end: Date;
  limits: any[];
  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  maxLevel: number;
  games: any[];
}

export class SetLimit extends Component<any, State> {
  adminService: AdminService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      limits: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      maxLevel: 8,
      games: [
        { type: "sport" },
        { type: "sport_special" },
        { type: "sport_live" },
        { type: "fx_minute1" },
        { type: "fx_minute2" },
        { type: "fx_minute3" },
        { type: "fx_minute5" },
      ],
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetBetLimitList();
    this.handleGetMiniGameInfo();
  }

  handleUpdateLimit = () => {
    this.adminService.admin_update_bet_limit(this.state.limits).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "배팅 리밋",
          message: "레벨정보가 업데이트 되어습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
        // this.setState({ limits: data.limits });
      }
    });
  };

  handleGetMiniGameInfo = () => {
    this.adminService.admin_get_mini_games_setting().then((data) => {
      if (data.status == "success") {
        // this.set.levels.
        for (let mini of data.setting) {
          this.state.games.push({
            type: mini.categoryId,
            text: mini.categoryName,
          });
        }

        this.setState({ games: this.state.games });
      }
    });
  };

  handleGetBetLimitList = () => {
    this.adminService.admin_get_limit_list().then((data) => {
      if (data.status == "success") {
        this.setState({ limits: data.limits });
      }
    });
  };

  handleMakeLimitLevel = () => {
    console.log(this.state.games);
    this.adminService
      .handleMakeLimitLevel(this.state.maxLevel, this.state.games)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "레벨",
            message: "레벨정보가 생성되었습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "레벨",
            message: "관리자에게 문의 부탁드림니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    const handelChange = (idx: any, type: any, value: any) => {
      let ben = this.state.limits.find((s) => s.idx == idx);
      if (ben != undefined) {
        ben[`${type}`] = value;
        this.setState({ limits: this.state.limits });
      }
    };
    const RenderPanel = () => {
      let benefitList: any[] = [];
      let levelIndexs: any[] = [];
      for (let i = 1; i < this.state.maxLevel; i++) {
        levelIndexs.push(i);
        if (this.state.limits != undefined) {
          let results = this.state.limits.filter((x) => x.level == i);

          benefitList.push(results);
        }
      }

      return levelIndexs.map((level, i) => {
        let items = benefitList[level - 1];
        return (
          <TabPanel
            header={level + "레벨"}
            leftIcon="pi-md-check"
            key={"level" + level}
          >
            <DataTable
              value={items}
              paginatorPosition="both"
              rows={50}
              sortOrder={-1}
              scrollable={true}
            >
              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="Level"
                body={(rowData: any, column: any) => {
                  return rowData.level;
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="ID"
                body={(rowData: any, column: any) => {
                  return rowData.limit_type;
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="설명"
                body={(rowData: any, column: any) => {
                  return ConvertGameText(rowData.limit_type);
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="최소배팅"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.min_bet}
                      onChange={(even: any) => {
                        handelChange(rowData.idx, "min_bet", even.target.value);
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="최대배팅"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.max_bet}
                      onChange={(even: any) => {
                        handelChange(rowData.idx, "max_bet", even.target.value);
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="최대배당"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.max_rate}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "max_rate",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="최대폴더"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.max_floer}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "max_floer",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center", width: "120px" }}
                header="최대당첨금"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.maximum_win}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "maximum_win",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />
            </DataTable>
          </TabPanel>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">배팅 리밋</div>

                  <div className="p-md-6">
                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleUpdateLimit();
                      }}
                    />

                    <Button
                      label="리밋 생성"
                      onClick={() => {
                        this.handleMakeLimitLevel();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                {/* {RenderBet()} */}

                <TabView>{RenderPanel()}</TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
