import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  begin: Date;
  end: Date;
  messenger: any[];
  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  maxLevel: number;
}

export class Messenger extends Component<any, State> {
  adminService: AdminService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      messenger: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      maxLevel: 8,
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetMessenger();
  }

  handleGetMessenger = () => {
    this.adminService.admin_get_messenger().then((data) => {
      if (data.status == "success") {
        this.setState({ messenger: data.messenger });
      }
    });
  };

  handleUpdate = (name: any, id: any) => {
    this.adminService.admin_update_messenger(name, id).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "메신저",
          message: "메신저 정보가 업데이트 되습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
        // this.setState({ benefits: data.benefits });
      }
    });
  };

  //   handleMakeUserLevel = () => {
  //     this.adminService
  //       .admin_make_benefit_level(this.state.maxLevel, this.state.levels)
  //       .then((data) => {
  //         if (data.status == "success") {
  //           confirmAlert({
  //             title: "레벨",
  //             message: "레벨정보가 생성되었습니다.",
  //             buttons: [
  //               {
  //                 label: "확인",
  //                 onClick: () => {
  //                   window.location.reload();
  //                 },
  //               },
  //             ],
  //           });
  //         } else {
  //           confirmAlert({
  //             title: "레벨",
  //             message: "관리자에게 문의 부탁드림니다.",
  //             buttons: [
  //               {
  //                 label: "확인",
  //                 onClick: () => {
  //                   window.location.reload();
  //                 },
  //               },
  //             ],
  //           });
  //         }
  //       });
  //   };

  render() {
    let messenger = this.state.messenger;
    // const handelChange = (idx: any, percent: any) => {
    //   let ben = this.state.benefits.find((s) => s.idx == idx);
    //   if (ben != null) {
    //     ben.percent = percent;
    //     this.setState({ benefits: this.state.benefits });
    //   }
    // };
    const RenderPanel = () => {
      //   return messenger.map((ms, i) => {
      return (
        <DataTable
          value={messenger}
          paginatorPosition="both"
          rows={100}
          sortOrder={-1}
        >
          <Column
            style={{ textAlign: "center" }}
            header="name"
            body={(rowData: any, column: any) => {
              return rowData.name;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="아이디"
            body={(rowData: any, column: any) => {
              return (
                <InputText
                  value={rowData.messengerID}
                  onChange={(even: any) => {
                    rowData.messengerID = even.target.value;
                    this.setState({ messenger: messenger });
                  }}
                ></InputText>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="name"
            body={(rowData: any, column: any) => {
              return (
                <Button
                  label="저장 하기"
                  onClick={() => {
                    console.log(rowData.messengerID);
                    this.handleUpdate(rowData.name, rowData.messengerID);
                    //this.handleUpdateBenefit();
                  }}
                />
              );
            }}
          />
        </DataTable>
      );
      //   });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">메신저 설정</div>
                </div>
              </div>

              <div className="card">
                {/* {RenderBet()} */}

                {RenderPanel()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
