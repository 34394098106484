import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";

import { AgentService } from "../../service/agent.service";
import { SubPaging } from "../../share/sub-paging";

import {
    numTomoeny,
    convertIP,
    convertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    convertAgentLogText,
    convertAgentBalanceText,
    OpenUserDetail,
    OpenAgentDetail,
    convertBalanceType,
    convertMessage
} from "../../utility/help";

interface Props {
    agentid: any;
}

interface State {
    logs: any;
    //  balanceType: "all";
    activePage: any;
    maxCount: any;
    balanceType: any;
    balanceTypes: any;
}

export class AgentBalanceLogKor extends Component<Props, State> {
    AgentService: AgentService;

    constructor(props: Props) {
        super(props);
        this.state = {
            logs: [],
            balanceType: "balance",
            activePage: 1,
            maxCount: 1,
            balanceTypes: [
                { label: "모두", value: "all" },
                { label: "벨런스", value: "balance" },
                { label: "롤링", value: "rolling" },
                { label: "포인트", value: "point" },
                { label: "루징", value: "losing" },
                { label: "카지노 롤링", value: "crolling" },
            ],
        };

        this.AgentService = new AgentService();
    }

    componentDidMount = () => {
        this.handleGetAgentBalanceLog(1, "balance");
    };

    handleGetAgentBalanceLog = (skip: any, balanceType: any) => {
        this.AgentService.admin_get_agnet_balance_tree_log_by_id(
            this.props.agentid,
            "balanceType",
            balanceType,
            skip
        ).then((s: any) => {
            this.setState({
                activePage: skip,
                logs: s.logs,
                maxCount: s.maxCount,
            });
        });
    };

    handleMoveSel = (skip: any) => {
        this.handleGetAgentBalanceLog(skip, this.state.balanceType);
    };

    render = () => {
        return (
            <div>
                <div>
                    {/* <Button label="전체" />
          <Button label="벨런스" className="p-button-success" />
          <Button label="롤링" className="p-button-info" />
          <Button label="포인트" className="p-button-warning" />
          <Button label="루징" className="p-button-danger" /> */}

                    <div className="p-col-12">
                        <SelectButton
                            value={this.state.balanceType}
                            options={this.state.balanceTypes}
                            onChange={(event) => {
                                if (event.value != null) {
                                    this.setState({ balanceType: event.value });
                                    this.handleGetAgentBalanceLog(1, event.value);
                                }
                            }}
                        />
                    </div>
                </div>

                <SubPaging
                    limit={20}
                    activePage={this.state.activePage}
                    maxCount={this.state.maxCount}
                    MoveSel={(page) => {
                        this.handleMoveSel(page);
                    }}
                />

                <DataTable
                    value={this.state.logs}
                    paginatorPosition="both"
                    rows={20}
                    sortOrder={-1}
                    scrollable={true}
                >

                    <Column

                        style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }} header="타입"
                        body={(agent: any) => {
                            if (agent.gamename != null) {
                                return agent.gamename;
                            }

                            return <div>{convertAgentLogText(agent.logType)}</div>;
                        }}
                        field="agent"
                    />




                    <Column

                        style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}

                        header="에이젼트"
                        body={(rowData: any, column: any) => {
                            return rowData.agent;
                        }}
                    />
                    {/* 
                    <Column

                        style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}

                        header="아이디"
                        body={(rowData: any, column: any) => {
                            return rowData.id;
                        }}
                    /> */}

                    <Column

                        style={{ textAlign: "center", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}
                        header={"일시"}
                        body={(rowData: any, column: any) => {
                            return <div>{convertDate(rowData.regdate)}</div>;
                        }}
                        field="depositdate"
                    />

                    <Column
                        style={{ textAlign: "center", width: "300px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "300px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}

                        header={"내용"}
                        body={(rowData: any, column: any) => {
                            return <div><span dangerouslySetInnerHTML={{ __html: convertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>
                                <span dangerouslySetInnerHTML={{ __html: convertMessage(rowData.logType, rowData.simplememo) }} ></span>
                            </div>
                        }}
                    />

                    <Column


                        style={{ textAlign: "right", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}

                        header="벨런스 +-"
                        body={(rowData: any, column: any) => {
                            return (
                                <div style={{ color: getWithdrawColor() }}>
                                    {numTomoeny(rowData.balance)}
                                </div>
                            );
                        }}
                    />

                    <Column

                        style={{ textAlign: "right", width: "120px", borderRight: '1px solid #d8d8d8' }}
                        headerStyle={{ textAlign: "center", width: "120px", background: '#', color: '#000', borderRight: '1px solid #d8d8d8' }}

                        header="남은 벨런스"
                        body={(rowData: any, column: any) => {
                            return <div>{numTomoeny(rowData.afterbalance)}</div>;
                        }}
                    />
                </DataTable>

                <SubPaging
                    limit={20}
                    activePage={this.state.activePage}
                    maxCount={this.state.maxCount}
                    MoveSel={(page) => {
                        this.handleMoveSel(page);
                    }}
                />
            </div>
        );
    };
}
