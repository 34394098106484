import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";

import { BetMatchTable } from "../bet/bet-match-table";
import { BetMiniTable } from "../bet/bet-mini-table";
import { BetSlotTable } from "../bet/bet-slot-table";
import { BetCasinoTable } from "../bet/bet-casino-table";

import { CustomPagination } from "../custom-pagination";
import { BetService } from "../../service/bet.service";
import { GetToday, numTomoeny, convertDate, ConvertCalendar } from "../../utility/help";

interface Props {
  id: any;
  management: any;
  handleUserChange: () => any;
}

interface State {
  begin: any;
  end: any;
  activeIndex: any;
  matchs: any;
  details: any;
  sport_folder: any;
  casino_date: any;

  minis: any;
  lives: any;
  slots: any;
  casins: any;
  fxs: any;
  page: any;
  pages: any;
  field: any;
  activePage: any;
  maxCount: any;
  summary: any;
  findText: any;
  searchText: any;

  lastBalance: number,
  lastBonus: number,

  lastBegin: any;
  lastEnd: any;

}

export class UserBets extends Component<Props, State> {
  betService: BetService = new BetService();

  constructor(props: Props) {
    super(props);
    const today = GetToday()

    this.state = {
      lastBalance: 0,
      lastBonus: 0,

      activeIndex: 0,
      sport_folder: "all",
      casino_date: "-1",
      lastBegin: "",
      lastEnd: "",

      matchs: [],
      details: [],

      minis: [],
      lives: [],
      slots: [],
      casins: [],
      fxs: [],
      page: 0,
      pages: {},
      field: "",
      activePage: 1,
      maxCount: 1,
      summary: [],
      findText: "",
      searchText: "",
      begin: today.begin,
      end: today.end,
    };


    // this.handleMatchBet = this.handleMatchBet.bind(this);
    // this.handleLiveBet = this.handleLiveBet.bind(this);
    // this.handleMinisBet = this.handleMinisBet.bind(this);
    // this.handleSlotBet = this.handleSlotBet.bind(this);
    // this.handleCasinoBet = this.handleCasinoBet.bind(this);
    // this.onTableChange = this.onTableChange.bind(this);

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleMoveChange = this.handleMoveChange.bind(this);

    this.handlePageChange(0);

  }

  componentDidMount = () => {
    // this.setState({ id: this.props.id });


    //this.setState({ begin: today.begin, end: today.end });
  };

  handleMatchBet = (page: any) => {
    this.betService
      .get_admin_get_match_bets_by_userId(
        page,
        this.state.begin,
        this.state.end,
        "id",
        this.props.id,
        this.state.sport_folder
      )
      .then((data) => {
        for (let match of data.matchs) {
          this.betService
            .get_match_detail_by_betId(match._id)
            .then((detailData) => {
              if (detailData.status == "success") {
                this.state.details[detailData.betId] = detailData.detail;

                this.setState({
                  details: this.state.details,
                });
              }
            });
        }

        this.setState({
          matchs: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  handleLiveBet = (page: any) => {
    this.betService
      .get_live_bet_list(
        page,
        this.state.begin,
        this.state.end,
        "id",
        this.props.id,
        ''
      )
      .then((data) => {
        for (let match of data.matchs) {
          this.betService
            .get_match_detail_by_betId(match._id)
            .then((detailData) => {
              if (detailData.status == "success") {
                this.state.details[detailData.betId] = detailData.detail;

                this.setState({
                  details: this.state.details,
                });
              }
            });
        }

        this.setState({
          lives: data.matchs,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  handleMinisBet = (page: any) => {
    this.betService
      .get_mini_bet_list_by_id(
        page,
        this.state.begin,
        this.state.end,
        this.props.id
      )
      .then((data) => {
        this.setState({
          minis: data.minis,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  handleSlotBet = async (page: any, casino_date: any) => {

    const data = await this.betService
      .get_user_detail_slot_bets(
        page,
        this.state.begin.toLocaleString('sv-SE'),
        this.state.end.toLocaleString('sv-SE'),
        casino_date,
        "userID",
        this.props.id
      )


    if (data.status == "success") {
      this.setState({
        slots: data.slots,
        maxCount: data.maxCount,
        summary: data.summary,
        lastBalance: data.lastBalance,
        lastBonus: data.lastBonus,
        lastBegin: convertDate(data.lastBegin),
        lastEnd: convertDate(data.lastEnd),

      });
    }

  };

  handleCasinoBet = async (page: any, casino_date: any) => {

    const data = await this.betService.get_user_detail_casino_bets(
      page,
      this.state.begin.toLocaleString('sv-SE'),
      this.state.end.toLocaleString('sv-SE'),
      casino_date,
      "userID",
      this.props.id
    )

    if (data.status == "success") {
      this.setState({
        casins: data.bets,
        maxCount: data.maxCount,
        summary: data.summary,
        lastBalance: data.lastBalance,
        lastBonus: data.lastBonus,
        lastBegin: convertDate(data.lastBegin),
        lastEnd: convertDate(data.lastEnd),

      });
    }


  };

  onTableChange = (e: any) => {
    this.setState({ activeIndex: e.index });
    this.handleMoveChange(e.index, 0);
  };

  handlePageChange = (page: any) => {
    this.setState({ activePage: page });
    this.handleMoveChange(this.state.activeIndex, page);
  };

  handleMoveChange = (activeIndex: any, page: any) => {
    if (activeIndex == 0) {
      this.handleMatchBet(page);
    } else if (activeIndex == 1) {
      this.handleLiveBet(page);
    } else if (activeIndex == 2) {
      this.handleMinisBet(page);
    } else if (activeIndex == 3) {
      this.handleCasinoBet(page, this.state.casino_date);
    } else if (activeIndex == 4) {
      this.handleSlotBet(page, this.state.casino_date);
    }
  };

  render() {
    let options: any[] = [
      { label: "전부 보기", value: "all" },
      { label: "1폴더", value: "1" },
      { label: "2폴더", value: "2" },
      { label: "3폴더", value: "3" },
      { label: "4폴더 이상", value: "4" },
    ];

    const RenderSport = () => {
      if (this.props.management.sportpre === "y") {
        return (
          <TabPanel header="스포츠" leftIcon="pi-md-check">
            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-5">
                  <Calendar
                    placeholder="시작일"
                    showWeek={false}
                    dateFormat="yy-mm-dd"
                    value={this.state.begin}
                    onChange={(e) => this.setState({ begin: e.value })}
                    locale={ConvertCalendar()}

                  />

                  <Calendar
                    placeholder="종료"
                    showWeek={false}
                    dateFormat="yy-mm-dd"
                    value={this.state.end}
                    onChange={(e) => this.setState({ end: e.value })}
                    locale={ConvertCalendar()}

                  />
                </div>
                <div className="p-col-1">
                  <Button
                    icon="pi-md-search"
                    onClick={() => {
                      this.handleMatchBet(1);
                    }}
                  />
                </div>

                <div className="p-col-4">
                  <SelectButton
                    value={this.state.sport_folder}
                    options={options}
                    onChange={(e) => {
                      this.setState({ sport_folder: e.value });
                      // this.handleMatchBet(1);
                    }}
                  />
                </div>
                <div className="p-col-1">
                  <Button
                    icon="pi-md-search"
                    onClick={() => {
                      this.handleMatchBet(1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="p-col-6">
              <DataTable value={this.state.summary} rows={1}>
                <Column
                  style={{ textAlign: "center" }}
                  field="bet"
                  header="배팅금"
                  body={(rowData: any, column: any) => {
                    return (
                      <div>
                        <div>{numTomoeny(rowData.totalBet)}</div>
                      </div>
                    );
                  }}
                />

                <Column
                  style={{ textAlign: "center" }}
                  field="win"
                  header="이긴금액"
                  body={(rowData: any, column: any) => {
                    return <div>{numTomoeny(rowData.totalWin)}</div>;
                  }}
                />
                <Column
                  style={{ textAlign: "center" }}
                  field="win"
                  header="총횟수"
                  body={(rowData: any, column: any) => {
                    return <div>{numTomoeny(rowData.count)}</div>;
                  }}
                />
              </DataTable>
            </div>

            <CustomPagination
              active={this.state.activePage}
              take={5}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
            {this.state.matchs.map((contact: any, i: any) => {
              return (
                <BetMatchTable
                  bet={contact}
                  details={this.state.details[contact._id]}
                  key={contact._id}
                  mode="user"
                ></BetMatchTable>
              );
            })}
            <CustomPagination
              active={this.state.activePage}
              take={5}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
          </TabPanel>
        );
      }
      return <></>;
    };

    const RenderLive = () => {
      if (this.props.management.sportlive === "y") {
        return (
          <TabPanel header="라이브" leftIcon="pi-md-person">
            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>

            {this.state.lives.map((contact: any, i: any) => {
              return (
                <BetMatchTable
                  bet={contact}
                  key={contact._id}
                  mode={""}
                  details={this.state.details[contact._id]}
                ></BetMatchTable>
              );
            })}

            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
          </TabPanel>
        );
      }
      return <></>;
    };
    const RenderMini = () => {
      if (this.props.management.mini === "y") {
        return (
          <TabPanel header="미니게임">
            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-5">
                  <Calendar
                    placeholder="시작일"
                    showWeek={false}
                    dateFormat="yy-mm-dd"
                    value={this.state.begin}
                    onChange={(e) => {
                      console.log(e.value);
                      this.setState({ begin: e.value });
                    }}
                    locale={ConvertCalendar()}

                  />

                  <Calendar
                    placeholder="종료"
                    showWeek={false}
                    dateFormat="yy-mm-dd"
                    value={this.state.end}
                    onChange={(e) => this.setState({ end: e.value })}
                    locale={ConvertCalendar()}

                  />

                  <Button
                    icon="pi-md-search"
                    onClick={() => {
                      this.handleMinisBet(1);
                    }}
                  />
                </div>
                <div className="p-col-4">
                  <DataTable value={this.state.summary} rows={1}>
                    <Column
                      style={{ textAlign: "center" }}
                      field="bet"
                      header="배팅금"
                      body={(rowData: any, column: any) => {
                        return (
                          <div>
                            <div>{numTomoeny(rowData.totalBet)}</div>
                          </div>
                        );
                      }}
                    />
                    <Column
                      style={{ textAlign: "center" }}
                      field="win"
                      header="이긴금액"
                      body={(rowData: any, column: any) => {
                        return <div>{numTomoeny(rowData.totalWin)}</div>;
                      }}
                    />
                    <Column
                      style={{ textAlign: "center" }}
                      field="win"
                      header="총횟수"
                      body={(rowData: any, column: any) => {
                        return <div>{numTomoeny(rowData.count)}</div>;
                      }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
            <div className="p-col-6"></div>

            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
            <BetMiniTable bets={this.state.minis}
            ></BetMiniTable>
            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
          </TabPanel>
        );
      }
      return <></>;
    };

    const RenderCasino = () => {
      if (this.props.management.casino === "y") {
        return (

          <TabPanel header="카지노">
            <div className="p-col-12">
              <div className="p-grid">


                <div className="p-col-8">
                  <SelectButton
                    value={this.state.casino_date}
                    options={[
                      { label: "마지막충전", value: "-1" },
                      { label: "마지막두번째", value: "0" },
                      { label: "마지막세번째", value: "1" },
                      { label: "마지막네번째", value: "2" },
                    ]}
                    onChange={(e) => {
                      if (e.value == null) {
                        return
                      }
                      this.setState({ casino_date: e.value });

                      this.handleCasinoBet(1, e.value);
                    }}
                  />
                </div>

                {
                  this.state.summary && this.state.summary.length > 0 && (
                    <div className="p-col-12">

                      <div className="p-grid form-group" style={{ paddingLeft: 20, paddingTop: 10 }}>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastBegin)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>시작</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastEnd)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>종료</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastBalance)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>마지막충전</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.summary[0].totalBet)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" >배팅</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.summary[0].totalWin)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext">이긴금액</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny((this.state.summary[0].totalBet / this.state.lastBalance) * 100)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext">롤링</label>
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
            <BetCasinoTable bets={this.state.casins} mode={""}></BetCasinoTable>
            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
          </TabPanel>
        );
      }
      return <></>;
    };

    const RenderSlot = () => {
      if (this.props.management.slot === "y") {
        return (
          <TabPanel header="슬롯">

            <div className="p-col-12">
              <div className="p-grid">


                <div className="p-col-8">
                  <SelectButton
                    value={this.state.casino_date}
                    options={[
                      { label: "마지막충전", value: "-1" },
                      { label: "마지막두번째", value: "0" },
                      { label: "마지막세번째", value: "1" },
                      { label: "마지막네번째", value: "2" },
                    ]}
                    onChange={(e) => {
                      if (e.value == null) {
                        return
                      }
                      this.setState({ casino_date: e.value });

                      this.handleSlotBet(1, e.value);
                    }}
                  />
                </div>

                {
                  this.state.summary && this.state.summary.length > 0 && (
                    <div className="p-col-12">

                      <div className="p-grid form-group" style={{ paddingLeft: 20, paddingTop: 10 }}>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastBegin)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>시작</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastEnd)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>종료</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.lastBalance)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" style={{ color: 'red' }}>마지막충전</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.summary[0].totalBet)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext" >배팅</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny(this.state.summary[0].totalWin)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext">이긴금액</label>
                        </span>
                        <span className="p-float-label">
                          <InputText id="inputtext" value={numTomoeny((this.state.summary[0].totalBet / this.state.lastBalance) * 100)} readOnly style={{ width: 120 }} />
                          <label htmlFor="inputtext">롤링</label>
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
            <BetSlotTable slots={this.state.slots} mode={""}></BetSlotTable>
            <CustomPagination
              active={this.state.activePage}
              take={20}
              count={this.state.maxCount}
              handlePageChange={this.handlePageChange}
            ></CustomPagination>
          </TabPanel>
        );
      }
      return <></>;
    };

    const RenderFx = () => {
      if (this.props.management.fx === "y") {
        return (
          <TabPanel header="FX">
            The Godfather Part III is set in 1979 and 1980. Michael has moved
            back to New York and taken great strides to remove the family from
            crime. He turns over his New York criminal interests to longtime
            enforcer Joey Zasa. He uses his wealth in an attempt to rehabilitate
            his reputation through numerous philanthropic acts, administered by
            a foundation named after his father. A decade earlier, he gave
            custody of his two children to Kay, who has since remarried.
          </TabPanel>
        );
      }
      return <></>;
    };

    if (this.props.management == null) {
      return <></>;
    }

    return (
      <TabView
        activeIndex={this.state.activeIndex}
        onTabChange={this.onTableChange}
      >
        {RenderSport()}
        {RenderLive()}
        {RenderMini()}
        {RenderCasino()}
        {RenderSlot()}
        {RenderFx()}
      </TabView>
    );
  }
}
