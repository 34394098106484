import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";

import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import { confirmAlert } from "react-confirm-alert";

// C:\WORK\eBetting\admin\src\service\community.service.tsx
import { CommunityService } from "../service/community.service";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  AdvertisementsType: any[];
  advertisements: any[];
  advertisement: any;
  activePage: number;
  maxCount: number;
}

export class Advertisement extends Component<any, State> {
  communityService = new CommunityService();

  constructor(props: any) {
    super(props);

    this.state = {
      advertisements: [],
      activePage: 1,
      maxCount: 1,
      advertisement: {
        mode: "user",
        del: "n",
        contents: "",
        title: "",
        type: "",
        titleColor: "",
        userId: "",
      },
      AdvertisementsType: [
        { text: "모바일 매치 상단 광고", type: "mobile_game_advertisements" },
        { text: "모바일 왼쪽 광고", type: "mobile_left_advertisements" },
      ],
    };

    // this.handleGetMarkets = this.handleGetMarkets.bind(this);
    //    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.handleUserNote(1);
  }

  handleUserNote = (skip: any) => {
    this.setState({ activePage: skip });
    this.communityService.admin_get_advertisement(skip).then((data: any) => {
      if (data.status == "success") {
        this.setState({ advertisements: data.advertisement });
      }
    });
  };

  handleWirteNote = () => {
    this.communityService
      .admin_wirte_advertisement(this.state.advertisement)
      .then((data) => {
        if (data.status == "success") {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성/수정 성공.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        } else {
          confirmAlert({
            title: "팝업 공지",
            message: "팝업 공지 작성 실패.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                },
              },
            ],
          });
        }
      });
  };

  render() {
    let items = this.state.advertisements;
    let AdvertisementsType = this.state.AdvertisementsType;

    const actionTemplate = (rowData, column) => {
      return (
        <div>
          <Button
            icon="pi-md-edit"
            onClick={() => {
              this.setState({ advertisement: rowData });
            }}
          ></Button>
        </div>
      );
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-w-title">
                <div className="card-title">광고 공지</div>
                <div className="p-md-6">
                  {/* <Button
                    label="저장 하기"
                    onClick={() => {
                    }}
                  />

                  <Button
                    label="레벨 생성"
                    onClick={() => {
                    }}
                  /> */}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="card">
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleUserNote}
                ></CustomPagination>
                <DataTable
                  value={items}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="타이틀"
                    body={(rowData: any, column: any) => {
                      return rowData.title;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="보기/숨김"
                    body={(rowData: any, column: any) => {
                      return rowData.del == "y" ? "숨김" : "보기";
                    }}
                  />
                  <Column
                    body={actionTemplate}
                    style={{ textAlign: "center", width: "8em" }}
                  />
                </DataTable>
                <CustomPagination
                  active={this.state.activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handleUserNote}
                ></CustomPagination>{" "}
              </div>
            </div>
            <div className="p-col-12 p-md-8">
              <div className="card">
                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "40%",
                      }}
                      value={this.state.advertisement.title}
                      onChange={(e: any) => {
                        this.state.advertisement.title = e.target.value;
                        this.setState({
                          advertisement: this.state.advertisement,
                        });
                      }}
                    />
                    <label htmlFor="in">Title</label>
                  </span>
                </div>

                <div className="p-col-12">
                  <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "40%",
                      }}
                      value={this.state.advertisement.order}
                      onChange={(e: any) => {
                        this.state.advertisement.order = e.target.value;
                        this.setState({
                          advertisement: this.state.advertisement,
                        });
                      }}
                    />
                    <label htmlFor="in">순서</label>
                  </span>
                </div>

                <div className="p-col-12">
                  <div className="p-grid">
                    {AdvertisementsType.map((advertisement: any) => {
                      console.log(advertisement);
                      return (
                        <div className="p-col-12 p-md-3">
                          <RadioButton
                            value={advertisement.type}
                            inputId="rb1"
                            onChange={(event: any) => {
                              console.log(event);

                              this.state.advertisement.type =
                                event.target.value;
                              // this.state.advertisement.title = e.target.value;

                              this.setState({
                                advertisement: this.state.advertisement,
                              });
                            }}
                            checked={
                              this.state.advertisement.type ===
                              advertisement.type
                            }
                          />
                          <label htmlFor="rb1" className="p-radiobutton-label">
                            {advertisement.text}
                          </label>
                        </div>
                      );
                    })}
                    {/* <div className="p-col-12 p-md-4">
                      <RadioButton
                        value="Avalon"
                        inputId="rb2"
                        onChange={event =>
                          this.setState({ radioValue: event.value })
                        }
                        checked={this.state.radioValue === "Avalon"}
                      />
                      <label htmlFor="rb2" className="p-radiobutton-label">
                        Avalon
                      </label>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <RadioButton
                        value="Serenity"
                        inputId="rb3"
                        onChange={event =>
                          this.setState({ radioValue: event.value })
                        }
                        checked={this.state.radioValue === "Serenity"}
                      />
                      <label htmlFor="rb3" className="p-radiobutton-label">
                        Serenity
                      </label>
                    </div> */}
                  </div>

                  {/* <span className="p-float-label">
                    <InputText
                      id="in"
                      style={{
                        width: "40%"
                      }}
                      value={this.state.notice.title}
                      onChange={(e: any) => {
                        this.state.notice.title = e.target.value;
                        this.setState({ notice: this.state.notice });
                      }}
                    />
                    <label htmlFor="in">순서</label>
                  </span> */}
                </div>
                <div className="p-col-12">
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.advertisement.contents}
                    onTextChange={(e) => {
                      this.state.advertisement.contents = e.htmlValue;

                      this.setState({
                        advertisement: this.state.advertisement,
                      });
                    }}
                  />
                </div>
                <div className="p-col-12">
                  <div className="p-col-12 p-md-4">
                    <RadioButton
                      value="y"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.advertisement.del = event.value;

                        this.setState({
                          advertisement: this.state.advertisement,
                        });
                      }}
                      checked={this.state.advertisement.del === "y"}
                    />
                    <label htmlFor="cb1" className="p-checkbox-label">
                      숨김
                    </label>
                    {"      "}
                    <RadioButton
                      value="n"
                      inputId="rb1"
                      onChange={(event) => {
                        this.state.advertisement.del = event.value;
                        this.setState({
                          advertisement: this.state.advertisement,
                        });
                      }}
                      checked={this.state.advertisement.del === "n"}
                    />

                    <label htmlFor="cb2" className="p-checkbox-label">
                      보기
                    </label>
                  </div>
                </div>
                <div className="p-col-12">
                  <Button
                    label="저장 하기"
                    onClick={() => {
                      this.handleWirteNote();
                      //this.handleEditNotice(this.state.notice);
                      //this.handleUpdateBenefit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
