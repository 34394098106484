import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  teams: any;
  maxCount: number;
  activePage: number;
  selectItem: any;
  select: any;
  searchText: any;
  findText: any;
}

export class SetTeams extends Component<any, State> {
  adminService: AdminService;
  growl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      teams: [],
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      searchText: "",
      findText: "",

      select: [{ label: "팀", value: "agent" }],
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetTeams(1, "", "");
  }

  handlePageChange = (page: any, searchText: any, findText: any) => {
    console.log(page);
    this.setState({ activePage: page });
    this.handleGetTeams(page, searchText, findText);
  };

  handleGetTeams = (page, searchText, findText) => {
    this.adminService
      .admin_get_teams(page, searchText, findText)
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({
            teams: data.teams,
            maxCount: Number(data.maxCount),
          });
        }
      });
  };

  render() {
    let teams = this.state.teams;
    let activePage = this.state.activePage;
    let adminService = this.adminService;
    let growl: any;

    const handleEditTeam = (TeamID: any, TeamName: any) => {
      adminService.admin_update_teams(TeamID, TeamName).then((data: any) => {
        if (data.status == "success") {
          let msg = {
            severity: "success",
            summary: data.name,
            detail: "가 수정되었습니다",
          };
          this.growl.show(msg);
        } else {
          let msg = {
            severity: "error",
            summary: data.tournamentid + " - " + data.name,
            detail: "예러 발생 되었습니다 관리자에게 문의",
          };
          this.growl.show(msg);
        }
      });
    };

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">팀 설정</div>

                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.select}
                          value={this.state.searchText}
                          onChange={(event: any) =>
                            this.setState({
                              searchText: event.value,
                            })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(event: any) => {
                            this.setState({
                              findText: event.target.value,
                            });
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={(event: any) => {
                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <Growl
                ref={(el) => (this.growl = el)}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText
                    );
                  }}
                ></CustomPagination>

                <DataTable
                  value={teams}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}
                  style={{ width: "1600px" }}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="번호"
                    body={(rowData: any, column: any) => {
                      return rowData.ID;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="영어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.en == null) return rowData.Name;
                      return rowData.Name.en;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="한국어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.ko == null) {
                        rowData.Name.ko = rowData.Name.en;
                      }

                      return (
                        <InputText
                          style={{ width: "300px" }}
                          value={rowData.Name.ko}
                          onChange={(even: any) => {
                            console.log(rowData.Name);
                            let tournament = this.state.teams.find(
                              (s: any) => s.ID == rowData.ID
                            );
                            tournament.Name.ko = even.target.value;

                            this.setState({
                              teams: this.state.teams,
                            });
                          }}
                        ></InputText>
                      );
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="등록일"
                    body={(rowData: any, column: any) => {
                      return rowData.LastUpdate;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="수정"
                    body={(rowData: any, column: any) => {
                      return (
                        <Button
                          label="확인"
                          onClick={() => {
                            handleEditTeam(rowData.ID, rowData.Name.ko);
                          }}
                        />
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
