import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { DataTable } from "primereact/datatable";
import { Tree } from "primereact/tree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { FullCalendar } from "primereact/fullcalendar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from 'primereact/overlaypanel';

import { UserService } from "../service/user.service";
import { RadioButton } from 'primereact/radiobutton';

import { HelpBox } from "../share/user/help-box";
import { UserTable } from "../share/user/user-table";
import { Item, Separator, Submenu, MenuProvider } from "react-contexify";
import { AlertYesNo } from "../share/alert-yes-no";

import { confirmAlert } from "react-confirm-alert";

import { Menu } from "primereact/menu";

import { SubPaging } from "../share/sub-paging";
import { BalanceService } from "../service/balance.service";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  OpenUserDetail,
  ConvertNick

} from "../utility/help";
import { CONNREFUSED } from "dns";

interface State {
  maxCount: number;
  userTableValue: any[];
  page: number;
  pages: any;
  search: string;
  find: string;
  sortType: string;
  sort: number;
  kmenu?: any;
  treefilter: string

}

export class UserConnectionList extends Component<any, State> {
  refConnection = React.createRef() as any;
  balanceService: BalanceService = new BalanceService();

  userService = new UserService();
  // balanceService = new BalanceService();

  menu: any;

  giveBalance = React.createRef() as any;


  constructor(props: any) {
    super(props);
    this.state = {
      userTableValue: [],
      page: 1,
      pages: {},
      search: "",
      find: "",
      sortType: "regdate",
      sort: 1,
      maxCount: 1,
      treefilter: ''
    };
  }

  componentDidMount() {
    this.GetUserList(
      0,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    this.userService
      .user_health_list(page, find, search, sortType, sort, "used")
      .then(data => {
        this.setState({
          userTableValue: data.users,
          maxCount: data.maxCount
        });
      });
  };

  SortTable = (sortType: any, sort: any) => {
    this.setState({ sortType: sortType, sort: sort });
    this.GetUserList(0, this.state.find, this.state.search, sortType, sort);
  };

  SearchData = (search: any, find: any) => {
    this.setState({ search: search, find: find });
    this.GetUserList(0, find, search, this.state.sortType, this.state.sort);
  };

  MoveSel = (page: any) => {
    this.GetUserList(
      page,
      this.state.find,
      this.state.search,
      this.state.sortType,
      this.state.sort
    );
  };


  handleBalanceUpdate = (id: string) => {
    let user = this.state.userTableValue.find((data) => data.id == id);
    if (user != null) {
      user.refresh = true;
    }

    try {
      this.balanceService.get_in_game_balance(id).then((s: any) => {
        if (s.status === "success") {
          let user = this.state.userTableValue.find((data) => data.id == s.id);
          if (user != null) {
            user.balance = s.balance;
            user.in_balance = 0;
            user.refresh = false;

            this.setState({
              userTableValue: this.state.userTableValue,
            });
          }
        }
        else {
        }
      });
    }
    catch {
      user.refresh = false;
    }

  };



  handleAllBalance = () => {

    try {
      this.balanceService.get_update_all_balance().then((s: any) => {
        if (s.status === "success") {
          confirmAlert({
            title: "확인.",
            message: "30초이후에 자동 새로고침됨니다.",
            buttons: [
              {
                label: "예",
                onClick: () => {
                  setTimeout(function () {
                    window.location.reload()
                  }, 30000);


                }
              },
            ]
          });
          // let user = this.state.userTableValue.find((data) => data.id == s.id);
          // if (user != null) {
          //   user.balance = s.balance;
          //   user.in_balance = 0;
          //   user.refresh = false;

          //   this.setState({
          //     userTableValue: this.state.userTableValue,
          //   });
          // }
        }
        else {
          confirmAlert({
            title: "에러.",
            message: `해당기능은 ${s.time} 이후에 사용가능합니다.`,
            buttons: [
              {
                label: "예",
                onClick: () => {

                }
              },
            ]
          });
        }
      });
    }
    catch {
    }

  };




  render() {



    const treeFilter = <InputText value={this.state.treefilter} onChange={(e: any) => {
      this.refConnection.current.filter(e.target.value, 'agent_tree_ko', 'custom');
      this.setState({ treefilter: e.target.value })
    }} />

    return (
      <div>

        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">

              <div className="card card-w-title">
                <div className="card-title">접속 유저 리스트</div>

                <div className="p-md-6">
                  <div className="p-grid form-group">{
                    (process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOR' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KOM' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'WINLOSE' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'KCROWN' ||
                      process.env.REACT_APP_API_CALCULATE_REAL_ROLLING === 'CASINO'
                    )
                    && (
                      <AlertYesNo
                        type="success"
                        className="p-button-success"
                        btnLabel="벨런스 업데이트"
                        title="벨런스 업데이트"
                        message="벨런스 새로고침을 사용하시겠습니까 ? 해당기능은 30분에 한번 사용가능합니다."
                        call={this.handleAllBalance}
                      ></AlertYesNo>
                    )
                  }

                  </div>
                </div>
              </div>

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>


              <DataTable
                value={this.state.userTableValue}
                paginatorPosition="both"
                sortOrder={-1}
                scrollable={true}

                rows={99999}
                ref={this.refConnection}
              >
                <Column
                  style={{ textAlign: "center", width: "120px" }}
                  header="아이디"
                  field="id"
                  sortable
                  filter
                  filterPlaceholder="에이전트 아이디"
                  body={(rowData) => {
                    return (
                      <div>
                        <div
                          onClick={() => {
                            OpenUserDetail(rowData.id);
                          }}
                          style={{ color: rowData.color }}
                        >



                          {rowData.id}
                          <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                          <br />({rowData.bankowner})
                        </div>
                      </div>
                    );
                  }}
                />


                <Column
                  style={{ textAlign: "right", width: "120px" }}
                  bodyStyle={{ textAlign: "right", width: "120px" }}
                  header={"보유금액"}
                  field="balance"
                  sortable
                  body={(rowData) => {
                    return (
                      <div>
                        <span>
                          {numTomoeny(rowData.balance + rowData.in_balance)}
                        </span>
                        {
                          rowData.refresh == true ? (
                            <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }} ></i>

                          ) : (
                            <i
                              className="pi-md-refresh"
                              onClick={() => {
                                this.handleBalanceUpdate(rowData.id);
                              }}
                            ></i>

                          )
                        }

                      </div>
                    );
                  }}
                />


                {/* <Column
                  style={{ textAlign: "center", width: "120px" }}
                  header={""}
                  sortable
                  body={(rowData) => {
                    return (
                      <Button type="button" label={'충/환전'} onClick={(e: any) => {


                        // this.setState({ userID: rowData.id })
                        // this.giveBalance.current.toggle(e)

                      }} aria-haspopup aria-controls="overlay_panel" className="select-product-button" />

                    );
                  }}
                /> */}

                <Column
                  style={{ textAlign: "center", width: "100px" }}
                  header={"포인트"}
                  field="point"
                  sortable

                  body={(rowData) => {
                    return (
                      <div>
                        <span>{numTomoeny(rowData.point)}</span>
                      </div>
                    );
                  }}
                />
                {/* <Column
                  style={{ textAlign: "center", width: "120px" }}
                  header={"충전 합계"}
                  field="total_deposit"
                  sortable body={(rowData) => {
                    return (
                      <div>
                        <span>{numTomoeny(rowData.total_deposit)}</span>
                      </div>
                    );
                  }}
                />
                <Column
                  style={{ textAlign: "center", width: "120px" }}
                  header={"환전 합계"}
                  field="total_withdraw"
                  sortable body={(rowData) => {
                    return (
                      <div>
                        <span>{numTomoeny(rowData.total_withdraw)}</span>
                      </div>
                    );
                  }}
                /> */}

                {/* <Column
                  style={{ textAlign: "center", width: "140px" }}
                  header={"마지막 충전"}
                  field="lastDeposit"
                  sortable
                  body={(rowData) => {
                    return (
                      <div>
                        <span>{convertDate(rowData.lastDeposit)}</span>
                      </div>
                    );
                  }}
                />

                <Column
                  style={{ textAlign: "center", width: "140px" }}
                  header={"마지막 환전"}
                  field="lastWithdraw"
                  sortable
                  body={(rowData) => {
                    return (
                      <div>
                        <span>{convertDate(rowData.lastWithdraw)}</span>
                      </div>
                    );
                  }}
                /> */}
                {/* 
                <Column
                  style={{ textAlign: "center", width: "140px" }}
                  header={"가입일"}
                  field="regdate"
                  sortable
                  body={(rowData) => {
                    return (
                      <div>
                        <span>{convertDate(rowData.regdate)}</span>
                      </div>
                    );
                  }}
                /> */}

                <Column
                  style={{ textAlign: "center", width: "140px" }}
                  header={"접속일"}
                  sortable
                  field="logindate"
                  body={(rowData) => {
                    return (
                      <div>
                        <span>{convertDate(rowData.logindate)}</span>
                      </div>
                    );
                  }}
                />


                <Column
                  style={{ textAlign: "center", width: "120px" }}
                  header={""}
                  field=""
                  body={(rowData) => {
                    return (
                      <Button
                        style={{ backgroundColor: "red" }}
                        label="중지"
                        onClick={() => {
                          let color = "#dc3545";
                          // this.handleSaveUser(rowData)
                          // handleUserChange({
                          //   target: { id: "color", value: color },
                          // });
                        }}
                      />
                    );
                  }}
                />


                <Column
                  style={{ textAlign: "left", width: "600px" }}
                  headerStyle={{ textAlign: "left", width: "600px", }}

                  header={"에이전트"}
                  field="agent_tree_ko"
                  filter
                  sortable
                  filterElement={treeFilter}
                  filterFunction={(value, filter) => {
                    return value.indexOf(filter) >= 0
                  }}
                  body={(rowData) => {
                    return (
                      <div>
                        <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                      </div>
                    );
                  }}
                />

              </DataTable>
              {/* <UserTable
                userTableValue={this.state.userTableValue}
                SortTable={this.SortTable}
                mode="used"
                handleUseStauseChange={() => {}}
              ></UserTable> */}

              <SubPaging
                activePage={this.state.page}
                maxCount={this.state.maxCount}
                limit={20}
                MoveSel={(page: number) => {
                  this.MoveSel(page);
                }}
              ></SubPaging>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
