

import { Console } from "console";
import { GetToday, numTomoeny, convertDate, numTomoeny2Fix } from "./help";

const evolapi = (context) => {


    if (context == null) {
        return ''
    }

    let text = `
    <table >

    `
    // if (info.gamename.toLowerCase().indexOf('baccarat') >= 0) {

    try {
        // text = text + `<div>`

        // if (context.result != null && context.result.banker != null) {
        //     text = text + `<span style = "color:blue">뱅커 : </span>`

        //     for (var card of Object.values(context.result.banker.cards) as any) {
        //         text = text + `<span style = "color:blue"> ${card}</span>`
        //     }
        // }

        // text = text + `<span style="color:#000;font-weight:bold"> - </span>`
        // if (context.result != null && context.result.player != null) {
        //     text = text + `<span style="color:red">플레이어 : </span>`
        //     for (var card of Object.values(context.result.player.cards) as any) {
        //         text = text + `<span style="color:red"> ${card}</span>`
        //     }

        // }
        // text = text + `</div>`



        for (var participant of Object.values(context.participants) as any) {
            for (var bet of Object.values(participant.bets) as any) {
                text = text + `
                <tr style="border-bottom : 1px solid #d8d8d8">
                    <td style="color:${bet.payout - bet.stake > 0 ? 'blue' : ''}">${bet.description}</td>
                    <td style="color:${bet.payout - bet.stake > 0 ? 'blue' : ''}">${numTomoeny(bet.stake)}</td>
                    <td style="color:${bet.payout - bet.stake > 0 ? 'blue' : ''}">${numTomoeny(bet.payout)}</td>
                </tr>`
            }
        }

        text = text + `</table>`


        //     <div style="border-bottom : 1px solid #d8d8d8">
        //     <span style="color:green;width:80px">배팅 : ${bet.description}</span>
        //     <span style="color:#ff9800;width:80px">배팅금 : ${numTomoeny(bet.stake)}</span>
        //     <span style="color:#000;width:80px">결과 : ${numTomoeny(bet.payout)}</span>
        // </div>
        return text
    }
    catch (ex) {

    }
    // }


    return text
}




export const ConvertBenefits = (benefits, userId, userRolling, userPercent) => {



    if (benefits == null) return '<div></div>'
    let text = `<table>`

    for (var benefit of benefits) {


        text = text + `
        <tr style="border-bottom : 1px solid #d8d8d8">
            <td>${benefit.agentId}</td>
            <td>${numTomoeny(Number(benefit.giveBalance))}</td>
            <td>${numTomoeny2Fix(Number(benefit.percent))}</td>
            <td>${numTomoeny(Number(benefit.afterBalance))}</td>
        </tr>`
    }

    text = text + `
    <tr style="color: #ffc107">
        <td>${userId}</td>
        <td>${numTomoeny(Number(userRolling))}</td>
        <td>${numTomoeny2Fix(Number(userPercent))}</td>
        <td>-</td>
    </tr>`
    text = text + `</table>`

    return text

}


const viewAG = (result: any) => {
    if (result == null) {
      return ''
    }
    let text = `<table>`
  
    const infos = result.split(':')
    if (infos.length === 2 && infos[0].indexOf('P;') >= 0 && infos[1].indexOf('B;') >= 0) {
      const player = infos[0].replace('P;', '')
      const banker = infos[1].replace('B;', '')
  
      text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>Player</td>`
  
      for (const card of player.split(';')) {
        text =
          text +
          `
                      <td>
                          <div class="poker-card-ve clover">
                              <b>${card[1]}</b>
                              <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[0]}.svg" alt="clover">
                          </div>
                      </td>
                      `
      }
      text = text + `</tr>`
      text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>Banker</td>`
  
      for (const card of banker.split(';')) {
        text =
          text +
          `
                      <td>
                          <div class="poker-card-ve clover">
                              <b>${card[1]}</b>
                              <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[0]}.svg" alt="clover">
                          </div>
                      </td>
                      `
      }
      text = text + `</tr>`
    }
    text = text + `</table>`
  
    return text
  }
  
  const viewDG = (infos: any) => {
    let text = `<table>`
  
    if (infos['player'] !== null && infos['banker'] !== null) {
      text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>Player</td>`
  
      for (const card of infos['player']) {
        text =
          text +
          `
                      <td>
                          <div class="poker-card-ve clover">
                              <b>${card[1]}</b>
                              <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[0]}.svg" alt="clover">
                          </div>
                      </td>
                      `
      }
      text = text + `</tr>`
      text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>Banker</td>`
  
      for (const card of infos['banker']) {
        text =
          text +
          `
                      <td>
                          <div class="poker-card-ve clover">
                              <b>${card[1]}</b>
                              <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[0]}.svg" alt="clover">
                          </div>
                      </td>
                      `
      }
      text = text + `</tr>`
    }
    text = text + `</table>`
  
    return text
  }
  
  const viewPPC = (infos: any) => {
    return infos.resultGame
  }
  
  const viewBOTA = (infos: any) => {
    // if (context == null) {
    //   return ''
    // }
    return infos.result
  }
  
const viewEvol = (result: any) => {
    if (result == null || result.result == null) {
      return ''
    }
  
    let tableSet = ''
    const seats = result.result.seats
  
    if (seats != null) {
      tableSet = tableSet + `<table>`
      for (const seat of Object.keys(seats) as any) {
        const cards = seats[seat].cards
        if (cards != null) {
          tableSet = tableSet + `<tr style="border-top : 1px solid #d8d8d8;"><td>${seat}`
  
          for (const card of Object.values(cards) as any) {
            tableSet =
              tableSet +
              `
                      <div class="poker-card-ve clover">
                          <b>${card[0]}</b>
                          <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[1]}.svg" alt="clover">
                      </div>`
          }
          tableSet = tableSet + `</td>`
        }
      }
  
      tableSet = tableSet + `</table>`
    }
  
    console.log(result.result)

    const betResult = result.result

    let text = `<table>`
    for (const gameResult of Object.keys(betResult)) {
      if (result != null && result.result != null) {
        // 식보
        if (gameResult === 'first' || gameResult === 'second' || gameResult === 'third') {
          text =
            text +
            `
                  <td>
                          <img src="http://d2ov46plj76ptt.cloudfront.net/cards/dice${betResult[gameResult]}.svg" style="width:42px" >
                  </td>
                  `
        } // 식보
        else if (gameResult.indexOf('buttonsCount') >= 0) {
          const info = betResult[gameResult]
          text =
            text +
            `
                <td>
                        <b>${gameResult} (${info})</b>
                </td>
                `
        } else if (gameResult.indexOf('outcomes') >= 0) {
          for (const outcomes of Object.values(betResult.outcomes) as any) {
            for (const key of Object.keys(outcomes) as any) {
              text = text + `<tr style="border-bottom : 1px solid #d8d8d8">`
              text = text + `<td>${key}     <b>${outcomes[key]}</b></td>`
              text = text + `</tr>`
            }
          }
        } else if (gameResult.indexOf('luckyNumbers') >= 0 || gameResult.indexOf('winningNumbers') >= 0) {
          for (const numbers of Object.values(betResult[gameResult]) as any) {
          }
        } else if (gameResult.indexOf('tiger') >= 0 || gameResult.indexOf('dragon') >= 0) {
          const score = betResult[gameResult].score
  
          text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>${gameResult} (${score})</td>`
  
          const info = betResult[gameResult].card
          text =
            text +
            `
                  <td>
                      <div class="poker-card-ve clover">
                          <b>${info[0]}</b>
                          <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${info[1]}.svg" alt="clover">
                      </div>
                  </td>
                  `
        } else if (gameResult === 'seats') {
          text = text + `<td colspan='4'>${tableSet}</td>`
        } else if (gameResult.indexOf('banker') >= 0 || gameResult.indexOf('player') >= 0) {
          const cards = betResult[gameResult].cards
          if (cards == null) {
            continue
          }
          const score = result.result[gameResult].score
  
          text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>${gameResult} (${score})</td>`
  
          for (const card of Object.values(cards) as any) {
            if (card[0] == null) {
              continue
            }
  
            text =
              text +
              `
                          <td>
                              <div class="poker-card-ve clover">
                                  <b>${card[0]}</b>
                                  <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[1]}.svg" alt="clover">
                              </div>
                          </td>
                          `
          }
        }
      }
  
      text = text + `</tr>`
    }
  
    text = text + `</table>`
  
    return text
  }

  export const ConvertResult = (info) => {
    const { context, content, thirdParty } = info
  
    if (thirdParty === 'evol' || thirdParty === 'evc' ||   thirdParty ==='Evolution') {
      return viewEvol(content != null ? content : context)
    }
  
    if (thirdParty === 'agc') {
      return viewAG(content.result)
    }
  
    if (thirdParty === 'dg') {
      return viewDG(content)
    }
  
    if (thirdParty === 'ppc') {
      return viewPPC(content)
    }
  
    if (thirdParty === 'bota') {
      return viewBOTA(content)
    }
  
    return ''
  }

// export const ConvertResult = (info) => {


//     const { context, content, thirdParty } = info

//     if (thirdParty !== "evol" && thirdParty !== "evc" && thirdParty !== "Evolution") {
//         return ''
//     }

//     const result = content != null ? content : context

//     if (result == null) {
//         return ''
//     }

//     let tableSet = ''
//     const seats = result.result.seats

//     if (seats != null) {
//         tableSet = tableSet + `<table>`
//         for (const seat of Object.keys(seats) as any) {

//             console.log(seats[seat])
//             const cards = seats[seat].cards
//             if (cards != null) {
//                 tableSet = tableSet + `<tr style="border-top : 1px solid #d8d8d8;"><td>${seat}`

//                 for (const card of Object.values(cards) as any) {
//                     tableSet = tableSet + `
//                     <div class="poker-card-ve clover">
//                         <b>${card[0]}</b>
//                         <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[1]}.svg" alt="clover">
//                     </div>`
//                 }
//                 tableSet = tableSet + `</td>`
//             }

//         }

//         tableSet = tableSet + `</table>`
//     }

//     let text = `<table>`
//     for (const gameResult of Object.keys(result.result)) {





//         if (result != null && result.result != null) {
//             const cards = result.result[gameResult].cards
//             const score = result.result[gameResult].score

//             console.log(gameResult)


//             if (score == null) {
//                 text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>${gameResult}</td>`
//             } else {
//                 text = text + `<tr style="border-bottom : 1px solid #d8d8d8"><td>${gameResult} (${score})</td>`
//             }

//             // 식보
//             if (gameResult === 'first' || gameResult === 'second' || gameResult === 'third') {
//                 text = text + `
//                 <td>
//                         <img src="http://d2ov46plj76ptt.cloudfront.net/cards/dice${result.result[gameResult]}.svg" style="width:42px" >
//                 </td>
//                 `
//             }// 식보
//             else if (gameResult === 'luckyNumbers' || gameResult === 'winningNumbers') {
//                 for (const numbers of Object.values(result.result[gameResult]) as any) {

//                 }
//             }
//             else if (gameResult === 'tiger' || gameResult === 'dragon') {
//                 const info = result.result[gameResult].card
//                 console.log(info)
//                 text = text + `
//                 <td>
//                     <div class="poker-card-ve clover">
//                         <b>${info[0]}</b>
//                         <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${info[1]}.svg" alt="clover">
//                     </div>
//                 </td>
//                 `
//             }
//             else if (gameResult === 'seats') {
//                 text = text + `<td colspan='4'>${tableSet}</td>`
//             }
//             else if (cards != null) {
//                 for (const card of Object.values(cards) as any) {
//                     if (card[0] == null) {
//                         continue
//                     }

//                     text = text + `
//                     <td>
//                         <div class="poker-card-ve clover">
//                             <b>${card[0]}</b>
//                             <img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card[1]}.svg" alt="clover">
//                         </div>
//                     </td>
//                     `
//                     //<td><img src="http://d2ov46plj76ptt.cloudfront.net/cards/${card}.png" style="width:42px"></td>
//                 }
//             }

//         }

//         text = text + `</tr>`

//     }
//     // <td style="color:${bet.payout - bet.stake > 0 ? 'blue' : ''}">${numTomoeny(bet.stake)}</td>
//     // <td style="color:${bet.payout - bet.stake > 0 ? 'blue' : ''}">${numTomoeny(bet.payout)}</td>

//     text = text + `</table>`

//     // text = text + `<div>`

//     // if (context.result != null && context.result.banker != null) {
//     //     text = text + `<span style = "color:blue">뱅커 : </span>`

//     //     for (var card of Object.values(context.result.banker.cards) as any) {
//     //         text = text + `<span style = "color:blue"> ${card}</span>`
//     //     }
//     // }

//     // text = text + `<span style="color:#000;font-weight:bold"> - </span>`
//     // if (context.result != null && context.result.player != null) {
//     //     text = text + `<span style="color:red">플레이어 : </span>`
//     //     for (var card of Object.values(context.result.player.cards) as any) {
//     //         text = text + `<span style="color:red"> ${card}</span>`
//     //     }

//     // }
//     // text = text + `</div>`




//     return text
// }





export const ConvertContext = (info) => {

    // if (info.thirdParty === 'evolapi' || info.thirdParty === 'evol') {

    const { context, content } = info

    return content != null ? evolapi(content) : evolapi(context)
    // }


    // return ''

}
