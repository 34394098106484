import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal, ConvertCalendar } from "../utility/help";

interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];
}

export class CalculatesubAgentNor extends Component<Props, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: Props) {
        super(props);

        const today = new Date();
        const begin = new Date(
            `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        );

        today.setMonth(today.getMonth() + 1);

        const end = new Date(
            `${today.getFullYear()}-${today.getMonth() + 1}-01 00:00:00`
        );

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
        };

        this.handlegetSubAgentCalculate(begin, end);

    }

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_sub_agent(this.props.agentId, begin, end).then((data) => {
            if (data.status == "success") {
                this.setState({ agentInfo: data.agentCombine });
            }
        });
    }

    componentDidMount() {
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }


        // let headerGroup = (
        //     <ColumnGroup>
        //         {/* <Row>
        //             <Column header="Product" rowSpan={4} />
        //             <Column header="Sale Rate" colSpan={4} />
        //         </Row> */}
        //         <Row>
        //             <Column header="아이디" colSpan={4} />
        //             <Column header="Sales" colSpan={2} />
        //             <Column header="Profits" colSpan={2} />
        //         </Row>
        //         <Row>
        //             <Column header="Last Year" sortable field="lastYearSale" />
        //             <Column header="This Year" sortable field="thisYearSale" />
        //             <Column header="Last Year" sortable field="lastYearProfit" />
        //             <Column header="This Year" sortable field="thisYearProfit" />
        //         </Row>
        //     </ColumnGroup>
        // );
        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">부본사 {this.props.agentId}</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                locale={ConvertCalendar()}
                                                showWeek={false}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">

                                <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={9999}
                                    sortOrder={-1}
                                    rowClassName={rowClass}
                                    scrollHeight="600px"

                                    style={{ width: isMobile ? '1600px' : '100%' }}

                                    scrollable

                                // headerColumnGroup={headerGroup}
                                >
                                    <Column
                                        style={{
                                            textAlign: "center", borderRight: '1px solid #d8d8d8'
                                        }}
                                        header="부본사"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 1) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                    </a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;


                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="총판"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 2) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="매장"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 3) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="매장?"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 4) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="접속 유저"
                                        body={(rowData: any, column: any) => {
                                            return <>
                                                <span style={{ color: rowData.conectCount > 0 ? 'red' : 'blue' }}>{rowData.conectCount}</span>
                                            </>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 입금</div><div>총판 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 출금</div><div>총판 회수</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>Bet</div><div>Win</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                            </div>;

                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>총회원 루징</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                            </div>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤링 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>
                                            <div>정산금</div>
                                            <div>루징프로테이지%</div>
                                        </div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) * rowData.benefitLosing) / 100) - ((rowData.totalSlotBet) * rowData.benefitSlotRolling) / 100)}</div>
                                                <div>{rowData.benefitLosing}%</div>
                                            </div>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤링</div><div>프로테이지</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(((rowData.totalSlotBet) * rowData.benefitSlotRolling) / 100)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                            </div>;
                                        }}
                                    />
                                </DataTable>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
