import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

import { AgentService } from "../service/agent.service";

import * as AgentData from "../interface/agent";

import { SubPaging } from "../share/sub-paging";

import { OpenAgentDetail } from "../utility/help";

interface AgentState {
  codes: any;
  maxCount: number;
  activePage: number;
  dropdownItem: string;
  seach: string;
  newType: any;
  newCode: any;
  newAgent: any;
  newCount: any;
  newUserId: any;
  selectBox: any;
}

export class AgentCode extends Component<any, AgentState> {
  AgentService: AgentService;
  constructor(props: any) {
    super(props);

    this.state = {
      codes: [],
      maxCount: 1,
      activePage: 1,

      dropdownItem: "",
      seach: "",

      newType: "",
      newCode: "",
      newAgent: "",
      newCount: "",
      newUserId: "",

      selectBox: [
        { label: "선택", value: null },
        { label: "유저", value: "user" },
        { label: "에이전트", value: "agent" },
      ],
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);

    this.AgentService = new AgentService();
  }

  componentDidMount() {
    this.handleAgentCode(1);
  }

  MoveSel = (page: any) => {
    this.handleAgentCode(page);
  };

  handleAgentCode = (page) => {
    this.AgentService.admin_get_agent_codes(page).then((s: any) => {
      this.setState({
        codes: s.codes,
        activePage: page,
        maxCount: s.maxCount,
      });
    });
  };

  handleEditAgentCode = (obj: any) => {
    this.AgentService.admin_edit_agent_code(obj).then((s: any) => {
      if (s.status == "success") {
        confirmAlert({
          title: "가입코드",
          message: "가입코드를 수정하였습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "가입코드",
          message: "관리자에게 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      }
    });
  };
  handleDelAgentCode = (idx: any) => {
    this.AgentService.admin_del_agent_code(idx).then((s: any) => {
      if (s.status == "success") {
        confirmAlert({
          title: "가입코드",
          message: "가입코드를 삭제하였습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "가입코드",
          message: "관리자에게 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      }
    });
  };

  handleMakeAgentCode = () => {
    // type: string,
    // code: string,
    // agent: string,
    // count: number

    this.AgentService.admin_add_reg_codes(
      this.state.newType,
      this.state.newCode,
      this.state.newAgent,
      this.state.newUserId,
      this.state.newCount
    ).then((s: any) => {
      if (s.status == "success") {
        confirmAlert({
          title: "가입코드",
          message: "가입코드를 생성하였습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "가입코드",
          message: "관리자에게 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      }
      //   this.setState({
      //     codes: s.codes,
      //     maxCount: s.maxCount
      //   });
    });
  };

  handleSaveCode = () => {
    let type = this.state.newType;
    let code = this.state.newCode;
    let agent = this.state.newAgent;
    let count = this.state.newCount;
    let userid = this.state.newUserId;

    if (type == "") {
      confirmAlert({
        title: "가입코드",
        message: "가입코드 타입을 선택해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
      return;
    }

    if (code == "") {
      confirmAlert({
        title: "가입코드",
        message: "가입코드 코드를 입력해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
      return;
    }

    if (agent == "") {
      confirmAlert({
        title: "가입코드",
        message: "아이전트 아이디를 입력해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
      return;
    }

    if (type == "user" && (userid == "" || userid == null)) {
      confirmAlert({
        title: "가입코드",
        message: "유저타입 선택시 유저아이디를 입력하셔야합니다. ",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
      return;
    }

    this.AgentService.admin_add_reg_codes(
      type,
      code,
      agent,
      userid,
      count
    ).then((s: any) => {
      if (s.status == "success") {
        confirmAlert({
          title: "가입코드",
          message: "가입코드를 생성하였습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else if (s.status == "agent") {
        confirmAlert({
          title: "가입코드",
          message: "찾을수 없는 에이전트입니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else if (s.status == "code") {
        confirmAlert({
          title: "가입코드",
          message: "이미 사용하고 있는 에이전트 코드입니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "가입코드",
          message: "관리자에게 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                location.reload();
              },
            },
          ],
        });
      }
      //   this.setState({
      //     codes: s.codes,
      //     maxCount: s.maxCount
      //   });
    });
  };

  render = () => {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">가입 코드생성</div>

                <div className="p-grid">
                  <div className="p-col-12 p-md-2">
                    <Dropdown
                      style={{ width: "100px" }}
                      options={this.state.selectBox}
                      value={this.state.newType}
                      onChange={(event) =>
                        this.setState({ newType: event.value })
                      }
                      autoWidth={false}
                    />{" "}
                  </div>

                  <div className="p-col-12 p-md-1">
                    <span className="md-inputfield">
                      <InputText
                        value={this.state.newAgent}
                        onChange={(e: any) =>
                          this.setState({ newAgent: e.target.value })
                        }
                      />
                      <label>에이전트</label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-1">
                    <span className="md-inputfield">
                      <InputText
                        value={this.state.newCode}
                        onChange={(e: any) =>
                          this.setState({ newCode: e.target.value })
                        }
                      />
                      <label>가입코드</label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-1">
                    <span className="md-inputfield">
                      <InputText
                        value={this.state.newUserId}
                        onChange={(e: any) =>
                          this.setState({ newUserId: e.target.value })
                        }
                      />
                      <label>유저아이디</label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-2">
                    <span className="md-inputfield">
                      <InputText
                        value={this.state.newCount}
                        onChange={(e: any) =>
                          this.setState({ newCount: e.target.value })
                        }
                      />
                      <label>가입횟수</label>
                    </span>
                  </div>
                  <div className="p-col-12 p-md-2">
                    <span className="md-inputfield">
                      <Button
                        label="가입코드 생성"
                        className="p-button-success"
                        onClick={() => {
                          this.handleSaveCode();
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-12">
              <div className="card">
                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.MoveSel(page);
                  }}
                />
                <DataTable
                  value={this.state.codes}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                  scrollable={true}

                >

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="에이전트"
                    body={(agent: any) => {
                      return (
                        <div
                          onClick={() => {
                            OpenAgentDetail(agent.agent);
                          }}
                        >
                          {agent.agent}
                          <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                        </div>
                      );
                    }}
                    field="agent"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="유저아이디"
                    body={(agent: any) => {
                      return agent.user_id;
                    }}
                    field="nick"

                  // sortFunction={}
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="코드"
                    // body={(agent: any) => {
                    //   return agent.code;
                    // }}
                    body={(agent: any) => {
                      return (
                        <InputText
                          value={agent.code}
                          onChange={(e: any) => {
                            let code = this.state.codes.find(
                              (s) => s.idx == agent.idx
                            );
                            if (code != undefined) {
                              code.code = e.target.value;
                              this.setState({ codes: this.state.codes });
                            }
                          }}
                        />
                      );
                    }}
                    sortable={true}
                    field="level"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="남은 가입횟수"
                    body={(agent: any) => {
                      return (
                        <InputText
                          value={agent.count}
                          onChange={(e: any) => {
                            let code = this.state.codes.find(
                              (s) => s.idx == agent.idx
                            );
                            if (code != undefined) {
                              code.count = e.target.value;
                              this.setState({ codes: this.state.codes });
                            }
                          }}
                        />
                      );
                    }}
                    sortable={true}
                    field="balance"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="생성일"
                    body={(agent: any) => {
                      return agent.regdate;
                    }}
                    sortable={true}
                    field="rolling"
                  />

                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="수정"
                    body={(agent: any) => {
                      return (
                        <Button
                          label="수정"
                          className="p-button-warning"
                          onClick={() => {
                            let code = this.state.codes.find(
                              (s) => s.idx == agent.idx
                            );
                            if (code != undefined) {
                              this.handleEditAgentCode(code);
                            }
                          }}
                        />
                      );
                    }}
                    sortable={true}
                    field="rolling"
                  />
                  <Column
                    style={{ textAlign: "center", width: "120px" }}
                    header="삭제"
                    body={(agent: any) => {
                      return (
                        <Button
                          label="삭제"
                          className="p-button-danger"
                          onClick={() => this.handleDelAgentCode(agent.idx)}
                        />
                      );
                    }}
                    sortable={true}
                    field="rolling"
                  />
                </DataTable>

                <SubPaging
                  limit={20}
                  activePage={this.state.activePage}
                  maxCount={this.state.maxCount}
                  MoveSel={(page) => {
                    this.MoveSel(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
