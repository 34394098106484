import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { SelectButton } from "primereact/selectbutton";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { FxService } from "../service/fx.service";

import { BetFxTable } from "../share/bet/bet-fx-table";
import { CustomPagination } from "../share/custom-pagination";

import {
  numTomoeny, GetToday, ConvertCalendar,
} from "../utility/help";

interface MatchState {
  // details: any;
  begin: any;
  end: any;
  findText: any;
  searchText: any;
  userTableValue: any;
  page: any;
  maxCount: any;
  summary: any;
  currency: any;

  veiwSelectType: any;

  viewType: any[];
  selectViewType: any;

  currencyType: any[];
  selectCurrency: any;
  selectSortType: string;
  // sortType: any[];
}

export class BetFx extends Component<any, MatchState> {
  fxService: FxService = new FxService();
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      begin: today.begin,
      end: today.end,
      summary: {},
      userTableValue: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      veiwSelectType: "all",
      currency: "all",

      currencyType: [
        { label: "전체보기", value: "all" },
        { label: "GBP/AUD", value: "gbpaud" },
        { label: "EUR/JPY", value: "eurjpy" },
        { label: "EUR/AUD", value: "euraud" },
        { label: "BTC/USD", value: "btcusd" },
      ],
      selectCurrency: "all",
      // details: [
      //   { label: "선택", value: null },
      //   { label: "아이디", value: "id" },
      //   { label: "에이전트", value: "agent" },
      // ],

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "대기중배팅", value: "reg" },
        { label: "낙점", value: "lose" },
        { label: "당첨", value: "win" },
      ],
      selectViewType: "all",

      // sortType: [
      //   { label: "시간", value: "beginDate" },
      //   { label: "배팅금액", value: "betBalance" },
      // ],
      selectSortType: "regDate",
    };

    // this.handlePageChange = this.handlePageChange.bind(this);

    this.get_fx_matchs_bet(
      1,
      this.state.currency,
      this.state.begin,
      this.state.end,
      this.state.selectCurrency,
      this.state.selectViewType,
      this.state.selectSortType
    );
  }

  componentDidMount() { }

  get_fx_matchs_bet = (
    page: any,
    currency: any,
    begin: any,
    end: any,
    selectCurrency: any,
    selectViewType: any,
    selectSortType: any
  ) => {
    console.log(
      page,
      currency,
      begin,
      end,
      selectCurrency,
      selectViewType,
      selectSortType
    );
    this.fxService
      .admin_get_fx_matchs_bet(
        page,
        currency,
        begin,
        end,
        selectCurrency,
        selectViewType,
        selectSortType
      )
      .then((data) => {
        this.setState({
          userTableValue: data.fx,
          maxCount: data.maxCount,
          summary: data.summary,
        });
      });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.setState({ page: page });
    this.get_fx_matchs_bet(
      page,
      this.state.currency,
      this.state.begin,
      this.state.end,
      this.state.selectCurrency,
      this.state.selectViewType,
      this.state.selectSortType
    );
  };

  render() {
    let activePage = this.state.page == undefined ? 0 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      // return userTableValue.map((contact: any, i: any) => {
      return (
        <BetFxTable
          bet={userTableValue}
          // key={contact._id}
          // details={this.state.details[contact._id]}
          mode={"bet"}
        ></BetFxTable>
      );
      //   });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">FX 배팅정보</div>

                  <div className="p-col-12 ">
                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="Begin"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.begin}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ begin: event.value })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Calendar
                          placeholder="End"
                          showIcon={true}
                          dateFormat="yy-mm-dd"
                          value={this.state.end}
                          showWeek={false}
                          onChange={(event: any) =>
                            this.setState({ end: event.value })
                          }
                          locale={ConvertCalendar()}
                        />
                      </div>

                      <div className="p-col-12 p-md-2">
                        <Button
                          label="검색"
                          onClick={() => {
                            this.handlePageChange(0);
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <DataTable
                          style={{ padding: "0px" }}
                          value={this.state.summary}
                          paginatorPosition="both"
                          rows={20}
                        >
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="error" text="BET" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalBet)}</span>
                                </div>
                              );
                            }}
                          />
                          <Column
                            style={{ textAlign: "center", padding: "0px" }}
                            header={<Message severity="info" text="WIN" />}
                            body={(rowData: any, column: any) => {
                              return (
                                <div>
                                  <span>{numTomoeny(rowData.totalWin)}</span>
                                </div>
                              );
                            }}
                          />
                        </DataTable>
                      </div>
                    </div>
                    <div className="p-col-12 ">
                      <div className="p-col-12 p-md-5">
                        <SelectButton
                          className={"p-buttonset-1"}
                          options={this.state.viewType}
                          value={this.state.veiwSelectType}
                          onChange={(event) => {
                            this.setState({ veiwSelectType: event.value });
                            this.get_fx_matchs_bet(
                              1,
                              this.state.currency,
                              this.state.begin,
                              this.state.end,
                              this.state.selectCurrency,
                              event.value,
                              this.state.selectSortType
                            );
                          }}
                        />
                      </div>
                      <div className="p-col-12 p-md-5">
                        <SelectButton
                          className={"p-buttonset-2"}
                          options={this.state.currencyType}
                          value={this.state.selectCurrency}
                          onChange={(event) => {
                            this.setState({ selectCurrency: event.value });
                            this.get_fx_matchs_bet(
                              1,
                              this.state.currency,
                              this.state.begin,
                              this.state.end,
                              event.value,
                              this.state.selectViewType,
                              this.state.selectSortType
                            );
                          }}
                        />
                      </div>

                      {/* <div className="p-col-12 p-md-2">
                        <SelectButton
                          value={this.state.selectSortType}
                          options={this.state.sortType}
                          onChange={(event) => {
                            this.setState({ selectSortType: event.value });
                            // this.get_admin_get_fx_matchs(
                            //   1,
                            //   this.state.selectSortType,
                            //   event.value,
                            //   this.state.selectSortType
                            // );
                          }}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={10}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={10}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
