import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { isMobile, isMobileOnly } from "react-device-detect";

import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetYesterDay, ConvertCalendar } from "../utility/help";

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];
    viewType: any;

    totalBalance: any;

}

export class CalculateMainAgent extends Component<any, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: any) {
        super(props);

        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            viewType: "balance",
            totalBalance: {},

        };

        this.handlegetTopAgentCalculate(begin, end);

    }

    handlegetTopAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_main_agent(begin, end).then((data) => {
            if (data.status == "success") {


                let totalBalance = {}
                for (let balances of Object.values(data.agentCombine) as any) {
                    for (let key of Object.keys(balances as [])) {

                        if (typeof (balances[key]) !== 'number') {
                            continue;
                        }

                        if (totalBalance[key] == null) {
                            totalBalance[key] = 0
                        }
                        totalBalance[key] += Number(balances[key] as Number)



                    }

                    if (totalBalance['total'] == null) {
                        totalBalance['total'] = 0
                    }

                    // totalBalance['total'] += (((balances['totalSlotBet'] - balances['totalSlotWin']) / 100) * balances['benefitLosing']) - ((balances['benefitSlotRolling'] / 100) * balances['totalSlotBet'])

                    totalBalance['total'] += (balances['totalDeposit'] + balances['totalGiveDeposit'] + balances['totalAdmindeposit'] - balances['totalWithdraw'] - balances['totalGiveWithdraw'] - balances['totalAdminwithdraw']
                        + balances['totalYDInBalance'] - balances['totalInBalance'])

                    if (totalBalance['totalBalance'] == null) {
                        totalBalance['totalBalance'] = 0
                    }

                    totalBalance['totalBalance'] = (balances['totalDeposit'] + balances['totalGiveDeposit'] + balances['totalAdmindeposit'] - balances['totalWithdraw'] - balances['totalGiveWithdraw'] - balances['totalAdminwithdraw']
                        + balances['totalYDInBalance'] - balances['totalInBalance']) - ((totalBalance['totalSlotBet'] / 100) * balances['benefitSlotRolling'])

                    if (totalBalance['winlose'] == null) {
                        totalBalance['winlose'] = 0
                    }

                    if (totalBalance['totalSlotRolling'] == null) {
                        totalBalance['totalSlotRolling'] = 0
                    }
                    if (totalBalance['totalSlotRolling'] < ((totalBalance['totalSlotBet'] / 100) * balances['benefitSlotRolling'])) {
                        totalBalance['totalSlotRolling'] = ((totalBalance['totalSlotBet'] / 100) * balances['benefitSlotRolling'])
                    }
                    console.log(totalBalance['totalSlotRolling'])
                    if (totalBalance['totalCasinoRolling'] == null) {
                        totalBalance['totalCasinoRolling'] = 0
                    }

                    if (totalBalance['totalCasinoRolling'] < ((totalBalance['totalCasinoBet'] / 100) * balances['benefitSlotRolling'])) {
                        totalBalance['totalCasinoRolling'] = ((totalBalance['totalCasinoBet'] / 100) * balances['benefitCasinoRolling'])
                    }



                    // totalBalance['total'] += (((balances['totalDeposit'] + balances['totalGiveDeposit'] + balances['totalAdmindeposit'] - balances['totalWithdraw'] - balances['totalGiveWithdraw'] - balances['totalAdminwithdraw']
                    // + balances['totalYDInBalance'] - balances['totalInBalance']) / 100) * balances['benefitLosing']) - ((totalBalance['totalSlotBet'] / 100) * balances['benefitSlotRolling'])
                    // console.log(totalBalance['totalSlotRolling'], totalBalance['totalSlotBet'], balances['benefitSlotRolling'], ((totalBalance['totalSlotBet'] / 100) * balances['benefitSlotRolling']))

                    // console.log(`111   ` + totalBalance['totalSlotRolling'])

                }

                if (totalBalance['winlose'] == null) {
                    totalBalance['winlose'] = 0
                }

                // console.log(totalBalance['totalSlotRolling'])

                totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']


                this.setState({ agentInfo: data.agentCombine, totalBalance: totalBalance });
            }
        });
    }

    componentDidMount() {
    }

    render() {


        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">에이전트 정산</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">
                                        {/* <div className="p-col-12 p-md-2">
                                            <Dropdown
                                                style={{ width: "200px" }}
                                                value={this.state.agent_name}
                                                options={this.state.agents_option}
                                                placeholder="에이전트 선택"
                                                onChange={(e) => {
                                                    this.setState({ agent_name: e.value });

                                                }}
                                            />
                                        </div> */}
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                                locale={ConvertCalendar()}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetTopAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <SelectButton
                                    value={this.state.viewType}
                                    options={
                                        [
                                            { label: "입출금 정산", value: "balance" },
                                            { label: "Bet-Win 정산", value: "betwin" },
                                        ]
                                    }
                                    onChange={(event) => {
                                        this.setState({ viewType: event.target.value });
                                        // this.handlePageChange(0, event.target.value)
                                    }}
                                />
                                {/* <div className="p-col-12 ">
                                    <SelectButton
                                        value={this.state.options_type}
                                        options={this.state.options}
                                        onChange={(e) => {
                                            if (e.value == null) {
                                                return;
                                            }
                                            this.setState({ options_type: e.value });
                                        }}
                                    />
                                </div> */}
                            </div>
                            <div className="card">


                                <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={99999}
                                    sortOrder={-1}
                                    scrollable
                                    scrollHeight="800px"
                                    style={{ width: isMobile ? '1600px' : '100%' }}

                                >
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header="에이전트"
                                        body={(rowData: any, column: any) => {
                                            return <>
                                                <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                    <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                </a>
                                                <br />
                                                <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                </a>
                                            </>

                                        }}
                                    />





                                    {
                                        this.state.viewType === 'balance' ? (
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>충전</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit)}</div>
                                                    </div>;
                                                }}
                                            />

                                        ) : (

                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>Bet</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                    </div>;

                                                }}
                                            />

                                        )
                                    }

                                    {
                                        this.state.viewType === 'balance' ? (
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>환전</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)}</div>

                                                    </div>;
                                                }}
                                            />

                                        ) : (
                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>Win</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                                    </div>;

                                                }}
                                            />
                                        )
                                    }

                                    {
                                        this.state.viewType === 'balance' ?
                                            (<Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>수익(루징금)</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    const sum = (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)
                                                    return <div style={{ color: sum > 0 ? 'red' : 'blue' }}>{numTomoeny(sum)}</div>
                                                }}
                                            />)
                                            : (<Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>BET - WIN</div></div>}
                                                body={(rowData: any, column: any) => {
                                                    return <div>
                                                        <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                                    </div>;
                                                }}
                                            />)
                                    }

                                    {
                                        this.state.viewType === 'balance' && (

                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div>루징%</div>}
                                                body={(rowData: any, column: any) => {

                                                    const lose = ((((rowData.totalGiveDeposit + rowData.totalDeposit + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw))) * rowData.benefitLosing) / 100
                                                    return <div>
                                                        <div style={{ color: (lose) > 0 ? 'red' : 'blue' }}>{numTomoeny(lose)}</div>
                                                        <div>{rowData.benefitLosing} %</div>
                                                    </div>;
                                                }}
                                            />

                                        )
                                    }


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤링</div><div>롤링 프로테이지%</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalRollingBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny((rowData.totalSlotBet * rowData.benefitSlotRolling) / 100)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                            </div>;
                                        }}
                                    />

                                    {
                                        this.state.viewType === 'balance' ? (

                                            process.env.REACT_APP_API_CALCULATE_ROLLING_OFF !== 'Y' && < Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>정산금</div><div>루징프로테이지%</div></div>}
                                                body={(rowData: any, column: any) => {

                                                    const lose = ((((rowData.totalGiveDeposit + rowData.totalDeposit + rowData.totalAdmindeposit)
                                                        - (rowData.totalWithdraw + rowData.totalGiveWithdraw + rowData.totalAdminwithdraw)) * rowData.benefitLosing) / 100) - ((rowData.totalSlotBet * rowData.benefitSlotRolling) / 100)


                                                    console.log(`${rowData.agent}, ${lose}, ${rowData.benefitLosing} ${rowData.totalRollingBalance}`)


                                                    return <div>
                                                        <div style={{ color: (lose) > 0 ? 'red' : 'blue' }}>{numTomoeny(lose)}</div>
                                                    </div>;
                                                }}

                                            />

                                        ) : (

                                            <Column
                                                style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                                bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                                header={<div><div>정산금</div></div>}
                                                body={(rowData: any, column: any) => {

                                                    return <div>
                                                        <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.totalRollingBalance > 0 ? 'red' : 'blue' }}>
                                                            {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) * rowData.benefitLosing) / 100) - ((rowData.totalSlotBet * rowData.benefitSlotRolling) / 100))}</div>
                                                        <div>{rowData.benefitLosing} %</div>
                                                    </div>
                                                }}
                                            />

                                        )
                                    }


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>롤링변환 to 벨런스</div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#009688' : '#009688' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />



                                </DataTable>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
