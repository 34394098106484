import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Growl } from "primereact/growl";
import { SelectButton } from "primereact/selectbutton";

import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { numTomoeny, GetToday } from "../utility/help";
import { CustomPagination } from "../share/custom-pagination";

interface State {
  categorys: any;
  maxCount: number;
  activePage: number;
  selectItem: any;
  select: any;
  searchText: any;
  findText: any;
  selectViewType: string;
  viewType: any[];
}

export class SetCategories extends Component<any, State> {
  adminService: AdminService;
  growl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      categorys: [],
      maxCount: 1,
      activePage: 1,
      selectItem: {},
      searchText: "",
      findText: "",

      selectViewType: "all",

      viewType: [{ label: "카테고리", value: "in" }],

      select: [
        { label: "카테고리", value: "categories" },
        { label: "카테고리 ID", value: "categoryId" },
      ],
    };

    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetCategories(1, "", "", "all");
  }

  handlePageChange = (page: any, searchText: any, findText: any, mode: any) => {
    console.log(page);
    this.setState({ activePage: page });
    this.handleGetCategories(page, searchText, findText, mode);
  };

  handleUpdateGame = () => {
    confirmAlert({
      title: "게임 업데이트",
      message: "게임 업데이트는 하루에 한번만 눌러주세요 ",
      buttons: [
        {
          label: "확인'",

          onClick: () => {
            this.adminService.admin_update_games().then((data: any) => {
              let msg = {
                severity: "success",
                summary: "게임이 ",
                detail: "업뎅이트 되습니다",
              };
              this.growl.show(msg);
            });
          },
        },
      ],
    });
  };

  handleGetCategories = (page, searchText, findText, mode) => {
    this.adminService
      .admin_get_categories(page, searchText, findText, mode)
      .then((data: any) => {
        if (data.status == "success") {
          this.setState({
            categorys: data.categorys,
            maxCount: Number(data.maxCount),
          });
        }
      });
  };

  render() {
    let categorys = this.state.categorys;
    let activePage = this.state.activePage;
    let adminService = this.adminService;
    let growl: any;

    // rowData.SportID,
    // rowData.ID,
    // rowData.Name.ko

    const handleEditCategorys = (
      _id: any,
      SportID: any,
      CategoryId: any,
      ko: any
    ) => {
      adminService
        .admin_update_categories(_id, SportID, CategoryId, ko)
        .then((data: any) => {
          if (data.status == "success") {
            let msg = {
              severity: "success",
              summary: data.name,
              detail: "가 수정되었습니다",
            };
            this.growl.show(msg);
          } else {
            let msg = {
              severity: "error",
              summary: data.tournamentid + " - " + data.name,
              detail: "예러 발생 되었습니다 관리자에게 문의",
            };
            this.growl.show(msg);
          }
        });
    };

    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card">
                <div className="card-title">카테고리 설정</div>

                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Dropdown
                          options={this.state.select}
                          value={this.state.searchText}
                          onChange={(event: any) =>
                            this.setState({
                              searchText: event.value,
                            })
                          }
                          autoWidth={false}
                        />{" "}
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <InputText
                          value={this.state.findText}
                          onChange={(event: any) => {
                            this.setState({
                              findText: event.target.value,
                            });
                          }}
                        />
                        <label>검색</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4">
                      <span className="md-inputfield">
                        <Button
                          label="검색"
                          onClick={(event: any) => {
                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              this.state.selectViewType
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-col-6">
                  <div className="p-grid form-group">
                    <div className="p-col-6">
                      <span className="md-inputfield">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.setState({ selectViewType: event.value });

                            this.handlePageChange(
                              0,
                              this.state.searchText,
                              this.state.findText,
                              event.value
                            );
                          }}
                        />
                      </span>
                    </div>

                    <div className="p-md-6">
                      <Button
                        label="카테고리 업데이트"
                        onClick={() => {
                          this.handleUpdateGame();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <Messages
                ref={(el: any) => this.setState({ messages: el })}
                style={{ marginBottom: "1em" }}
              /> */}
              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>

                <DataTable
                  value={categorys}
                  paginatorPosition="both"
                  rows={20}
                  sortOrder={-1}
                >
                  <Column
                    style={{ textAlign: "center" }}
                    header="번호"
                    body={(rowData: any, column: any) => {
                      return rowData.ID;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="종목"
                    body={(rowData: any, column: any) => {
                      if (rowData.SportName == null) return "";
                      return rowData.SportName;
                    }}
                  />

                  <Column
                    style={{ textAlign: "center" }}
                    header="영어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.en == null) return rowData.Name;
                      return rowData.Name.en;
                    }}
                  />
                  <Column
                    style={{ textAlign: "center" }}
                    header="한국어"
                    body={(rowData: any, column: any) => {
                      if (rowData.Name.ko == null) {
                        rowData.Name.ko = rowData.Name.en;
                      }

                      return (
                        <InputText
                          style={{ width: "300px" }}
                          value={rowData.Name.ko}
                          onChange={(even: any) => {
                            let tournament = this.state.categorys.find(
                              (s: any) => s._id == rowData._id
                            );
                            tournament.Name.ko = even.target.value;

                            this.setState({
                              categorys: this.state.categorys,
                            });
                          }}
                        ></InputText>
                      );
                    }}
                  />
                  {/* <Column
                    style={{ textAlign: "center" }}
                    header="사용"
                    body={(rowData: any, column: any) => {
                      return (
                        <div className="p-grid form-group">
                          <div className="p-col-6">
                            <RadioButton
                              value="Ultima"
                              inputId="cb1"
                              onChange={() => {
                                rowData.Active = true;
                              }}
                              checked={rowData.Active == true}
                            />
                            <label htmlFor="cb1" className="p-checkbox-label">
                              사용
                            </label>
                          </div>
                          <div className="p-col-6">
                            <RadioButton
                              value="Avalon"
                              inputId="cb2"
                              onChange={() => {
                                rowData.Active = false;
                              }}
                              checked={rowData.Active == false}
                            />
                            <label htmlFor="cb2" className="p-checkbox-label">
                              미사용
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  /> */}
                  {/* <Column
                    style={{ textAlign: "center" }}
                    header="단축 리그등록"
                    body={(rowData: any, column: any) => {
                      return (
                        <div className="p-grid form-group">
                          <div className="p-col-6">
                            <RadioButton
                              value="Ultima"
                              inputId="cb1"
                              onChange={() => {
                                rowData.Quick = true;
                              }}
                              checked={rowData.Quick == true}
                            />
                            <label htmlFor="cb1" className="p-checkbox-label">
                              등록
                            </label>
                          </div>
                          <div className="p-col-6">
                            <RadioButton
                              value="Avalon"
                              inputId="cb2"
                              onChange={() => {
                                rowData.Quick = false;
                              }}
                              checked={rowData.Quick == false}
                            />
                            <label htmlFor="cb2" className="p-checkbox-label">
                              미등록
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  /> */}

                  <Column
                    style={{ textAlign: "center" }}
                    header="수정"
                    body={(rowData: any, column: any) => {
                      return (
                        <Button
                          label="확인"
                          onClick={() => {
                            handleEditCategorys(
                              rowData._id,
                              rowData.SportID,
                              rowData.CategoryId,
                              rowData.Name.ko
                            );
                          }}
                        />
                      );
                    }}
                  />
                </DataTable>

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={(event: any) => {
                    this.handlePageChange(
                      event,
                      this.state.searchText,
                      this.state.findText,
                      this.state.selectViewType
                    );
                  }}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
