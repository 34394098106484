import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { isMobile, isMobileOnly } from "react-device-detect";
import { SelectButton } from "primereact/selectbutton";

import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { LogBalanceTable } from "../share/log/log-balance-table";

// C:\WORK\eBetting\admin\src\share\log\balanceLog.js

import { AgentService } from "../service/agent.service";
import { SubPaging } from "../share/sub-paging";

import * as AgentData from "../interface/agent";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  GetToday,
  GetNow,
  convertSimpleDate,
  convertToCSV,
  OpenUserDetail,
  ConvertNick,
  OpenAgentCal,
  OpenAgentDetail,
  ConvertCalendar,
  convertAgentLogText,
  convertAgentBalanceText,
  converBalanceColor
} from "../utility/help";


interface AgentState {
  logs: any;
  activePage: any;
  maxCount: any;
  option?: any;
  balanceOption: any;
  begin: any;
  end: any;
  cities: any;
  dropdownFind: any;
  searchText: any;
  findText: any;

}

export class AgentBalanceLog extends Component<any, AgentState> {
  AgentService: AgentService = new AgentService();
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      logs: [],
      maxCount: 1,
      activePage: 1,
      balanceOption: "balance",
      begin: today.begin,
      end: today.end,
      option: [
        { label: "포인트", value: "point" },
        { label: "롤링", value: "rolling" },
        { label: "벨런스", value: "balance" },
        { label: "롤링 => 벨런스", value: "exchange_rolling_to_balance" },
        { label: "매장 => 유저", value: "deposit_agent_withdraw_agent" },
        { label: "관리자 => 매장", value: "admin_deposit_withdraw" },
        { label: "에이전트 => 에이전트", value: "agent_to_agent" },
      ],
      cities: [
        { label: "선택", value: null },
        { label: "아이디", value: "agent" },
        // { label: "이름", value: "bankowner" },
        // { label: "에이전트", value: "agent" },
        // { label: "에이전트 트리", value: "tree" },
      ],
      dropdownFind: "tree",
      searchText: "",
      findText: "agent",
    };

    //this.handleAgentBalance = this.handleAgentBalance.bind(this);


  }

  componentDidMount() {
    this.handleAgentBalanceLog(0, this.state.balanceOption);
  }

  handleAgentBalanceLog = (page: any, mode: any) => {
    this.AgentService.admin_get_agnet_balance_log(page, this.state.begin, this.state.end, this.state.findText, this.state.searchText, mode).then((s: any) => {
      this.setState({
        logs: s.logs,
        maxCount: s.maxCount,
      });
    });
  };
  handleMoveSel = (page: any) => {
    this.setState({ activePage: page });
    this.handleAgentBalanceLog(page, this.state.balanceOption);
  };

  render = () => {
    return (
      <div>
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-col-12 ">
                <div className="card">
                  <div className="card-title">에이전트 벨런스</div>



                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-3">
                      <Calendar
                        placeholder="Begin"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.begin}
                        onChange={(e) => this.setState({ begin: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}
                      />
                      <Calendar
                        placeholder="End"
                        showIcon={true}
                        dateFormat="yy-mm-dd"
                        value={this.state.end}
                        onChange={(e) => this.setState({ end: e.value })}
                        showWeek={false}
                        locale={ConvertCalendar()}
                      />
                    </div>
                    <div className="p-col-12 p-md-3">
                      <div className="p-grid form-group">

                        <div style={{ paddingLeft: 20, paddingTop: 7 }}>
                          <Dropdown
                            style={{ width: 140 }}

                            options={this.state.cities}
                            value={this.state.dropdownFind}
                            onChange={(event) =>
                              this.setState({ dropdownFind: event.value })
                            }
                            autoWidth={true}
                          />
                        </div>

                        <div className="p-inputgroup" style={{ paddingLeft: 20 }}>
                          <InputText
                            style={{ width: 100 }}
                            placeholder="검색"
                            onChange={(event: any) => {
                              this.setState({
                                searchText: event.target.value,
                              });
                            }}
                          />
                          <Button
                            icon="pi pi-search"
                            className="p-button-warning"
                            onClick={() => {
                              this.handleAgentBalanceLog(0, this.state.balanceOption);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="p-col-12 p-md-6">
                          <div className="p-grid form-group">
                            <span className="p-float-label">
                              <InputText id="inputtext" value={numTomoeny(total.deposit)} readOnly />
                              <label htmlFor="inputtext" style={{ color: 'red' }}>충전 {total.depositCount}</label>
                            </span>
                            <span className="p-float-label">
                              <InputText id="inputtext" value={numTomoeny(total.withdraw)} readOnly />
                              <label htmlFor="inputtext" style={{ color: 'blue' }}>환전 {total.withdrawCount}</label>
                            </span>
                            <span className="p-float-label">
                              <InputText id="inputtext" value={numTomoeny(total.deposit - total.withdraw)} readOnly />
                              <label htmlFor="inputtext">충전-환전</label>
                            </span>
                          </div>

                        </div> */}

                  </div>



                  {/* <div className="p-col-12 p-md-6">
                        <div className="p-grid form-group">
                          <div className="p-col-12 p-md-3">


                            <Button
                              icon="pi pi-cog"
                              className="p-button-rounded p-button-warning "
                              onClick={(event) => {
                                this.menu.toggle(event);
                              }}

                            />

                          </div>
                          <div className="p-col-12 p-md-3">

                            <SelectButton
                              value={this.state.selectViewType}
                              options={this.state.viewType}
                              onChange={(event) => {
                                this.setState({ selectViewType: event.value });
                                this.get_deposit_and_withdraw(0, event.value, this.state.selectBalanceType);
                              }}
                            />
                          </div>


                          <div className="p-col-12 p-md-6">

                            <SelectButton
                              value={this.state.selectBalanceType}
                              options={this.state.balanceType}
                              onChange={(event) => {
                                this.state.selectViewType;
                                this.setState({ selectBalanceType: event.value });
                                this.get_deposit_and_withdraw(0, this.state.selectViewType, event.value);
                              }}
                            />
                          </div>

                        </div>

                      </div> */}


                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-8">
                      <SelectButton
                        className={"p-buttonset-1"}
                        options={this.state.option}
                        value={this.state.balanceOption}
                        onChange={(event) => {
                          this.setState({ balanceOption: event.value });
                          this.handleAgentBalanceLog(1, event.value);
                        }}
                      />
                    </div>


                  </div>

                  <div className="p-grid form-group">
                    <div className="p-col-12 p-md-6">
                      {/* <SelectButton
                        value={this.state.selectViewType}
                        options={this.state.viewType}
                        onChange={(event) => {
                          this.setState({ selectViewType: event.value });
                          this.headerGetEndMiniGame(
                            1,
                            "categoryId",
                            this.state.selectType,
                            event.value
                          );
                        }}
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="card">
                  <SubPaging
                    limit={20}
                    activePage={this.state.activePage}
                    maxCount={this.state.maxCount}
                    MoveSel={(page) => {
                      this.handleMoveSel(page);
                    }}
                  />
                  <DataTable
                    value={this.state.logs}
                    paginatorPosition="both"
                    rows={20}
                    sortOrder={-1}
                    scrollable={true}
                  >
                    <Column
                      style={{ textAlign: "center", width: "220px" }}
                      header="타입"
                      body={(agent: any) => {
                        return <div style={{ color: converBalanceColor(agent.logType) }}>{convertAgentLogText(agent.logType)}</div>;
                      }}
                      field="agent"
                    />

                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="벨런스타입"
                      body={(agent: any) => {
                        return (
                          <div>
                            {convertAgentBalanceText(agent.balanceType)}
                          </div>
                        );
                      }}
                      field="point"
                    />

                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="에이전트"
                      body={(agent: any) => {
                        return (
                          <div
                            onClick={() => {
                              OpenAgentDetail(agent.agent);
                            }}
                          >
                            {agent.agent}
                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                          </div>
                        );
                      }}
                      field="level"
                    />

                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="유저 아이디"
                      body={(agent: any) => {
                        return (
                          <div
                            onClick={() => {
                              OpenUserDetail(agent.id);
                            }}
                          >
                            {agent.id}
                            <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                          </div>
                        );
                      }}
                      field="nick"

                    // sortFunction={}
                    />
                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="오리지널"
                      body={(agent: any) => {
                        return numTomoeny(agent.origenbalance);
                      }}
                      field="balance"
                    />
                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="충/환전/롤링/관리자"
                      body={(agent: any) => {
                        return numTomoeny(agent.balance);
                      }}
                      field="balance"
                    />

                    <Column
                      style={{ textAlign: "center", width: "120px" }}
                      header="남은금액"
                      body={(agent: any) => {
                        return numTomoeny(agent.afterbalance);
                      }}
                      field="rolling"
                    />

                    <Column
                      style={{ textAlign: "center", width: "180px" }}
                      header="등록일"
                      body={(agent: any) => {
                        return convertDate(agent.regdate);
                      }}
                      field="agent_tree"
                    />
                  </DataTable>

                  <SubPaging
                    limit={20}
                    activePage={this.state.activePage}
                    maxCount={this.state.maxCount}
                    MoveSel={(page) => {
                      this.handleMoveSel(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
