import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";
import { RadioButton } from "primereact/radiobutton";

import { FxService } from "../service/fx.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  fxset: any[];
  fxonoff: any[];
  findText: string;
  searchText: string;
  page: number;
  maxCount: number;
  maxLevel: number;
}

export class SetFx extends Component<any, State> {
  fxService: FxService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      fxset: [],
      fxonoff: [],
      findText: "",
      searchText: "",
      page: 1,
      maxCount: 0,
      maxLevel: 8,
    };

    this.fxService = new FxService();
  }

  componentDidMount() {
    this.handleGetMessenger();
  }

  handleGetMessenger = () => {
    this.fxService.admin_get_set_fx().then((data) => {
      if (data.status == "success") {
        this.setState({ fxset: data.fxset });
      }
    });

    this.fxService.admin_get_gameonoff().then((data) => {
      if (data.status == "success") {
        this.setState({ fxonoff: data.onoffs });
      }
    });
  };

  handleFxUsed = (name: any, used: any) => {
    this.fxService.admin_update_gameonoff(name, used).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "FX",
          message: "FX 정보가 업데이트 되습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        // this.setState({ benefits: data.benefits });
      }
    });
  };

  handleFxUpdate = (currency: any, minute: any, rate: any) => {
    this.fxService.admin_update_fx(currency, minute, rate).then((data) => {
      if (data.status == "success") {
        confirmAlert({
          title: "FX",
          message: "FX 정보가 업데이트 되습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
        // this.setState({ benefits: data.benefits });
      }
    });
  };

  render() {
    let fxset = this.state.fxset;

    const RenderPanel = () => {
      //   return messenger.map((ms, i) => {
      return (
        <DataTable
          value={fxset}
          paginatorPosition="both"
          rows={100}
          sortOrder={-1}
        >
          <Column
            style={{ textAlign: "center" }}
            header="단위"
            body={(rowData: any, column: any) => {
              return rowData.currency;
            }}
          />

          <Column
            style={{ textAlign: "center" }}
            header="분"
            body={(rowData: any, column: any) => {
              return rowData.minute;
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="배당"
            body={(rowData: any, column: any) => {
              return (
                <InputText
                  value={rowData.rate}
                  onChange={(even: any) => {
                    rowData.rate = Number(even.target.value);
                    this.setState({ fxset: fxset });
                  }}
                ></InputText>
              );
            }}
          />
          <Column
            style={{ textAlign: "center" }}
            header="저장"
            body={(rowData: any, column: any) => {
              return (
                <Button
                  label="저장 하기"
                  onClick={() => {
                    this.handleFxUpdate(
                      rowData.currency,
                      rowData.minute,
                      rowData.rate
                    );
                  }}
                />
              );
            }}
          />
        </DataTable>
      );
    };

    const RenderOnOff = (onoff) => {
      console.log(onoff);
      return (
        <div className="p-col-2 p-md-3">
          <div className="p-col-12 p-md-2">{onoff.name}</div>
          <div className="p-col-12 p-md-4">
            <div className="p-grid">
              <div className="p-col-12">
                <RadioButton
                  value="Serenity"
                  inputId="rb3"
                  onChange={(event) => {
                    onoff.used = "y";
                    this.handleFxUsed(onoff.name, onoff.used);
                  }}
                  checked={onoff.used === "y"}
                />
                <label htmlFor="rb3" className="p-radiobutton-label">
                  사용
                </label>
              </div>
              <div className="p-col-12">
                <RadioButton
                  value="Serenity"
                  inputId="rb3"
                  onChange={(event) => {
                    onoff.used = "n";

                    this.handleFxUsed(onoff.name, onoff.used);
                  }}
                  checked={onoff.used === "n"}
                />
                <label htmlFor="rb3" className="p-radiobutton-label">
                  미사용
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    };

    let fxonoff = this.state.fxonoff;
    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">FX 설정</div>
                  <div className="p-grid">
                    {fxonoff.map((s: any) => {
                      return RenderOnOff(s);
                    })}
                  </div>
                </div>
              </div>

              <div className="card">{RenderPanel()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
