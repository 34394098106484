import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";

import { confirmAlert } from "react-confirm-alert";

import { Message } from "primereact/message";
import { RadioButton } from "primereact/radiobutton";
import { UserService } from "../../service/user.service";
import { CustomPagination } from "../custom-pagination";

import {
  numTomoeny,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  converBalanceStatus,
  converBalanceType,
  GetToday,
  convertSimpleDate,
  convertLogText,
  ConvertCalendar
} from "../../utility/help";
import { WindowScrollController } from "@fullcalendar/core";

interface Props {
  id: string;
}

interface State {
  id: string;
  logs: any;
  maxCount: number;
  activePage: number;

  begin: any;
  end: any;

  balanceType: any;
  summary: any;
}

export class UserLogUserBalance extends Component<Props, State> {
  userService = new UserService();

  constructor(props: any) {
    super(props);
    let today = GetToday();
    today.begin.setDate(today.begin.getDate() - 31);

    this.state = {
      balanceType: "point",
      id: "",
      logs: [],
      maxCount: 1,
      activePage: 1,

      begin: today.begin,
      end: today.end,
      summary: [{ tBalance: 0, count: 0 }],
    };

    // this.headerDepositAndWithdraw = this.headerDepositAndWithdraw.bind(this);
    // this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount = () => {
    let id = this.props.id;
    this.headerUserLoginLog(id, 1);
  };

  handlePageChange = (page) => {
    this.setState({
      activePage: page,
    });
    this.headerUserLoginLog(this.props.id, page);
  };

  headerUserLoginLog = (id: string, page: any) => {
    this.userService
      .get_user_balance_log_list(
        id,
        page,
        this.state.balanceType,
        this.state.begin,
        this.state.end
      )
      .then((data) => {
        if (data.status == "success") {
          this.setState({
            logs: data.logs,
            maxCount: data.maxCount,
            summary: [data.summary],
          });
        }
      });
  };

  render = () => {
    const logs = this.state.logs;

    let balanceOptions: any[] = [
      { label: "포인트", value: "point" },
      // { label: "미니게임배팅", value: "miniBet" },
    ];

    return (
      <div>
        <div className="p-col-12">
          <div className="p-col-12">
            <div className="p-grid">
              <div className="p-md-3">
                <Calendar
                  placeholder="Begin"
                  showIcon={true}
                  dateFormat="yy-mm-dd"
                  value={this.state.begin}
                  onChange={(e) => this.setState({ begin: e.value })}
                  showWeek={false}
                  locale={ConvertCalendar()}

                />
                <Calendar
                  placeholder="End"
                  showIcon={true}
                  dateFormat="yy-mm-dd"
                  value={this.state.end}
                  onChange={(e) => this.setState({ end: e.value })}
                  showWeek={false}
                  locale={ConvertCalendar()}

                />
              </div>
              <div className="p-md-4">
                <SelectButton
                  value={this.state.balanceType}
                  options={balanceOptions}
                  onChange={(e) => {
                    this.setState({ balanceType: e.value });
                    // this.handleMatchBet(1);
                  }}
                />
              </div>
              {/* <div className="p-md-4">
                <SelectButton
                  value={this.state.balanceType}
                  options={logOptions}
                  onChange={(e) => {
                    this.setState({ balanceType: e.value });
                    // this.handleMatchBet(1);
                  }}
                />
              </div> */}
              <div className="p-md-1">
                <Button
                  label={"확 인"}
                  onClick={() => {
                    this.handlePageChange(0);
                    // this.headerDepositAndWithdraw(
                    //   this.state.id,
                    //   0,
                    //   this.state.selectType,
                    //   this.state.begin,
                    //   this.state.end
                    // );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-4">
              <DataTable
                style={{ padding: "0px" }}
                value={this.state.summary}
                paginatorPosition="both"
                rows={20}
              >
                <Column
                  style={{ textAlign: "center", padding: "0px" }}
                  header={<Message severity="error" text="합산" />}
                  body={(rowData: any, column: any) => {
                    if (rowData == null) {
                      return (
                        <div>
                          <span>0</span>
                        </div>
                      );
                    }
                    return (
                      <div>
                        <span>{numTomoeny(rowData.tBalance)}</span>
                      </div>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </div>
        <div className="p-col-12">
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>

          <DataTable
            value={logs}
            paginatorPosition="both"
            rows={20}
            sortOrder={-1}
          >
            <Column
              style={{ textAlign: "center" }}
              header="벨런스 +"
              body={(rowData: any, column: any) => {
                if (rowData.balance >= 0) {
                  return (
                    <div style={{ color: getDepositColor() }}>
                      {numTomoeny(rowData.balance)}
                    </div>
                  );
                }

                return "";
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="벨런스 -"
              body={(rowData: any, column: any) => {
                if (rowData.balance <= 0) {
                  return (
                    <div style={{ color: getWithdrawColor() }}>
                      {numTomoeny(rowData.balance)}
                    </div>
                  );
                }

                return "";
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header="남은 벨런스"
              body={(rowData: any, column: any) => {

                return <div>{numTomoeny(rowData.afterbalance)}</div>;
              }}
            />


            <Column
              style={{ textAlign: "center" }}
              header="게임 벨런스"
              body={(rowData: any, column: any) => {
                if (rowData.inBalance == null || Number(rowData.inBalance) <= 0) {
                  return
                }

                return <div>{numTomoeny(rowData.inBalance)}</div>;
              }}
            />


            <Column
              style={{ textAlign: "center" }}
              header={"에이전트"}
              body={(rowData: any, column: any) => {
                return <div>{rowData.agent}</div>;
              }}
            />
            <Column
              style={{ textAlign: "center" }}
              header={"타입"}
              body={(rowData: any, column: any) => {
                return <div>{convertLogText(rowData.logType)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"게임정보"}
              body={(rowData: any, column: any) => {
                return <div>{rowData.game}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"벨런스타입"}
              body={(rowData: any, column: any) => {
                return <div>{convertLogText(rowData.balanceType)}</div>;
              }}
            />

            <Column
              style={{ textAlign: "center" }}
              header={"요청시간"}
              body={(rowData: any, column: any) => {
                return <div>{convertDate(rowData.regdate)}</div>;
              }}
              field="depositdate"
            />


            {/*
        <Column
          style={{ textAlign: "center" }}
          header={"CODE"}
          body={(rowData, column) => {
            return <div>{rowData._id}</div>;
          }}
          field="depositdate"
        /> */}
          </DataTable>
          <CustomPagination
            active={this.state.activePage}
            take={20}
            count={this.state.maxCount}
            handlePageChange={this.handlePageChange}
          ></CustomPagination>
        </div>
      </div>
    );
  };
}
