import React, { Component, AnchorHTMLAttributes } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar, LocaleSettings } from "primereact/calendar";
import { BetService } from "../service/bet.service";
import { AdminService } from "../service/admin.service";
import { SelectButton } from "primereact/selectbutton";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";
import { LogBalanceTable } from "../share/log/log-balance-table";

import { numTomoeny, balanceColor, OpenAgentDetail, OpenAgentCal, GetYesterDay } from "../utility/help";
import { Console } from "console";

interface Props {
    agentId: string;
}

interface BalanceState {
    findText: any;
    searchText: any;
    select: any;
    selectItem: any;
    options: any[];
    options_type: string;
    begin: any;
    end: any;

    agentInfo: any[];
    totalBalance: any;

}

export class CalculatesubagentWinLose extends Component<Props, BalanceState> {
    adminService: AdminService = new AdminService();

    constructor(props: Props) {
        super(props);


        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.state = {
            begin: begin,
            end: end,
            findText: "",
            searchText: "",
            selectItem: {},

            options: [{ label: "입출금/통계", value: "balance" }, { label: "총판/입출", value: "agentGive" }],

            options_type: "balance",

            select: [{ label: "선택", value: null }],

            agentInfo: [],
            totalBalance: {},

        };


    }

    handlegetSubAgentCalculate(begin: any, end: any) {
        this.adminService.admin_get_sub_agent(this.props.agentId, begin, end).then((data) => {
            if (data.status == "success") {

                let totalBalance = {}
                for (let balances of Object.values(data.agentCombine) as any) {
                    for (let key of Object.keys(balances as [])) {

                        if (typeof (balances[key]) !== 'number') {
                            continue;
                        }

                        if (totalBalance[key] == null) {
                            totalBalance[key] = 0
                        }
                        totalBalance[key] += Number(balances[key] as Number)



                    }

                    if (totalBalance['total'] == null) {
                        totalBalance['total'] = 0
                    }

                    totalBalance['total'] += (((balances['totalSlotBet'] - balances['totalSlotWin']) / 100) * balances['benefitLosing']) - ((balances['benefitSlotRolling'] / 100) * balances['totalSlotBet'])


                    if (totalBalance['winlose'] == null) {
                        totalBalance['winlose'] = 0
                    }

                    if (totalBalance['totalSlotRolling'] == null) {
                        totalBalance['totalSlotRolling'] = 0
                    }
                    totalBalance['totalSlotRolling'] += ((totalBalance['totalSlotBet'] / 100) * totalBalance['benefitSlotRolling'])


                    console.log(((totalBalance['totalSlotBet'] / 100) * totalBalance['benefitSlotRolling']))


                }

                if (totalBalance['winlose'] == null) {
                    totalBalance['winlose'] = 0
                }

                totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']


                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })

                this.setState({ agentInfo: combin, totalBalance: totalBalance });
                // this.setState({ agentInfo: data.agentCombine });
            }


        });
    }

    componentDidMount() {

        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end


        this.handlegetSubAgentCalculate(begin, end);
    }

    render() {

        const rowClass = (data) => {

            if (data.level === 1) return { 'row-level1': data.level === 1 }
            if (data.level === 2) return { 'row-level2': data.level === 2 }
            if (data.level === 3) return { 'row-level3': data.level === 3 }


            return { 'row-level4': data.level === 4 }
        }

        let headerGroup = (
            <ColumnGroup>
                {/* <Row>
                    <Column header="Product" rowSpan={3} />
                    <Column header="Sale Rate" colSpan={4} />
                </Row
                > */}

                <Row>
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 입금</div><div>총판 지급</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 출금</div><div>총판 회수</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>유저 수익</div><div>총판 수익</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>Bet</div><div>Win</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>총회원 루징</div>} field="thisYearSale" />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>롤링케쉬전환</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div><div>정산금</div><div>루징프로테이지%</div></div>} />
                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>롤링</div>} />

                    <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header={<div>
                        <div>파트너충전 </div>
                        <div>파트너환전 </div></div>} />
                </Row>
                {
                    this.state.totalBalance && this.state.totalBalance['idx'] !== null && (

                        <Row >
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }} header="" />
                            <Column style={{ textAlign: "center", borderRight: '1px solid #d8d8d8', backgroundColor: '#8bc34a57' }} header={<div>
                                <div style={{ color: 'red' }}> {numTomoeny(this.state.totalBalance['realAgentChangeBalance'])}</div>
                                <div style={{ color: 'blue' }}> {numTomoeny(this.state.totalBalance['realAgentExchangeBalance'])}</div></div>} />


                        </Row>
                    )

                }

            </ColumnGroup >
        );

        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <div className="card-title">부본사 {this.props.agentId}</div>

                                <div className="p-col-12 ">
                                    <div className="p-grid form-group">

                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.begin}
                                                onChange={(e) => this.setState({ begin: e.value })}
                                                showWeek={false}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Calendar
                                                placeholder="Begin"
                                                showIcon={true}
                                                dateFormat="yy-mm-dd"
                                                view="date"
                                                value={this.state.end}
                                                onChange={(e) => this.setState({ end: e.value })}
                                                showWeek={false}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            <Button
                                                label="검색"
                                                onClick={() => {
                                                    this.handlegetSubAgentCalculate(this.state.begin, this.state.end);

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">

                                <DataTable
                                    value={this.state.agentInfo}
                                    paginatorPosition="both"
                                    rows={99999}
                                    sortOrder={-1}
                                    // rowClassName={rowClass}
                                    headerColumnGroup={headerGroup}
                                    scrollHeight="600px"

                                    scrollable
                                    style={{ width: isMobile ? '1600px' : '100%' }}

                                // headerColumnGroup={headerGroup}
                                >
                                    <Column
                                        style={{
                                            textAlign: "center", borderRight: '1px solid #d8d8d8'
                                        }}
                                        header="부본사"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 1) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                                    </a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;


                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="총판"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 2) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="매장"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 3) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        header="매장?"
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 4) return (
                                                <>
                                                    <a onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}
                                                        <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i></a>
                                                    <br />
                                                    <a onClick={() => OpenAgentCal(rowData.agent)}>{rowData.nick}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: 'green' }}></i>
                                                    </a>
                                                </>
                                            )
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                            </div>;
                                        }}
                                    />


                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>}
                                        body={(rowData: any, column: any) => {

                                            return <div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                                <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                            </div>;
                                        }}
                                    />



                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>Bet</div><div>Win</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                                            </div>;

                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>총회원 루징</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalSlotBet - rowData.totalSlotWin)}</div>
                                            </div>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤롱케쉬전환</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.exchangeBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div>
                                            <div>정산금</div>
                                            <div>루징프로테이지%</div>
                                        </div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin - rowData.exchangeBalance > 0 ? 'red' : 'blue' }}>
                                                    {numTomoeny((((rowData.totalSlotBet - rowData.totalSlotWin) * rowData.benefitLosing) / 100) - ((rowData.totalSlotBet) / 100) * rowData.benefitSlotRolling)}</div>
                                                <div>{rowData.benefitLosing}%</div>
                                            </div>;
                                        }}
                                    />
                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>롤링</div><div>프로테이지</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(((rowData.totalSlotBet)) * rowData.benefitSlotRolling / 100)}</div>
                                                <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                                            </div>;
                                        }}
                                    />

                                    <Column
                                        style={{ textAlign: "center", borderRight: '1px solid #d8d8d8' }}
                                        bodyStyle={{ textAlign: "right", borderRight: '1px solid #d8d8d8' }}
                                        header={<div><div>파트너 충전</div><div>파트너 환전</div></div>}
                                        body={(rowData: any, column: any) => {
                                            return <div>
                                                <div style={{ color: 'red' }}>{numTomoeny(rowData.realAgentChangeBalance)}</div>
                                                <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                            </div>;
                                        }}
                                    />
                                </DataTable>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
