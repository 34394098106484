import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import cookie from "react-cookies";

import { UserService } from "../service/user.service";
import { AxiosMag } from "../service/axios.service";

interface State {
  id: string;
  pw: string;
}
export default class Login extends Component<any, State> {
  userService = new UserService();
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      pw: ""
    };
  }

  render() {
    // const [cookies, setCookie] = useCookies(["session"]);
    // this.state = { userId: cookie.load("userId") };

    const DoLogin = () => {
      console.log(this.state.id, this.state.pw);
      this.userService
        .do_login_manager(this.state.id, this.state.pw)
        .then(data => {
          if (data.status == "success") {
            cookie.save("session", data.session, { path: "/" });

            //setCookie("session", data.session, { path: "/" });

            AxiosMag.SetSession(data.session);
            window.location.hash = "/dashboard";
            // this.setState({ user: data.user });
          } else {
            // window.location.reload();
          }
        });
    };

    return (
      <div className="login-body">
        <div className="login-panel ui-fluid">
          <div className="login-panel-header">
            <img
              src="assets/layout/images/login/logo-sapphire-color.png"
              alt="sapphire"
            />
          </div>
          <div className="login-panel-content">
            <div className="p-grid">

              <div className="p-col-12">

                <h1>{
                  process.env.REACT_APP_API_NAME
                } NETWORK</h1>
                <h2>Welcome, please use the form to sign-in</h2>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    style={{ width: "100%" }}
                    value={this.state.id}
                    id="id"
                    name="id"
                    onChange={(event: any) => {
                      this.setState({
                        id: event.target.value
                      });
                    }}
                  />
                  <label>Username</label>
                </span>
              </div>
              <div className="p-col-12">
                <span className="md-inputfield">
                  <InputText
                    type="password" name="passwd"
                    style={{ width: "100%" }}
                    value={this.state.pw}
                    onChange={(event: any) => {

                      this.setState({
                        pw: event.target.value
                      });
                    }}

                    onKeyPress={(event) => {

                      console.log(event.key)

                      if (event.key === 'Enter') {
                        DoLogin();
                      }

                    }}
                  />
                  <label>Password</label>
                </span>
              </div>
              <div className="p-col-6">
                <button className="p-link">Forget Password?</button>
              </div>
              <div className="p-col-6" style={{ textAlign: "right" }}>
                <Button
                  label="로그인"
                  onClick={() => {
                    DoLogin();
                  }}
                  style={{ width: "100%" }}
                />
              </div>

            </div>

          </div>
          {/* </form> */}

        </div>
      </div >
    );
  }
}
