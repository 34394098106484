import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmAlert } from "react-confirm-alert";
import { Editor } from "primereact/editor";

import { AdminService } from "../service/admin.service";
import { BetService } from "../service/bet.service";

import { BetMatchTable } from "../share/bet/bet-match-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface State {
  banks: any[];
  maxLevel: number;
}

export class SetManagerAccount extends Component<any, State> {
  adminService: AdminService;
  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      banks: [],
      maxLevel: 8
    };
    //C:\WORK\eBetting\admin\src\main\setmanageraccount.tsx
    this.adminService = new AdminService();
  }

  componentDidMount() {
    this.handleGetAccount();
  }

  handleSaveBank = () => {
    this.adminService.admin_save_bank_account(this.state.banks).then(data => {
      if (data.status == "success") {
        confirmAlert({
          title: "레벨",
          message: "레벨정보가 업데이트 되어습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              }
            }
          ]
        });
        // this.setState({ benefits: data.benefits });
      }
    });
  };

  handleGetAccount = () => {
    this.adminService.admin_get_make_bank_account_level().then(data => {
      if (data.status == "success") {
        console.log(data.banks);

        this.setState({ banks: data.banks });
      }
    });
  };

  handleMakeUserLevel = () => {
    this.adminService
      .admin_make_bank_account_level(this.state.maxLevel)
      .then(data => {
        if (data.status == "success") {
          confirmAlert({
            title: "레벨",
            message: "레벨정보가 생성되었습니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                }
              }
            ]
          });
        } else {
          confirmAlert({
            title: "레벨",
            message: "관리자에게 문의 부탁드림니다.",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  window.location.reload();
                }
              }
            ]
          });
        }
      });
  };

  render() {
    const handelChange = (idx: number, name: string, value: any) => {
      let ben = this.state.banks.find(s => s.idx == idx);
      if (ben != null) {
        ben[name] = value;
        this.setState({ banks: this.state.banks });
      }
    };
    const RenderPanel = () => {
      // let banks: any[] = [];
      // let levelIndexs: any[] = [];
      // for (let i = 1; i < this.state.maxLevel; i++) {
      //   levelIndexs.push(i);
      //   if (this.state.banks != null) {
      //     let results = this.state.banks.filter(x => x.level == i);

      //     banks.push(results);
      //   }
      // }
      let banks = this.state.banks;
      return banks.map((item, i) => {
        return (
          <TabPanel
            header={item.level + "레벨"}
            leftIcon="pi-md-check"
            key={"level" + item.level}
          >
            <div className="p-col-12">
              <span className="p-float-label">
                <InputText
                  id="in"
                  style={{
                    width: "70%"
                  }}
                  value={item.title}
                  onChange={(e: any) => {
                    // items.title = e.target.value;
                    // this.setState({ banks: this.state.banks });
                    handelChange(item.idx, "title", e.target.value);
                  }}
                />
                <label htmlFor="in">Title</label>
              </span>
              {/* 
                  <ColorPicker
                    onChange={(event: any) => {
                      console.log(event.value);
                      this.state.notice.title_color = event.value;
                      this.setState({ notice: this.state.notice });
                    }}
                  ></ColorPicker> */}
            </div>
            <div className="p-col-12">
              <Editor
                style={{ height: "320px" }}
                value={item.text}
                onTextChange={e => {
                  // items.text = e.htmlValue;
                  handelChange(item.idx, "text", e.htmlValue);
                  // this.setState({ banks: this.state.banks });
                }}
              />
            </div>
            {/* <DataTable
              value={items}
              paginatorPosition="both"
              rows={20}
              sortOrder={-1}
              scrollable={true}
              style={{ width: "1600px" }}
            >
              <Column
                style={{ textAlign: "center" }}
                header="Level"
                body={(rowData: any, column: any) => {
                  return rowData.level;
                }}
              />

              <Column
                style={{ textAlign: "center" }}
                header="은행명"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.bank_name}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "bank_name",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center" }}
                header="통장번호"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.bank_number}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "bank_number",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />

              <Column
                style={{ textAlign: "center" }}
                header="통장명의자"
                body={(rowData: any, column: any) => {
                  return (
                    <InputText
                      value={rowData.bank_owner}
                      onChange={(even: any) => {
                        handelChange(
                          rowData.idx,
                          "bank_owner",
                          even.target.value
                        );
                      }}
                    ></InputText>
                  );
                }}
              />
            </DataTable> */}
          </TabPanel>
        );
      });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="card-title">레벨 통장 설정</div>

                  <div className="p-md-6">
                    <Button
                      label="저장 하기"
                      onClick={() => {
                        this.handleSaveBank();
                      }}
                    />

                    <Button
                      label="레벨 생성"
                      onClick={() => {
                        this.handleMakeUserLevel();
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card">
                {/* {RenderBet()} */}

                <TabView>{RenderPanel()}</TabView>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
