import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { confirmAlert } from "react-confirm-alert";

//import Pagination from "react-paginate";
// import ReactPaginate from 'react-paginate';

import {
  numTomoeny,
  converBetStatus,
  convertIP,
  convertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  convertSpecifiers,
  OpenUserDetail,
  ConverBTC,
  converBalanceStatus,
} from "../../utility/help";

import { FxService } from "../../service/fx.service";
import { AnyTxtRecord } from "dns";

interface FXProps {
  mode: any;
  //   details: any;
  bet: any;
  //   key: any;

  // details: any;
}

export class BetFxTable extends Component<FXProps> {
  fxService = new FxService();
  constructor(props: FXProps) {
    super(props);
    this.state = {
      active: 0,
      deposits: [],
      details: [],

      page: 0,
      field: "",
    };
  }

  componentDidMount = () => { };

  handleCancel = (e: any) => {
    this.fxService.admin_do_bet_cancel(e).then((data: any) => {
      if (data.status == "success") {
        confirmAlert({
          title: "확인",
          message: "게임이 취소되어습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else if (data.status == "params") {
        confirmAlert({
          title: "확인",
          message: "이미 완료 또는 취소가된 게임입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "확인",
          message: "관리자에게 문의 부탁드림니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };


  handleEdit = (e: any) => {
    this.fxService.editBet(e).then((data: any) => {
      if (data.status == "success") {
        confirmAlert({
          title: "확인",
          message: "정상적으로 변경되습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else if (data.status == "already") {
        confirmAlert({
          title: "확인",
          message: "종료된 게임입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else if (data.status == "dont_have_moeny") {
        confirmAlert({
          title: "확인",
          message: "돈이 부족합니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "확인",
          message: "관리자에게 문의 부탁드림니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  // header = (up, down) => {
  //   return (
  //     <div>
  //       <span>{up}</span>
  //       <br />
  //       <span>{down}</span>
  //       <br />
  //     </div>
  //   );
  // };

  handlePageChange = (e: any) => {
    console.log(e);
    this.setState({ active: e });
  };

  render = () => {
    const mode = this.props.mode;
    const bets = this.props.bet == undefined ? [] : this.props.bet;
    // const details = this.props.details == undefined ? [] : this.props.details;
    // const tdetails = Object.values(details);

    let customHeaderStyle = { backgroundColor: "#4caf50", textAlign: "center" };
    /*if (bets.balance >= 300000) {
      //customHeaderStyle["backgroundColor"] = "oringe";
      customHeaderStyle = { backgroundColor: "red", textAlign: "center" };
    }*/

    return (
      <div>
        <DataTable
          value={bets}
          paginatorPosition="both"
          rows={20}
          sortOrder={-1}
          scrollable={true}
        >
          <Column
            style={{ textAlign: "center", width: "180px" }}
            // style={{ textAlign: "center", width: "10%" }}
            header="시작/종료"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {convertDate(rowData.beginDate)}
                  <br />
                  {convertDate(rowData.endDate)}
                </div>
              );
            }}
          />

          <Column
            // style={{ textAlign: "center", width: "120px" }}
            style={{ textAlign: "center", width: "180px" }}
            header="배팅시간"
            body={(rowData: any, column: any) => {
              return <div>{rowData.regDate}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="아이디"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  <div
                    onClick={() => {
                      OpenUserDetail(rowData.id);
                    }}
                    style={{ color: rowData.color }}
                  >
                    {rowData.id}
                    <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                  </div>
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="배팅금/ 이긴금액"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {numTomoeny(rowData.betBalance)}
                  <br />
                  {numTomoeny(rowData.winBalance)}
                </div>
              );
            }}
          />
          {/* 
          <Column
            style={{ textAlign: "center" }}
            header="이긴금액"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.winBalance)}</div>;
            }}
          />
 */}

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="currency/게임분"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {ConverBTC(rowData.currency)} ({rowData.betMinute})
                </div>
              );
            }}
          />
          {/* 
          <Column
            style={{ textAlign: "center" }}
            header="게임분"
            body={(rowData: any, column: any) => {
              return <div>{rowData.betMinute}</div>;
            }}
          /> */}

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="win/lose"
            body={(rowData: any, column: any) => {
              return <div>{numTomoeny(rowData.winlose)}</div>;
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            header="배팅타입"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {rowData.betType}
                  <br />
                  {rowData.resultType}
                </div>
              );
            }}
          />

          {/* <Column
            style={{ textAlign: "center" }}
            header="결과타입"
            body={(rowData: any, column: any) => {
              return <div>{rowData.resultType}</div>;
            }}
          /> */}

          <Column
            style={{ textAlign: "center", width: "100px" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="open/close"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  {rowData.open} <br />
                  {rowData.close}
                </div>
              );
            }}
          />
          {/* 
          <Column
            style={{ textAlign: "center" }}
            header="close"
            body={(rowData: any, column: any) => {
              return <div>{rowData.close}</div>;
            }}
          /> */}

          {/* <Column
            style={{ textAlign: "center" }}
            header="상태"
            body={(rowData: any, column: any) => {
              return <div>{rowData.status}</div>;
            }}
            
          /> */}
          <Column
            style={{ textAlign: "center", width: "100px" }}
            // style={{ textAlign: "center", width: "120px" }}
            header="X10/X0.1"
            body={(rowData: any, column: any) => {
              return (
                <div>
                  <Button
                    label="X10"
                    className="p-button-secondary"
                    onClick={() => {
                      this.handleEdit({ id: rowData._id, mode: 'up' });
                    }}
                  /> <br />
                  <Button
                    label="X01"
                    className="p-button-warning"
                    onClick={() => {
                      this.handleEdit({ id: rowData._id, mode: 'down' });
                    }}
                  />
                </div>
              );
            }}
          />

          <Column
            style={{ textAlign: "center", width: "100px" }}
            // style={{ textAlign: "center", width: "150px" }}
            header="결과"
            body={(rowData: any, column: any) => {
              if (rowData.betStaus === "reg") {
                return (
                  <div>
                    {converBetStatus(rowData.betStaus)}
                    <Button
                      label="취소"
                      className="p-button-warning"
                      onClick={() => {
                        this.handleCancel(rowData._id);
                      }}
                    />
                  </div>
                );
              }
              return <div>{converBetStatus(rowData.betStaus)}</div>;
            }}
          />
        </DataTable>
      </div>
    );
  };
}
