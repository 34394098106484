import React, { Component, useRef } from "react";
//import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Message } from "primereact/message";
import { SelectButton } from "primereact/selectbutton";
import { Growl } from "primereact/growl";


import { BalanceService } from "../service/balance.service";
import { FileService } from "../service/file.service";

import { BalanceTable } from "../share/blaance/balance-table";
import { AlertYesNo } from "../share/alert-yes-no";
import { CustomPagination } from "../share/custom-pagination";

import {
    numTomoeny,
    convertIP,
    convertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    converBalanceStatus,
    GetToday,
    GetNow,
    convertSimpleDate,
    convertToCSV,
    OpenUserDetail,
    ConvertNick,
    OpenAgentCal,
    OpenAgentDetail,
    ConvertCalendar
} from "../utility/help";

interface WithdrawsState {
    balances: any;
    withdraws: any;
    activePage: any;
    field: any;
    begin: any;
    end: any;
    total: any;
    maxCount: number;
    searchText: any;
    findText: any;
    dropdownFind: any;
    cities: any;
    selectViewType: any;
    viewType: any;
    balanceType: any
    selectBalanceType: any

}

export class DeporsitAndWithdrawTotal extends Component<any, WithdrawsState> {
    balanceService: BalanceService = new BalanceService();
    fileService: FileService = new FileService();

    menu: any;


    growl: any;


    constructor(props: any) {
        super(props);


        let today = GetToday();

        this.state = {
            maxCount: 1,
            activePage: 1,
            balances: 0,
            withdraws: [],
            field: "",
            begin: today.begin,
            end: today.end,
            total: null,

            searchText: "",
            findText: "",
            dropdownFind: "tree",
            selectViewType: "all",
            selectBalanceType: "all",

            viewType: [
                { label: "모두 보기", value: "all" },
                { label: "충전", value: "deposit" },
                { label: "환전", value: "withdraw" },
                { label: "보너스", value: "bonus" },
            ],
            balanceType: [
                { label: "모두 보기", value: "all" },
                { label: "유저", value: "user" },
                { label: "에이전트", value: "agent" },
                { label: "센터", value: "center" },
                { label: "취소", value: "cancel" },
            ],
            cities: [
                { label: "선택", value: null },
                { label: "아이디", value: "id" },
                { label: "이름", value: "bankowner" },
                { label: "에이전트", value: "agent" },
                { label: "에이전트 트리", value: "tree" },
            ],
        };


        this.get_deposit_and_withdraw(0, "all", this.state.selectBalanceType);

    }

    componentDidMount() {
    }

    handlePageChange = (page) => {
        this.setState({ activePage: page });
        this.get_deposit_and_withdraw(page, this.state.selectViewType, this.state.selectBalanceType);
    };
    // active={activePage}
    // take={20}
    // count={this.state.maxCount}
    // handlePageChange={this.handlePageChange}


    exportSave = async () => {

        let begin = this.state.begin;
        let end = this.state.end;
        let searchText = this.state.searchText;
        let findText = this.state.dropdownFind;

        this.fileService
            .get_wait_deposit(
                convertSimpleDate(begin),
                convertSimpleDate(end),
                searchText,
                findText,
                this.state.selectViewType
            )
            .then((data: any) => {

                for (let balance of Object.values(data.balances) as any) {
                    delete balance.idx
                    delete balance.master
                    delete balance.del
                    delete balance.color
                }

                let csv = convertToCSV(data.balances)
                csv = '아이디,총판,이름,계좌,은행,트리,금액,,상태,타입,,보너스,금일환전,금일충전,신청시간,완료시간 \r\n' + csv

                const link = window.document.createElement('a');
                link.charset = 'euc-kr';
                link.setAttribute('href', 'data:text/csv; charset=euc-kr,' + encodeURIComponent(csv));

                link.setAttribute('download', `${convertDate(GetNow())}.csv`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();

            });
    };



    exportPoint = async (type: string) => {

        let begin = this.state.begin;
        let end = this.state.end;
        let searchText = this.state.searchText;
        let findText = this.state.dropdownFind;

        this.fileService
            .exportBalancePoint(
                convertSimpleDate(begin),
                convertSimpleDate(end),
                searchText,
                findText,
                this.state.selectViewType,
                type
            )
            .then((data: any) => {

                for (let balance of Object.values(data.balances) as any) {
                    delete balance._id
                    delete balance.idx
                    delete balance.master
                    delete balance.del
                    delete balance.color
                }

                let csv = convertToCSV(data.balances)
                csv = '아이디,보너스,합,타입,타입2,총판,트리,시간 \r\n' + csv

                const link = window.document.createElement('a');
                link.charset = 'euc-kr';
                link.setAttribute('href', 'data:text/csv; charset=euc-kr,' + csv);

                link.setAttribute('download', `point_${convertDate(GetNow())}.csv`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();

            });


    };



    OnEditItem = (idx: any, name: any, value: any) => {
        if (name == "bonus" || name == "rolling") {
            return;
        }

        let withdraw = this.state.withdraws.find((s: any) => s.idx == idx);
        if (withdraw != undefined) {
            withdraw[name] = value;
            this.setState({ withdraws: this.state.withdraws });
        }
    };

    OnSelectItem = (idx: any) => {
        let element = this.state.withdraws.find((s: any) => s.idx == idx);

        element.sel = element.sel == "y" ? "n" : "y";

        this.setState({ withdraws: this.state.withdraws });
    };

    get_deposit_and_withdraw = async (page: any, viewType: any, selectBalanceType: any) => {
        let begin = this.state.begin;
        let end = this.state.end;
        let searchText = this.state.searchText;
        let findText = this.state.dropdownFind;

        let data = await this.balanceService
            .get_deposit_and_withdraw_total_balance(
                page,

                begin.toLocaleString('sv-SE'),
                end.toLocaleString('sv-SE'),

                searchText,
                findText,
                viewType,
                selectBalanceType
            );
        this.setState({
            maxCount: data.maxCount,
            balances: data.balances,
            total: data.total,
            searchText: searchText,
            findText: findText,
            // viewType: viewType
        });
    };

    send_message_server = (mode: any) => {
        let ex: any[] = [];

        for (let deposit of this.state.withdraws) {
            if (deposit.sel == "y") {
                ex.push(deposit);
            }
        }

        if (mode == "success" && ex.length > 0) {
            this.balanceService.set_withdraw_success(ex).then((s) => {
                if (s.status == "success") {
                    alert("환전이 완료되어 습니다.");
                    window.location.reload();
                } else if (s.status == "deposits") {
                    alert("선택된 아이템이 존재하지 않습니다");
                    window.location.reload();
                } else if (s.status == "already") {
                    alert("환전완료 또는환전취소 정보가 있습니다");
                    window.location.reload();
                } else {
                    alert("관리자에게 문의 부탁드림니다");
                    window.location.reload();
                }
            });
        } else if (mode == "wait" && ex.length > 0) {
            this.balanceService.set_withdraw_wait(ex).then((s) => {
                if (s.status == "success") {
                    alert("환전 대기상태로 변경되었습니다 .");
                    window.location.reload();
                } else if (s.status == "deposits") {
                    alert("선택된 아이템이 존재하지 않습니다");
                    window.location.reload();
                } else if (s.status == "already") {
                    alert("환전완료 또는환전취소 정보가 있습니다");
                    window.location.reload();
                } else {
                    alert("관리자에게 문의 부탁드림니다");
                    window.location.reload();
                }
            });
        } else if (mode == "cancel" && ex.length > 0) {
            this.balanceService.set_withdraw_cancel(ex).then((s) => {
                if (s.status == "success") {
                    alert("환전을 취소 하였습니다.");
                    window.location.reload();
                } else if (s.status == "deposits") {
                    alert("선택된 아이템이 존재하지 않습니다");
                    window.location.reload();
                } else if (s.status == "already") {
                    alert("환전완료 또는환전취소 정보가 있습니다");
                    window.location.reload();
                } else {
                    alert("관리자에게 문의 부탁드림니다");
                    window.location.reload();
                }
            });
        }
    };

    render() {

        let total = this.state.total ? this.state.total[0] : { withdrawCount: 0, withdraw: 0, deposit: 0, depositCount: 0 }


        return (
            <div>
                {/* <Top></Top> */}
                <div className="content">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div>
                                <div className="card card-w-title" style={{ padding: "0px" }}>
                                    <div className="p-col-12 ">
                                        <div className="p-md-11">
                                            <div className="card-title">입급/출금 완료</div>

                                            <div className="p-grid form-group">
                                                <div className="p-col-12 p-md-3">
                                                    <Calendar
                                                        showTime
                                                        hourFormat="24"
                                                        placeholder="Begin"
                                                        showIcon={true}
                                                        dateFormat="yy-mm-dd"
                                                        value={this.state.begin}
                                                        onChange={(e) => this.setState({ begin: e.value })}
                                                        showWeek={false}
                                                        locale={ConvertCalendar()}
                                                    />
                                                    <Calendar
                                                        showTime
                                                        hourFormat="24"

                                                        placeholder="End"
                                                        showIcon={true}
                                                        dateFormat="yy-mm-dd"
                                                        value={this.state.end}
                                                        onChange={(e) => this.setState({ end: e.value })}
                                                        showWeek={false}
                                                        locale={ConvertCalendar()}
                                                    />
                                                </div>
                                                <div className="p-col-12 p-md-3">
                                                    <div className="p-grid form-group">

                                                        <div style={{ paddingLeft: 20, paddingTop: 7 }}>
                                                            <Dropdown
                                                                style={{ width: 140 }}

                                                                options={this.state.cities}
                                                                value={this.state.dropdownFind}
                                                                onChange={(event) =>
                                                                    this.setState({ dropdownFind: event.value })
                                                                }
                                                                autoWidth={true}
                                                            />
                                                        </div>

                                                        <div className="p-inputgroup" style={{ paddingLeft: 20 }}>
                                                            <InputText
                                                                style={{ width: 100 }}
                                                                placeholder="검색"
                                                                onChange={(event: any) => {
                                                                    this.setState({
                                                                        searchText: event.target.value,
                                                                    });
                                                                }}
                                                            />
                                                            <Button
                                                                icon="pi pi-search"
                                                                className="p-button-warning"
                                                                onClick={() => {
                                                                    this.get_deposit_and_withdraw(
                                                                        0,
                                                                        this.state.selectViewType,
                                                                        this.state.selectBalanceType,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-col-12 p-md-6">
                                                    <div className="p-grid form-group">
                                                        <span className="p-float-label">
                                                            <InputText id="inputtext" value={numTomoeny(total.deposit)} readOnly />
                                                            <label htmlFor="inputtext" style={{ color: 'red' }}>충전 {total.depositCount}</label>
                                                        </span>
                                                        <span className="p-float-label">
                                                            <InputText id="inputtext" value={numTomoeny(total.withdraw)} readOnly />
                                                            <label htmlFor="inputtext" style={{ color: 'blue' }}>환전 {total.withdrawCount}</label>
                                                        </span>
                                                        <span className="p-float-label">
                                                            <InputText id="inputtext" value={numTomoeny(total.deposit - total.withdraw)} readOnly />
                                                            <label htmlFor="inputtext">충전-환전</label>
                                                        </span>
                                                    </div>

                                                </div>

                                            </div>


                                            <Menu
                                                model={[
                                                    {
                                                        label: "충환전 저장",
                                                        icon: "pi pi-fw pi-file",

                                                        command: () => {
                                                            this.exportSave();
                                                        },
                                                    },
                                                    {
                                                        label: "보너스",
                                                        icon: "pi pi-fw pi-save",
                                                        items: [
                                                            {
                                                                label: "첫충 저장",
                                                                icon: "pi pi-save",
                                                                command: () => {
                                                                    this.exportPoint('first');
                                                                },
                                                            },
                                                            {
                                                                label: "재충전 저장",
                                                                icon: "pi pi-save",
                                                                command: () => {
                                                                    this.exportPoint('second');
                                                                },
                                                            },
                                                            {
                                                                label: "모두 저장",
                                                                icon: "pi pi-save",
                                                                command: () => {
                                                                    this.exportPoint('all');
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                popup
                                                ref={(el) => {
                                                    if (el != null) {
                                                        this.menu = el
                                                    }
                                                }}
                                                id="popup_menu1"
                                            />


                                            <div className="p-col-12 p-md-6">
                                                <div className="p-grid form-group">
                                                    <div className="p-col-12 p-md-3">


                                                        <Button
                                                            icon="pi pi-cog"
                                                            className="p-button-rounded p-button-warning "
                                                            onClick={(event) => {
                                                                this.menu.toggle(event);
                                                            }}

                                                        />

                                                    </div>
                                                    <div className="p-col-12 p-md-3">

                                                        <SelectButton
                                                            value={this.state.selectViewType}
                                                            options={this.state.viewType}
                                                            onChange={(event) => {
                                                                this.setState({ selectViewType: event.value });
                                                                this.get_deposit_and_withdraw(0, event.value, this.state.selectBalanceType);
                                                            }}
                                                        />
                                                    </div>


                                                    <div className="p-col-12 p-md-6">

                                                        <SelectButton
                                                            value={this.state.selectBalanceType}
                                                            options={this.state.balanceType}
                                                            onChange={(event) => {
                                                                this.state.selectViewType;
                                                                this.setState({ selectBalanceType: event.value });
                                                                this.get_deposit_and_withdraw(0, this.state.selectViewType, event.value);
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 ">
                                    <CustomPagination
                                        active={this.state.activePage}
                                        take={20}
                                        count={this.state.maxCount}
                                        handlePageChange={this.handlePageChange}
                                    ></CustomPagination>

                                    <DataTable
                                        value={this.state.balances}
                                        paginatorPosition="both"
                                        rows={1000}
                                        sortOrder={-1}
                                        scrollable={true}
                                    >
                                        <Column
                                            style={{ textAlign: "center", width: "100px" }}
                                            header="종류"
                                            body={(rowData: any, column: any) => {
                                                if (rowData.logType == "deposit") {
                                                    return <div style={{ color: 'red' }} >유저 충전 </div>;
                                                }

                                                if (rowData.logType == "agentdeposit") {
                                                    return <div style={{ color: 'red' }} >에이전트 충전</div>;
                                                }

                                                if (rowData.logType == "admindeposit") {
                                                    return <div style={{ color: 'red' }} >관리자 충전 </div>;
                                                }

                                                if (rowData.logType == "withdraw") {
                                                    return <div style={{ color: 'blue' }} >유저 환전</div>;
                                                }

                                                if (rowData.logType == "agentwithdraw") {
                                                    return <div style={{ color: 'blue' }} >에이전트 환전</div>;
                                                }

                                                if (rowData.logType == "adminwithdraw") {
                                                    return <div style={{ color: 'blue' }} >관리자 환전</div>;
                                                }

                                                if (rowData.logType == "withdrawcancel") {
                                                    return <div style={{ color: 'blue' }} >환전 취소</div>;
                                                }
                                                if (rowData.logType == "depositcancel") {
                                                    return <div style={{ color: 'blue' }} >충전 취소</div>;
                                                }
                                                if (rowData.logType == "bonusfirst" || rowData.logType == "bonussecond") {
                                                    return <div style={{ color: 'blue' }} >보너스</div>;
                                                }


                                                return "미정의";
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", width: "120px" }}
                                            header="아이디"
                                            field="id"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div
                                                        style={{ color: rowData.color }}
                                                        onClick={() => {
                                                            OpenUserDetail(rowData.id);
                                                        }}
                                                    >
                                                        {rowData.id}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                                                    </div>
                                                );
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", width: "120px" }}
                                            header="이름"
                                            field="id"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div style={{ color: rowData.color }}>{rowData.owner}</div>
                                                );
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", width: "120px" }}
                                            header="닉네임"
                                            field="id"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div style={{ color: rowData.color }}>
                                                        {rowData.nick}
                                                    </div>
                                                );
                                            }}
                                        />


                                        <Column
                                            style={{ textAlign: "center", width: "120px" }}
                                            header="에이전트"
                                            field="id"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div
                                                        style={{ color: rowData.color }}
                                                        onClick={() => {
                                                            OpenAgentDetail(rowData.agent);
                                                        }}
                                                    >
                                                        {rowData.agent}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                                                    </div>
                                                );
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", width: "100px" }}
                                            header="입금/출금"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div style={{ color: rowData.balance > 0 ? getDepositColor() : getWithdrawColor() }}>
                                                        {numTomoeny(rowData.balance)}
                                                    </div>
                                                );
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center", width: "140px" }}
                                            header={"완료일"}
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div>
                                                        <span>{convertDate(rowData.regdate)}</span>
                                                    </div>
                                                );
                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center", width: "300px" }}
                                            header={"소속"}
                                            bodyStyle={{ textAlign: "left", width: "300px" }}
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div>
                                                        <span dangerouslySetInnerHTML={{ __html: ConvertNick(rowData.agent_tree, rowData.agent_tree_ko) }} ></span>
                                                    </div>
                                                );
                                            }}
                                        />

                                    </DataTable>


                                    <CustomPagination
                                        active={this.state.activePage}
                                        take={20}
                                        count={this.state.maxCount}
                                        handlePageChange={this.handlePageChange}
                                    ></CustomPagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
