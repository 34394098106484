import React, { Component } from "react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { OrganizationChart } from "primereact/organizationchart";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import { Growl } from "primereact/growl";
import { confirmAlert } from "react-confirm-alert";

import { FxService } from "../service/fx.service";

import { FxTable } from "../share/fx/fx-table";
import { CustomPagination } from "../share/custom-pagination";

import { numTomoeny, GetToday } from "../utility/help";

interface Props { }

interface State {
  page: number;
  maxCount: number;
  userTableValue: any[];

  eventType: any[];
  selectCurrency: string;

  minuteType: any[];
  selectMinute: string;

  cities: any[];

  selectViewType: string;
  viewType: any[];

  selectSortType: string;
  sortType: any[];

  summary: any;
}

export class EndFx extends Component<any, State> {
  fxService = new FxService();
  growl: any;

  constructor(props: any) {
    super(props);
    let today = GetToday();

    this.state = {
      page: 1,
      maxCount: 0,
      userTableValue: [],
      eventType: [
        { label: "전체보기", value: "all" },
        { label: "GBP/AUD", value: "gbpaud" },
        { label: "EUR/JPY", value: "eurjpy" },
        { label: "EUR/AUD", value: "euraud" },
        { label: "BTC/USD", value: "btcusd" },
      ],
      selectCurrency: "all",

      minuteType: [
        { label: "전체보기", value: "all" },
        { label: "1분", value: "1" },
        { label: "2분", value: "2" },
        { label: "3분", value: "3" },
        { label: "5분", value: "5" },
      ],
      selectMinute: "all",

      cities: [
        { label: "선택", value: null },
        { label: "종목", value: "sportName" },
        { label: "팀명", value: "team" },
        { label: "리그명", value: "tournamentName" },
      ],

      selectViewType: "all",

      viewType: [
        { label: "모두 보기", value: "all" },
        { label: "배팅중", value: "bet" },
      ],

      sortType: [
        { label: "시간", value: "endDate" },
        { label: "배팅금액", value: "betBalance" },
      ],
      selectSortType: "endDate",

      summary: {},
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    // this.handleOpenDetail = this.handleOpenDetail.bind(this);
  }

  componentDidMount() {
    this.get_admin_get_fx_matchs(
      1,
      this.state.selectCurrency,
      this.state.selectMinute,

      this.state.selectViewType,
      this.state.selectSortType
    );
  }

  get_admin_get_fx_matchs = (
    page: number,
    selectEventType: string,
    selectMinute: string,
    selectViewType: string,
    selectSortType: string
  ) => {
    this.setState({ page: page });

    this.fxService
      .admin_get_fx_matchs_end(
        page,
        selectEventType,
        selectMinute,
        selectViewType,
        selectSortType
      )
      .then((data) => {
        this.setState({
          userTableValue: data.fx,
          maxCount: data.maxCount,
          //     summary: data.summary,
        });
      });
  };

  get_match_details = (matchId: any) => {
    // this.betService.get_match_details(matchId).then((data) => {
    //   if (data.pre != undefined) {
    //     let matchID = data.pre._id;
    //     this.state.details[matchID] = data.pre;
    //     this.setState({
    //       details: this.state.details,
    //     });
    //   }
    // });
  };

  orderListTemplate(car: any) {
    if (!car) {
      return;
    }

    return (
      <div className="p-clearfix">
        <img
          src={`assets/demo/images/car/${car.brand}.gif`}
          alt={car.brand}
          style={{
            display: "inline-block",
            margin: "2px 0 2px 2px",
            width: "50px",
          }}
        />
        <div style={{ fontSize: 14, float: "right", margin: "15px 5px 0 0" }}>
          {car.year} - {car.color}
        </div>
      </div>
    );
  }

  handlePageChange = (page: any) => {
    this.get_admin_get_fx_matchs(
      page,
      this.state.selectCurrency,
      this.state.selectMinute,
      this.state.selectViewType,
      this.state.selectSortType
    );
    // this.get_progress_match_game(
    //   page,
    //   this.props.selectViewType,
    //   this.state.selectSortType
    // );
  };

  render() {
    let activePage = this.state.page == undefined ? 1 : this.state.page;
    let userTableValue =
      this.state.userTableValue == undefined ? [] : this.state.userTableValue;
    const RenderBet = () => {
      // if (userTableValue.length == 0) {
      //   return <BetMatchTable></BetMatchTable>;
      // }

      //   return userTableValue.map((contact: any, i: any) => {
      return (
        <FxTable
          mode={"end"}
          game={userTableValue}
          // handleUpdateOdds={this.handleUpdateOdds}
          //handleOpenDetail={this.handleOpenDetail}
          //details={this.state.details[contact._id]}
          handleSelectWinGame={() => { }}
        ></FxTable>
      );
      //   });
    };

    return (
      <div>
        {/* <Top></Top> */}
        <div className="content">
          <div className="p-grid">
            <div className="p-col-12">
              <div>
                <div className="card card-w-title">
                  <div className="p-col-12  p-md-12">
                    <div className="card-title">종료된 FX 게임</div>

                    <div className="p-grid form-group">
                      <div className="p-col-12 p-md-5">
                        <SelectButton
                          value={this.state.selectCurrency}
                          options={this.state.eventType}
                          onChange={(event) => {
                            this.setState({ selectCurrency: event.value });
                            this.get_admin_get_fx_matchs(
                              1,
                              event.value,
                              this.state.selectMinute,

                              this.state.selectViewType,
                              this.state.selectSortType
                            );
                          }}
                        />
                        <SelectButton
                          value={this.state.selectMinute}
                          options={this.state.minuteType}
                          onChange={(event) => {
                            this.setState({ selectMinute: event.value });
                            this.get_admin_get_fx_matchs(
                              1,
                              this.state.selectCurrency,

                              event.value,
                              this.state.selectViewType,
                              this.state.selectSortType
                            );
                          }}
                        />
                      </div>

                      <div className="p-col-12 p-md-2">
                        <SelectButton
                          value={this.state.selectViewType}
                          options={this.state.viewType}
                          onChange={(event) => {
                            this.setState({ selectViewType: event.value });
                            this.get_admin_get_fx_matchs(
                              1,
                              this.state.selectCurrency,
                              this.state.selectMinute,

                              event.value,
                              this.state.selectSortType
                            );
                          }}
                        />
                      </div>

                      <div className="p-col-12 p-md-2">
                        <SelectButton
                          value={this.state.selectSortType}
                          options={this.state.sortType}
                          onChange={(event) => {
                            console.log(event.value);
                            if (event.value == null) {
                              return;
                            }
                            this.setState({ selectSortType: event.value });
                            this.get_admin_get_fx_matchs(
                              1,
                              this.state.selectCurrency,
                              this.state.selectMinute,

                              this.state.selectViewType,
                              event.value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Growl
                ref={(el) => (this.growl = el)}
                // ref={(el: any) => {
                //   growl = el;
                // }}
                style={{ marginTop: "75px" }}
              />

              <div className="card">
                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>

                {RenderBet()}

                <CustomPagination
                  active={activePage}
                  take={20}
                  count={this.state.maxCount}
                  handlePageChange={this.handlePageChange}
                ></CustomPagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
