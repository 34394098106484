//eBetting\admin\src\service\WORK\eBetting\admin\src\service\AxiosService.js;
import { AxiosMag } from "./axios.service";

export class UserService {
  do_login_manager = async (id: string, pw: string) => {
    return await AxiosMag.SendMessageByPost("/user/admin_do_login", {
      id: id,
      pw: pw,
    });
  };

  user_health_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/user_health_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
    });
  };

  get_user_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    mode: any
  ) => {


    let users: any = {}
    process.env.REACT_APP_API_ALL_USER === 'Y' ? (

      users = await AxiosMag.SendMessageByPost("/user/get_user_list", {
        skip: page,
        status: mode,
        sortType: type,
        sort: sort,
        find: find,
        search: search,
      })

    ) : (
      users = await AxiosMag.SendMessageByPost("/user/get_user_list", {
        skip: page,
        status: mode,
        sortType: type,
        sort: sort,
        find: find,
        search: search,
      })
    )




    return users
  };

  get_today_user_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/get_today_user_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
    });
  };

  admin_get_user_ip = async (page: any, find: any, search: any) => {
    return await AxiosMag.SendMessageByPost("/user/admin_get_member_ip", {
      skip: page,
      find: find,
      search: search,
    });
  };


  admin_user_stause_change = async (userId: string, status: string) => {
    return await AxiosMag.SendMessageByPost("/user/admin_user_stause_change", {
      userId: userId,
      status: status,
    });
  };

  get_ghost_user_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    begin: Date,
    end: Date,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/get_ghost_user_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
      begin: begin,
      end: end,
    });
  };

  get_graduation_user_list = async (
    page: any,
    find: any,
    search: any,
    type: any,
    sort: any,
    begin: Date,
    end: Date,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/user/get_graduation_user_list", {
      skip: page,
      status: mode,
      sortType: type,
      sort: sort,
      find: find,
      search: search,
      begin: begin,
      end: end,
    });
  };

  get_admin_user_detail = async (userID: any) => {
    return await AxiosMag.SendMessageByPost("/user/admin_get_user_detail", {
      userid: userID,
    });
  };

  admin_user_detail_calculate = async (userID: any, begin: any, end: any) => {
    return await AxiosMag.SendMessageByPost(
      "/user/admin_get_user_detail_calculate",
      {
        userid: userID,
        begin: begin,
        end: end,
      }
    );
  };

  save_user_detail = async (info: any) => {
    return await AxiosMag.SendMessageByPost(
      "/user/admin_update_user_detail",
      info
    );
  };

  get_dashboard = async () => {
    return await AxiosMag.SendMessageByPost(
      "/calculate/admin_get_dashboard",
      {}
    );
  };


  get_dashboard_total_banace = async () => {
    return await AxiosMag.SendMessageByPost(
      "/calculate/admin_get_dashboard_total_banace",
      {}
    );
  };

  get_user_login_log_list = async (id, page) => {
    return await AxiosMag.SendMessageByPost(
      "/user/admin_get_user_login_log_list",
      {
        id,
        skip: page,
      }
    );
  };

  get_user_balance_log_list = async (id, page, balanceType, begin, end) => {
    return await AxiosMag.SendMessageByPost(
      "/user/admin_get_user_balance_log_list",
      {
        id,
        page,
        balanceType,
        begin,
        end,
      }
    );
  };


  admin_get_block_ip = async (page: any) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_get_block_ip", {
      skip: page,
    });
  };


  admin_set_user_block_ip = async (ip: string, contents: string) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_set_user_block_ip", {
      lockIP: ip,
      contents: contents,
    });
  };


  admin_remove_user_block_ip = async (idx: string) => {
    return await AxiosMag.SendMessageByPost("/admin/admin_del_block_ip", {
      idx: idx,
    });
  };
}
